import { Box, Typography } from "@mui/material";
import Linkify from "react-linkify";

export default function ItemDescription(props) {
    const {
        itemDescription
    } = props;

    return (
        <Box>
            <Typography
                variant="body2"
                sx={{
                    maxWidth: 300,
                    minWidth: 200,
                    wordBreak: "break-word"
                }}
            >
                <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                        </a>
                    )}
                >
                    {itemDescription}
                </Linkify>
            </Typography>
        </Box>
    );
}