import {
    Box,
    Grid,
    useTheme
} from "@mui/material";

import ContentCardPreview from "./ContentCardPreview/ContentCardPreview";
import ContentCardEngagement from "./ContentCardEngagement/ContentCardEngagement";
import useIntersection from "../../../hooks/useIntersection";
import { useEffect } from "react";
import { useRef } from "react";

export default function ContentCard(props) {
    /* 
    
    This is the main way to display preview content on Slyke

    */

    const {
        isMobile,
        isEngagement,
        title,
        onClick,
        onInView,
        imageURL,
        itemType,
        isVideo,
        leftButton,
        leftButtonOnClick,
        rightButton,
        rightButtonOnClick,
        views,
        likes,
        comments
    } = props;

    const theme = useTheme();
    const ref = useRef();

    const { setupObserver } = useIntersection();

    const mobileWidth = 315;
    const desktopWidth = 450;

    useEffect(() => {
        if (onInView) {
            setupObserver(ref, onInView);
        }
    }, []);

    if (!isEngagement) {
        return (
            <Box
                ref={ref}
                sx={{
                    m: !isMobile ? 1 : 0
                }}
            >
                <ContentCardPreview
                    isMobile={isMobile}
                    title={title}
                    onClick={onClick}
                    imageURL={imageURL}
                    itemType={itemType}
                    isVideo={isVideo}
                    leftButton={leftButton}
                    leftButtonOnClick={leftButtonOnClick}
                    rightButton={rightButton}
                    rightButtonOnClick={rightButtonOnClick}
                />
            </Box>
        );
    } else if (isEngagement) {
        return (
            <Grid
                ref={ref}
                container
                sx={{
                    m: 1,
                    mx: "auto",
                    p: 2,
                    pr: 0,
                    width: isMobile ? mobileWidth : desktopWidth,
                    maxHeight: isMobile ? 300 : 600,
                    border: `1px solid ${theme.palette.grey["300"]}`,
                    borderRadius: 2
                }}
            >
                <Grid
                    item
                    md={9.5}
                    xs={9}
                >
                    <ContentCardPreview
                        isMobile={isMobile}
                        isEngagement
                        title={title}
                        onClick={onClick}
                        imageURL={imageURL}
                        itemType={itemType}
                        isVideo={isVideo}
                        leftButton={leftButton}
                        leftButtonOnClick={leftButtonOnClick}
                        rightButton={rightButton}
                        rightButtonOnClick={rightButtonOnClick}
                    />
                </Grid>
                <Grid
                    item
                    md
                    xs
                    sx={{
                        borderLeft: `1px solid ${theme.palette.grey["300"]}`,
                        maxWidth: 100
                    }}
                >
                    <ContentCardEngagement
                        views={views}
                        likes={likes}
                        comments={comments}
                        title={title}
                        isMobile={isMobile}
                    />
                </Grid>
            </Grid>
        );
    }
}