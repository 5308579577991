import {
    Grid,
    Typography,
    TextField,
    Autocomplete,
    Button,
    Link,
    Box,
    Chip,
    Checkbox,
    Tooltip,
    tooltipClasses
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    getUserId,
    getHandle,
    getDisplayName,
    getToken,
    getUsername
} from '../../store/user/userSlice'
import colors from '../../constants/colors'
import CollectionCreated from './collection-created'
import apiRoutes, { cfImageWorker } from '../../constants/api-routes'
import axios from 'axios'
import TagHandler from '../tag-handler/tag-handler'
import { FileUploader } from 'react-drag-drop-files'
import { useTheme } from '@mui/system'
import { Fragment } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import Error from '../../components/Common/Error/Error'
import LoadingModal from '../../components/Common/LoadingModal/LoadingModal'

const defaultErrors = {
    tags: false,
    name: false,
    description: false,
    curators: false
}

export default function CreateCollection(props) {
    const {
        isMobile
    } = props;

    const theme = useTheme()

    const userId = useSelector(getUserId)
    const displayName = useSelector(getDisplayName)
    const userHandle = useSelector(getHandle)
    const username = useSelector(getUsername)
    const token = useSelector(getToken)
    const loggedInUser = {
        userId,
        displayName,
        handle: userHandle,
        username
    }

    const slykeUser = {
        userId: 1,
        displayName: 'slyke',
        handle: '@slyke',
        username: 'slyke'
    }

    const textStyle = {
        fontFamily: 'Open Sans'
    }

    const [collectionData, setCollectionData] = useState({
        name: '',
        description: '',
        curators: [loggedInUser]
    })

    const [tagChoices, setTagChoices] = useState([])
    const [headCurator, setHeadCurator] = useState(null)
    const [users, setUsers] = useState([])
    const [userTyping, setUserTyping] = useState([])
    const [errors, setErrors] = useState(defaultErrors)
    const [file, setFile] = useState(null)
    const [collectionTooShort, setCollectionTooShort] = useState(false)
    const [loading, setLoading] = useState(false)

    const buttonStyle = {
        color: 'white',
        fontFamily: 'Open Sans',
        fontSize: 13,
        borderRadius: 5 / 10,
        textTransform: 'none',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: colors.darkGray,
            color: colors.lightGray
        }
    }

    const handleChange = (file) => {
        if (file.type.toLowerCase().includes('video')) {
            alert('invalid media format')
            return
        } else {
            // set preview image
            file.arrayBuffer().then(buffer => {
                const blob = new Blob([buffer])
                const url = URL.createObjectURL(blob)
                const img = document.getElementById('preview-image')
                img.src = url
                // So the Blob can be Garbage Collected
                img.onload = () => URL.revokeObjectURL(url)
            })
        }

        setFile(file)
    }

    const handleRemoveCurator = (userId) => {
        setCollectionData({ ...collectionData, curators: [...collectionData.curators.filter(curator => curator.userId !== userId)] })
    }

    const isValidCollection = (modifiedCurators) => {
        let isValid = true
        let tags = false
        let name = false
        let description = false
        let curators = false

        if (tagChoices.length < 3) {
            tags = true
            isValid = false
        }
        if (collectionData.name.length < 18) {
            name = true
            isValid = false
            setCollectionTooShort(true);
        }
        if (collectionData.name.length > 100) {
            name = true
            isValid = false
        }
        if (collectionData.description.length < 20) {
            description = true
            isValid = false
        }

        if (
            !modifiedCurators.length ||
            modifiedCurators.length > 3 ||
            !modifiedCurators.some(curator => curator.isHeadCurator)
        ) {
            curators = true
            isValid = false
        }

        setErrors({
            tags,
            name,
            description,
            curators
        })

        return isValid
    }

    useEffect(() => {
        if (userTyping.length) {
            axios.get(apiRoutes.searchUsers, {
                params: {
                    searchString: userTyping,
                    limit: 10
                }
            }).then((res) => {
                const matches = res.data.matchingUsers
                const transformedMatches = matches.map((obj) => {
                    return {
                        userId: obj.id,
                        handle: obj.handle,
                        displayName: obj.displayName,
                        username: obj.username
                    }
                })
                setUsers(transformedMatches)
            })
        }
    }, [userTyping])

    const uploadMediaToCf = async () => {
        let res = await axios.get(cfImageWorker)
        let uploadUrl = res.data.result.uploadURL

        let formData = new FormData()
        formData.append('file', file)

        return new Promise((resolve, reject) => {
            axios.post(uploadUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                resolve(res.data.result.id)
            }).catch(() => {
                reject()
            })
        })
    }

    const handleSubmit = async () => {
        let curators = []
        if (collectionData.curators.length === 1) {
            curators = [{ ...collectionData.curators[0], isHeadCurator: true }]
        } else {
            curators = collectionData.curators.map(curator => {
                return {
                    ...curator,
                    isHeadCurator: curator.userId === headCurator
                }
            })
        }

        if (isValidCollection(curators)) {
            if (file) {
                setLoading(true)

                let cfHash = await uploadMediaToCf()
                axios.post(apiRoutes.addCollection, {
                    title: collectionData.name,
                    description: collectionData.description,
                    tags: tagChoices.map((tag) => { return { tagId: tag.id } }),
                    curators,
                    defaultImage: cfHash,
                    creatorId: userId
                }, {
                    headers: {
                        'authorization': `Bearer ${token}`
                    }
                }).then(res => {
                    setLoading(false)
                    props.setActiveComponent(
                        <CollectionCreated
                            setActiveComponent={props.setActiveComponent}
                            collectionData={collectionData}
                            collectionId={res.data.collectionId}
                            isMobile={isMobile}
                        />
                    )
                })
            } else {
                setLoading(true)

                axios.post(apiRoutes.addCollection, {
                    title: collectionData.name,
                    description: collectionData.description,
                    tags: tagChoices.map((tag) => { return { tagId: tag.id } }),
                    curators,
                    creatorId: userId
                }, {
                    headers: {
                        'authorization': `Bearer ${token}`
                    }
                }).then(res => {
                    setLoading(false)
                    props.setActiveComponent(
                        <CollectionCreated
                            setActiveComponent={props.setActiveComponent}
                            collectionData={collectionData}
                            collectionId={res.data.collectionId}
                            isMobile={isMobile}
                        />
                    )
                })
            }
        }
    }

    return (
        <Grid
            container
            sx={{
                px: !isMobile ? 6 : 2,
                py: !isMobile ? 2 : 4,
                overflowY: !isMobile ? null : "scroll",
                height: !isMobile ? null : "90vh"
            }}
        >
            <Grid
                item
                xs={12}
                sx={{
                    textAlign: 'center'
                }}
            >
                <Typography
                    variant='h5'
                    sx={{
                        ...textStyle,
                        fontWeight: 'bold'
                    }}
                >
                    Create Collection
                </Typography>
            </Grid>

            <Grid
                item
                xs={5}
                sm={6}
            >
                <Typography
                    variant={!isMobile ? null : "body2"}
                    sx={{
                        ...textStyle,
                    }}
                >
                    Collection Name
                </Typography>
            </Grid>
            <Grid
                item
                xs={7}
                sm={6}
                sx={{
                    textAlign: 'right'
                }}
            >
                <Typography
                    variant='caption'
                >
                    {100 - collectionData.name.length} Characters Remaining
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
            >
                <TextField
                    variant='outlined'
                    value={collectionData.name}
                    color='secondary'
                    onChange={e => setCollectionData({ ...collectionData, name: e.target.value })}
                    sx={{
                        width: '100%',
                        mb: 3,
                        border: errors.name ? 'red 1px solid' : '',
                        borderRadius: 2,
                        '& .MuiOutlinedInput-input': {
                            py: 1
                        }
                    }}
                    inputProps={{ maxLength: 100 }}
                />
            </Grid>

            <Grid
                item
                xs={5}
                sm={6}
            >
                <Typography
                    variant={!isMobile ? null : "body2"}
                    sx={{
                        ...textStyle
                    }}
                >
                    Collection Criteria
                </Typography>
            </Grid>
            <Grid
                item
                xs={7}
                sm={6}
                sx={{
                    textAlign: 'right'
                }}
            >
                <Typography
                    variant='caption'
                >
                    {500 - collectionData.description.length} Characters Remaining
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
            >
                <TextField
                    variant='outlined'
                    color='secondary'
                    value={collectionData.description}
                    onChange={e => setCollectionData({ ...collectionData, description: e.target.value })}
                    placeholder='Describe the items that users should submit for this collection'
                    multiline
                    rows={3}
                    sx={{
                        width: '100%',
                        mb: 3,
                        border: errors.description ? 'red 1px solid' : '',
                        borderRadius: 2,
                        '& .MuiOutlinedInput-input': {
                            py: 0
                        }
                    }}
                    inputProps={{ maxLength: 500 }}
                />
            </Grid>

            <Grid
                item
                xs={5}
                sm={6}
            >
                <Typography
                    variant={!isMobile ? null : "body2"}
                    sx={{
                        ...textStyle
                    }}
                >
                    Tags (at least 3)
                </Typography>
            </Grid>
            <Grid
                item
                xs={7}
                sm={6}
                sx={{
                    textAlign: 'right'
                }}
            >
                {/* <Typography
                    variant='caption'
                >
                    Suggest
                </Typography> */}
            </Grid>
            <Grid
                item
                xs={12}
            >
                <TagHandler
                    tagChoices={tagChoices}
                    setTagChoices={setTagChoices}
                    placeholderText={'Tags'}
                    error={errors.tags}
                />
            </Grid>

            <Grid
                item
                xs={12}
                sm={6}
                sx={{
                    mt: 2
                }}
            >
                <Typography
                    variant={!isMobile ? null : "body2"}
                    sx={{
                        ...textStyle
                    }}
                >
                    Collection Curator/Moderator (max 3)
                </Typography>
            </Grid>
            <Grid
                item
                xs={0}
                sm={6}
                sx={{
                    textAlign: 'right',
                    mt: 2,
                    display: !isMobile ? null : "none"
                }}
            >
                <Tooltip
                    title='A Curator reviews any Items submitted by other users for the Collection 
                        (Curators can also submit Items themselves) to ensure they are appropriate 
                        and relevant for the Collection. They accept or reject Items submitted 
                        by other users.
                    '
                    componentsProps={{
                        popper: {
                            sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: theme.palette.common.white,
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    boxShadow: theme.shadows[1],
                                    fontSize: 14,
                                    fontFamily: 'Open Sans',
                                    letterSpacing: 1,
                                    lineHeight: 1.3,
                                    p: 2
                                },
                            }
                        }
                    }}
                >
                    <Typography
                        variant='caption'
                        sx={{
                            '&:hover': {
                                cursor: 'pointer',
                                color: theme.palette.primary.main,
                                backgroundColor: theme.palette.grey['300'],
                                borderRadius: 4
                            },
                            p: 1
                        }}
                    >
                        What is a Curator?
                    </Typography>
                </Tooltip>
            </Grid>
            <Grid
                item
                xs={12}
                sm={9}
            >
                <Autocomplete
                    sx={{
                        border: errors.curators ? 'red 1px solid' : '',
                        borderRadius: 0,
                    }}
                    multiple
                    options={users}
                    value={collectionData.curators}
                    onChange={(e, n) => {
                        let newCurators = new Set(n)
                        setCollectionData({ ...collectionData, curators: [...newCurators] })
                    }}
                    getOptionLabel={(option) => {
                        return `${option.username}`
                    }}
                    renderTags={(options, getTagProps) => {
                        return options.map(option => {
                            return (
                                <Chip
                                    {...getTagProps}
                                    key={option.userId}
                                    label={
                                        <Fragment>
                                            <Typography
                                                sx={{
                                                    display: 'inline',
                                                    verticalAlign: 'middle'
                                                }}
                                            >
                                                {option.username}
                                            </Typography>
                                            {
                                                collectionData.curators.length !== 1 ?
                                                    <Tooltip
                                                        title={'Make Head Curator'}
                                                        placement='right'
                                                    >
                                                        <Checkbox
                                                            value={headCurator === option.userId ? true : false}
                                                            checked={headCurator === option.userId ? true : false}
                                                            onChange={() => {
                                                                setHeadCurator(option.userId)
                                                            }}
                                                            color='secondary'
                                                            sx={{
                                                                color: theme.palette.secondary.main,
                                                                pl: 2
                                                            }}
                                                        />
                                                    </Tooltip> : null
                                            }
                                            <CloseIcon
                                                onClick={() => handleRemoveCurator(option.userId)}
                                                sx={{
                                                    verticalAlign: 'middle',
                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                        backgroundColor: theme.palette.primary.light,
                                                        color: theme.palette.error.dark,
                                                        borderRadius: 2
                                                    }
                                                }}
                                            />
                                        </Fragment>
                                    }
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: theme.palette.common.white,
                                        my: 0.25
                                    }}
                                />
                            )
                        })
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                onChange={(e) => {
                                    setUserTyping(e.target.value)
                                }}
                                variant='standard'
                                placeholder='Enter username(s), display name(s), or handle(s)'
                                sx={{
                                    '& .MuiAutocomplete-tag': {
                                        backgroundColor: colors.lightGray,
                                        borderRadius: 0,
                                        border: `1px solid ${colors.gray}`
                                    },
                                    '& .MuiChip-label': {
                                        ...textStyle,
                                        p: 2
                                    },
                                    '& .MuiInput-input': {
                                        ...textStyle,
                                        ml: 1
                                    },
                                    display: 'inline'
                                }}
                            />
                        )
                    }}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={3}
                sx={{
                    textAlign: 'right',
                    mt: !isMobile ? null : 1
                }}
            >
                <Button
                    onClick={() => {
                        /* This currently just makes slyke the curator. */
                        setCollectionData({ ...collectionData, curators: [slykeUser] })
                    }}
                    variant='contained'
                    sx={{
                        ...buttonStyle,
                        width: isMobile ? "100%" : null,
                        mb: isMobile ? 2 : null,
                        backgroundColor: colors.white,
                        color: colors.darkGray,
                        border: `${colors.darkGray} solid 1px`
                    }}
                >
                    I need a Curator
                </Button>
            </Grid>

            <Grid
                item
                xs={12}
                sx={{
                    mt: 2
                }}
            >
                <Typography
                    variant={!isMobile ? null : "body2"}
                    sx={{
                        ...textStyle
                    }}
                >
                    Default Image (Optional)
                </Typography>
            </Grid>
            <Grid
                item
                xs={2.5}
                sm={1.25}
            >
                <img
                    id='preview-image'
                    height={50}
                    width={50}
                    style={{
                        border: `1px solid ${colors.darkGray}`,
                        borderRadius: 4
                    }}
                />
            </Grid>
            <Grid
                item
                xs
                sm={6}
                sx={{
                    mt: !isMobile ? 1 : 0
                }}
            >
                <FileUploader
                    label='Upload Media File'
                    classes='file-upload'
                    handleChange={handleChange}
                    maxSize={25}
                    onSizeError={() => { alert('file is too large!') }}
                    // eslint-disable-next-line react/no-children-prop
                    children={
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Button
                                variant='contained'
                                sx={{
                                    ...buttonStyle,
                                    color: colors.white,
                                    backgroundColor: colors.darkGray,
                                    width: '70%',
                                    '&:hover': {
                                        cursor: 'pointer',
                                        backgroundColor: colors.secondary,
                                        color: colors.lightGray
                                    },
                                    mb: 2
                                }}
                            >
                                Choose Image
                            </Button>
                        </Box>
                    }
                />
            </Grid>

            {
                collectionTooShort ?
                    <Error
                        isMobile={isMobile}
                        text="Collection name must be at least 18 characters. Make sure the name is descriptive."
                        sx={{
                            width: "100%"
                        }}
                    /> : null
            }

            <Grid
                item
                xs={12}
                sx={{
                    textAlign: 'center',
                    mt: 3
                }}
            >
                <Button
                    variant='contained'
                    onClick={handleSubmit}
                    sx={{
                        ...buttonStyle,
                        width: '50%'
                    }}
                >
                    Create Collection
                </Button>
            </Grid>
            <Grid xs={8}></Grid>

            <Grid
                item
                xs={12}
            >
                <Box
                    sx={{
                        textAlign: 'center',
                        mt: 2,
                        width: !isMobile ? '50%' : '80%',
                        mx: 'auto'
                    }}
                >
                    <Typography
                        variant='caption'
                        sx={{
                            ...textStyle
                        }}
                    >
                        By creating this collection, you agree to abide by the&nbsp;
                        <Link>
                            Terms & Conditions
                        </Link>
                        &nbsp;.
                    </Typography>
                </Box>
            </Grid>

            <LoadingModal
                open={loading}
                onClose={() => setLoading(false)}
            />
        </Grid>
    )
}