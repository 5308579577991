import { Fragment, useEffect, useState } from 'react'
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
    Grid,
    Typography,
    Button,
    Link,
    Popover,
    useTheme,
    Tooltip,
    Stack,
    SvgIcon,
    Box,
    tooltipClasses
} from '@mui/material'
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined'
import colors from '../../constants/colors'
import { getCollectionByIndex } from '../../store/collection/collectionSlice'
import {
    getUserId,
    getToken,
    getIdToken,
    getUsername,
    getIsLoggedIn
} from '../../store/user/userSlice'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SettingsIcon from '@mui/icons-material/Settings'
import EditIcon from '@mui/icons-material/Edit'
import ItemThumbnail from '../../legacy-components/item-thumbnail/item-thumbnail'
import BlankItemThumbnail from '../../legacy-components/blank-item-thumbnail/blank-item-thumbnail'
import EngagementBox from '../../legacy-components/engagement-box/engagement-box'
import addFollow from '../../functions/add-follow'
import deleteFollow from '../../functions/delete-follow'
import AddItemModal from '../../legacy-components/add-item-modal/add-item-modal'
import apiRoutes, { frontRoot } from '../../constants/api-routes'
import {
    getSpecificCollection
} from '../../store/collection/collectionSlice'
import AccountModalHandler from '../../legacy-components/account-modals/account-modal'
import { updateSpecificCollection } from '../../store/collection/collectionSlice'
import CreateCollectionModal from '../../legacy-components/create-collection-modal/create-collection-modal'
import CollectionDescriptionModal from '../../legacy-components/collection-description-modal/collection-description-modal'
import CollectionSettingsModal from '../../legacy-components/collection-settings-modal/collection-settings-modal'
import ApplyToCurateModal from '../../legacy-components/apply-to-curate-modal/apply-to-curate-modal'
import CuratorInviteDeclined from '../../legacy-components/curator-invite-modals/curator-invite-declined'
import CuratorInviteAccepted from '../../legacy-components/curator-invite-modals/curator-invite-accepted'
import Reject from '../../legacy-components/request-to-curate-modals/reject'
import Approve from '../../legacy-components/request-to-curate-modals/approve'
import axios from 'axios'
import ItemApproval from '../../legacy-components/item-approval-queue/item-approval-queue'
import { OpenInFull } from '@mui/icons-material'
import CollectionCreditsModal from '../../legacy-components/collection-credits-modal/collection-credits-modal'
import Navbar from '../../components/Navbar/Navbar'
import AddItemPopup from '../../components/AddItemPopup/AddItemPopup'
import PopupA from '../../components/CollectEmailA/Popup'
import PopupB from '../../components/CollectEmailB/Popup'

export default function Collection() {
    const location = useLocation()
    const navigate = useNavigate()
    const theme = useTheme()
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useDispatch()
    const loadedCollection = useSelector(getCollectionByIndex(location.state ? location.state.collectionIndex : 0))
    const userId = useSelector(getUserId)
    const username = useSelector(getUsername)
    const isLoggedIn = useSelector(getIsLoggedIn)
    const token = useSelector(getToken)
    const idToken = useSelector(getIdToken)

    const [collection, setCollection] = useState(loadedCollection)
    const [refreshFeed, setRefreshFeed] = useState(1)
    const [itemModalOpen, setItemModalOpen] = useState(null)
    const [openApplyToCurateModal, setOpenApplyToCurateModal] = useState(false)
    const [openCuratorInviteDeclined, setOpenCuratorInviteDeclined] = useState(false)
    const [openCuratorInviteAccepted, setOpenCuratorInviteAccepted] = useState(false)
    const [showCollectionDescription, setShowCollectionDescription] = useState(null)
    const [showSignUp, setShowSignUp] = useState(false)
    const [showSignIn, setShowSignIn] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [buttonIsHovered, setButtonHovered] = useState(false)
    const [showCreateCollection, setShowCreateCollection] = useState(false)
    const [showCollectionSettings, setShowCollectionSettings] = useState(false)
    const [showRequestApproveModal, setShowRequestApproveModal] = useState(false)
    const [showRequestRejectModal, setShowRequestRejectModal] = useState(false)
    const [showCollectionCreditsModal, setShowCollectionCreditsModal] = useState(false)
    const [pendingRequestToCurate, setPendingRequestToCurate] = useState(false)
    const [editState, setEditState] = useState(false)
    const [pendingItemCount, setPendingItemCount] = useState(false)
    const [addItemPopupOpen, setAddItemPopupOpen] = useState(false);
    const [popupA, setPopupA] = useState(false);
    const [popupB, setPopupB] = useState(false);

    const [activeEditState, setActiveEditState] = useState({
        'Current': true,
        'Pending': false,
        'Rejected': false
    })
    const isCurator = collection ? collection.curators.some(curator => curator.id === userId) : null

    let headCurator = collection ? collection.curators.filter((curator) => {
        return curator.isHeadCurator === 1
    })[0] : {}
    headCurator = headCurator ? headCurator : {}

    const textStyle = {
        fontFamily: 'Open Sans'
    }

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }
    const open = Boolean(anchorEl)
    const popoverId = open ? 'simple-popover' : undefined

    const openSignUp = () => {
        setShowSignUp(true)
    }

    const getCollectionLink = () => {
        return `${frontRoot}/collection?collectionId=${collection ? collection.collectionId : null}`
    }

    const showRejectedItems = () => {
        if (token) {
            axios.get(apiRoutes.getCollectionRejectedItems, {
                params: {
                    collectionId: collection ? collection.collectionId : null
                },
                headers: {
                    'authorization': `Bearer ${token}`
                }
            }).then((res) => {
                let updatedCollection = { ...collection }
                updatedCollection.items = res.data.items
                setCollection(updatedCollection)
            })
        } else {
            window.location.reload()
        }
    }

    const showPendingItems = () => {
        if (token) {
            axios.get(apiRoutes.getCollectionQueue, {
                params: {
                    collectionId: collection ? collection.collectionId : null
                },
                headers: {
                    'authorization': `Bearer ${token}`
                }
            }).then((res) => {
                let updatedCollection = { ...collection }
                updatedCollection.items = res.data.queue
                setCollection(updatedCollection)
            })
        } else {
            window.location.reload()
        }
    }

    const getPendingItemCount = () => {
        if (token && isCurator) {
            axios.get(apiRoutes.getCollectionQueue, {
                params: {
                    collectionId: collection ? collection.collectionId : null
                },
                headers: {
                    'authorization': `Bearer ${token}`
                }
            }).then((res) => {
                const pendingItemsLength = res.data.queue.length
                if (pendingItemsLength > 9) {
                    setPendingItemCount('9+')
                } else {
                    setPendingItemCount(pendingItemsLength)
                }
            }).catch(() => {
                setPendingItemCount(false)
            })
        } else {
            setPendingItemCount(false)
        }
    }

    const getPendingRequestsToCurate = () => {
        if (token && collection && headCurator) {
            axios.get(apiRoutes.getPendingRequestsToCurate, {
                params: {
                    headCuratorId: collection ? headCurator.id : null,
                    collectionId: collection ? collection.collectionId : null
                },
                headers: {
                    'authorization': `Bearer ${token}`
                }
            }).then((res) => {
                if (res.data.requests && res.data.requests.length > 0) {
                    setPendingRequestToCurate(true)
                }
            })
        }
    }

    useEffect(() => {
        if (!collection) {
            setCollection(loadedCollection)
        }

        if (!collection && location.state && location.state.collectionIndex !== 0) {
            navigate(`/collection?collectionId=${searchParams.get('collectionId')}`)
        }
    })

    useEffect(() => {
        getPendingItemCount()
        getPendingRequestsToCurate()

        if (!location.state) {
            dispatch(getSpecificCollection({ collectionId: searchParams.get('collectionId'), userId }))
            if (searchParams.get('collectionCreated')) {
                // remove param to show modal once shown
                searchParams.delete('collectionCreated')
                setSearchParams(searchParams)
                setShowCreateCollection(true)
            }
        } else if (location.state && !collection) {
            // this should only happen when the browser is refreshed since it retains location.state but loses the collection state
            dispatch(getSpecificCollection({ collectionId: searchParams.get('collectionId'), userId }))
        }

        setTimeout(() => {
            window.scrollTo({
                top: 0
            })
        }, 50)
    }, [])

    useEffect(() => {
        if (!location.state || !collection) {
            dispatch(getSpecificCollection({ collectionId: searchParams.get('collectionId'), userId }))
        }
    }, [searchParams.get('collectionId')])

    useEffect(() => {
        getPendingItemCount()
        getPendingRequestsToCurate()
    }, [collection])

    useEffect(() => {
        if (refreshFeed !== 1) {
            dispatch(updateSpecificCollection({ userId: userId, collectionId: searchParams.get('collectionId') }))
        }
    }, [refreshFeed])

    useEffect(() => {
        if (searchParams.get('approveRequest')) {
            setShowRequestApproveModal(true)
        }
    }, [searchParams.get('approveRequest')])

    useEffect(() => {
        if (searchParams.get('rejectRequest')) {
            setShowRequestRejectModal(true)
        }
    }, [searchParams.get('rejectRequest')])

    useEffect(() => {
        if (searchParams.get('inviteDeclined')) {
            setOpenCuratorInviteDeclined(true)
        }
    }, [searchParams.get('rejectRequest')])

    useEffect(() => {
        if (searchParams.get('inviteAccepted')) {
            setOpenCuratorInviteAccepted(true)
        }
    }, [searchParams.get('inviteAccepted')])

    useEffect(() => {
        getPendingItemCount()
        setCollection(loadedCollection)
    }, [loadedCollection])

    useEffect(() => {
        getPendingItemCount()
        getPendingRequestsToCurate()

        dispatch(updateSpecificCollection({ userId: userId, collectionId: searchParams.get('collectionId') }))
    }, [userId])

    useEffect(() => {
        // show a CTA popup after 8 seconds
        setTimeout(() => {
            if (!userId) {
                if (Math.random() < 0.5) {
                    setPopupA(true);
                } else {
                    setPopupB(true);
                }
                // setAddItemPopupOpen(true);
            }
        }, 8000);
    }, []);

    return (
        <Fragment>
            <AddItemModal
                open={itemModalOpen}
                close={() => { setItemModalOpen(false) }}
                collectionIndex={location.state ? location.state.collectionIndex : 0}
            />
            <Navbar
                search
                collectionIndex={location.state ? location.state.collectionIndex : 0}
            />

            {/* Header */}
            <Grid
                container
                sx={{
                    mt: 16
                }}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Stack
                        direction='row'
                        sx={{
                            mx: 'auto',
                            width: 'fit-content'
                        }}
                    >
                        {/* Pending Items Badge */}
                        {
                            pendingItemCount ?
                                <Tooltip
                                    componentsProps={{
                                        popper: {
                                            sx: {
                                                [`& .${tooltipClasses.arrow}`]: {
                                                    color: (theme) => theme.palette.common.black
                                                },
                                                [`& .${tooltipClasses.tooltip}`]: {
                                                    backgroundColor: (theme) => theme.palette.common.black
                                                }
                                            }
                                        }
                                    }}
                                    title={
                                        <Fragment>
                                            <Typography>
                                                You have new item submissions to review!
                                            </Typography>
                                        </Fragment>
                                    }
                                >
                                    <Box
                                        onClick={() => {
                                            setEditState(true)
                                            setActiveEditState({
                                                'Current': false,
                                                'Pending': true,
                                                'Rejected': false
                                            })
                                            showPendingItems()
                                        }}
                                        sx={{
                                            display: 'inherit',
                                            '&:hover': {
                                                cursor: 'pointer'
                                            }
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: theme.palette.secondary.main,
                                                mt: 0.5,
                                                mr: 0.5
                                            }}
                                        >
                                            {pendingItemCount}
                                        </Typography>
                                        <SvgIcon
                                            fontSize='large'
                                            width={34}
                                            height={34}
                                            htmlColor='none'
                                            viewBox='0 0 34 34'
                                            sx={{
                                                fill: 'none',
                                                mr: 3
                                            }}
                                        >
                                            <path d="M29.1739 11.3226L33 15.4516L29.6184 17.172M29.1739 11.3226L17 17.172M29.1739 11.3226L17 4.78495M29.1739 11.3226L32.3043 7.53763L20.4783 1L17 4.78495M17 17.172L20.8261 21.6452L29.6184 17.172M17 17.172L5.17391 10.6344M17 17.172L13.1739 21.6452L4.63285 17.172M17 17.172V33M5.17391 10.6344L17 4.78495M5.17391 10.6344L1 7.53763L13.1739 1L17 4.78495M5.17391 10.6344L1.34783 15.4516L4.63285 17.172M17 33L4.47826 25.7742L4.63285 17.172M17 33L29.5217 26.4624L29.6184 17.172" stroke="#335CFF" />
                                        </SvgIcon>
                                    </Box>
                                </Tooltip>
                                : null
                        }

                        {/* Collection Title */}
                        <Typography
                            variant='h5'
                            align='center'
                            sx={{
                                fontFamily: 'Open Sans',
                                fontWeight: 'bold',
                                mb: 2
                            }}
                        >
                            {collection ? collection.collectionTitle : ''}
                            <InfoOutlinedIcon
                                onClick={() => { setShowCollectionDescription(true) }}
                                sx={{
                                    fontSize: 16,
                                    mb: 1,
                                    ml: 1,
                                    '&:hover': {
                                        color: theme.palette.primary.main,
                                        cursor: 'pointer'
                                    },
                                    '&:active': {
                                        color: theme.palette.secondary.main
                                    }
                                }} />
                        </Typography>
                    </Stack>
                </Grid>

                <Grid
                    item
                    xs={12}
                >
                    <Typography
                        variant='body1'
                        align='center'
                        sx={{
                            fontFamily: 'Open Sans'
                        }}
                    >
                        Curated By&nbsp;
                        <Link
                            href={frontRoot + '/profile?' + 'userId=' + (collection ? headCurator.id : 5)}
                            sx={{
                                display: 'inline',
                                '&:hover': {
                                    cursor: 'pointer'
                                }
                            }}
                        >
                            {collection ? headCurator.displayName : ''}
                        </Link>
                        <OpenInFull
                            onClick={() => setShowCollectionCreditsModal(true)}
                            sx={{
                                fontSize: 15,
                                verticalAlign: 'center',
                                color: '#94908f',
                                ml: 1,
                                transform: 'rotate(90deg)',
                                '&:hover': {
                                    color: theme.palette.primary.main,
                                    cursor: 'pointer'
                                },
                                '&:active': {
                                    color: theme.palette.secondary.main
                                }
                            }}
                        />

                        {
                            !isCurator ?
                                <PanToolOutlinedIcon
                                    sx={{
                                        fontSize: 14,
                                        verticalAlign: 'baseline',
                                        color: colors.secondary,
                                        mr: 0.5,
                                        mb: -0.2,
                                        ml: 2
                                    }}
                                /> : null
                        }
                        {
                            isCurator ?
                                null :
                                pendingRequestToCurate ?
                                    <Typography
                                        sx={{
                                            color: colors.secondary,
                                            display: 'inline',
                                            fontFamily: 'Open Sans'
                                        }}
                                    >
                                        Request Pending
                                    </Typography> :
                                    <Link
                                        onClick={() => {
                                            if (isLoggedIn) {
                                                setOpenApplyToCurateModal(true)
                                            } else {
                                                setShowSignUp(true)
                                            }
                                        }}
                                        sx={{
                                            color: colors.secondary,
                                            textDecorationColor: colors.secondary,
                                            display: 'inline',
                                            '&:hover': {
                                                cursor: 'pointer',
                                            }
                                        }}
                                    >
                                        Apply To Curate
                                    </Link>
                        }
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    <Button
                        onMouseEnter={() => setButtonHovered(true)}
                        onMouseLeave={() => setButtonHovered(false)}
                        onClick={() => {
                            if (collection && !collection.isFollowing) {
                                addFollow(userId, collection.collectionId, openSignUp, token).then(() => {
                                    setRefreshFeed(refreshFeed + 1)
                                })
                            } else {
                                deleteFollow(userId, collection.collectionId, token).then(() => {
                                    setRefreshFeed(refreshFeed + 1)
                                })
                            }
                        }}
                        sx={{
                            verticalAlign: 'super',
                            fontFamily: 'Open Sans',
                            backgroundColor: collection && collection.isFollowing ? colors.secondary : colors.primary,
                            border: `${collection && collection.isFollowing ? colors.secondary : colors.primary} solid 1px`,
                            color: colors.white,
                            borderRadius: '24px',
                            textTransform: 'none',
                            py: 0.3,
                            px: {
                                xs: 0.5,
                                sm: 3
                            },
                            my: 2,
                            '&:hover': {
                                backgroundColor: colors.darkGray,
                                color: colors.lightGray,
                                border: `${colors.darkGray} solid 1px`
                            }
                        }}
                    >
                        {
                            collection && collection.isFollowing ?
                                (buttonIsHovered ? 'Unfollow' : 'Following')
                                : 'Follow'
                        }
                    </Button>
                    <ShareOutlinedIcon
                        onClick={(e) => {
                            navigator.clipboard.writeText(getCollectionLink())
                            handlePopoverOpen(e)
                        }}
                        sx={{
                            verticalAlign: 'baseline',
                            color: theme.palette.grey['600'],
                            pt: 2.8,
                            ml: 3,
                            fontSize: 24,
                            '&:hover': {
                                cursor: 'pointer',
                                color: colors.primary
                            }
                        }}
                    />
                    <Typography
                        variant='body2'
                        sx={{
                            display: 'inline-flex',
                            fontFamily: 'Open Sans',
                            verticalAlign: 'super',
                            color: colors.darkGray,
                            ml: 1
                        }}
                    >
                        Share
                    </Typography>
                    {
                        isCurator ?
                            <Fragment>
                                <Tooltip title='edit items'>
                                    <EditIcon
                                        onClick={() => {
                                            setEditState(!editState)
                                            if (editState) {
                                                window.location.reload()
                                            }
                                        }}
                                        sx={{
                                            pl: 3,
                                            '&:hover': {
                                                color: theme.palette.primary.main,
                                                cursor: 'pointer'
                                            },
                                            '&:active': {
                                                color: theme.palette.secondary.main
                                            },
                                            color: editState ? theme.palette.secondary.main : theme.palette.common.black
                                        }}
                                    />
                                </Tooltip>
                                {
                                    isCurator ?
                                        <Tooltip title='collection settings'>
                                            <SettingsIcon
                                                onClick={() => {
                                                    if (isCurator) {
                                                        setShowCollectionSettings(true)
                                                    }
                                                }}
                                                sx={{
                                                    pl: 1,
                                                    '&:hover': {
                                                        color: theme.palette.primary.main,
                                                        cursor: 'pointer'
                                                    },
                                                    '&:active': {
                                                        color: theme.palette.secondary.main
                                                    }
                                                }}
                                            />
                                        </Tooltip> : null
                                }
                            </Fragment> :
                            <Fragment>
                                <MailOutlinedIcon
                                    sx={{
                                        verticalAlign: 'baseline',
                                        color: colors.darkGray,
                                        pt: 2.8,
                                        ml: 3,
                                        fontSize: 24
                                    }}
                                />
                                <Typography
                                    variant='body2'
                                    sx={{
                                        display: 'inline-flex',
                                        fontFamily: 'Open Sans',
                                        verticalAlign: 'super',
                                        color: colors.darkGray,
                                        ml: 1
                                    }}
                                >
                                    Message Curator
                                </Typography>
                            </Fragment>
                    }
                </Grid>

                <Grid item xs={4} />
                <Grid
                    item
                    xs={4}
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    <EngagementBox
                        followers={collection ? collection.followerCount : 0}
                        likes={collection ? collection.totalItemLikes : 0}
                        itemCount={collection ? collection.items.length : 0}
                        views={collection ? collection.totalItemViews : 0}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={{
                        textAlign: 'right'
                    }}
                >
                    {/* <TableRowsOutlinedIcon
                        sx={{
                            color: colors.gray,
                            verticalAlign: 'middle'
                        }}
                    />
                    <CalendarViewMonthOutlinedIcon
                        sx={{
                            color: colors.primary,
                            verticalAlign: 'middle',
                            ml: 2
                        }}
                    />
                    <Button
                        sx={{
                            textTransform: 'none',
                            borderRadius: 8,
                            border: `${colors.gray} solid 1px`,
                            px: 2,
                            mr: 4,
                            ml: 10,
                            color: colors.gray,
                            '&:hover': {
                                backgroundColor: colors.darkGray,
                                color: colors.lightGray
                            }
                        }}
                    >
                        <DateRangeIcon
                            sx={{
                                mr: 1
                            }}
                        />
                        Today
                    </Button> */}
                </Grid>

                <Grid
                    item
                    xs={12}
                    sx={{
                        textAlign: 'center',
                        mt: 3,
                        mb: 2
                    }}
                >
                    {
                        !editState ?
                            <Button
                                variant='contained'
                                onClick={() => { setItemModalOpen(true) }}
                                sx={{
                                    textTransform: 'none',
                                    color: colors.white,
                                    fontFamily: 'Open Sans'
                                }}
                            >
                                Add an Item
                            </Button> :
                            <Stack
                                direction='row'
                                sx={{
                                    justifyContent: 'center'
                                }}
                            >
                                {
                                    Object.entries(activeEditState).map(([editState, active]) => {
                                        return (
                                            <Button
                                                key={editState}
                                                onClick={() => {
                                                    setActiveEditState({
                                                        'Current': editState === 'Current' ? true : false,
                                                        'Pending': editState === 'Pending' ? true : false,
                                                        'Rejected': editState === 'Rejected' ? true : false
                                                    })

                                                    if (editState === 'Rejected') {
                                                        showRejectedItems()
                                                    } else if (editState === 'Pending') {
                                                        showPendingItems()
                                                    } else if (editState === 'Current') {
                                                        setCollection(loadedCollection)
                                                    }
                                                }}
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: active ? theme.palette.secondary.main : theme.palette.grey['700'],
                                                    textTransform: 'none',
                                                    fontSize: 16,
                                                    mx: 2,
                                                    px: 5,
                                                    borderRadius: 0,
                                                    borderBottom: active ? `${theme.palette.secondary.main} 1px solid` : 'none'
                                                }}
                                            >
                                                {editState}
                                            </Button>
                                        )

                                    })
                                }
                            </Stack>
                    }
                </Grid>
            </Grid >

            {/* Item Thumbnails */}
            < Grid
                container
                sx={{
                    mt: 1
                }
                }
            >
                {
                    collection ? collection.items.map((item, index) => {
                        if (activeEditState.Pending) {
                            return (
                                <ItemApproval
                                    key={`item-approval-${index}`}
                                    item={item}
                                    showPendingItems={() => {
                                        showPendingItems()
                                        getPendingItemCount()
                                    }}
                                />
                            )
                        }
                        if (collection.items.length < 4 && index === collection.items.length - 1) {
                            return (
                                <Fragment key={`${item.title}-${index}`}>
                                    <Grid
                                        key={`${item.title}-${index}`}
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        mdlg={4}
                                        lg={4}
                                        lgxl={4}
                                        xl={3}
                                        sx={{
                                            mb: 3
                                        }}
                                    >
                                        <ItemThumbnail
                                            item={item}
                                            collectionId={collection.collectionId}
                                            itemIndex={index}
                                            editState={editState}
                                            isRejected={activeEditState.Rejected}
                                            refresh={() => setRefreshFeed(refreshFeed + 1)}
                                        />
                                    </Grid>
                                    {
                                        activeEditState.Current ?
                                            <Grid
                                                key={`cta-item-card-${index + 1}`}
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                mdlg={4}
                                                lg={4}
                                                lgxl={4}
                                                xl={3}
                                                sx={{
                                                    mb: 3,
                                                    alignSelf: 'self-end'
                                                }}
                                            >
                                                <BlankItemThumbnail setItemModalOpen={() => { setItemModalOpen(true) }} />
                                            </Grid> : null
                                    }
                                </Fragment>
                            )
                        } else {
                            return (
                                <Grid
                                    key={index}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    mdlg={4}
                                    lg={4}
                                    lgxl={4}
                                    xl={3}
                                    sx={{
                                        mb: 3
                                    }}
                                >
                                    <ItemThumbnail
                                        item={item}
                                        collectionId={collection.collectionId}
                                        itemIndex={index}
                                        editState={editState}
                                        isRejected={activeEditState.Rejected}
                                        refresh={() => setRefreshFeed(refreshFeed + 1)}
                                    />
                                </Grid>
                            )
                        }
                    }) : null
                }
                {
                    collection ?
                        collection.items.length || !activeEditState.Current ? null :
                            <Grid
                                key={1}
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                sx={{
                                    mb: 3
                                }}
                            >
                                <BlankItemThumbnail setItemModalOpen={() => { setItemModalOpen(true) }} />
                            </Grid> : null
                }
            </Grid >
            <AccountModalHandler
                signUp={showSignUp}
                onCloseSignUp={() => {
                    setShowSignUp(false)
                }}
                signIn={showSignIn}
                onCloseSignIn={() => {
                    setShowSignIn(false)
                }}
            />
            <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2, ...textStyle }}>Link copied to clipboard!</Typography>
            </Popover>
            <CreateCollectionModal
                open={showCreateCollection}
                close={() => { setShowCreateCollection(false) }}
                collectionCreated
            />
            <CollectionDescriptionModal
                open={showCollectionDescription}
                close={() => { setShowCollectionDescription(false) }}
                description={collection ? collection.collectionDescription : null}
            />
            <CollectionSettingsModal
                open={showCollectionSettings}
                close={() => { setShowCollectionSettings(false) }}
                collection={collection}
            />
            <ApplyToCurateModal
                open={openApplyToCurateModal}
                close={() => { setOpenApplyToCurateModal(false) }}
                token={token}
                idToken={idToken}
                collection={collection}
                setShowSignUp={setShowSignUp}
                username={username}
                userId={userId}
                getPendingRequestsToCurate={getPendingRequestsToCurate}
            />
            <CuratorInviteDeclined
                open={openCuratorInviteDeclined}
                close={() => { setOpenCuratorInviteDeclined(false) }}
            />
            <CuratorInviteAccepted
                open={openCuratorInviteAccepted}
                close={() => { setOpenCuratorInviteAccepted(false) }}
            />
            <Reject
                open={showRequestRejectModal}
                close={() => { setShowRequestRejectModal(false) }}
                token={token}
                idToken={idToken}
                collection={collection}
                setShowSignIn={setShowSignIn}
                username={username}
                userId={userId}
            />
            <Approve
                open={showRequestApproveModal}
                close={() => { setShowRequestApproveModal(false) }}
                token={token}
                idToken={idToken}
                collection={collection}
                setShowSignIn={setShowSignIn}
                username={username}
                userId={userId}
            />
            <CollectionCreditsModal
                open={showCollectionCreditsModal}
                close={() => { setShowCollectionCreditsModal(false) }}
                curators={collection ? collection.curators : []}
                creatorId={collection ? collection.creatorId : null}
            />

            {/* <AddItemPopup
                openAddItem={() => setItemModalOpen(true)}
                isOpen={addItemPopupOpen}
                close={() => setAddItemPopupOpen(!addItemPopupOpen)}
            /> */}

            {/* Add to item view as well */}
            <PopupA
                isOpen={popupA}
                close={() => setPopupA(!popupA)}
            />

            <PopupB
                isOpen={popupB}
                close={() => setPopupB(!popupB)}
            />
        </Fragment >
    )
}