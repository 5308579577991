import {
    Grid,
    Typography,
    Button,
    Link
} from '@mui/material'
import colors from '../../constants/colors'
import CreateCollection from './create-collection'
import Search from '../../components/Landing/Hero/Search/Search';

export default function ButFirst(props) {
    const {
        isMobile,
        openAddItem,
        close
    } = props;

    const textStyle = {
        fontFamily: 'Open Sans'
    }

    return (
        <Grid
            container
            sx={{
                px: !isMobile ? 6 : 2
            }}
        >
            <Grid
                item
                xs={12}
                sx={{
                    textAlign: 'center',
                    mt: 2,
                    mb: !isMobile ? 4 : 2
                }}
            >
                <Typography
                    variant={!isMobile ? 'h5' : 'h6'}
                    sx={{
                        ...textStyle,
                        fontWeight: 'bold'
                    }}
                >
                    But first...
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    mb: !isMobile ? 4 : 2
                }}
            >
                <Typography
                    variant={!isMobile ? 'h6' : 'body1'}
                    sx={{
                        ...textStyle,
                        fontWeight: 'bold'
                    }}
                >
                    Search Existing Collections
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    mb: !isMobile ? 4 : 2
                }}
            >
                <Typography
                    variant={!isMobile ? 'body1' : 'body2'}
                    sx={{
                        ...textStyle
                    }}
                >
                    Have you searched to see if a collection like this is already on Slyke?
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
            >
                <Typography
                    variant={!isMobile ? 'body1' : 'body2'}
                    sx={{
                        ...textStyle
                    }}
                >
                    To keep Slyke organized, if the subject of your collection exists, your new one will be rejected and any items will be added to the existing one. We'll let you know though!
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    mt: 2
                }}
            >
                <Search
                    isMobile={isMobile}
                />
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    textAlign: 'center',
                    mt: !isMobile ? 4 : 2
                }}
            >
                <Button
                    variant='contained'
                    onClick={() => {
                        props.setActiveComponent(
                            <CreateCollection
                                setActiveComponent={props.setActiveComponent}
                                isMobile={isMobile}
                            />
                        )
                    }}
                    sx={{
                        color: 'white',
                        fontFamily: 'Open Sans',
                        fontSize: 16,
                        textTransform: 'none'
                    }}
                >
                    Continue to Create Collection
                </Button>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    textAlign: 'center',
                    mt: !isMobile ? 4 : 2,
                    mb: 2
                }}
            >
                <Typography
                    variant='subtitle2'
                    sx={{
                        ...textStyle
                    }}
                >
                    Start by&nbsp;
                    <Link
                        onClick={() => {
                            openAddItem()
                            close()
                        }}
                        sx={{
                            color: colors.secondary,
                            textDecorationColor: colors.secondary,
                            '&:hover': {
                                cursor: "pointer"
                            }
                        }}
                    >
                        Adding an Item
                    </Link>
                    &nbsp;and we'll suggest any existing Collections.
                </Typography>
            </Grid>
        </Grid>
    )
}