import {
    Fragment
} from "react";

import DesktopFeedButtons from "./DesktopFeedButtons/DesktopFeedButtons";
import MobileFeedButtons from "./MobileFeedButtons/MobileFeedButtons";

export default function FeedButtons(props) {
    const {
        isMobile,
        activeFeed,
        setActiveFeed,
        manualFeeds
    } = props;

    const feeds = !manualFeeds ? [
        { feedName: "Trending" },
        { feedName: "Most Popular" },
        { feedName: "For You" },
        { feedName: "Recently Added" },
        { feedName: "Random" },
    ] : manualFeeds;

    return (
        <Fragment>
            {
                !isMobile ?
                    <DesktopFeedButtons
                        feeds={feeds}
                        activeFeed={activeFeed}
                        setActiveFeed={setActiveFeed}
                    /> :
                    <MobileFeedButtons
                        feeds={feeds}
                        activeFeed={activeFeed}
                        setActiveFeed={setActiveFeed}
                    />
            }
        </Fragment>
    );
}