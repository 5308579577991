import InstagramIcon from '@mui/icons-material/Instagram'
import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'
import RedditIcon from '@mui/icons-material/Reddit'
import YouTubeIcon from '@mui/icons-material/YouTube'
import TikTokIcon from '../../assets/icons/tiktok.svg'
import {
    Grid,
    Icon
} from '@mui/material'

export default function SocialMedia(props) {
    const navigateToExternalSite = (link) => {
        window.open(
            link,
            '_blank'
        )
    }

    const tikTokStyle = {
        height: '100%',
        width: '100%'
    }

    return (
        <Grid
            container
            direction='row'
        >
            {props.center ? <Grid xs /> : null}
            {
                [
                    (styles) => <InstagramIcon sx={styles} onClick={() => navigateToExternalSite('https://www.instagram.com/slyke_22/')} />,
                    (styles) => <TwitterIcon sx={styles} onClick={() => navigateToExternalSite('https://twitter.com/SLYKE__22')} />,
                    (styles) => <FacebookIcon sx={styles} onClick={() => navigateToExternalSite('https://www.facebook.com/profile.php?id=100077222005575')} />,
                    // (styles) => <YouTubeIcon sx={styles} onClick={() => navigateToExternalSite('https://www.youtube.com')} />,
                    (styles) => <RedditIcon sx={styles} onClick={() => navigateToExternalSite('https://www.reddit.com/user/slyke__22')} />,
                    (styles) => <Icon sx={styles} onClick={() => navigateToExternalSite('https://www.tiktok.com/@slyke__22')} ><img src={TikTokIcon} style={tikTokStyle} /></Icon>
                ].map((icon, ind) => {
                    return (
                        <Grid
                            key={ind}
                            item
                            xs={1.4}
                        >
                            {icon({
                                fontSize: 24,
                                margin: 0,
                                backgroundColor: 'white',
                                borderRadius: 1,
                                '&:hover': {
                                    cursor: 'pointer',
                                    backgroundColor: 'orange'
                                }
                            })}
                        </Grid>
                    )
                })
            }
            {props.center ? <Grid xs /> : null}
        </Grid>
    )
}