import {
    Box, Typography, useTheme
} from "@mui/material";

export default function CollectionDescription(props) {
    const {
        collectionDescription
    } = props;

    const theme = useTheme();

    return (
        <Box
            sx={{
                p: 1
            }}
        >
            <Typography
                variant="body2"
                sx={{
                    color: theme.palette.grey["600"]
                }}
            >
                {collectionDescription}
            </Typography>
        </Box>
    );
}