import {
    useMediaQuery,
    useTheme
} from "@mui/material";

export default function useCardBreakpoints() {
    const theme = useTheme();

    const threeCards = useMediaQuery(theme.breakpoints.down("xxl"), { noSsr: true });
    const twoCards = useMediaQuery(theme.breakpoints.down("lg"), { noSsr: true });
    const oneCard = useMediaQuery(theme.breakpoints.down("xsm"), { noSsr: true });

    return {
        threeCards,
        twoCards,
        oneCard
    };
}