import useIsMobile from "../../hooks/media/useIsMobile";

import UserProfileMobileBody from "../../components/UserProfile/UserProfileMobileBody/UserProfileMobileBody";

import UserProfile from "../../legacy-pages/user-pofile/user-profile";

export default function UserProfilePage() {
    const isMobile = useIsMobile();

    if (isMobile) {
        return (
            <UserProfileMobileBody />
        );
    } else {
        return (
            <UserProfile />
        );
    }
}