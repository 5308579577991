import {
    Modal,
    Button,
    IconButton,
    Typography,
    Box,
    Grid,
    TextField,
    Link,
    Avatar,
    Alert
} from '@mui/material'
import {
    Fragment,
    useEffect,
    useState
} from 'react'
import CloseIcon from '@mui/icons-material/Close'
import ReasonsToJoin from '../../reasons-to-join/reasons-to-join'
import logo from '../../../assets/images/slyke-logo-cropped.png'
import colors from '../../../constants/colors'
import validateDisplayName from '../../../functions/display-name-validation'
import validateUsername from '../../../functions/username-validation'
import validateEmail from '../../../functions/email-validation'
import axios from 'axios'
import apiRoutes from '../../../constants/api-routes'
import { useNavigate } from 'react-router-dom'

export default function SignUpModal(props) {
    const {
        isMobile
    } = props;

    const navigate = useNavigate()
    const [error, setError] = useState(false)
    const [userInfo, setUserInfo] = useState({
        displayName: {
            label: 'Display Name',
            placeholder: 'ex John Doe',
            value: '',
            isValid: false,
            validationFuncs: [validateDisplayName],
            sx: {
                maxWidth: !isMobile ? null : '90%'
            }
        },
        username: {
            label: 'Username',
            placeholder: 'ex jdoe1987',
            value: '',
            isValid: false,
            validationFuncs: [validateUsername],
            sx: {
                maxWidth: !isMobile ? null : '90%'
            }
        },
        email: {
            label: 'Email Address',
            placeholder: 'j.doe@email.com',
            value: '',
            isValid: false,
            validationFuncs: [validateEmail],
            sx: {
                maxWidth: !isMobile ? null : '90%'
            }
        }
    })

    useEffect(() => {
        Object.entries(userInfo).forEach(([key, field]) => {
            field.validationFuncs.forEach(func => {
                let result = func(field.value)
                if (result != field.isValid) {
                    setUserInfoIsValid(key, result)
                }
            })
        })
    }, [userInfo])

    const setUserInfoValue = (key, newValue) => {
        setUserInfo(prev => {
            let updatedInfo = prev[key]
            updatedInfo.value = newValue
            let updatedObj = { ...prev, [key]: updatedInfo }
            return updatedObj
        })
    }

    const setUserInfoIsValid = (key, newValue) => {
        setUserInfo(prev => {
            let updatedInfo = prev[key]
            updatedInfo.isValid = newValue
            let updatedObj = { ...prev, [key]: updatedInfo }
            return updatedObj
        })
    }

    const handleSubmit = async () => {
        let res = await axios.post(apiRoutes.signUp, {
            name: `@${userInfo.username.value}`,
            email: userInfo.email.value,
            username: userInfo.username.value,
            displayName: userInfo.displayName.value
        })

        return res.status
    }

    const canSubmit = () => {
        let validations = Object.values(userInfo).map(field => field.isValid)
        return !validations.every(value => value === true)
    }

    const modalBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            xs: '95%',
            sm: 660
        },
        maxHeight: '98%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 0,
        m: 0,
        borderRadius: 5,
        '&:active': {
            outline: 'none'
        },
        '&:focus': {
            outline: 'none'
        }
    }

    const rightTextStyle = {
        color: 'white',
        fontFamily: 'Open Sans'
    }

    return (
        <Modal
            open={props.open}
            onClose={() => {
                props.close()
                setError(false)
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={modalBoxStyle}
            >
                <Grid
                    container
                    direction='row'
                >
                    <Grid
                        item
                        xs={12}
                        sm={7}
                        sx={{
                            p: 2,
                            pl: {
                                xs: 5,
                                sm: 4
                            }
                        }}
                    >
                        <Typography
                            variant='h6'
                            sx={{
                                fontFamily: 'Open Sans',
                                borderBottom: '1px solid black',
                                width: 182,
                                fontWeight: 'bold',
                                mb: 3
                            }}
                        >
                            Create An Account
                        </Typography>
                        <Typography
                            variant='body2'
                            sx={{
                                width: 250,
                                mb: 3
                            }}
                        >
                            Join and contribute to the Slyke community. You&apos;ll love it!
                        </Typography>
                        {
                            Object.entries(userInfo).map(([key, field]) => {
                                return (
                                    <Fragment
                                        key={field.label}
                                    >
                                        <Typography
                                            variant='body1'
                                            sx={{
                                                fontWeight: 'bold',
                                                fontFamily: 'Open Sans'
                                            }}
                                        >
                                            {field.label}
                                        </Typography>
                                        <TextField
                                            variant='outlined'
                                            placeholder={field.placeholder}
                                            type={field.type}
                                            value={field.value}
                                            color={field.isValid ? 'secondary' : 'error'}
                                            onChange={(e) => { setUserInfoValue(key, e.target.value) }}
                                            sx={{
                                                p: 0,
                                                m: 0,
                                                '& .MuiOutlinedInput-root': {
                                                    height: 35
                                                },
                                                mb: 2,
                                                width: 300,
                                                ...field.sx
                                            }}
                                        />
                                    </Fragment>
                                )
                            })
                        }
                        {
                            error ?
                                <Alert
                                    sx={{
                                        p: 0,
                                        mb: 3
                                    }}
                                    severity="error"
                                >
                                    An error ocurred. Please try again later!
                                </Alert> : null
                        }
                        <Button
                            disabled={canSubmit()}
                            onClick={() => {
                                handleSubmit().then(() => {
                                    props.close()
                                    props.openSignUpSuccess()
                                }).catch(() => {
                                    setError(true)
                                })
                            }}
                            variant='contained'
                            sx={{
                                display: 'block',
                                width: 300,
                                maxWidth: !isMobile ? null : '90%',
                                color: 'white',
                                textTransform: 'none',
                                fontFamily: 'Open Sans',
                                mt: 1,
                                mb: 2
                            }}
                        >
                            Create Account
                        </Button>
                        <Typography
                            variant='subtitle2'
                            sx={{
                                mt: 2
                            }}
                        >
                            Creating an account means you&apos;re accepting our&nbsp;
                            <Link
                                onClick={() => navigate('/terms')}
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                            >
                                Terms of Service
                            </Link>
                        </Typography>

                        {/* Mobile Only */}
                        <Box
                            sx={{
                                display: {
                                    xs: 'block',
                                    sm: 'none'
                                }
                            }}
                        >
                            <Typography
                                variant='h6'
                                sx={{
                                    mt: 3,
                                    mb: 0,
                                    width: 300,
                                    fontSize: 16,
                                    fontWeight: 'bold'
                                }}
                            >
                                Already Have An Account?&nbsp;
                                <Link
                                    onClick={() => {
                                        props.openSignIn()
                                        props.close()
                                    }}
                                    sx={{
                                        ...rightTextStyle,
                                        textDecoration: 'underline',
                                        fontSize: 16,
                                        color: 'black',
                                        '&:hover': {
                                            cursor: 'pointer',
                                            color: colors.secondary
                                        }
                                    }}
                                >
                                    Sign In
                                </Link>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs
                        sx={{
                            backgroundImage: 'linear-gradient(to bottom, #FF7300, #0033FF)',
                            p: 2,
                            borderRadius: '0 19px 19px 0',
                            display: {
                                xs: 'none',
                                sm: 'block'
                            }
                        }}
                    >
                        <Typography
                            variant='body2'
                            sx={{
                                ...rightTextStyle,
                                mt: 7,
                                mb: 2
                            }}>
                            Already have an Account?
                        </Typography>
                        <Link
                            onClick={() => {
                                props.openSignIn()
                                props.close()
                            }}
                            sx={{
                                ...rightTextStyle,
                                textDecoration: 'underline',
                                fontSize: 18,
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: colors.secondary
                                }
                            }}
                        >
                            Sign In
                        </Link>
                        <Box
                            sx={{
                                mt: 4
                            }}
                        >
                            <ReasonsToJoin
                                color={'white'}
                                fontWeight={'lighter'}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 20,
                        margin: 'auto',
                        display: {
                            xs: 'none',
                            sm: 'block'
                        }
                    }}
                >
                    <Avatar
                        src={logo}
                        sx={{
                            width: 36,
                            height: 36,
                            mr: 1,
                            display: 'inline-flex'
                        }}
                    />
                    <Typography
                        variant='h3'
                        sx={{
                            display: 'inline-flex',
                            mb: 2,
                            color: 'white'
                        }}
                    >
                        SLYKE
                    </Typography>
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        top: {
                            xs: 9,
                            sm: 5
                        },
                        left: {
                            xs: 9,
                            sm: 5
                        },
                        margin: 'auto'
                    }}
                >
                    <IconButton
                        onClick={props.close}
                        sx={{
                            m: 0,
                            p: 0
                        }}
                    >
                        <CloseIcon
                            sx={{
                                color: colors.gray
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>
        </Modal>
    )
}