import {
    Box
} from "@mui/material";

import CollectionPreview from "./CollectionPreview/CollectionPreview";

export default function CollectionPreviews(props) {
    const {
        collections,
        isMobile,
        incrementSegment,
        setupObserver,
        refreshCollection
    } = props;

    return (
        <Box>
            {
                collections.map((collection, ind) => {
                    return (
                        <CollectionPreview
                            key={`collection-preview-${ind}`}
                            collection={collection}
                            isMobile={isMobile}
                            incrementSegment={incrementSegment}
                            setupObserver={setupObserver}
                            needsObserver={ind !== 0 && ind % 8 === 0}
                            refreshCollection={refreshCollection}
                        />
                    );
                })
            }
        </Box>
    );
}