import axios from "axios";
import apiRoutes from "../../constants/api-routes";

export default function useGetComments() {
    const getComments = (itemId, setStateComments, page) => {
        axios.get(
            `${apiRoutes.getComments}/${itemId}`,
            {
                params: {
                    page: page,
                    perPage: 5,
                    sortOrder: "desc"
                }
            }
        ).then((res) => {
            setStateComments(prev => {
                return [...prev, ...res.data.data];
            });
        });
    };

    return getComments;
}