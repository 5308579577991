import { useNavigate } from "react-router-dom";

export default function useNavigateToFAQ() {
    const navigate = useNavigate();

    const navigateToFAQ = () => {
        navigate("/faq");
    };

    return navigateToFAQ;
}