import {
    useTheme
} from "@mui/styles";
import { useState } from "react";

import PrimaryButton from "../PrimaryButton/PrimaryButton";

export default function FollowButton(props) {
    const {
        isFollowing,
        collectionId,
        handleFollow,
        openSignUpModal,
        refreshCollection,
        isMobile,
        sx
    } = props;

    const theme = useTheme();

    const [hoverFollowButton, setHoverFollowButton] = useState(0);

    return (
        <PrimaryButton
            text={isFollowing ? hoverFollowButton ? "Unfollow" : "Following" : "Follow"}
            onMouseEnter={() => setHoverFollowButton(true)}
            onMouseLeave={() => setHoverFollowButton(false)}
            onClick={() => {
                handleFollow(
                    isFollowing,
                    collectionId,
                    openSignUpModal,
                    () => refreshCollection(collectionId)
                );
            }}
            borderRadius={6}
            sx={{
                mt: !isMobile ? 2.75 : null,
                ml: !isMobile ? 4 : 2,
                px: 3,
                backgroundColor: isFollowing ? theme.palette.secondary.main : null,
                ...sx
            }}
        />
    );
}