import {
    Avatar,
    Box,
    Typography
} from "@mui/material";

import logo from "../../../../../../assets/images/slyke-logo-cropped.png";

export default function SlykeLogo(props) {
    const {
        isMobile
    } = props;

    return (
        <Box
            sx={{
                position: "absolute",
                bottom: 0,
                right: 20,
                margin: "auto",
                display: {
                    xs: "none",
                    sm: "block"
                }
            }}
        >
            <Avatar
                src={logo}
                sx={{
                    width: 36,
                    height: 36,
                    mr: 1,
                    mb: 3,
                    display: "inline-flex"
                }}
            />
        </Box>
    );
}