import {
    Box
} from "@mui/material";
import {
    useEffect,
    Fragment,
    useState
} from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import ItemViewNavbar from "./ItemViewNavbar/ItemViewNavbar";
import ItemViewCard from "./ItemViewCard/ItemViewCard";

import useGetCollectionFromParam from "../../../hooks/data/useGetCollectionFromParam";
import useRefreshSpecificCollection from "../../../hooks/data/useRefreshSpecificCollection";

import { getUserData } from "../../../store/user/userSlice";
import { getCollections } from "../../../store/collection/collectionSlice";
import AddItemPopup from "../../AddItemPopup/AddItemPopup";
import useAddItemModal from "../../../hooks/state/useAddItemModal";
import PopupA from "../../CollectEmailA/Popup";
import PopupB from "../../CollectEmailB/Popup";

export default function ItemViewBodyMobile() {
    /* 
    
    This is the mobile version of the item view page.
    
    */

    const [searchParams, setSearchParams] = useSearchParams();

    const {
        isLoggedIn
    } = useSelector(getUserData);
    const collection = useSelector(getCollections)[0];

    const refreshSpecificCollection = useRefreshSpecificCollection();
    useGetCollectionFromParam();

    const {
        isAddItemModalOpen,
        openAddItemModal,
        closeAddItemModal,
        AddItemModal
    } = useAddItemModal();
    const [addItemPopupOpen, setAddItemPopupOpen] = useState(false);
    const [popupA, setPopupA] = useState(false);
    const [popupB, setPopupB] = useState(false);

    const {
        userId
    } = useSelector(getUserData);

    useEffect(() => {
        // show a CTA popup after 8 seconds
        setTimeout(() => {
            if (!userId) {
                if (Math.random() < 0.5) {
                    setPopupA(true);
                } else {
                    setPopupB(true);
                }
                // setAddItemPopupOpen(true);
            }
        }, 8000);
    }, []);

    useEffect(() => {
        /*
        
        Upon collection load, navigate to the item that 
        corresponds to the item index search param.

        */

        const itemIndex = searchParams.get("itemIndex");
        if (collection) {
            if (collection.items[itemIndex]) {
                document.getElementById(`item_${itemIndex}`).scrollIntoView({
                    behavior: "instant",
                    block: "center"
                });
            }
        }
    }, [collection]);

    useEffect(() => {
        if (collection) {
            refreshSpecificCollection(collection.collectionId);
        }
    }, [isLoggedIn]);

    return (
        <Box>
            {
                collection ?
                    <Fragment>
                        <ItemViewNavbar
                            collectionTitle={collection.collectionTitle}
                            collectionId={collection.collectionId}
                        />

                        {
                            collection.items.map((item, index) => {
                                return (
                                    <ItemViewCard
                                        key={item.itemId}
                                        item={item}
                                        itemIndex={index}
                                        collectionId={collection.collectionId}
                                        updateItemIndex={() => setSearchParams({
                                            collectionId: searchParams.get("collectionId"),
                                            itemIndex: index
                                        }, { replace: true })}
                                    />
                                );
                            })
                        }
                    </Fragment> : null
            }

            <AddItemModal
                open={isAddItemModalOpen}
                close={closeAddItemModal}
                collectionIndex={0}
            />
            {/* <AddItemPopup
                openAddItem={openAddItemModal}
                isOpen={addItemPopupOpen}
                close={() => setAddItemPopupOpen(!addItemPopupOpen)}
            /> */}

            <PopupA
                isOpen={popupA}
                close={() => setPopupA(!popupA)}
            />

            <PopupB
                isOpen={popupB}
                close={() => setPopupB(!popupB)}
            />
        </Box>
    );
}