import {
    Box,
    Grid,
    useTheme
} from "@mui/material";
import {
    Fragment,
    useEffect,
    useState
} from "react";
import {
    useSelector
} from "react-redux";

import Navbar from "../Navbar/Navbar";
import CollectionOverviewHeader from "./CollectionOverviewHeader/CollectionOverviewHeader";
import ContentCard from "../Common/ContentCard/ContentCard";
import BlankItemThumbnail from "./BlankItemThumbnail/BlankItemThumbnail";

import {
    getUserData
} from "../../store/user/userSlice";
import {
    getCollections
} from "../../store/collection/collectionSlice";

import useActiveFeed from "../../hooks/state/useActiveFeed";
import useLoginModal from "../../hooks/state/useLoginModal";
import useHandleFollow from "../../hooks/data/useHandleFollow";
import useNavigateToItem from "../../hooks/navigation/useNavigateToItem";
import useIsMobile from "../../hooks/media/useIsMobile";
import useGetImageLink from "../../hooks/data/useGetImageLink";
import useCardBreakpoints from "../../hooks/media/useCardBreakpoints";
import useGetCollectionFromParam from "../../hooks/data/useGetCollectionFromParam";
import useAddItemModal from "../../hooks/state/useAddItemModal";
import useIsCollectionCurator from "../../hooks/data/useIsCollectionCurator";
import AddItemPopup from "../AddItemPopup/AddItemPopup";
import PopupA from "../CollectEmailA/Popup";
import PopupB from "../CollectEmailB/Popup";

export default function CollectionOverview() {
    const theme = useTheme();

    const {
        userId
    } = useSelector(getUserData);
    const collection = useSelector(getCollections)[0];

    useGetCollectionFromParam();
    const isCurator = useIsCollectionCurator(collection ? collection.curators : null);
    const [addItemPopupOpen, setAddItemPopupOpen] = useState(false);
    const [popupA, setPopupA] = useState(false);
    const [popupB, setPopupB] = useState(false);

    const {
        isSignUpModalOpen,
        openSignUpModal,
        closeSignUpModal,
        AccountModalHandler
    } = useLoginModal();
    const {
        refreshCollection
    } = useActiveFeed(userId, true);
    const handleFollow = useHandleFollow();
    const navigateToItem = useNavigateToItem();
    const isMobile = useIsMobile();
    const getImageLink = useGetImageLink();
    const {
        threeCards,
        twoCards,
        oneCard
    } = useCardBreakpoints(theme);
    const {
        isAddItemModalOpen,
        openAddItemModal,
        closeAddItemModal,
        AddItemModal
    } = useAddItemModal();

    let cardsPerRow = 0;
    if (oneCard) {
        cardsPerRow = 1;
    } else if (twoCards) {
        cardsPerRow = 2;
    } else if (threeCards) {
        cardsPerRow = 3;
    } else {
        cardsPerRow = 4;
    }

    useEffect(() => {
        // show a CTA popup after 8 seconds
        setTimeout(() => {
            if (!userId) {
                if (Math.random() < 0.5) {
                    setPopupA(true);
                } else {
                    setPopupB(true);
                }
                // setAddItemPopupOpen(true);
            }
        }, 8000);
    }, []);

    return (
        <Fragment>
            <Navbar
                search
                currentCollectionIndex={0}
            />

            <Box
                sx={{
                    mt: !isMobile ? 14 : 10
                }}
            >
                {
                    collection ?
                        <Fragment>
                            <CollectionOverviewHeader
                                pendingItemCount={"9+"}
                                collection={collection}
                                refreshCollection={refreshCollection}
                                openSignUpModal={openSignUpModal}
                                handleFollow={handleFollow}
                                isMobile={isMobile}
                                isCurator={isCurator}
                            />
                            <Grid
                                container
                                sx={{
                                    mt: 2
                                }}
                            >
                                {
                                    collection.items.map((item, ind) => {
                                        return (
                                            <Grid
                                                key={`item-preview-${item.title}-${ind}`}
                                                item
                                                xs={12 / cardsPerRow}
                                                sx={{
                                                    my: 2
                                                }}
                                            >
                                                <ContentCard
                                                    key={`item-preview-${item.title}-${ind}`}
                                                    itemType={item.itemType}
                                                    isVideo={!!item.ogVideoLink}
                                                    title={item.title}
                                                    collectionId={collection.collectionId}
                                                    itemIndex={ind}
                                                    imageURL={getImageLink({
                                                        image: item.image,
                                                        variant: "itempreviewthumb",
                                                        itemType: item.itemType,
                                                        ogVideoLink: item.ogVideoLink,
                                                        height: 400,
                                                        width: 400
                                                    })}
                                                    isMobile={isMobile}
                                                    onClick={() => navigateToItem(collection.collectionId, ind)}
                                                    isEngagement
                                                    views={item.views}
                                                    likes={item.likes}
                                                    comments={item.comments ? item.comments.length : 0}
                                                />
                                            </Grid>
                                        );
                                    })
                                }
                                <Grid
                                    key={"item-preview-grid-cta"}
                                    item
                                    xs={12 / cardsPerRow}
                                    sx={{
                                        my: 2
                                    }}
                                >
                                    <BlankItemThumbnail
                                        key={"item-preview-cta"}
                                        isMobile={isMobile}
                                        onClick={openAddItemModal}
                                    />
                                </Grid>
                            </Grid>
                        </Fragment>
                        : null
                }
            </Box>

            <AccountModalHandler
                signUp={isSignUpModalOpen}
                onCloseSignUp={closeSignUpModal}
            />

            <AddItemModal
                open={isAddItemModalOpen}
                close={closeAddItemModal}
                collectionIndex={0}
                isMobile={isMobile}
            />

            <AddItemPopup
                isMobile={isMobile}
                openAddItem={openAddItemModal}
                isOpen={addItemPopupOpen}
                close={() => setAddItemPopupOpen(!addItemPopupOpen)}
            />

            {/* Add to item view as well */}
            <PopupA
                isMobile={isMobile}
                isOpen={popupA}
                close={() => setPopupA(!popupA)}
            />

            <PopupB
                isMobile={isMobile}
                isOpen={popupB}
                close={() => setPopupB(!popupB)}
            />
        </Fragment>
    );
}