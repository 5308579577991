import {
    Typography,
    List,
    Box
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import CelebrationIcon from "@mui/icons-material/Celebration";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";

import BaseModal from "../../BaseModal/BaseModal";
import CloseButton from "../CloseButton/CloseButton";
import CTA from "./CTA/CTA";
import useNavigateToBrowseCollections from "../../../../hooks/navigation/useNavigateToBrowseCollections";

export default function ChangePasswordSuccess(props) {
    const {
        isMobile,
        changePasswordSuccess,
        setChangePasswordSuccess,
        createCollection,
        addItem
    } = props;

    const navigateToBrowseCollections = useNavigateToBrowseCollections();

    return (
        <BaseModal
            open={changePasswordSuccess}
            onClose={() => setChangePasswordSuccess(false)}
            sx={{
                width: !isMobile ? 400 : 300,
                overflowY: isMobile ? "scroll" : null,
                p: !isMobile ? 3 : 1,
                pb: 5
            }}
        >
            <Box>
                <Typography
                    variant="h5"
                    sx={{
                        my: 4,
                        textAlign: "center",
                        textDecoration: "underline"
                    }}
                >
                    All set with your new password and Slyke account!
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        my: 1,
                        textAlign: "center"
                    }}
                >
                    What now?
                </Typography>

                <List
                    sx={{
                        width: 275,
                        mx: "auto"
                    }}
                >
                    <CTA
                        hover
                        icon={
                            <CreateIcon
                                sx={{
                                    borderRadius: 8
                                }}
                            />
                        }
                        text="Create a Collection"
                        onClick={() => {
                            setChangePasswordSuccess(false);
                            createCollection();
                        }}
                    />
                    <CTA
                        hover
                        icon={
                            <FollowTheSignsIcon
                                sx={{
                                    borderRadius: 8
                                }}
                            />
                        }
                        text="Browse and Follow Collections"
                        onClick={navigateToBrowseCollections}
                    />
                    <CTA
                        hover
                        icon={
                            <AddIcon
                                sx={{
                                    borderRadius: 8
                                }}
                            />
                        }
                        text="Add Items to Collections"
                        onClick={() => {
                            setChangePasswordSuccess(false);
                            addItem();
                        }}
                    />
                    <CTA
                        icon={
                            <CelebrationIcon
                                sx={{
                                    borderRadius: 8
                                }}
                            />
                        }
                        text="Have Fun!"
                    />
                </List>
            </Box>

            <CloseButton
                onClose={() => setChangePasswordSuccess(false)}
            />
        </BaseModal>
    );
}