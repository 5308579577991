import {
    Fragment,
    useEffect,
    useState
} from "react";
import {
    useSelector
} from "react-redux";
import {
    useSearchParams
} from "react-router-dom";

import {
    getUserData
} from "../../store/user/userSlice";
import {
    getCollections
} from "../../store/collection/collectionSlice";

import Navbar from "../../components/Navbar/Navbar";
import Hero from "./Hero/Hero";

import useIsMobile from "../../hooks/media/useIsMobile";
import CollectionPreviews from "./CollectionPreviews/CollectionPreviews";
import FeedButtons from "../Common/FeedButtons/FeedButtons";
import useActiveFeed from "../../hooks/state/useActiveFeed";
import useIntersection from "../../hooks/useIntersection";
import useLoginModal from "../../hooks/state/useLoginModal";
import useCreateCollectionModal from "../../hooks/state/useCreateCollectionModal";
import useAddItemModal from "../../hooks/state/useAddItemModal";

export default function Landing() {
    const {
        userId
    } = useSelector(getUserData);
    const collections = useSelector(getCollections);

    const [showChangePassword, setShowChangePassword] = useState();
    const [changePasswordCode, setChangePasswordCode] = useState();
    const [showForgotPassword, setShowForgotPassword] = useState();
    const [forgotPasswordCode, setForgotPasswordCode] = useState();
    const [username, setUsername] = useState();

    const {
        AccountModalHandler
    } = useLoginModal();
    const isMobile = useIsMobile();
    const {
        activeFeed,
        setActiveFeed,
        incrementSegment,
        refreshCollection
    } = useActiveFeed(userId);
    const {
        setupObserver
    } = useIntersection();

    const {
        isAddItemModalOpen,
        openAddItemModal,
        closeAddItemModal,
        AddItemModal
    } = useAddItemModal();
    const {
        isCreateCollectionModalOpen,
        openCreateCollectionModal,
        closeCreateCollectionModal,
        CreateCollectionModal
    } = useCreateCollectionModal();

    const [searchParams, setSearchParams] = useSearchParams();

    const handleChangePasswordParams = () => {
        const passwordCode = searchParams.get("changePassword");
        const username = searchParams.get("username");
        if (passwordCode && username && !showChangePassword) {
            setShowChangePassword(true);
            setChangePasswordCode(passwordCode);
            setUsername(username);

            // clean up
            searchParams.delete("changePassword");
            searchParams.delete("username");
            setSearchParams(searchParams);
        }
    };

    const handleForgotPasswordParam = () => {
        const forgotPasswordCode = searchParams.get("forgotPasswordCode");
        if (forgotPasswordCode) {
            setShowForgotPassword(true);
            setForgotPasswordCode(forgotPasswordCode);

            // clean up
            searchParams.delete("forgotPasswordCode");
            setSearchParams(searchParams);
        }
    };

    useEffect(() => {
        handleChangePasswordParams();
        handleForgotPasswordParam();
    }, []);
    return (
        <Fragment>
            <Navbar />
            <Hero
                isMobile={isMobile}
                userId={userId}
            />

            <FeedButtons
                isMobile={isMobile}
                activeFeed={activeFeed}
                setActiveFeed={setActiveFeed}
                userId={userId}
            />

            {
                collections.length ?
                    <CollectionPreviews
                        isMobile={isMobile}
                        collections={collections}
                        incrementSegment={incrementSegment}
                        setupObserver={setupObserver}
                        refreshCollection={refreshCollection}
                    /> : null
            }

            <AccountModalHandler
                isMobile={isMobile}
                changePassword={showChangePassword}
                changePasswordCode={changePasswordCode}
                username={username}
                createCollection={openCreateCollectionModal}
                addItem={openAddItemModal}
                forgotPassword={showForgotPassword}
                forgotPasswordCode={forgotPasswordCode}
            />

            <AddItemModal
                open={isAddItemModalOpen}
                close={closeAddItemModal}
                isMobile={isMobile}
            />
            <CreateCollectionModal
                open={isCreateCollectionModalOpen}
                close={closeCreateCollectionModal}
                isMobile={isMobile}
                openAddItem={openAddItemModal}
            />
        </Fragment>
    );
}