import { useState } from "react";
import {
    Box
} from "@mui/material";

import useGetImageLink from "../../../../../hooks/data/useGetImageLink";
import ImageModal from "../../../../Common/ImageModal/ImageModal";

export default function PhotoItemBody(props) {
    const {
        image
    } = props;

    const variant = "mediaitemviewsmall";

    const [showImageModal, setShowImageModal] = useState(false);

    const getImageLink = useGetImageLink();

    return (
        <Box
            sx={{
                my: 1,
                flexGrow: 1
            }}
        >
            <img
                onClick={() => setShowImageModal(true)}
                width={200}
                height={200}
                src={getImageLink({ image, variant })}
                style={{
                    borderRadius: "16px 16px 16px 16px"
                }}
            />

            <ImageModal
                showImageModal={showImageModal}
                setShowImageModal={setShowImageModal}
                image={image}
                isMobile
            />
        </Box>
    );
}