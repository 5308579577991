import {
    useState,
    useEffect
} from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import apiRoutes from "../../constants/api-routes";

export default function useGetUserFromParam() {
    const [searchParams] = useSearchParams();

    const [displayName, setDisplayName] = useState("");
    const [handle, setHandle] = useState("");
    const [userItemsPosted, setUserItemsPosted] = useState("");
    const [userCollectionsFollowed, setUserCollectionsFollowed] = useState("");

    const getUserEngagementStats = (userId) => {
        axios.get(apiRoutes.getUserEngagementStats, {
            params: {
                userId
            }
        }).then(res => {
            setUserItemsPosted(res.data.engagementStats.ItemsAdded);
            setUserCollectionsFollowed(res.data.engagementStats.CollectionsFollowed);
        });
    };

    const getUserById = (userId) => {
        axios.get(apiRoutes.getUserById, {
            params: {
                userId
            }
        }).then(res => {
            const {
                displayName,
                handle
            } = res.data.user;
            setDisplayName(displayName);
            setHandle(handle);
        });
    };

    useEffect(() => {
        const userId = searchParams.get("userId");

        getUserEngagementStats(userId);
        getUserById(userId);
    }, [searchParams.get("userId")]);


    return {
        userId: searchParams.get("userId"),
        displayName,
        handle,
        userItemsPosted,
        userCollectionsFollowed
    };
}