import {
    Typography,
    Box,
    IconButton,
    Link,
    useTheme
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

export default function BlankItemThumbnail(props) {
    const {
        onClick,
        isMobile
    } = props;

    const textStyle = {
        fontFamily: "Open Sans",
        fontSize: 18
    };

    const theme = useTheme();

    return (
        <Box>
            <Box
                onClick={onClick}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: !isMobile ? 360 : 270,
                    height: !isMobile ? 360 : 270,
                    border: `${theme.palette.grey["300"]} solid 20px`,
                    borderRadius: 4,
                    margin: "auto",
                    "&:before": {
                        border: `${theme.palette.grey["300"]} dashed 2px`,
                        content: "\" \"",
                        width: !isMobile ? 356 : 266,
                        height: !isMobile ? 356 : 266,
                        position: "absolute"
                    }
                }}
            >
                <IconButton>
                    <AddOutlinedIcon
                        sx={{
                            fontSize: 30,
                            border: `${theme.palette.grey["700"]} solid 2px`,
                            borderRadius: 1,
                            color: theme.palette.grey["700"],
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }}
                    />
                </IconButton>
                <Box
                    sx={{
                        textAlign: "center",
                        "&:hover": {
                            cursor: "pointer"
                        }
                    }}
                >
                    <Link
                        component='button'
                        sx={{
                            textAlign: "center",
                            ...textStyle,
                            color: theme.palette.secondary.main,
                            textDecorationColor: theme.palette.secondary.main
                        }}
                    >
                        Add an Item
                    </Link>
                </Box>
                <Typography
                    sx={{
                        textAlign: "center",
                        ...textStyle
                    }}
                >
                    to get this collection started!
                </Typography>
                <Typography
                    sx={{
                        textAlign: "center",
                        ...textStyle
                    }}
                >
                    Empty Slyke Collections will get removed.
                </Typography>
            </Box>
        </Box>
    );
}