import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    username: null,
    userId: null,
    token: null,
    idToken: null,
    displayName: null,
    handle: null,
    refreshToken: null
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        setUsername: (state, action) => {
            state.username = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setIdToken: (state, action) => {
            state.idToken = action.payload;
        },
        setRefreshToken: (state, action) => {
            state.refreshToken = action.payload;
        },
        setUserId: (state, action) => {
            state.userId = action.payload;
        },
        setDisplayName: (state, action) => {
            state.displayName = action.payload;
        },
        setHandle: (state, action) => {
            state.handle = action.payload;
        }
    },
});

export const {
    setIsLoggedIn,
    setUsername,
    setToken,
    setIdToken,
    setRefreshToken,
    setUserId,
    setDisplayName,
    setHandle
} = userSlice.actions;

export const getIsLoggedIn = (state) => state.user.isLoggedIn;
export const getUsername = (state) => state.user.username;
export const getToken = (state) => state.user.token;
export const getIdToken = (state) => state.user.idToken;
export const getRefreshToken = (state) => state.user.refreshToken;
export const getUserId = (state) => state.user.userId;
export const getDisplayName = (state) => state.user.displayName;
export const getHandle = (state) => state.user.handle;
export const getUserData = (state) => state.user;

export default userSlice.reducer;