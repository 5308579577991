import {
    Modal,
    Button,
    IconButton,
    Typography,
    Box,
    Grid,
    TextField,
    Alert
} from '@mui/material'
import {
    Fragment,
    useState
} from 'react'
import CloseIcon from '@mui/icons-material/Close'
import colors from '../../../constants/colors'
import { useDispatch } from 'react-redux'
import {
    setIsLoggedIn,
    setUserId,
    setToken,
    setUsername,
    setDisplayName,
    setHandle
} from '../../../store/user/userSlice'
import axios from 'axios'
import apiRoutes from '../../../constants/api-routes'
import { useEffect } from 'react'

export default function ChangePasswordModal(props) {
    const {
        isMobile
    } = props;

    const dispatch = useDispatch()
    const [error, setError] = useState(false)
    const [userInfo, setUserInfo] = useState({
        tempPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        username: '',
        code: '',
        session: props.session
    })

    const formFields = [{
        skip: props.forgotPassword,
        label: 'Temporary Password',
        value: props.changePasswordCode || userInfo.tempPassword,
        onChange: (e) => { setUserInfo({ ...userInfo, tempPassword: e.target.value }) },
        plainText: true
    },
    {
        skip: !props.forgotPassword,
        label: 'Username',
        value: props.changePasswordUsername || userInfo.username,
        onChange: (e) => { setUserInfo({ ...userInfo, username: e.target.value }) },
        plainText: true
    },
    {
        skip: !props.forgotPassword,
        label: 'Password Reset Code',
        value: userInfo.code,
        onChange: (e) => { setUserInfo({ ...userInfo, code: e.target.value }) },
        plainText: true
    },
    {
        label: 'New Password',
        value: userInfo.newPassword,
        onChange: (e) => { setUserInfo({ ...userInfo, newPassword: e.target.value }) }
    }, {
        label: 'Confirm New Password',
        value: userInfo.confirmNewPassword,
        onChange: (e) => { setUserInfo({ ...userInfo, confirmNewPassword: e.target.value }) }
    }]

    const submitNewPassword = async () => {
        // Used for new users logging in for the first time
        if (userInfo.newPassword !== userInfo.confirmNewPassword) {
            setError('Passwords do not match!')
            return
        }

        setError(false)
        let loginResponse = await axios.post(apiRoutes.authChallenge, {
            username: props.changePasswordUsername || props.username,
            challengeName: 'NEW_PASSWORD_REQUIRED',
            challengeResponses: {
                NEW_PASSWORD: userInfo.newPassword,
                USERNAME: props.changePasswordUsername || props.username,
                'userAttributes.name': props.changePasswordUsername || props.handle
            },
            session: userInfo.session || props.session
        }).catch(() => {
            setError('Failed to change password!')
        })

        if (loginResponse.data) {
            dispatch(setToken(loginResponse.data.token))
            dispatch(setUserId(loginResponse.data.userId))
            dispatch(setUsername(loginResponse.data.username))
            dispatch(setDisplayName(loginResponse.data.displayName))
            dispatch(setHandle(loginResponse.data.handle))
            dispatch(setIsLoggedIn(true))
            props.openChangePasswordSuccess()
            props.close()
        }
    }

    const submitChangedPassword = async () => {
        // Used for new existing users changing password
        if (userInfo.newPassword !== userInfo.confirmNewPassword) {
            setError('Passwords do not match!')
            return
        }

        setError(false)
        let changeResponse = await axios.post(apiRoutes.confirmResetPassword, {
            username: userInfo.username,
            password: userInfo.newPassword,
            code: userInfo.code
        }).catch(() => {
            setError('Failed to change password!')
        })

        if (changeResponse.status === 200) {
            props.openChangePasswordSuccess()
            props.close()
        }
    }

    useEffect(() => {
        if (props.changePasswordCode && props.changePasswordUsername) {
            axios.post(apiRoutes.login, {
                username: props.changePasswordUsername,
                password: props.changePasswordCode
            }).catch((e) => {
                if (e.response.status === 401 && e.response.data.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    const session = e.response.data.session
                    setUserInfo({ ...userInfo, session: session })
                }
            })
        }
    }, [props.changePasswordCode, props.changePasswordUsername])

    return (
        <Modal
            open={props.open}
            onClose={() => {
                props.close()
                setError(false)
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: !isMobile ? 350 : "100%",
                    height: !isMobile ? null : "100%",
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 0,
                    m: 0,
                    borderRadius: !isMobile ? 5 : 0,
                    '&:active': {
                        outline: 'none'
                    },
                    '&:focus': {
                        outline: 'none'
                    },
                    overflowY: !isMobile ? null : "scroll"
                }}
            >
                <Box
                    sx={{
                        p: 4
                    }}
                >
                    <Typography
                        variant='h6'
                        sx={{
                            fontFamily: 'Open Sans',
                            borderBottom: '1px solid black',
                            width: 182,
                            fontWeight: 'bold',
                            mb: !isMobile ? 3 : 1
                        }}
                    >
                        Set New Password
                    </Typography>
                    {
                        !isMobile ?
                            <Typography
                                variant='body2'
                                sx={{
                                    width: 250,
                                    mb: !isMobile ? 3 : 1
                                }}
                            >
                                {
                                    props.forgotPassword ?
                                        'Check your email for your password reset confirmation code.' :
                                        'Create your new password below!'
                                }
                            </Typography> : null
                    }
                    {
                        formFields.map((field) => {
                            if (field.skip) {
                                return
                            }
                            return (
                                <Fragment
                                    key={field.label}
                                >
                                    <Grid
                                        container
                                        direction='row'
                                    >
                                        <Grid
                                            item
                                        >
                                            <Typography
                                                variant='body1'
                                                sx={{
                                                    fontWeight: 'bold',
                                                    fontFamily: 'Open Sans'
                                                }}
                                            >
                                                {field.label}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <TextField
                                        autoComplete="new-password"
                                        variant='outlined'
                                        type={field.plainText ? '' : 'password'}
                                        value={field.value}
                                        onChange={field.onChange}
                                        sx={{
                                            p: 0,
                                            m: 0,
                                            '& .MuiOutlinedInput-root': {
                                                height: 35
                                            },
                                            mb: !isMobile ? 2 : 1,
                                            width: !isMobile ? 275 : 250
                                        }}
                                    />
                                </Fragment>
                            )
                        })
                    }
                    {
                        error ?
                            <Alert
                                sx={{
                                    p: 0,
                                    mb: 3
                                }}
                                severity="error"
                            >
                                {error}
                            </Alert> : null
                    }
                    <Alert
                        severity="info"
                        sx={{
                            pl: 0
                        }}
                    >
                        Passwords must:<br />
                        <ul
                            style={{
                                paddingLeft: 10
                            }}
                        >
                            <li>Be at least 8 characters long</li>
                            <li>Contain 1 uppercase letter</li>
                            <li>Contain 1 lowercase letter</li>
                            <li>Contain 1 symbol</li>
                            <li>Contain 1 number</li>
                        </ul>
                    </Alert>
                    <Button
                        onClick={() => {
                            props.forgotPassword ?
                                submitChangedPassword() :
                                submitNewPassword()
                        }}
                        variant='contained'
                        sx={{
                            display: 'block',
                            width: !isMobile ? 275 : 250,
                            color: 'white',
                            textTransform: 'none',
                            fontFamily: 'Open Sans',
                            mt: 1,
                            mb: 2
                        }}
                    >
                        Submit
                    </Button>
                </Box>

                {/* Close Icon */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: {
                            xs: 9,
                            sm: 5
                        },
                        left: {
                            xs: 9,
                            sm: 5
                        },
                        margin: 'auto'
                    }}
                >
                    <IconButton
                        onClick={() => {
                            props.close()
                            setError(false)
                        }}
                        sx={{
                            m: 0,
                            p: 0
                        }}
                    >
                        <CloseIcon
                            sx={{
                                color: colors.gray
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>
        </Modal>
    )
}