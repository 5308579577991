import {
    Box,
    Typography,
    useTheme
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

export default function Success(props) {
    const {
        isMobile
    } = props;

    const theme = useTheme();

    return (
        <Box
            sx={{
                p: isMobile ? 1 : 5,
                textAlign: "center"
            }}
        >
            <CheckIcon
                sx={{
                    fontSize: 125,
                    color: theme.palette.common.white,
                    backgroundColor: "#6685FF",
                    borderRadius: 5,
                    mb: isMobile ? 1 : 6,
                    mt: isMobile ? 1 : 8
                }}
            />
            <Typography
                variant="h5"
                sx={{
                    fontWeight: "bold",
                    mb: 2
                }}
            >
                Thank you for contacting Slyke!
            </Typography>
            <Typography
                sx={{
                    fontWeight: "bold",
                    pb: 10
                }}
            >
                We will get back to you soon
            </Typography>
        </Box>
    );
}