import {
    Link,
    Typography
} from "@mui/material";

import useNavigateToTerms from "../../../../../../hooks/navigation/useNavigateToTerms";

export default function TermsLink() {
    const navigateToTerms = useNavigateToTerms();

    return (
        <Typography
            variant='subtitle2'
            sx={{
                mt: 2
            }}
        >
            Creating an account means you&apos;re accepting our&nbsp;
            <Link
                onClick={navigateToTerms}
                sx={{
                    "&:hover": {
                        cursor: "pointer"
                    }
                }}
            >
                Terms of Service
            </Link>
        </Typography>
    );
}