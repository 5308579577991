import {
    useState
} from "react";
import {
    Box,
    Link,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import axios from "axios";

import DisplayName from "./DisplayName/DisplayName";
import Username from "../../Username/Username";
import Email from "./Email/Email";
import PrimaryButton from "../../../PrimaryButton/PrimaryButton";
import BrowseAsGuestLink from "./BrowseAsGuestLink/BrowseAsGuestLink";
import TermsLink from "./TermsLink/TermsLink";
import MobileSignInLink from "./MobileSignInLink/MobileSignInLink";
import Error from "../../../Error/Error";

import apiRoutes from "../../../../../constants/api-routes";

import useLoadingModal from "../../../../../hooks/state/useLoadingModal";

export default function FormBody(props) {
    const {
        isMobile,
        onCloseSignUp,
        openSignUpSuccess,
        openSignIn
    } = props;

    const [displayName, setDisplayName] = useState("");
    const [isDisplayNameValid, setIsDisplayNameValid] = useState(true);

    const [username, setUsername] = useState("");
    const [isUsernameValid, setIsUsernameValid] = useState(true);

    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(true);
    const theme = useTheme();

    const {
        loading,
        setLoading,
        LoadingModal
    } = useLoadingModal();

    const handleSubmit = async () => {
        setLoading(true);
        axios.post(apiRoutes.signUp, {
            name: `@${username}`,
            email: email,
            username: username,
            displayName: displayName
        }).then(() => {
            onCloseSignUp();
            openSignUpSuccess();
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            alert("Something unexpected happened!");
        });
    };

    return (
        <Box>
            <DisplayName
                isMobile={isMobile}
                displayName={displayName}
                setDisplayName={setDisplayName}
                isDisplayNameValid={isDisplayNameValid}
                setIsDisplayNameValid={setIsDisplayNameValid}
            />
            <Username
                isMobile={isMobile}
                username={username}
                setUsername={setUsername}
                isUsernameValid={isUsernameValid}
                setIsUsernameValid={setIsUsernameValid}
            />
            <Email
                isMobile={isMobile}
                email={email}
                setEmail={setEmail}
                isEmailValid={isEmailValid}
                setIsEmailValid={setIsEmailValid}
            />

            <Stack
                direction="row"
            >
                <Typography
                    sx={{
                        mr: 1
                    }}
                >
                    Already Registered?
                </Typography>
                <Link
                    onClick={() => {
                        onCloseSignUp();
                        openSignIn();
                    }}
                    sx={{
                        textDecoration: "underline",
                        fontSize: 18,
                        fontFamily: theme.typography.fontFamily,
                        "&:hover": {
                            cursor: "pointer",
                        }
                    }}
                >
                    Log In
                </Link>
            </Stack>

            {
                !isDisplayNameValid ?
                    <Error
                        isMobile={isMobile}
                        text="Display names must be 3-35 characters and can only contain alphanumeric characters, spaces, periods and hyphens."
                    /> : null
            }
            {
                !isUsernameValid ?
                    <Error
                        isMobile={isMobile}
                        text="Usernames must be 3-23 characters and can only contain alphanumeric characters and symbols."
                    /> : null
            }
            {
                !isEmailValid ?
                    <Error
                        isMobile={isMobile}
                        text="Please enter a valid email."
                    /> : null
            }

            <PrimaryButton
                onClick={handleSubmit}
                disabled={!(
                    (isDisplayNameValid && displayName) &&
                    (isUsernameValid && username) &&
                    (isEmailValid && email)
                )}
                text="Submit"
                sx={{
                    width: !isMobile ? 300 : "90%",
                    mt: 1,
                    mb: 2
                }}
            />

            <TermsLink
                isMobile={isMobile}
            />
            {
                !isMobile ?
                    null :
                    <MobileSignInLink
                        close={onCloseSignUp}
                        openSignIn={openSignIn}
                    />
            }

            <LoadingModal
                open={loading}
                onClose={() => setLoading(false)}
            />
        </Box>
    );
}