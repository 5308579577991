import colors from '../../constants/colors'
import {
    Box, IconButton, Typography, useTheme
} from '@mui/material'
import { useState, useEffect } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

export default function TextItemPreview(props) {
    const [textChunk, setTextChunk] = useState(0)
    const [textShowing, setTextShowing] = useState('')
    const theme = useTheme()
    const chunkSize = 250
    const numberOfChunks = Math.ceil(props.text.length / chunkSize)

    useEffect(() => {
        const regex = /(<([^>]+)>)/ig

        // Split the text into an array of words
        const words = props.text.split(' ')

        // Use reduce() to create an array of chunks
        const chunks = words.reduce((result, word) => {
            // If the current chunk is empty or adding the current word would exceed the chunk size, start a new chunk
            if (result.length === 0 || result[result.length - 1].length + word.length + 1 > chunkSize) {
                result.push(word)
            } else {  // Otherwise, add the current word to the current chunk
                result[result.length - 1] += ' ' + word
            }
            return result
        }, [])
        setTextShowing(chunks[textChunk].replace(regex, ' ').replace(/(&nbsp;|<br>)+/ig, ' '))
    }, [textChunk])

    return (
        <Box
            sx={{
                height: 400,
                width: '97%',
                backgroundColor: colors.darkGray,
                borderRadius: 4,
                pl: 1,
                position: 'relative',
                zIndex: 1
            }}
        >
            <Typography
                sx={{
                    fontFamily: theme.typography.openSans,
                    color: theme.palette.common.white,
                    width: '60%',
                    mx: 'auto',
                    my: 'auto',
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    top: '20%',
                    zIndex: 1
                }}
            >
                {textShowing}
            </Typography>
            <Box
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: '40%'
                }}
            >
                <IconButton
                    sx={{
                        display: numberOfChunks === 1 ? 'none' : null,
                        color: theme.palette.primary.main,
                        border: `2px solid ${theme.palette.primary.main}`,
                        ml: 1
                    }}
                    onClick={() => {
                        if (textChunk !== 0) {
                            setTextChunk(textChunk - 1)
                        }
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    right: 0,
                    top: '40%'
                }}
            >
                <IconButton
                    sx={{
                        display: numberOfChunks === 1 ? 'none' : null,
                        color: theme.palette.primary.main,
                        border: `2px solid ${theme.palette.primary.main}`,
                        mr: 1
                    }}
                    onClick={() => {
                        if (textChunk + 1 < numberOfChunks) {
                            setTextChunk(textChunk + 1)
                        }
                    }}
                >
                    <ArrowForwardIcon />
                </IconButton>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    right: '45%',
                    bottom: 0,
                    mr: 1
                }}
            >
                <Typography
                    variant='h6'
                    sx={{
                        color: theme.palette.primary.main,
                    }}
                    onClick={() => setTextChunk(textChunk + 1)}
                >
                    {textChunk + 1} / {numberOfChunks}
                </Typography>
            </Box>
        </Box>
    )
}