import useIsMobile from "../../hooks/media/useIsMobile";

import ItemViewBodyMobile from "../../components/ItemView/ItemViewBodyMobile/ItemViewBodyMobile";
import ItemViewBodyDesktop from "../../components/ItemView/ItemViewBodyDesktop/ItemViewBodyDesktop";

export default function ItemView() {
    const isMobile = useIsMobile();

    if (isMobile) {
        return (
            <ItemViewBodyMobile />
        );
    } else {
        return (
            <ItemViewBodyDesktop />
        );
    }
}