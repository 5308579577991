import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const menuItemStyle = {
    fontFamily: 'Open Sans',
}

export default function MobileFilterMenu(props) {
    return (
        <Menu
            id="basic-menu"
            anchorEl={props.anchorEl}
            open={props.open}
            onClose={props.handleClose}
        >
            {
                props.filters.map((filter) => {
                    return (
                        <MenuItem
                            key={filter}
                            onClick={() => {
                                props.setActiveFilter(filter)
                                props.handleClose()
                            }}
                            sx={{
                                ...menuItemStyle
                            }}
                        >
                            {filter}
                        </MenuItem>
                    )
                })
            }
        </Menu>
    )
}