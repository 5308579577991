import { useNavigate } from "react-router-dom";

export default function useNavigateToTerms() {
    const navigate = useNavigate();

    const navigateToTerms = () => {
        navigate("/terms");
    };

    return navigateToTerms;
}