import { useTheme } from '@emotion/react'
import { styled } from '@mui/system'
import { Grid, Typography, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import SocialMedia from '../social-media/social-media'

export default function Footer() {
    const theme = useTheme()
    const navigate = useNavigate()

    const StyledTypography = styled(Typography)({
        my: 2,
        fontFamily: 'Open Sans',
        color: theme.palette.common.white
    })

    return (
        <Grid
            container
            sx={{
                width: '100%',
                height: '400px',
                mt: 6,
                bgcolor: theme.palette.secondary.main
            }}
        >
            <Grid
                item
                xs={12}
            >
                <Grid
                    container
                >
                    <Grid xs={1.5} item />
                    <Grid
                        xs={2}
                        item
                    >
                        <StyledTypography
                            sx={{
                                mt: 11
                            }}
                        >
                            Content. Collection. <br />Curation. Community
                        </StyledTypography>
                        <StyledTypography
                            sx={{
                                mt: 9
                            }}
                        >
                            SOCIAL MEDIA
                        </StyledTypography>
                        <SocialMedia />
                    </Grid>
                    <Grid xs={2} item />
                    <Grid
                        xs={2}
                        item
                    >
                        <StyledTypography
                            sx={{
                                mt: 11
                            }}
                        >
                            SLYKE
                        </StyledTypography>
                        <StyledTypography
                            onClick={() => navigate('/about')}
                            sx={{
                                mt: 2,
                                width: 'fit-content',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: theme.palette.primary.light
                                }
                            }}
                        >
                            About Us
                        </StyledTypography>
                        <StyledTypography
                            onClick={() => navigate('/faq')}
                            sx={{
                                mt: 1,
                                width: 'fit-content',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: theme.palette.primary.light
                                }
                            }}
                        >
                            FAQ
                        </StyledTypography>
                        <StyledTypography
                            onClick={() => navigate('/terms')}
                            sx={{
                                mt: 1,
                                width: 'fit-content',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: theme.palette.primary.light
                                }
                            }}
                        >
                            Terms & Conditions
                        </StyledTypography>
                        <StyledTypography
                            onClick={() => navigate('/rules')}
                            sx={{
                                mt: 1,
                                width: 'fit-content',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: theme.palette.primary.light
                                }
                            }}
                        >
                            Rules
                        </StyledTypography>
                    </Grid>
                    <Grid xs={1.5} item />
                    <Grid
                        item
                        xs={2}
                    >
                        <StyledTypography
                            sx={{
                                mt: 11
                            }}
                        >
                            COLLECTIONS
                        </StyledTypography>
                        <StyledTypography
                            onClick={() => navigate('/browse-collections?filter=recently added')}
                            sx={{
                                mt: 2,
                                width: 'fit-content',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: theme.palette.primary.light
                                }
                            }}
                        >
                            New
                        </StyledTypography>
                        <StyledTypography
                            onClick={() => navigate('/browse-collections?filter=for you')}
                            sx={{
                                mt: 1,
                                width: 'fit-content',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: theme.palette.primary.light
                                }
                            }}
                        >
                            For You
                        </StyledTypography>
                        <StyledTypography
                            onClick={() => navigate('/browse-collections?filter=most popular')}
                            sx={{
                                mt: 1,
                                width: 'fit-content',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: theme.palette.primary.light
                                }
                            }}
                        >
                            Most Popular
                        </StyledTypography>
                        <StyledTypography
                            onClick={() => navigate('/browse-collections?filter=trending')}
                            sx={{
                                mt: 1,
                                width: 'fit-content',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: theme.palette.primary.light
                                }
                            }}
                        >
                            Trending
                        </StyledTypography>
                    </Grid>
                    <Grid item xs />
                </Grid>
            </Grid>
            <Grid
                item
                xs
            >
                <Grid
                    container
                >
                    <Grid item xs={1.5} />
                    <Grid
                        item
                        xs
                    >
                        <Box
                            sx={{
                                borderTop: '2px solid white'
                            }}
                        >
                            <StyledTypography
                                align='center'
                                mt={3}
                            >
                                Copyright 2023 Slyke
                            </StyledTypography>
                        </Box>
                    </Grid>
                    <Grid item xs={1.5} />
                </Grid>
            </Grid>
        </Grid>
    )
}