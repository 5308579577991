import CollectionOverviewHeaderDesktop from "./CollectionOverviewHeaderDesktop/CollectionOverviewHeaderDesktop";
import CollectionOverviewHeaderMobile from "./CollectionOverviewHeaderMobile/CollectionOverviewHeaderMobile";

export default function CollectionOverviewHeader(props) {
    const {
        pendingItemCount,
        isMobile,
        isCurator,
        collection,
        refreshCollection,
        handleFollow,
        openSignUpModal
    } = props;

    if (!isMobile) {
        return (
            <CollectionOverviewHeaderDesktop
                isMobile={isMobile}
                pendingItemCount={pendingItemCount}
                collection={collection}
                handleFollow={handleFollow}
                openSignUpModal={openSignUpModal}
                refreshCollection={refreshCollection}
            />
        );
    } else {
        return (
            <CollectionOverviewHeaderMobile
                isMobile={isMobile}
                collection={collection}
                handleFollow={handleFollow}
                openSignUpModal={openSignUpModal}
                refreshCollection={refreshCollection}
                isCurator={isCurator}
            />
        );
    }
}