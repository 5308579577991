import { Grid, Typography, Box, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import RadioButtonUncheckedTwoToneIcon from '@mui/icons-material/RadioButtonUncheckedTwoTone'
import Footer from '../../legacy-components/footer/footer'
import { Link } from 'react-router-dom'
import Navbar from '../../components/Navbar/Navbar'

const StyledTypography = styled(Typography)({
    fontFamily: 'Open Sans'
})

export default function About() {
    const theme = useTheme()

    return (
        <Box
            sx={{
                minWidth: 1280
            }}
        >
            <Navbar
                search
            />

            <StyledTypography
                variant='h2'
                mt={16}
                align={'center'}
                sx={{
                    fontWeight: 'bolder'
                }}
            >
                WHAT SLYKE DOES
            </StyledTypography>
            <StyledTypography
                align={'center'}
                my={8}
                variant='body1'
            >
                Slyke provides a platform to help individuals and groups crowdsource and curate content from any source. <br />It’s amazing what we can accomplish when we work together.
            </StyledTypography>

            <Grid
                container
                sx={{
                    width: '80%',
                    borderTop: '2px solid #c4cff5',
                    mx: 'auto'
                }}
            >
                <Grid
                    item
                    xs={3.25}
                    align='right'
                    mt={4}
                >
                    <Box>
                        <RadioButtonUncheckedTwoToneIcon
                            sx={{
                                color: theme.palette.common.white,
                                backgroundColor: theme.palette.secondary.light,
                                borderRadius: 4,
                                p: 0.5
                            }}
                        />
                        <div
                            style={{
                                marginLeft: 'auto',
                                marginTop: 20,
                                paddingLeft: '12px',
                                borderLeft: '2px solid #c4cff5',
                                height: '200px',
                                width: '2px'
                            }}
                        />
                    </Box>
                </Grid>
                <Grid xs={0.5} />
                <Grid
                    item
                    xs
                    align='left'
                    mt={4}
                >
                    <StyledTypography
                        variant='h5'
                        mb={2}
                        sx={{
                            fontWeight: 'bolder'
                        }}
                    >
                        Distinctly Unique Collections
                    </StyledTypography>
                    <StyledTypography
                        mb={2}
                        width={600}
                    >
                        Slyke is organized with Collections around specific categories. Collections are made up of Items: links, videos, photos, or even text entries.
                    </StyledTypography>
                    <StyledTypography
                        width={600}
                    >
                        The main rule — and part of what makes Slyke so valuable — is that Collections are distinct and unique, there are no duplicates. No duplicate Collections and, within Collections, no duplicate Items.
                    </StyledTypography>
                </Grid>
            </Grid>
            <Grid
                container
                sx={{
                    width: '80%',
                    mx: 'auto'
                }}
            >
                <Grid
                    item
                    xs={3.25}
                    align='right'
                    mt={4}
                >
                    <Box>
                        <RadioButtonUncheckedTwoToneIcon
                            sx={{
                                color: theme.palette.common.white,
                                backgroundColor: theme.palette.secondary.light,
                                borderRadius: 4,
                                p: 0.5
                            }}
                        />
                        <div
                            style={{
                                marginLeft: 'auto',
                                marginTop: 20,
                                paddingLeft: '12px',
                                borderLeft: '2px solid #c4cff5',
                                height: '200px',
                                width: '2px'
                            }}
                        />
                    </Box>
                </Grid>
                <Grid xs={0.5} />
                <Grid
                    item
                    xs
                    align='left'
                    mt={4}
                >
                    <StyledTypography
                        variant='h5'
                        mb={2}
                        sx={{
                            fontWeight: 'bolder'
                        }}
                    >
                        Curation
                    </StyledTypography>
                    <StyledTypography
                        width={600}
                        mb={2}
                    >
                        Each Collection has one or more users in charge of managing the Collection and any Items submitted for inclusion. This is the Curator. The user that initially creates the Collection is the Curator unless they forgo that opportunity. Then Slyke serves as the Curator until another user volunteers for the role.
                    </StyledTypography>
                    <StyledTypography
                        width={600}
                    >
                        Curators ensure that all Items fit the category of the Collection and are unique. They have the ability to accept or reject Items submitted.
                    </StyledTypography>
                </Grid>
            </Grid>
            <Grid
                container
                sx={{
                    width: '80%',
                    mx: 'auto'
                }}
            >
                <Grid
                    item
                    xs={3.25}
                    align='right'
                    mt={4}
                >
                    <Box>
                        <RadioButtonUncheckedTwoToneIcon
                            sx={{
                                color: theme.palette.common.white,
                                backgroundColor: theme.palette.secondary.light,
                                borderRadius: 4,
                                p: 0.5
                            }}
                        />
                    </Box>
                </Grid>
                <Grid xs={0.5} />
                <Grid
                    item
                    xs
                    align='left'
                    mt={4}
                >
                    <StyledTypography
                        variant='h5'
                        mb={2}
                        sx={{
                            fontWeight: 'bolder'
                        }}
                    >
                        Interactive Communications
                    </StyledTypography>
                    <StyledTypography
                        width={600}
                        mb={2}
                    >
                        Slyke users are able to engage on the platform by commenting on Items and chatting in the Talk rooms (coming soon) associated with each Collection. Users utilizing the comment and Talk functions must abide by the policies stipulated in the <Link to='/terms'>Terms and Conditions</Link>
                    </StyledTypography>
                </Grid>
            </Grid>

            <Grid
                container
                sx={{
                    width: '80%',
                    borderTop: '2px solid #c4cff5',
                    mx: 'auto',
                    my: 10
                }}
            >
                <Grid
                    item
                    xs={12}
                >
                    <StyledTypography
                        variant='h3'
                        mt={6}
                        mb={8}
                        align={'center'}
                        sx={{
                            fontWeight: 'bolder'
                        }}
                    >
                        Why did we create Slyke?
                    </StyledTypography>
                    <StyledTypography
                        my={3}
                        align={'center'}
                        width={1000}
                        mx='auto'
                    >
                        Stories, information, moments, content — they come in clusters. One buzzer-beating basket to win a championship in the NBA begets another decades later. One consumer brands launches a rewards program, and then others follow in the days and years to come.
                    </StyledTypography>
                    <StyledTypography
                        my={3}
                        align={'center'}
                        width={1000}
                        mx='auto'
                    >
                        What if you want to see and keep track of all the championship buzzer beaters and brands with rewards programs from now and moving forward?
                    </StyledTypography>
                    <StyledTypography
                        my={3}
                        align={'center'}
                        width={1000}
                        mx='auto'
                    >
                        That&apos;s where Slyke comes in. We can work together to organize and curate all the content, information, and stories that matter from the past, present, and future. Any category or subject or content cluster you want.
                    </StyledTypography>
                </Grid>
            </Grid>

            <Footer />
        </Box>
    )
}