import {
    useState
} from "react";

import LoadingModal from "../../components/Common/LoadingModal/LoadingModal";

export default function useLoadingModal() {
    const [loading, setLoading] = useState(false);

    return {
        loading,
        setLoading,
        LoadingModal
    };
}