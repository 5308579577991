import { createTheme } from "@mui/material/styles";

const mainTheme = createTheme({
    palette: {
        primary: {
            main: "#FF7300",
            light: "#FFE6CF"
        },
        secondary: {
            main: "#0033FF",
            light: "#CBD2EC"
        },
        slykeGray: {
            dark: "#222425"
        }
    },
    typography: {
        fontFamily: "'Open Sans', sans-serif",
        serifFontFamily: "'Sanchez', serif"
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            xsm: 860, // 1 card breakpoint
            md: 900,
            mdlg: 1200,
            xmd: 1260, // 2 card breakpoint
            lg: 1366,
            lgxl: 1400,
            xl: 1538,
            xxl: 1660 // 3 card breakpoint
        },
    }
});

export default mainTheme;