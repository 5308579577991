import {
    Box,
    Typography,
    useTheme
} from "@mui/material";

export default function UserAvatar(props) {
    const {
        avatarLetters
    } = props;

    const theme = useTheme();

    return (
        <Box>
            <Typography
                variant="h6"
                sx={{
                    borderRadius: 200,
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.common.black,
                    width: 100,
                    textAlign: "center",
                    py: 4,
                    mx: "auto"
                }}
            >
                {avatarLetters}
            </Typography>
        </Box>
    );
}