import { useState } from "react";
import {
    Box,
    Typography,
    useTheme
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";

import useGetImageLink from "../../../../../hooks/data/useGetImageLink";
import ImageModal from "../../../../Common/ImageModal/ImageModal";

export default function LinkItemBody(props) {
    const {
        image,
        ogTitle,
        link
    } = props;

    const [showImageModal, setShowImageModal] = useState(false);

    const theme = useTheme();
    const getImageLink = useGetImageLink();

    const variant = "itemviewv2";

    return (
        <Box
            sx={{
                my: 1,
                flexGrow: 1
            }}
        >
            <img
                onClick={() => setShowImageModal(true)}
                width={600}
                height={400}
                src={getImageLink({ image, variant })}
                style={{
                    borderRadius: "16px 16px 0px 0px",
                    borderLeft: `1px solid ${theme.palette.grey["300"]}`,
                    borderRight: `1px solid ${theme.palette.grey["300"]}`,
                    borderTop: `1px solid ${theme.palette.grey["300"]}`,
                }}
            />
            <Box
                sx={{
                    width: 584,
                    borderLeft: `1px solid ${theme.palette.grey["300"]}`,
                    borderRight: `1px solid ${theme.palette.grey["300"]}`,
                    borderBottom: `1px solid ${theme.palette.grey["300"]}`,
                    borderRadius: "0px 0px 16px 16px",
                    mt: -0.5,
                    p: 1
                }}
            >
                <Typography
                    onClick={() => window.open(link, "_blank")}
                    sx={{
                        fontWeight: "bolder",
                        p: 1,
                        "&:hover": {
                            color: theme.palette.grey["700"],
                            cursor: "pointer"
                        }
                    }}
                >
                    {ogTitle}
                </Typography>
                <Typography
                    onClick={() => window.open(link, "_blank")}
                    sx={{
                        fontFamily: theme.typography.openSans,
                        fontSize: 14,
                        color: theme.palette.grey["500"],
                        "&:hover": {
                            color: theme.palette.grey["700"],
                            cursor: "pointer"
                        }
                    }}
                >
                    <LinkIcon
                        sx={{
                            verticalAlign: "middle",
                            mx: 1,
                            transform: "rotate(-45deg)",
                            fontSize: 16
                        }}
                    />
                    {link.split("/").length > 2 ? link.split("/")[2] : link}
                </Typography>
            </Box>

            <ImageModal
                showImageModal={showImageModal}
                setShowImageModal={setShowImageModal}
                image={image}
                isMobile={false}
            />
        </Box>
    );
}