import { useNavigate } from "react-router-dom";

export default function useSearchCollections() {
    const navigate = useNavigate();

    const searchCollections = (searchString) => {
        navigate(`/browse-collections?search=${searchString}`);
    };

    return searchCollections;
}