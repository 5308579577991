import {
    TextField, useTheme
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
    Fragment
} from "react";
import { useState } from "react";
import useSearchCollections from "../../../hooks/data/useSearchCollections";
import SearchCollectionsMobileModal from "./SearchCollectionsMobileModal/SearchCollectionsMobileModal";


export default function SearchCollections(props) {
    const {
        isMobile
    } = props;

    const theme = useTheme();

    const [searchString, setSearchString] = useState("");
    const [openMobileModal, setOpenMobileModal] = useState(false);

    const searchCollections = useSearchCollections();

    const search = () => searchCollections(searchString);

    return (
        <Fragment>
            <SearchIcon
                onClick={() => {
                    if (!isMobile) {
                        searchCollections(searchString);
                    } else {
                        setOpenMobileModal(true);
                    }
                }}
                sx={{
                    verticalAlign: "middle",
                    mr: 1,
                    mt: !isMobile ? 1.5 : 0.75,
                    ml: !isMobile ? null : 1,
                    "&:hover": {
                        color: theme.palette.primary.main,
                        cursor: "pointer"
                    }
                }}
            />
            {
                !isMobile ?
                    <TextField
                        variant='standard'
                        placeholder={!isMobile ? "What are you looking for?" : "Search"}
                        value={searchString}
                        onInput={(e) => setSearchString(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.code === "Enter") {
                                search();
                            }
                        }}
                        sx={{
                            width: !isMobile ? 200 : 55,
                            mr: !isMobile ? 4 : 1,
                            mt: 0.75
                        }}
                    /> : null
            }
            <SearchCollectionsMobileModal
                openMobileModal={openMobileModal}
                setOpenMobileModal={setOpenMobileModal}
                search={search}
                searchString={searchString}
                setSearchString={setSearchString}
            />
        </Fragment>
    );
}