import { useNavigate } from "react-router-dom";

export default function useNavigateToCollection() {
    const navigate = useNavigate();

    const navigateToCollection = (collectionId) => {
        navigate(`/collection?collectionId=${collectionId}`);
    };

    return navigateToCollection;
}