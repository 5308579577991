import {
    cfVideoRoot,
    cfImageRoot
} from "../../constants/api-routes";

export default function useGetImageLink() {
    const getImageLink = ({ itemType, ogVideoLink, image, height, width, variant }) => {
        if (itemType === "media" && ogVideoLink) {
            return `${cfVideoRoot}/${ogVideoLink}/thumbnails/thumbnail.jpg?time=1s&height=${height}&width=${width}`;
        } else {
            if (!image) {
                return "https://imagedelivery.net/4jZu4HPxc6tlbuYZlGIsgQ/d4145aef-518b-4299-8df6-6311aa53e800/public";
            }

            return `${cfImageRoot}/${image}/${variant}`;
        }
    };

    return getImageLink;
}