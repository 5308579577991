import {
    useState
} from "react";

import AccountHandler from "../../components/Common/AccountHandler/AccountHandler";

export default function useLoginModal() {
    const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
    const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);

    const openSignInModal = () => {
        setIsSignInModalOpen(true);
    };

    const closeSignInModal = () => {
        setIsSignInModalOpen(false);
    };

    const openSignUpModal = () => {
        setIsSignUpModalOpen(true);
    };

    const closeSignUpModal = () => {
        setIsSignUpModalOpen(false);
    };

    return {
        isSignInModalOpen,
        openSignInModal,
        closeSignInModal,
        isSignUpModalOpen,
        openSignUpModal,
        closeSignUpModal,
        AccountModalHandler: AccountHandler
    };
}