import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import faqs from './faqs'
import Footer from '../../legacy-components/footer/footer'
import FAQAccordion from '../../legacy-components/faq-accordion/faq-accordion'
import Navbar from '../../components/Navbar/Navbar'
import ContactUs from '../../components/ContactUs/ContactUs'
import { useState } from 'react'
import PrimaryButton from '../../components/Common/PrimaryButton/PrimaryButton'
import useIsMobile from '../../hooks/media/useIsMobile'

const TypographyFAQ = styled(Typography)({
    my: 2,
    fontFamily: 'Open Sans',
    textAlign: 'center'
})

export default function FAQ() {
    const [showContactUs, setShowContactUs] = useState(false);

    const isMobile = useIsMobile();

    return (
        <Box
            sx={{
                minWidth: 1280
            }}
        >
            <Navbar
                search
            />
            <Box
                sx={{
                    mt: 16,
                    width: 800,
                    mx: 'auto'
                }}
            >
                <TypographyFAQ
                    variant='h4'
                    mb={4}
                >
                    Frequently Asked Questions
                </TypographyFAQ>

                <FAQAccordion
                    faqs={faqs}
                />

                <Box
                    sx={{
                        width: "100%",
                        textAlign: "center",
                        my: 5,
                        mb: 8
                    }}
                >
                    <PrimaryButton
                        onClick={() => setShowContactUs(true)}
                        text="Contact Slyke"
                        sx={{
                            width: 300,
                            py: 1
                        }}
                        isMobile={isMobile}
                    />
                </Box>
                <ContactUs
                    showContactUs={showContactUs}
                    setShowContactUs={setShowContactUs}
                    isMobile={isMobile}
                >

                </ContactUs>
            </Box>
            <Footer />
        </Box>
    )
}