import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material'

export default function CuratorInviteDeclined(props) {
    const theme = useTheme()

    const modalBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            xs: '95%',
            sm: 660
        },
        bgcolor: 'background.paper',
        boxShadow: 24,
        py: 8,
        px: 1,
        m: 0,
        borderRadius: 5,
        '&:active': {
            outline: 'none'
        },
        '&:focus': {
            outline: 'none'
        },
        textAlign: 'center'
    }

    return (
        <Modal
            open={props.open || false}
            onClose={() => {
                props.close()
            }}
        >
            <Box
                sx={modalBoxStyle}
            >
                <Typography
                    align='center'
                    variant='h4'
                    sx={{
                        fontFamily: 'Open Sans',
                        mb: 4
                    }}
                >
                    Curator Invite Declined
                </Typography>
                <Typography
                    align='center'
                    sx={{
                        fontFamily: 'Open Sans'
                    }}
                >
                    We will notify the user that invited you.
                </Typography>
                <Typography
                    align='center'
                    sx={{
                        fontFamily: 'Open Sans',
                        mb: 6
                    }}
                >
                    You can still:
                </Typography>

                <Button
                    variant='contained'
                    sx={{
                        backgroundColor: theme.palette.primary.light,
                        border: `${theme.palette.primary.light} solid 1px`,
                        color: theme.palette.common.white,
                        fontFamily: theme.typography.openSans,
                        fontWeight: 'bold',
                        textTransform: 'none',
                        letterSpacing: 0.5,
                        borderRadius: 8,
                        px: 4,
                        '&:hover': {
                            backgroundColor: theme.palette.grey['700'],
                            border: `${theme.palette.grey['700']} solid 1px`
                        },
                        mb: 2
                    }}
                >
                    View the collection
                </Button>
                <Typography
                    align='center'
                >
                    &
                </Typography>
                <Button
                    variant='contained'
                    align='center'
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        border: `${theme.palette.secondary.light} solid 1px`,
                        color: theme.palette.secondary.light,
                        fontFamily: theme.typography.openSans,
                        fontWeight: 'bold',
                        textTransform: 'none',
                        letterSpacing: 0.5,
                        borderRadius: 8,
                        px: 4,
                        '&:hover': {
                            backgroundColor: theme.palette.grey['300']
                        },
                        mt: 2
                    }}
                >
                    Add an item
                </Button>

                {/* Close Icon */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: {
                            xs: 9,
                            sm: 5
                        },
                        left: {
                            xs: 9,
                            sm: 5
                        },
                        margin: 'auto'
                    }}
                >
                    <IconButton
                        onClick={() => {
                            props.close()
                        }}
                        sx={{
                            m: 0,
                            p: 0
                        }}
                    >
                        <CloseIcon
                            sx={{
                                color: theme.palette.grey['700']
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>
        </Modal>
    )
}