import { useDispatch, useSelector } from "react-redux";
import { updateSpecificCollection } from "../../store/collection/collectionSlice";
import { getUserId } from "../../store/user/userSlice";



export default function useRefreshSpecificCollection() {
    const dispatch = useDispatch();
    const userId = useSelector(getUserId);

    const refreshCollection = (collectionId) => {
        dispatch(updateSpecificCollection({ collectionId, userId }));
    };

    return refreshCollection;
}