import {
    Typography,
    TextField,
    Grid
} from "@mui/material";
import { Fragment } from "react";

export default function Title(props) {
    const {
        itemTitle,
        setItemTitle
    } = props;

    const charactersRemaining = itemTitle ? 100 - itemTitle.length : 100

    return (
        <Fragment>
            <Grid
                container
            >
                <Grid
                    item
                    xs
                >
                    <Typography>
                        Title
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={7}
                    textAlign="right"
                >
                    <Typography
                        variant='caption'
                        sx={{
                            verticalAlign: "sub"
                        }}
                    >
                        {charactersRemaining} Characters Remaining
                    </Typography>
                </Grid>
            </Grid>
            <TextField
                value={itemTitle}
                onInput={(e) => {
                    setItemTitle(e.target.value)
                }}
                inputProps={{ maxLength: 100 }}
                sx={{
                    '& .MuiOutlinedInput-input': {
                        py: 1
                    }
                }}
            />
        </Fragment>
    );
}