import axios from "axios";
import { useDispatch } from "react-redux";

import apiRoutes from "../../constants/api-routes";
import { setDisplayName, setHandle, setIsLoggedIn, setToken, setUserId, setUsername } from "../../store/user/userSlice";

export default function useSetupPassword() {
    /*
    
    This handles changing the user's password from the 
    temporary one assigned during account creation.

    */

    const dispatch = useDispatch();

    const attemptLogin = async (username, changePasswordCode) => {
        /* 
        
        This is ported from existing code.

        The code attempts to login with the temporary password and returns a session
        that is used to change the password. I do not know why it is required, it 
        might just be a weird cognito "feature."
        
        */

        return new Promise((resolve, reject) => {
            axios.post(apiRoutes.login, {
                username,
                password: changePasswordCode
            }).then(() => {
                reject(false);
            }).catch((e) => {
                if (e.response.status === 401 && e.response.data.challengeName === "NEW_PASSWORD_REQUIRED") {
                    resolve(e.response.data.session);
                } else {
                    reject(false);
                }
            });
        });
    };

    const submitNewPassword = async ({
        newPassword,
        username,
        changePasswordCode
    }) => {
        let session;
        try {
            session = await attemptLogin(username, changePasswordCode);
            if (!session) {
                return false;
            }
        } catch {
            return false;
        }

        let loginResponse = await axios.post(apiRoutes.authChallenge, {
            username: username,
            challengeName: "NEW_PASSWORD_REQUIRED",
            challengeResponses: {
                NEW_PASSWORD: newPassword,
                USERNAME: username,
                "userAttributes.name": username
            },
            session: session
        }).catch(() => {
            return false;
        });

        // login
        // TODO: this should be its own hook
        if (loginResponse.data) {
            dispatch(setToken(loginResponse.data.token));
            dispatch(setUserId(loginResponse.data.userId));
            dispatch(setUsername(loginResponse.data.username));
            dispatch(setDisplayName(loginResponse.data.displayName));
            dispatch(setHandle(loginResponse.data.handle));
            dispatch(setIsLoggedIn(true));
        }

        return true;
    };

    return submitNewPassword;
}