// eslint-disable-next-line no-undef
const isLocal = process.env.REACT_APP_LOCAL;
if (isLocal) {
    console.log("APP RUNNING IN LOCAL MODE");
}

const root = isLocal ? "http://localhost:3000" : "https://api.myslyke.com"; // backend url root
const frontRoot = window.location.origin; // url root of the hosted front end site
const cfImageRoot = "https://imagedelivery.net/4jZu4HPxc6tlbuYZlGIsgQ";
const cfVideoRoot = "https://customer-j7dz6yw37svnapxc.cloudflarestream.com";
const cfImageWorker = "https://image-processing-handler.slyke.workers.dev/";

const apiRoutes = {
    searchTags: root + "/search/tags",
    addTag: root + "/add/tag",
    addCollection: root + "/add/collection",
    getRandomCollections: root + "/get/collections/random",
    getTrendingCollections: root + "/get/collections/trending",
    getPopularCollections: root + "/get/collections/popular",
    getRecentlyAddedCollections: root + "/get/collections/recently-added",
    getForYouCollections: root + "/get/collections/for-you",
    getCuratedCollections: root + "/get/collections/curated",
    getFollowedCollections: root + "/get/collections/followed",
    addItemView: root + "/add/item/view",
    login: root + "/login",
    addComment: root + "/add/comment",
    getSpecificCollection: root + "/get/collections/single",
    likeItem: root + "/add/item/like",
    unlikeItem: root + "/delete/item/like",
    getOpenGraphData: root + "/get/open-graph-data",
    searchCollectionsByName: root + "/search/collections/by-name",
    addItem: root + "/add/item",
    searchItemsWithinCollection: root + "/search/items/by-name",
    addCollectionFollow: root + "/add/collection/follow",
    deleteCollectionFollow: root + "/delete/collection/follow",
    searchUsers: root + "/search/users",
    getQueue: root + "/get/curators/queue",
    addItemApproval: root + "/add/item/approval",
    addItemRejection: root + "/add/item/rejection",
    getUserEngagementStats: root + "/get/users/engagement-stats",
    getUserById: root + "/get/user/single",
    addRawAsset: root + "/add/asset",
    signUp: root + "/sign-up",
    authChallenge: root + "/auth-challenge",
    resetPassword: root + "/reset-password",
    confirmResetPassword: root + "/confirm-reset-password",
    refreshLogin: root + "/refresh-login",
    updateDefaultImage: root + "/update/item/default-image",
    updateCollectionTags: root + "/update/collection/tags",
    updateCollectionDefaultImage: root + "/update/collection/default-image",
    deleteCurator: root + "/delete/collection/curator",
    updateHeadCurator: root + "/update/curator/is-head-curator",
    getPendingRequestsToCurate: root + "/get/curator/request",
    addPendingRequestToCurate: root + "/add/curator/request",
    updatePendingRequestToCurate: root + "/update/curator/request",
    getCollectionQueue: root + "/get/collection/queue",
    getCollectionRejectedItems: root + "/get/collections/rejected",
    deleteItem: root + "/delete/item",
    addBookmark: root + "/add/item/bookmark",
    getBookmarks: root + "/v2/bookmarks",
    deleteBookmark: root + "/v2/bookmarks",
    getComments: root + "/v2/comments",
    contact: root + "/v2/contact"
};

export default apiRoutes;
export { root, frontRoot, cfImageRoot, cfImageWorker, cfVideoRoot };