import {
    Box
} from "@mui/material";
import {
    useEffect,
    Fragment,
    useState
} from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import ItemViewNavbar from "./ItemViewNavbar/ItemViewNavbar";
import ItemViewCard from "./ItemViewCard/ItemViewCard";

import useGetCollectionFromParam from "../../../hooks/data/useGetCollectionFromParam";
import useRefreshSpecificCollection from "../../../hooks/data/useRefreshSpecificCollection";

import { getUserData } from "../../../store/user/userSlice";
import { getCollections } from "../../../store/collection/collectionSlice";
import ProgressBar from "./ProgressBar/ProgressBar";
import useAddItemModal from "../../../hooks/state/useAddItemModal";
import AddItemPopup from "../../AddItemPopup/AddItemPopup";
import PopupA from "../../CollectEmailA/Popup";
import PopupB from "../../CollectEmailB/Popup";


export default function ItemViewBodyDesktop() {
    /* 
    
    This is the mobile version of the item view page.
    
    */

    const [searchParams, setSearchParams] = useSearchParams();

    const [currentItemIndex, setCurrentItemIndex] = useState();

    const {
        isLoggedIn
    } = useSelector(getUserData);
    const collection = useSelector(getCollections)[0];

    const refreshSpecificCollection = useRefreshSpecificCollection();
    useGetCollectionFromParam();

    useEffect(() => {
        /*
        
        Upon collection load, navigate to the item that 
        corresponds to the item index search param.

        */

        const itemIndex = searchParams.get("itemIndex");
        if (collection) {
            if (collection.items[itemIndex]) {
                document.getElementById(`item_${itemIndex}`).scrollIntoView({
                    behavior: "instant",
                    block: "center"
                });
            }
        }
    }, [collection]);

    useEffect(() => {
        if (collection) {
            refreshSpecificCollection(collection.collectionId);
        }
    }, [isLoggedIn]);

    const updateItemIndex = (itemIndex) => {
        if (searchParams.get("bookmarked")) {
            setSearchParams({
                collectionId: searchParams.get("collectionId"),
                itemIndex: searchParams.get("itemIndex")
            }, { replace: true });
        } else {
            setCurrentItemIndex(itemIndex);
            setSearchParams({
                collectionId: searchParams.get("collectionId"),
                itemIndex: itemIndex
            }, { replace: true });
        }
    };

    const [addItemPopupOpen, setAddItemPopupOpen] = useState(false);
    const [popupA, setPopupA] = useState(false);
    const [popupB, setPopupB] = useState(false);

    const {
        userId
    } = useSelector(getUserData);
    const {
        isAddItemModalOpen,
        openAddItemModal,
        closeAddItemModal,
        AddItemModal
    } = useAddItemModal();

    useEffect(() => {
        // show a CTA popup after 8 seconds
        setTimeout(() => {
            if (!userId) {
                if (Math.random() < 0.5) {
                    setPopupA(true);
                } else {
                    setPopupB(true);
                }
                // setAddItemPopupOpen(true);
            }
        }, 8000);
    }, []);

    return (
        <Box>
            {
                collection ?
                    <Fragment>
                        <ItemViewNavbar
                            collectionTitle={collection.collectionTitle}
                            collectionId={collection.collectionId}
                        />

                        <Box
                            sx={{
                                mt: 12
                            }}
                        >
                            {
                                collection.items.map((item, index) => {
                                    return (
                                        <ItemViewCard
                                            key={item.itemId}
                                            item={item}
                                            itemIndex={index}
                                            collectionId={collection.collectionId}
                                            updateItemIndex={() => updateItemIndex(index)}
                                        />
                                    );
                                })
                            }
                        </Box>

                        <ProgressBar
                            currentItemIndex={currentItemIndex}
                            totalItems={collection.items.length}
                        />
                    </Fragment> : null
            }

            <AddItemModal
                open={isAddItemModalOpen}
                close={closeAddItemModal}
                collectionIndex={0}
            />
            {/* <AddItemPopup
                openAddItem={openAddItemModal}
                isOpen={addItemPopupOpen}
                close={() => setAddItemPopupOpen(!addItemPopupOpen)}
            /> */}

            <PopupA
                isOpen={popupA}
                close={() => setPopupA(!popupA)}
            />

            <PopupB
                isOpen={popupB}
                close={() => setPopupB(!popupB)}
            />
        </Box>
    );
}