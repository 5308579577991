import jwt_decode from "jwt-decode";

import useLogout from "./useLogout";

export default function useCheckTokenValidity() {
    const logout = useLogout();

    const checkToken = (token) => {
        // check if token is close to, or is already expired
        if (token) {
            const parsedToken = jwt_decode(token);
            const timeToExp = parsedToken.exp - Math.floor(Date.now() / 1000);
            // logout when under five minutes left on token
            if (timeToExp < 300) {
                logout();
            }
        } else {
            logout();
        }
    };

    return checkToken;
}