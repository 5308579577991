import { useSelector } from "react-redux";
import axios from "axios";
import ReactGA from "react-ga4";

import { getUserData } from "../../store/user/userSlice";
import apiRoutes from "../../constants/api-routes";

export default function useAddComment() {
    const {
        isLoggedIn,
        token,
        userId
    } = useSelector(getUserData);

    const addComment = async (commentText, itemId, onSuccess, onLoggedOut) => {
        const TRACKING_ID = "G-CSY9YW7QWL";
        ReactGA.initialize(TRACKING_ID);
        ReactGA.event("comment-added");
        if (isLoggedIn) {
            axios.post(apiRoutes.addComment, {
                text: commentText,
                itemId,
                userId
            }, {
                headers: {
                    "authorization": `Bearer ${token}`
                }
            }).then(() => {
                onSuccess();
            });
        } else {
            onLoggedOut();
        }
    };

    return addComment;
}