import {
    Grid
} from "@mui/material";

import BaseModal from "../../BaseModal/BaseModal";
import ModalHeader from "./ModalHeader/ModalHeader";
import FormBody from "./FormBody/FormBody";
import DesktopSideColumn from "./DesktopSideColumn/DesktopSideColumn";
import CloseButton from "../CloseButton/CloseButton";

export default function SignIn(props) {
    const {
        isMobile,
        signIn,
        onCloseSignIn,
        openForgotPassword,
        openSignUp,
        openForcePasswordReset
    } = props;

    return (
        <BaseModal
            open={signIn}
            onClose={onCloseSignIn}
        >
            <Grid
                container
                direction='row'
            >
                <Grid
                    item
                    xs={12}
                    sm={7}
                    sx={{
                        p: 2,
                        pl: {
                            xs: 5,
                            sm: 4
                        }
                    }}
                >
                    <ModalHeader
                        isMobile={isMobile}
                    />
                    <FormBody
                        isMobile={isMobile}
                        openForgotPassword={openForgotPassword}
                        onCloseSignIn={onCloseSignIn}
                        openForcePasswordReset={openForcePasswordReset}
                        openSignUp={openSignUp}
                    />
                </Grid>
                <DesktopSideColumn
                    isMobile={isMobile}
                    close={onCloseSignIn}
                    openSignUp={openSignUp}
                />
            </Grid>

            <CloseButton
                isMobile={isMobile}
                onClose={onCloseSignIn}
            />
        </BaseModal>
    );
}