import {
    Box,
    IconButton,
    Typography,
    useTheme
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import useTextChunking from "../../../../../hooks/data/useTextChunking";

export default function TextItemBody(props) {
    const {
        text
    } = props;

    const theme = useTheme();

    const {
        chunks,
        currentChunkIndex,
        setCurrentChunkIndex
    } = useTextChunking(text, 250);

    return (
        <Box
            width={600}
            height={400}
            sx={{
                backgroundColor: theme.palette.grey["800"],
                borderRadius: 4,
                position: "relative",
                zIndex: 1
            }}
        >
            <Box
                sx={{
                    fontFamily: theme.typography.openSans,
                    color: theme.palette.common.white,
                    width: "70%",
                    mx: "auto",
                    my: "auto",
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    top: "25%",
                    zIndex: 1
                }}
            >
                {
                    chunks[currentChunkIndex].split("<br>").map((chunk, index) => {
                        return (
                            <Typography
                                key={`chunk-${index}`}
                            >
                                {chunk}
                            </Typography>
                        );
                    })
                }
            </Box>
            <Box
                sx={{
                    position: "absolute",
                    left: -10,
                    top: "40%"
                }}
            >
                <IconButton
                    onClick={() => {
                        if (currentChunkIndex !== 0) {
                            setCurrentChunkIndex(currentChunkIndex - 1);
                        }
                    }}
                    sx={{
                        color: theme.palette.primary.main,
                        display: currentChunkIndex === 0 ? "none" : null,
                        ml: 1
                    }}
                >
                    <ArrowBackIcon
                        sx={{
                            fontSize: 26,
                            border: `1px solid ${theme.palette.primary.main}`,
                            borderRadius: 8,
                            p: 1
                        }}
                    />
                </IconButton>
            </Box>
            <Box
                sx={{
                    position: "absolute",
                    right: -10,
                    top: "40%"
                }}
            >
                <IconButton
                    onClick={() => {
                        if (currentChunkIndex + 1 < chunks.length) {
                            setCurrentChunkIndex(currentChunkIndex + 1);
                        }
                    }}
                    sx={{
                        color: theme.palette.primary.main,
                        display: chunks.length === currentChunkIndex + 1 ? "none" : null,
                        mr: 1
                    }}
                >
                    <ArrowForwardIcon
                        sx={{
                            fontSize: 26,
                            border: `1px solid ${theme.palette.primary.main}`,
                            borderRadius: 8,
                            p: 1
                        }}
                    />
                </IconButton>

            </Box>
            <Typography
                sx={{
                    position: "absolute",
                    bottom: 10,
                    left: 280,
                    color: theme.palette.primary.main
                }}
            >
                {currentChunkIndex + 1} / {chunks.length}
            </Typography>
        </Box>
    );
}