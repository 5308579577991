import { useNavigate } from "react-router-dom";

export default function useNavigateToItem() {
    const navigate = useNavigate();

    const navigateToItem = (collectionId, itemIndex, extraParams) => {
        let url = `/item?collectionId=${collectionId}&itemIndex=${itemIndex}`;

        if (extraParams) {
            Object.entries(extraParams).forEach(([key, value]) => {
                url += `&${key}=${value}`;
            });
        }

        navigate(url);
    };

    return navigateToItem;
}