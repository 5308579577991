import {
    Box,
    Grid,
    Stack
} from "@mui/material";

import FollowButton from "../../../Common/FollowButton/FollowButton";
import PrimaryButton from "../../../Common/PrimaryButton/PrimaryButton";

import ItemsToReviewIcon from "../ItemsToReviewIcon/ItemsToReviewIcon";
import CollectionTitle from "../CollectionTitle/CollectionTitle";
import CuratedBy from "../CuratedBy/CuratedBy";
import Engagement from "../Engagement/Engagement";
import Share from "../Share/Share";
import EngagementMetrics from "../EngagementMetrics/EngagementMetrics";
import ApplyToCurate from "../ApplyToCurate/ApplyToCurate";

export default function CollectionOverviewHeaderDesktop(props) {
    const {
        isMobile,
        pendingItemCount,
        collection,
        handleFollow,
        openSignUpModal,
        refreshCollection
    } = props;

    return (
        <Box
            sx={{
                width: 800,
                mx: "auto",
                textAlign: "center"
            }}
        >
            <Stack
                direction="row"
                justifyContent="center"
            >
                <ItemsToReviewIcon
                    pendingItemCount={pendingItemCount}
                    isMobile={isMobile}
                />
                <CollectionTitle
                    collectionTitle={collection.collectionTitle}
                    isMobile={isMobile}
                />
            </Stack>
            <Grid
                container
                justifyContent="center"
                sx={{
                    mx: "auto"
                }}
            >
                <Grid
                    item
                    xs={12}
                    sm={6}
                >
                    <CuratedBy
                        headCuratorDisplayName={"Test Testerson"}
                        curators={collection.curators}
                        creatorId={collection.creatorId}
                        isMobile={isMobile}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    textAlign={!isMobile ? "left" : "center"}
                >
                    <ApplyToCurate
                        isMobile={isMobile}
                    />
                </Grid>
            </Grid>
            <Stack
                direction="row"
                justifyContent="center"
                sx={{
                    mb: 3
                }}
            >
                <FollowButton
                    isFollowing={collection.isFollowing}
                    collectionId={collection.collectionId}
                    handleFollow={handleFollow}
                    openSignUpModal={openSignUpModal}
                    refreshCollection={refreshCollection}
                    isMobile={isMobile}
                    sx={{
                        py: 0.25,
                        px: 4
                    }}
                />
                <Share
                    collectionId={collection.collectionId}
                />
                <Engagement
                    isMobile={isMobile}
                    isCurator
                />
            </Stack>
            <Stack
                direction="row"
                sx={{
                    my: 2
                }}
            >
                <EngagementMetrics
                    followers={collection.followerCount}
                    likes={collection.totalItemLikes}
                    items={collection.totalItemViews}
                    views={collection.items.length}
                    isMobile={isMobile}
                />
            </Stack>
            <PrimaryButton
                text="Add an Item"
            />
        </Box>
    );
}