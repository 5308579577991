import {
    AppBar,
    Box,
    Stack,
    Toolbar,
    useTheme
} from "@mui/material";
import {
    useState
} from "react";

import {
    getUserData
} from "../../store/user/userSlice";
import {
    useSelector
} from "react-redux";

import Hamburger from "./Hamburger/Hamburger";
import HomeLink from "./HomeLink/HomeLink";
import BrowseCollectionsLink from "./BrowseCollectionsLink/BrowseCollectionsLink";
import SearchCollections from "./SearchCollections/SearchCollections";
import Sidebar from "./Sidebar/Sidebar";

import WhatIsSlykeLink from "../Common/WhatIsSlykeLink/WhatIsSlykeLink";
import CreateMenu from "../Common/CreateMenu/CreateMenu";
import LoginButton from "../Common/LoginButton/LoginButton";
import UserMenu from "../Common/UserMenu/UserMenu";

import useLoginModal from "../../hooks/state/useLoginModal";
import useIsMobile from "../../hooks/media/useIsMobile";

export default function Navbar(props) {
    const {
        search,
        currentCollectionIndex
    } = props;

    const {
        isLoggedIn,
        displayName,
        userId
    } = useSelector(getUserData);

    const theme = useTheme();
    const isMobile = useIsMobile();
    const {
        isSignInModalOpen,
        openSignInModal,
        closeSignInModal,
        AccountModalHandler
    } = useLoginModal();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    return (
        <Box
            width="100%"
        >
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: theme.palette.common.white,
                    zIndex: 1300
                }}
            >
                <Toolbar
                    disableGutters
                >
                    <Stack
                        direction="row"
                        sx={{
                            flexGrow: 1
                        }}
                    >
                        <Hamburger
                            isSidebarShowing={isSidebarOpen}
                            setIsSidebarShowing={setIsSidebarOpen}
                            isMobile={isMobile}
                            sx={{
                                ml: 2
                            }}
                        />
                        <HomeLink
                            isMobile={isMobile}
                        />
                        {
                            !isMobile ?
                                <WhatIsSlykeLink /> :
                                null
                        }
                    </Stack>
                    <Stack
                        direction="row"
                        sx={{
                            mr: 2
                        }}
                    >
                        {
                            search ?
                                <SearchCollections
                                    isMobile={isMobile}
                                /> :
                                null
                        }
                        {
                            !isMobile ?
                                <BrowseCollectionsLink /> :
                                null
                        }
                        <CreateMenu
                            currentCollectionIndex={currentCollectionIndex}
                            isMobile={isMobile}
                            sx={{
                                mx: !isMobile ? 2 : 1
                            }}
                        />
                        {
                            isMobile ?
                                null :
                                isLoggedIn ?
                                    <UserMenu
                                        displayName={displayName}
                                        userId={userId}
                                        isMobile={isMobile}
                                    /> :
                                    <LoginButton
                                        openSignInModal={openSignInModal}
                                    />
                        }
                    </Stack>
                </Toolbar>
            </AppBar>

            <Sidebar
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                isLoggedIn={isLoggedIn}
                userId={userId}
                isMobile={isMobile}
            />
            <AccountModalHandler
                isMobile={isMobile}
                signIn={isSignInModalOpen}
                onCloseSignIn={closeSignInModal}
            />
        </Box>
    );
}