import {
    Grid,
    Typography
} from "@mui/material";

import EyeIcon from "../../../../assets/icons/engagement/Eye.svg";
import LikeIcon from "../../../../assets/icons/engagement/Like.svg";
import CommentIcon from "../../../../assets/icons/engagement/Comment.svg";
import ShareIcon from "../../../../assets/icons/engagement/Share.svg";
import useEngagementMetrics from "../../../../hooks/data/useEngagementMetrics";
import { Fragment } from "react";

export default function ContentCardEngagement(props) {
    const {
        views,
        likes,
        comments,
        title,
        isMobile
    } = props;

    const convertEngagementMetricToString = useEngagementMetrics();

    const icons = [
        {
            svg: EyeIcon,
            count: convertEngagementMetricToString(views),
            height: !isMobile ? 24 : 20,
            width: !isMobile ? 24 : 20,
            pb: 2
        },
        {
            svg: LikeIcon,
            count: convertEngagementMetricToString(likes),
            height: !isMobile ? 20 : 16,
            width: !isMobile ? 20 : 16,
            pb: 2
        },
        {
            svg: CommentIcon,
            count: convertEngagementMetricToString(comments),
            height: !isMobile ? 20 : 16,
            width: !isMobile ? 20 : 16,
            pb: 2
        },
        {
            svg: ShareIcon,
            height: !isMobile ? 20 : 16,
            width: !isMobile ? 20 : 16
        }
    ];

    return (
        <Grid
            container
            sx={{
                height: "100%",
                p: 1,
                pl: 2
            }}
        >
            {
                icons.map((icon, ind) => {
                    return (
                        <Fragment
                            key={`eng-ico-${icon.count}-${title}-${ind}`}
                        >
                            <Grid
                                item
                                xs={12}
                                key={`eng-ico-${icon.count}-${title}-${ind}`}
                                textAlign="center"
                                alignSelf="center"
                            >
                                <img
                                    src={icon.svg}
                                    width={icon.width}
                                    height={icon.height}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                key={`eng-text-${icon.count}-${title}-${ind}`}
                                textAlign="center"
                                alignSelf="center"
                            >
                                <Typography
                                    textAlign="center"
                                    sx={{
                                        pb: icon.pb ? icon.pb : 0.5,
                                        fontSize: !isMobile ? null : 12
                                    }}
                                >
                                    {icon.count}
                                </Typography>
                            </Grid>
                        </Fragment>
                    );
                })
            }
        </Grid>
    );
}