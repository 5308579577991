import {
    Grid,
    Typography,
    useTheme
} from "@mui/material";

import Search from "./Search/Search";
import RandomCollectionButton from "./RandomCollectionButton/RandomCollectionButton";
import WhatIsSlykeLink from "../../Common/WhatIsSlykeLink/WhatIsSlykeLink";

export default function Hero(props) {
    const {
        isMobile,
        userId
    } = props;

    const theme = useTheme();

    return (
        <Grid
            container
            direction='row'
            sx={{
                height: "375px",
                background: "linear-gradient(201deg, rgba(255,115,0,0.8883928571428571) 16%, rgba(0,51,255,1) 100%)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                mt: 4
            }}
            alignContent='center'
            justifyContent='center'
        >
            <Grid
                item
                xs={12}
                md={6}
            >
                <Grid
                    container
                    justifyContent='center'
                    direction='column'
                >
                    <Grid
                        item
                        xs
                    >
                        <Typography
                            variant={isMobile ? "h5" : "h4"}
                            align='center'
                            sx={{
                                color: theme.palette.common.white,
                                mt: 2,
                                fontFamily: "'Sanchez', serif"
                            }}
                        >
                            Content. Collection.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs
                    >
                        <Typography
                            variant={isMobile ? "h5" : "h4"}
                            align='center'
                            sx={{
                                color: theme.palette.common.white,
                                fontFamily: "'Sanchez', serif"
                            }}
                        >
                            Curation. Community.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            mt: 2
                        }}
                    >
                        <Search
                            isMobile={isMobile}
                        />
                    </Grid>
                    <Grid
                        item
                        sx={{
                            textAlign: "center",
                            mt: 2
                        }}
                    >
                        <RandomCollectionButton
                            userId={userId}
                        />
                    </Grid>
                    {
                        isMobile ?
                            <Grid
                                item
                                sx={{
                                    textAlign: "center",
                                    mt: 2
                                }}
                            >
                                <WhatIsSlykeLink
                                    sx={{
                                        m: 0,
                                        color: theme.palette.common.white
                                    }}
                                />
                            </Grid> : null
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}