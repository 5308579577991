import SecondaryButton from "../../../../Common/SecondaryButton/SecondaryButton";

import useNavigateToCollection from "../../../../../hooks/navigation/useNavigateToCollection";

export default function ViewCollectionButton(props) {
    const {
        collectionId
    } = props;

    const navigateToCollection = useNavigateToCollection();

    return (
        <SecondaryButton
            onClick={() => navigateToCollection(collectionId)}
            borderRadius={2}
            text="View Full Collection"
            sx={{
                width: "100%"
            }}
        />
    );
}