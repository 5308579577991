import {
    Box,
    Typography
} from "@mui/material";

export default function CollectionTitle(props) {
    const {
        collectionTitle
    } = props;

    return (
        <Box
            sx={{
                p: 1
            }}
        >
            <Typography
                sx={{
                    fontWeight: "bolder"
                }}
            >
                {collectionTitle}
            </Typography>
        </Box>
    );
}