import {
    Box,
    TextField,
    useTheme,
    Stack
} from "@mui/material";
import { useState } from "react";

import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import useAddComment from "../../../../hooks/data/useAddComment";
import useRefreshSpecificCollection from "../../../../hooks/data/useRefreshSpecificCollection";

export default function AddComment(props) {
    /*
    
    This component is the Add Comment input and associated submit button on the comments modal.

    */
    const {
        collectionId,
        itemId,
        onClose,
        openSignUpModal,
        isMobile,
        refreshOverride,
        v2Refresh
    } = props;

    const theme = useTheme();

    const addComment = useAddComment();
    const refreshSpecificCollection = useRefreshSpecificCollection();

    const [commentText, setCommentText] = useState("");

    const onSuccess = () => {
        setCommentText("");
        console.log(!!refreshOverride);
        if (refreshOverride) {
            // when using the v2 comments API, start at the top again
            // comments are sorted with newest on top
            v2Refresh();
            refreshOverride();
        } else {
            refreshSpecificCollection(collectionId);
        }
    };

    const onLoggedOut = () => {
        onClose();
        openSignUpModal();
    };

    return (
        <Box
            sx={{
                px: 1,
                py: 2,
                borderTop: "1px solid black"
            }}
        >
            <Stack
                direction="row"
            >
                <TextField
                    placeholder='Add a comment'
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            addComment(
                                commentText,
                                itemId,
                                onSuccess,
                                onLoggedOut
                            );
                        }
                    }}
                    sx={{
                        my: "auto",
                        width: 500,
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "none"
                        }
                    }}
                />
                <PrimaryButton
                    text="Comment"
                    onClick={() => {
                        addComment(
                            commentText,
                            itemId,
                            onSuccess,
                            onLoggedOut
                        );
                    }}
                    borderRadius={0.5}
                    sx={{
                        height: 30,
                        borderRadius: 2,
                        backgroundColor: theme.palette.common.white,
                        color: theme.palette.primary.main,
                        fontFamily: theme.typography.openSans,
                        textTransform: "none",
                        letterSpacing: 0.5,
                        my: "auto",
                        mr: isMobile ? 3 : 1,
                        p: isMobile ? null : 2,
                        boxShadow: "none",
                        "&:hover": {
                            backgroundColor: theme.palette.grey["900"],
                            color: theme.palette.common.white,
                        }
                    }}
                />
            </Stack>
        </Box>
    );
}