import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getSpecificCollection } from "../../store/collection/collectionSlice";
import { useSearchParams } from "react-router-dom";
import { getUserData } from "../../store/user/userSlice";


export default function useGetCollectionFromParam() {
    const { userId } = useSelector(getUserData);

    const [searchParams] = useSearchParams();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSpecificCollection({ collectionId: searchParams.get("collectionId"), userId }));
    }, [searchParams.get("collectionId")]);
}