import {
    Box,
    Typography
} from "@mui/material";
import LoginCTA from "./LoginCTA/LoginCTA";

export default function CommentsHeader(props) {
    const {
        isLoggedIn,
        openSignUpModal,
        openSignInModal,
        onClose,
        isMobile
    } = props;

    return (
        <Box
            sx={{
                px: 4,
                py: 2,
                borderBottom: "1px solid black"
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    fontWeight: "bolder"
                }}
            >
                Comments
            </Typography>
            {
                !isLoggedIn ?
                    <LoginCTA
                        openSignUpModal={openSignUpModal}
                        openSignInModal={openSignInModal}
                        onClose={onClose}
                        isMobile={isMobile}
                    /> : null
            }
        </Box>
    );
}