import {
    Stack,
    Box,
    Typography,
    Button,
    Grid,
    useTheme
} from '@mui/material'
import { useState, useEffect, useRef } from 'react'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ForwardSharpIcon from '@mui/icons-material/ForwardSharp'
import { useNavigate } from 'react-router-dom'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import CircleIcon from '@mui/icons-material/Circle'
import { cfImageRoot } from '../../constants/api-routes'
import useGetImageLink from '../../hooks/data/useGetImageLink'

export default function CollectionPreview(props) {
    const navigate = useNavigate()
    const theme = useTheme()
    const ref = useRef()

    // using the new hook to patch preview image media upload bug FE-50
    const getImageLink = useGetImageLink()

    const [itemPreviewIndex, setItemPreviewIndex] = useState(0)

    const totalPreviewItems = props.collection && props.collection.items.length >= 3 ? 3 :
        props.collection && props.collection.items.length === 2 ? 2 :
            props.collection && props.collection.items.length === 1 ? 1 : 0

    useEffect(() => {
        if (props.getMoreCollections) {
            const observer = props.getMoreCollections(ref)
            return () => { observer.disconnect() }
        }
    }, [])

    return (
        <Grid
            ref={ref}
            container
            direction='row'
            sx={{
                my: 4,
                mb: 8,
                border: `1px solid ${theme.palette.grey['400']}`,
                p: 4,
                borderRadius: 3,
                boxShadow: `3px 5px 5px ${theme.palette.grey['400']}`,
                width: 1110
            }}
        >
            <Grid
                xs={5}
                item
                sx={{
                    height: 460
                }}
            >
                <Stack
                    direction='column'
                    sx={{
                        height: '100%',
                    }}
                >
                    <Typography
                        variant='h4'
                        sx={{
                            fontFamily: theme.typography.openSans,
                            fontWeight: 'bold',
                            wordBreak: 'break-word'
                        }}
                    >
                        {props.collection.collectionTitle}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: theme.typography.openSans,
                            color: theme.palette.grey['600'],
                            mt: 6,
                            flexGrow: 1,
                            pr: 4,
                            wordBreak: 'break-word'
                        }}
                    >
                        {props.collection.collectionDescription}
                    </Typography>

                    {/* Engagement Metrics */}
                    <Stack
                        direction='row'
                        sx={{
                            border: `1px solid ${theme.palette.grey['300']}`,
                            p: 3,
                            borderRadius: 1,
                            width: 'fit-content'
                        }}
                    >
                        {/* Item Count */}
                        <ContentCopyIcon
                            sx={{
                                mr: 1
                            }}
                        />
                        <Typography
                            sx={{
                                fontFamily: theme.typography.openSans,
                                color: theme.palette.grey['600'],
                                mr: 4
                            }}
                        >
                            <span style={{ fontWeight: 'bold', color: theme.palette.common.black, marginRight: 4 }}>{props.collection.items.length}</span> Items
                        </Typography>

                        {/* Follower Count */}
                        <PeopleAltIcon
                            sx={{
                                mr: 1
                            }}
                        />
                        <Typography
                            sx={{
                                fontFamily: theme.typography.openSans,
                                color: theme.palette.grey['600'],
                                flexGrow: 1
                            }}
                        >
                            <span style={{ fontWeight: 'bold', color: theme.palette.common.black, marginRight: 4 }}>{props.collection.followerCount}</span> Followers
                        </Typography>
                    </Stack>
                </Stack>
            </Grid>
            <Grid
                xs={7}
                item
                sx={{
                    height: 440,
                    position: 'relative'
                }}
            >
                <Grid
                    container
                    sx={{
                        border: `1px solid ${theme.palette.grey['300']}`,
                        p: 4
                    }}
                >
                    {/* Item Title */}
                    <Box
                        sx={{
                            height: 32,
                            width: '100%',
                            textAlign: 'center',
                            mb: 5
                        }}
                    >
                        <Typography
                            variant='h6'
                            onClick={() => navigate(`/collection?collectionId=${props.collection.collectionId}`, {
                                state: {
                                    collectionIndex: props.collectionIndex
                                }
                            })}
                            sx={{
                                fontFamily: theme.typography.openSans,
                                fontWeight: 'bold',
                                color: theme.palette.grey['600'],
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: theme.palette.primary.main,
                                },
                            }}
                        >
                            {props.collection && props.collection.items[itemPreviewIndex] && itemPreviewIndex !== totalPreviewItems ? props.collection.items[itemPreviewIndex].title : null}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            textAlign: 'center'
                        }}
                    >
                        {/* Item Image or Text Preview */}
                        {
                            props.collection.items[itemPreviewIndex] && props.collection.items[itemPreviewIndex].itemType === 'text' ?
                                // text item preview
                                <Box
                                    onClick={() => navigate(`/collection?collectionId=${props.collection.collectionId}`, {
                                        state: {
                                            collectionIndex: props.collectionIndex
                                        }
                                    })}
                                    sx={{
                                        height: 300,
                                        width: 492,
                                        backgroundColor: theme.palette.slykeGray.dark,
                                        mx: 'auto',
                                        borderRadius: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        '&:hover': {
                                            cursor: 'pointer',
                                            color: theme.palette.primary.main,
                                        },
                                    }}
                                >
                                    <Typography
                                        onClick={() => navigate(`/collection?collectionId=${props.collection.collectionId}`, {
                                            state: {
                                                collectionIndex: props.collectionIndex
                                            }
                                        })}
                                        sx={{
                                            color: theme.palette.common.white,
                                            fontFamily: theme.typography.openSans,
                                            my: 'auto',
                                            mx: 'auto',
                                            pt: 8,
                                            width: '80%'
                                        }}
                                    >
                                        {props.collection.items[itemPreviewIndex].title}
                                    </Typography>
                                    <TextFieldsIcon
                                        sx={{
                                            color: theme.palette.common.white,
                                            mx: 'auto',
                                            fontSize: 50
                                        }}
                                    />
                                </Box> : itemPreviewIndex !== totalPreviewItems ?
                                    // link item image preview
                                    <img
                                        onClick={() => navigate(`/collection?collectionId=${props.collection.collectionId}`, {
                                            state: {
                                                collectionIndex: props.collectionIndex
                                            }
                                        })}
                                        src={props.collection && props.collection.items[itemPreviewIndex] ? getImageLink({
                                            image: props.collection.items[itemPreviewIndex].image,
                                            variant: "itempreviewthumb",
                                            itemType: props.collection.items[itemPreviewIndex].itemType,
                                            ogVideoLink: props.collection.items[itemPreviewIndex].ogVideoLink,
                                            height: 400,
                                            width: 400
                                        }) : null}
                                        height={300}
                                        width={492}
                                        style={{
                                            marginRight: 'auto',
                                            marginLeft: 'auto',
                                            borderRadius: 4,
                                            cursor: "pointer"
                                        }}
                                    /> :
                                    // cta to view collection
                                    <Box
                                        onClick={() => navigate(`/collection?collectionId=${props.collection.collectionId}`, {
                                            state: {
                                                collectionIndex: props.collectionIndex
                                            }
                                        })}
                                        sx={{
                                            height: 300,
                                            width: 492,
                                            backgroundColor: theme.palette.slykeGray.dark,
                                            mx: 'auto',
                                            borderRadius: 2,
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}
                                    >
                                        <Box
                                            onClick={() => navigate(`/collection?collectionId=${props.collection.collectionId}`, {
                                                state: {
                                                    collectionIndex: props.collectionIndex
                                                }
                                            })}
                                            sx={{
                                                height: 'fit-content',
                                                width: 'fit-content',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                mx: 'auto',
                                                my: 'auto',
                                                p: 1,
                                                color: theme.palette.common.white,
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    color: theme.palette.primary.main,
                                                },
                                                '&:active': {
                                                    color: theme.palette.secondary.light
                                                }
                                            }}
                                        >
                                            <Typography
                                                variant='h5'
                                                sx={{
                                                    fontFamily: theme.typography.openSans,
                                                    my: 'auto',
                                                    ml: 'auto'
                                                }}
                                            >
                                                View Full Collection
                                            </Typography>
                                            <ForwardSharpIcon
                                                sx={{
                                                    my: 'auto',
                                                    fontSize: 40,
                                                    ml: 2
                                                }}
                                            />

                                        </Box>
                                    </Box>
                        }
                    </Box>

                    {/* Carousel Icons */}
                    <Stack
                        direction='row'
                        sx={{
                            mt: 3,
                            mx: 'auto'
                        }}
                    >
                        {
                            [...Array(totalPreviewItems), 'cta'].map((_, index) => {
                                return (
                                    <CircleIcon
                                        key={`circle-icon-${index}`}
                                        sx={{
                                            color: itemPreviewIndex === index ? theme.palette.secondary.main : theme.palette.secondary.light,
                                            fontSize: 16,
                                            mx: 1
                                        }}
                                    />
                                )
                            })
                        }
                    </Stack>

                    {/* Carousel Arrows */}
                    <Box
                        onClick={() => {
                            if (itemPreviewIndex < totalPreviewItems) {
                                setItemPreviewIndex(itemPreviewIndex + 1)
                            }
                        }}
                        sx={{
                            display: itemPreviewIndex < totalPreviewItems ? null : 'none',
                            position: 'absolute',
                            top: 250,
                            right: 59,
                            backgroundColor: theme.palette.common.black,
                            '&:hover': {
                                cursor: 'pointer',
                                backgroundColor: theme.palette.grey['400'],
                                color: theme.palette.common.black
                            }
                        }}
                    >
                        <ArrowForwardIcon
                            sx={{
                                color: theme.palette.common.white,
                                pt: 0.4,
                                px: 1,
                                fontSize: 20,
                                '&:hover': {
                                    cursor: 'pointer',
                                    backgroundColor: theme.palette.grey['400'],
                                    color: theme.palette.common.black
                                }
                            }}
                        />
                    </Box>

                    <Box
                        onClick={() => {
                            if (itemPreviewIndex > 0) {
                                setItemPreviewIndex(itemPreviewIndex - 1)
                            }
                        }}
                        sx={{
                            display: itemPreviewIndex > 0 ? null : 'none',
                            position: 'absolute',
                            top: 250,
                            left: 58,
                            backgroundColor: theme.palette.common.black,
                            '&:hover': {
                                cursor: 'pointer',
                                backgroundColor: theme.palette.grey['400'],
                                color: theme.palette.common.black
                            }
                        }}
                    >
                        <ArrowBackIcon
                            sx={{
                                color: theme.palette.common.white,
                                pt: 0.4,
                                px: 1,
                                fontSize: 20,
                                '&:hover': {
                                    cursor: 'pointer',
                                    backgroundColor: theme.palette.grey['400'],
                                    color: theme.palette.common.black
                                }
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Grid
                xs={12}
                item
                sx={{
                    mt: 4,
                    pt: 4,
                    borderTop: `1px solid ${theme.palette.secondary.light}`
                }}
            >
                <Button
                    onClick={() => navigate(`/collection?collectionId=${props.collection.collectionId}`, {
                        state: {
                            collectionIndex: props.collectionIndex
                        }
                    })}
                    variant='contained'
                    sx={{
                        width: '100%',
                        p: 1,
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.grey['100'],
                        fontFamily: theme.typography.openSans,
                        textTransform: 'none',
                        letterSpacing: 0.5,
                        fontSize: 20,
                        '&:hover': {
                            cursor: 'pointer',
                            backgroundColor: theme.palette.grey['300'],
                            color: theme.palette.grey['600']
                        }
                    }}
                >
                    View Full Collection
                </Button>
            </Grid>
        </Grid>
    )
}