import {
    useSelector
} from "react-redux";
import axios from "axios";

import {
    getUserData
} from "../../store/user/userSlice";
import apiRoutes from "../../constants/api-routes";

export default function useGetBookmarks() {
    const {
        userId,
        token
    } = useSelector(getUserData);

    const getBookmarks = (bookmarksPerPage, bookmarksPage, callback) => {
        axios.get(apiRoutes.getBookmarks, {
            params: {
                userId,
                perPage: bookmarksPerPage,
                page: bookmarksPage
            },
            headers: {
                "authorization": `Bearer ${token}`
            }
        }).then(res => {
            callback(res);
        });
    };

    return getBookmarks;
}