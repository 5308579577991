import { Grid, TextField, Typography } from "@mui/material";

export default function UsernameTextField(props) {
    const {
        isMobile,
        username,
        setUsername
    } = props;

    return (
        <Grid
            container
            direction='row'
        >
            <Grid
                item
                xs={12}
            >
                <Typography
                    variant='body1'
                    sx={{
                        fontWeight: "bold",
                        fontFamily: "Open Sans"
                    }}
                >
                    Username
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
            >
                <TextField
                    variant='outlined'
                    type="username"
                    value={username}
                    onChange={(e) => { setUsername(e.target.value); }}
                    name="username"
                    autoComplete="UserName"
                    sx={{
                        p: 0,
                        m: 0,
                        "& .MuiOutlinedInput-root": {
                            height: 35
                        },
                        mb: 2,
                        width: 300,
                        maxWidth: !isMobile ? null : "90%"
                    }}
                />
            </Grid>
        </Grid>
    );
}