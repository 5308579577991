import {
    Fragment,
    useState
} from "react";
import {
    useTheme
} from "@mui/styles";
import {
    Typography
} from "@mui/material";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";

import useHandleShare from "../../../../hooks/data/useHandleShare";
import BasicAlert from "../../../Common/BasicAlert/BasicAlert";

export default function Share(props) {
    const {
        isMobile
    } = props;

    const handleShare = useHandleShare();
    const theme = useTheme();

    const [showSuccess, setShowSuccess] = useState();

    return (
        <Fragment>
            <ShareOutlinedIcon
                onClick={() => {
                    handleShare(() => setShowSuccess(true));
                }}
                sx={{
                    verticalAlign: "baseline",
                    color: theme.palette.grey["600"],
                    pt: 2.8,
                    ml: !isMobile ? 3 : 0,
                    mt: 0.5,
                    fontSize: 24,
                    "&:hover": {
                        cursor: "pointer",
                        color: theme.palette.primary.main
                    }
                }}
            />
            <Typography
                variant='body2'
                sx={{
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    verticalAlign: "super",
                    color: theme.palette.grey["900"],
                    ml: 1,
                    mt: 3.5
                }}
            >
                Share
            </Typography>

            <BasicAlert
                showAlert={showSuccess}
                setShowAlert={setShowSuccess}
                severity="info"
                alertText='Collection link copied to clipboard!'
                sx={{
                    position: "absolute",
                    bottom: -75
                }}
            />
        </Fragment>
    );
}