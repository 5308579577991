import {
    Box,
    IconButton,
    useTheme
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function CloseButton(props) {
    const {
        onClose
    } = props;

    const theme = useTheme();

    return (
        <Box
            sx={{
                position: "absolute",
                top: {
                    xs: 9,
                    sm: 5
                },
                right: {
                    xs: 9,
                    sm: 5
                },
                margin: "auto"
            }}
        >
            <IconButton
                onClick={() => {
                    onClose();
                }}
                sx={{
                    m: 0,
                    p: 0
                }}
            >
                <CloseIcon
                    sx={{
                        color: theme.palette.grey["700"]
                    }}
                />
            </IconButton>
        </Box>
    );
}