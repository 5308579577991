import {
    Grid,
    Typography,
    useTheme
} from "@mui/material";

import useNavigateToFAQ from "../../../../hooks/navigation/useNavigateToFAQ";
import useNavigateToAbout from "../../../../hooks/navigation/useNavigateToAbout";
import useNavigateToTerms from "../../../../hooks/navigation/useNavigateToTerms";
import useNavigateToRules from "../../../../hooks/navigation/useNavigateToRules";

export default function SiteLinks() {
    const theme = useTheme();

    const navigateToAbout = useNavigateToAbout();
    const navigateToTerms = useNavigateToTerms();
    const navigateToFAQ = useNavigateToFAQ();
    const navigateToRules = useNavigateToRules();

    const links = [
        { text: "About Slyke", onClick: navigateToAbout },
        { text: "Terms of Service", onClick: navigateToTerms },
        { text: "FAQ", onClick: navigateToFAQ },
        { text: "Rules", onClick: navigateToRules }
    ];

    return (
        <Grid
            container
            sx={{
                mt: 10,
                mb: 2
            }}
        >
            {
                links.map((link) => {
                    return (
                        <Grid
                            key={`site-links-${link.text}`}
                            item
                            xs={6}
                        >
                            <Typography
                                onClick={link.onClick}
                                variant="caption"
                                sx={{
                                    "&:hover": {
                                        cursor: "pointer",
                                        color: theme.palette.primary.main
                                    }
                                }}
                            >
                                {link.text}
                            </Typography>
                        </Grid>
                    );
                })
            }
        </Grid>
    );
}