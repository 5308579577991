import {
    Box,
    CircularProgress,
    Modal
} from "@mui/material";

export default function LoadingModal(props) {
    /*
    
    This puts a circular progress component on the center of the screen.

    */


    const {
        open,
        onClose
    } = props;

    return (
        <Modal
            open={open || false}
            onClose={onClose}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                <CircularProgress
                    size={80}
                />
            </Box>
        </Modal>
    );
}