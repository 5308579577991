import {
    useState,
    Fragment
} from "react";
import {
    Menu,
    MenuItem,
    Box,
    useTheme
} from "@mui/material";
import {
    Add
} from "@mui/icons-material";

import PrimaryButton from "../PrimaryButton/PrimaryButton";

import useAddItemModal from "../../../hooks/state/useAddItemModal";
import useCreateCollectionModal from "../../../hooks/state/useCreateCollectionModal";
import useLoginModal from "../../../hooks/state/useLoginModal";

export default function CreateMenu(props) {
    const {
        sx,
        currentCollectionIndex,
        isMobile
    } = props;

    const theme = useTheme();
    const {
        isAddItemModalOpen,
        openAddItemModal,
        closeAddItemModal,
        AddItemModal
    } = useAddItemModal();
    const {
        isCreateCollectionModalOpen,
        openCreateCollectionModal,
        closeCreateCollectionModal,
        CreateCollectionModal
    } = useCreateCollectionModal();
    const {
        isSignUpModalOpen,
        openSignUpModal,
        closeSignUpModal,
        AccountModalHandler
    } = useLoginModal();

    const [menuOpen, setMenuOpen] = useState(false);
    const [anchor, setAnchor] = useState(false);

    const openMenu = (e) => {
        setMenuOpen(true);
        setAnchor(e.currentTarget);
    };

    const MenuItemStyle = {
        "&:hover": {
            backgroundColor: theme.palette.grey["800"],
            color: theme.palette.common.white
        }
    };

    return (
        <Box
            sx={{
                alignSelf: "center",
                ...sx
            }}
        >
            <PrimaryButton
                text={!isMobile ? "Create" : "+"}
                onClick={openMenu}
                borderRadius={0.5}
                sx={
                    isMobile ? {
                        fontSize: 20.5,
                        py: 0,
                        px: 0,
                        width: 40,
                        minWidth: 40
                    } : null
                }
                adornment={
                    !isMobile ?
                        <Fragment>
                            <svg
                                height={40}
                                width={2}
                                style={{
                                    position: "absolute",
                                    right: 38
                                }}
                            >
                                <line
                                    x1='0'
                                    y1='0'
                                    x2='0'
                                    y2='40'
                                    stroke={theme.palette.common.white}
                                    style={{ strokeWidth: "4" }}
                                />
                            </svg>
                            <Add
                                sx={{
                                    ml: 2
                                }}
                            />
                        </Fragment> :
                        null
                }
            />
            <Menu
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                anchorEl={anchor}
            >
                <MenuItem
                    onClick={() => {
                        if (openAddItemModal()) {
                            setMenuOpen(false);
                        } else {
                            openSignUpModal();
                        }
                    }}
                    sx={MenuItemStyle}
                >
                    Add Item
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        if (openCreateCollectionModal()) {
                            setMenuOpen(false);
                        } else {
                            openSignUpModal();
                        }
                    }}
                    sx={MenuItemStyle}
                >
                    Create Collection
                </MenuItem>
            </Menu>

            <AddItemModal
                open={isAddItemModalOpen}
                close={closeAddItemModal}
                collectionIndex={currentCollectionIndex}
                isMobile={isMobile}
            />
            <CreateCollectionModal
                open={isCreateCollectionModalOpen}
                close={closeCreateCollectionModal}
                openAddItem={openAddItemModal}
                isMobile={isMobile}
            />

            <AccountModalHandler
                signUp={isSignUpModalOpen}
                onCloseSignUp={closeSignUpModal}
                isMobile={isMobile}
            />
        </Box>
    );
}