import {
    Modal,
    Box,
    IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { cfImageRoot } from '../../constants/api-routes'
import colors from '../../constants/colors'

const modalBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 6,
    m: 0,
    maxHeight: '90%',
    maxWidth: '90%',
    '&:active': {
        outline: 'none'
    },
    '&:focus': {
        outline: 'none'
    },
    borderRadius: 4
}

export default function ImageModal({ open, onClose, image }) {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Box
                sx={{
                    ...modalBoxStyle
                }}
            >
                <img
                    src={`${cfImageRoot}/${image}/public`}
                    style={{
                        maxHeight: '100%',
                        width: '100%',
                        maxWidth: 900,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        mb: 3
                    }}
                />

                {/* Close Icon */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 15,
                        left: 15,
                        margin: 'auto',
                        border: `2px solid ${colors.gray}`,
                        borderRadius: 14
                    }}
                >
                    <IconButton
                        onClick={onClose}
                        sx={{
                            m: 0,
                            p: 0
                        }}
                    >
                        <CloseIcon
                            sx={{
                                color: colors.gray
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>

        </Modal>
    )
}