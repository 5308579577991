import {
    Box,
    Stack,
    Typography
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function Requirement(props) {
    const {
        isMobile,
        isValid,
        text
    } = props;

    return (
        <Box
            sx={{
                my: 0.5
            }}
        >
            <Stack
                direction="row"
            >
                {
                    isValid ?
                        <CheckCircleIcon
                            color="success"
                        /> :
                        <CancelIcon
                            color="error"
                        />
                }
                <Typography
                    variant="body2"
                    sx={{
                        mt: 0.5,
                        ml: 1,
                        maxWidth: !isMobile ? null : 250
                    }}
                >
                    {text}
                </Typography>
            </Stack>
        </Box>
    );
}