import {
    Box,
    IconButton,
    useTheme
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function ItemCarouselButtons(props) {
    const {
        leftButton,
        leftButtonOnClick,
        rightButton,
        rightButtonOnClick
    } = props;

    const theme = useTheme();

    const iconButtonStyle = {
        backgroundColor: theme.palette.grey["900"],
        color: theme.palette.common.white,
        borderRadius: 1,
        "&:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.grey["900"],
            color: theme.palette.grey["300"]
        }
    };

    return (
        <Box>
            {
                leftButton ?
                    <Box
                        sx={{
                            position: "absolute",
                            top: "calc(50% - 34px)",
                            left: "calc(50% - 125px)"
                        }}
                    >
                        <IconButton
                            onClick={leftButtonOnClick}
                            sx={{
                                ...iconButtonStyle
                            }}
                        >
                            <ArrowBackIcon
                                sx={{
                                    fontSize: 18
                                }}
                            />
                        </IconButton>
                    </Box> : null
            }
            {
                rightButton ?
                    <Box
                        sx={{
                            position: "absolute",
                            top: "calc(50% - 34px)",
                            right: "calc(50% - 125px)"
                        }}
                    >
                        <IconButton
                            onClick={rightButtonOnClick}
                            sx={{
                                ...iconButtonStyle
                            }}
                        >
                            <ArrowForwardIcon
                                sx={{
                                    fontSize: 18
                                }}
                            />
                        </IconButton>
                    </Box> : null
            }
        </Box>
    );
}