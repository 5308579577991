import { Fragment } from "react";

import PrimaryButton from "../PrimaryButton/PrimaryButton";

export default function LoginButton(props) {
    const {
        openSignInModal
    } = props;

    return (
        <Fragment>
            <PrimaryButton
                onClick={openSignInModal}
                text="Login"
                borderRadius={0.5}
            />
        </Fragment>
    );
}