import { Grid } from "@mui/material";

import BaseModal from "../../../../../Common/BaseModal/BaseModal";

import Engagement from "../../../Engagement/Engagement";
import Share from "../../../Share/Share";


export default function MobileCTAsModal(props) {
    const {
        openMobileModal,
        setOpenMobileModal,
        collection
    } = props;

    return (
        <BaseModal
            open={openMobileModal}
            onClose={() => setOpenMobileModal(false)}
            sx={{
                height: 190,
                textAlign: "center",
                top: 180
            }}
        >
            <Grid
                container
                textAlign="left"
            >
                <Grid
                    item
                    xs={12}
                >
                    <Engagement />
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <Share
                        collectionId={collection.collectionId}
                    />
                </Grid>
            </Grid>
        </BaseModal>
    );
}