import {
    Checkbox,
    Grid,
    Typography
} from "@mui/material";

export default function StayLoggedInCheckbox(props) {
    const {
        stayLoggedIn,
        setStayLoggedIn
    } = props;

    return (
        <Grid
            container
            direction='row'
        >
            <Grid
                item
                xs={1}
            >
                <Checkbox
                    checked={stayLoggedIn}
                    onChange={(e) => setStayLoggedIn(e.target.checked)}
                />
            </Grid>
            <Grid
                item
                xs
            >
                <Typography
                    sx={{
                        fontFamily: "Open Sans",
                        fontSize: 12,
                        mt: 1.5,
                        ml: 2
                    }}
                >
                    Keep me logged in
                </Typography>
            </Grid>
        </Grid>
    );
}