import { Alert } from "@mui/material";

export default function Error(props) {
    const {
        isMobile,
        text,
        sx
    } = props;

    return (
        <Alert
            severity="error"
            sx={{
                width: 270,
                maxWidth: !isMobile ? null : "80%",
                mt: 1,
                mb: isMobile ? 1 : null,
                ...sx
            }}
        >
            {text}
        </Alert>
    );
}