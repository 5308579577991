import {
    useNavigate
} from "react-router-dom";


export default function useNavigateToRandomCollection() {
    const navigate = useNavigate();

    const navigateToRandomCollection = async () => {
        /* 
        
        This is a hack to make it work in the short term.
        This needs to be cleaned up.

        */

        const min = 97;
        const max = 316;

        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

        navigate(`/collection?collectionId=${randomNumber}`);
    };

    return navigateToRandomCollection;
}