import {
    Box,
    Typography,
    useTheme
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import ReactPlayer from "react-player";


export default function VideoItemBody(props) {
    const {
        videoUrl,
        link,
        ogTitle
    } = props;

    const theme = useTheme();

    return (
        <Box
            sx={{
                my: 1,
                flexGrow: 1
            }}
        >
            <ReactPlayer
                url={videoUrl}
                light
                pip
                controls={true}
                width={200}
                height={300}
                style={{
                    borderRadius: "16px 16px 0px 0px",
                    borderLeft: `1px solid ${theme.palette.grey["300"]}`,
                    borderRight: `1px solid ${theme.palette.grey["300"]}`,
                    borderTop: `1px solid ${theme.palette.grey["300"]}`,
                    overflow: "hidden"
                }}
            />
            <Box
                sx={{
                    width: 184,
                    borderLeft: `1px solid ${theme.palette.grey["300"]}`,
                    borderRight: `1px solid ${theme.palette.grey["300"]}`,
                    borderBottom: `1px solid ${theme.palette.grey["300"]}`,
                    borderRadius: "0px 0px 16px 16px",
                    mt: -0.5,
                    p: 1
                }}
            >
                <Typography
                    onClick={() => window.open(link, "_blank")}
                    variant="body2"
                    sx={{
                        fontWeight: "bolder",
                        fontSize: "80%",
                        p: 1,
                        "&:hover": {
                            color: theme.palette.grey["700"],
                            cursor: "pointer"
                        }
                    }}
                >
                    {ogTitle}
                </Typography>
                <Typography
                    variant="body2"
                    onClick={() => window.open(link, "_blank")}
                    sx={{
                        fontFamily: theme.typography.openSans,
                        fontSize: 12,
                        color: theme.palette.grey["500"],
                        "&:hover": {
                            color: theme.palette.grey["700"],
                            cursor: "pointer"
                        }
                    }}
                >
                    <LinkIcon
                        sx={{
                            verticalAlign: "middle",
                            mx: 1,
                            transform: "rotate(-45deg)",
                            fontSize: 16
                        }}
                    />
                    {link.split("/").length > 2 ? link.split("/")[2] : link}
                </Typography>
            </Box>
        </Box>
    );
}