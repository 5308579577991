import { Typography } from "@mui/material";

export default function Copyright() {
    const year = new Date().getFullYear();

    return (
        <Typography
            variant='caption'
        >
            {year} SLYKE Inc. All Right Reserved
        </Typography>
    );
}