import {
    Typography,
    Box,
    useTheme,
    IconButton,
    Stack,
    Avatar
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import useNavigateToCollection from "../../../../hooks/navigation/useNavigateToCollection";

import logo from "../../../../assets/images/slyke-logo-cropped.png";
import { useNavigate } from "react-router-dom";

export default function ItemViewNavbar(props) {
    const {
        collectionTitle,
        collectionId
    } = props;

    const theme = useTheme();
    const navigate = useNavigate();

    const navigateToCollection = useNavigateToCollection();

    return (
        <Box
            sx={{
                p: 2,
                position: "fixed",
                backgroundColor: theme.palette.common.white,
                width: "100%",
                top: 0,
                boxShadow: 3,
                zIndex: 2000
            }}
        >
            <Box
                sx={{
                    width: "64px",
                    position: "fixed",
                    top: 0
                }}
            >
                <Avatar
                    src={logo}
                    onClick={() => {
                        navigate("/");
                    }}
                    sx={{
                        width: 36,
                        height: 36,
                        mb: 0,
                        mr: 1,
                        mt: 2,
                        display: {
                            xs: "none",
                            sm: "block"
                        },
                        "&:hover": {
                            cursor: "pointer"
                        }
                    }}
                />
            </Box>
            <Stack
                direction="row"
                justifyContent="center"
                sx={{
                    width: "100%"
                }}
            >
                <IconButton
                    onClick={() => navigateToCollection(collectionId)}
                    variant="contained"
                    sx={{
                        backgroundColor: theme.palette.grey["300"],
                        borderRadius: 1,
                        mr: 10
                    }}
                >
                    <ArrowBackIosNewIcon
                        sx={{
                            pr: 0.25,
                            fontSize: 16
                        }}
                    />
                </IconButton>
                <Typography
                    onClick={() => navigateToCollection(collectionId)}
                    variant="h5"
                    sx={{
                        color: theme.palette.grey["700"],
                        fontWeight: "bold",
                        "&:hover": {
                            color: theme.palette.secondary.main,
                            cursor: "pointer"
                        }
                    }}
                >
                    {collectionTitle}
                </Typography>
            </Stack>
        </Box>
    );
}