import {
    Modal,
    IconButton,
    Box,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    TextField
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import colors from '../../constants/colors'
import DoubleArrowSharpIcon from '@mui/icons-material/DoubleArrowSharp'
import { useState } from 'react'

export default function ItemRejectionReasonModal(props) {
    const X = props.modalLocation[0]
    const Y = props.modalLocation[1]
    const [rejectionReason, setRejectionReason] = useState(null)
    const [note, setNote] = useState(null)

    const modalBoxStyle = {
        position: 'absolute',
        top: Y,
        left: X,
        transform: 'translate(-50%, -50%)',
        width: 400,
        height: 450,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 0,
        m: 0,
        borderRadius: 5,
        '&:active': {
            outline: 'none'
        },
        '&:focus': {
            outline: 'none'
        }
    }

    return (
        <Modal
            open={props.open || false}
            onClose={() => {
                setRejectionReason(null)
                setNote(null)
                props.close()
            }}
        >
            <Box
                sx={modalBoxStyle}
            >
                {/* Content */}
                <Box>
                    <Typography
                        align='center'
                        sx={{
                            fontFamily: 'Open Sans',
                            color: colors.error,
                            fontSize: 28
                        }}
                    >
                        REJECT ITEM
                    </Typography>
                    <Typography
                        align='center'
                        sx={{
                            fontFamily: 'Open Sans',
                            fontSize: 12,
                            color: colors.gray
                        }}
                    >
                        Select best reason for rejecting Item
                    </Typography>
                    <FormControl
                        sx={{
                            p: 2,
                            ml: 5
                        }}
                    >
                        <RadioGroup
                            value={rejectionReason}
                            onChange={(e) => { setRejectionReason(e.target.value) }}
                        >
                            <FormControlLabel
                                value='Does not fit Collection criteria'
                                control={<Radio />}
                                label='Does not fit Collection criteria'
                                labelPlacement='start'
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontFamily: 'Open Sans'
                                    }
                                }}
                            />
                            <FormControlLabel
                                value='Item is already in Collection'
                                control={<Radio />}
                                label='Item is already in Collection'
                                labelPlacement='start'
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontFamily: 'Open Sans'
                                    }
                                }}
                            />
                            <FormControlLabel
                                value='Not from a reputable source'
                                control={<Radio />}
                                label='Not from a reputable source'
                                labelPlacement='start'
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontFamily: 'Open Sans'
                                    }
                                }}
                            />
                            <FormControlLabel
                                value='Inappropriate or spam'
                                control={<Radio />}
                                label='Inappropriate or spam'
                                labelPlacement='start'
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontFamily: 'Open Sans'
                                    }
                                }}
                            />
                            <FormControlLabel
                                value='Other'
                                control={<Radio />}
                                label='Other (explain in note)'
                                labelPlacement='start'
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontFamily: 'Open Sans'
                                    }
                                }}
                            />
                        </RadioGroup>
                    </FormControl>
                    <TextField
                        multiline
                        rows={4}
                        value={note}
                        onChange={(e) => { setNote(e.target.value) }}
                        placeholder='Add a note... (optional, but reccomended)'
                        sx={{
                            display: 'inline',
                            width: '50%',
                            m: 2,
                            ml: 6,
                            mb: 4
                        }}
                    />
                    <DoubleArrowSharpIcon
                        onClick={() => {
                            props.rejectItem(rejectionReason, note)
                            setRejectionReason(null)
                            setNote(null)
                            props.close()
                        }}
                        sx={{
                            display: 'inline',
                            fontSize: 50,
                            mt: 3,
                            p: 1,
                            '&:hover': {
                                color: colors.primary,
                                cursor: 'pointer',
                                borderRadius: 8,
                                backgroundColor: colors.darkGray
                            },
                            '&:active': {
                                color: colors.white
                            }
                        }}
                    />
                </Box>

                {/* Close Icon */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: {
                            xs: 9,
                            sm: 5
                        },
                        left: {
                            xs: 9,
                            sm: 5
                        },
                        margin: 'auto'
                    }}
                >
                    <IconButton
                        onClick={() => {
                            setRejectionReason(null)
                            setNote(null)
                            props.close()
                        }}
                        sx={{
                            m: 0,
                            p: 0
                        }}
                    >
                        <CloseIcon
                            sx={{
                                color: colors.gray
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>
        </Modal>
    )
}