import { Box, Typography } from "@mui/material";

import BaseModal from "../../../BaseModal/BaseModal";
import CloseButton from "../../../BaseModal/CloseButton/CloseButton";

export default function ForgotPasswordInfo(props) {
    /* 
    
    This modal lets the user know to check their email 
    for their password reset link.

    */

    const {
        isMobile,
        forgotPasswordInfo,
        close
    } = props;

    return (
        <BaseModal
            open={forgotPasswordInfo}
            onClose={close}
            sx={{
                width: !isMobile ? 400 : 300
            }}
        >
            <Box
                sx={{
                    p: 4,
                    pl: !isMobile ? 6 : 2
                }}
            >
                <Typography
                    variant='h6'
                    sx={{
                        fontFamily: "Open Sans",
                        borderBottom: "1px solid black",
                        width: 250,
                        fontWeight: "bold",
                        mb: !isMobile ? 3 : 1
                    }}
                >
                    Password Reset Link Sent
                </Typography>
                <Typography
                    sx={{
                        width: 300,
                        mb: !isMobile ? 3 : 1
                    }}
                >
                    Check your email for a password reset link.
                    This step helps us keep you safe while using Slyke.
                </Typography>
            </Box>

            <CloseButton
                isMobile={isMobile}
                onClose={close}
            />
        </BaseModal>
    );
}