import useIsMobile from "../../hooks/media/useIsMobile";

import CollectionOverview from "../../components/CollectionOverview/CollectionOverview";
import Collection from "../../legacy-pages/collection/collection";

export default function CollectionOverviewPage() {
    const isMobile = useIsMobile();

    if (isMobile) {
        return (
            <CollectionOverview />
        );
    } else {
        return (
            <Collection />
        );
    }
}