import { Box, Stack, Typography, Button, Popover, useTheme, Link } from '@mui/material'
import { cfImageRoot } from '../../constants/api-routes'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ShuffleIcon from '@mui/icons-material/Shuffle'
import { useState } from 'react'
import apiRoutes from '../../constants/api-routes'
import axios from 'axios'
import ItemRejectionReasonModal from '../item-rejection-reason-modal/item-rejection-reason-modal'
import { useSelector } from 'react-redux'
import { getToken, getUserId } from '../../store/user/userSlice'
import ReactPlayer from 'react-player'
import { cfVideoRoot, frontRoot } from '../../constants/api-routes'
import TextItemPreview from './text-item-preview'
import LinkIcon from '@mui/icons-material/Link'
import ImageModal from './image-modal'
import useNavigateToProfile from '../../hooks/navigation/useNavigateToProfile'

export default function ItemApproval({ item, showPendingItems }) {
    const theme = useTheme()

    const token = useSelector(getToken)
    const userId = useSelector(getUserId)

    const navigateToProfile = useNavigateToProfile()

    const [openImage, setOpenImage] = useState(false)
    const [showInfo, setShowInfo] = useState(null)
    const [showRejectionReasonModal, setShowRejectionReasonModal] = useState(false)
    const [modalLocation, setModalLocation] = useState([0, 0])

    const handleDefaultImage = (itemId) => {
        const imgRef = `image-preview-${itemId}`
        const img = document.getElementById(imgRef)
        const defaultImageLink = `${cfImageRoot}/${item.defaultImage}/itempreviewthumb`

        if (img.src === defaultImageLink) {
            axios.get(apiRoutes.updateDefaultImage, {
                params: {
                    itemId
                },
                headers: {
                    'authorization': `Bearer ${token}`
                }
            })
        }
    }

    const approveItem = () => {
        if (item.itemType === 'link' && !item.ogVideoLink) {
            handleDefaultImage(item.itemId)
        }

        axios.get(apiRoutes.addItemApproval, {
            params: {
                itemId: item.itemId,
                userId
            },
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(() => {
            setTimeout(() => {
                showPendingItems()
            }, 500)
        })
    }

    const openRejectionModal = (e) => {
        setShowRejectionReasonModal(true)
        setModalLocation([e.clientX - 50, e.clientY])
    }

    const rejectItem = (rejectionReason, note) => {
        axios.get(apiRoutes.addItemRejection, {
            params: {
                itemId: item.itemId,
                userId,
                reason: rejectionReason,
                note: note ? note : ''
            },
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(() => {
            showPendingItems()
        }).catch((e) => alert(e))
    }

    const swapImage = () => {
        const imgRef = `image-preview-${item.itemId}`
        const img = document.getElementById(imgRef)

        const imageLink = `${cfImageRoot}/${item.image}/itempreviewthumb`
        const defaultImageLink = `${cfImageRoot}/${item.defaultImage}/itempreviewthumb`

        if (img.src === imageLink) {
            img.src = defaultImageLink
        } else {
            img.src = imageLink
        }
    }


    return (
        <Box
            sx={{
                width: '100%'
            }}
        >
            <Stack
                direction='row'
                sx={{
                    mx: 'auto',
                    p: 4,
                    backgroundColor: theme.palette.grey['300'],
                    borderRadius: 4,
                    width: 'fit-content',
                    mb: 4
                }}
            >
                <Box
                    height={400}
                    width={400}
                    sx={{
                        mr: 2
                    }}
                >
                    {
                        item.itemType === 'text' ?
                            <TextItemPreview
                                text={item.ogDescription}
                            /> :
                            !item.ogVideoLink ?
                                <Box
                                    sx={{
                                        cursor: 'pointer'
                                    }}
                                >
                                    <img
                                        onClick={() => setOpenImage(true)}
                                        id={`image-preview-${item.itemId}`}
                                        src={`${cfImageRoot}/${item.image}/itempreviewthumb`}
                                        height={350}
                                        width={400}
                                        style={{
                                            borderRadius: '16px'
                                        }}
                                    />
                                </Box>
                                :
                                <ReactPlayer
                                    url={item.itemType !== 'media' ? item.ogVideoLink : `${cfVideoRoot}/${item.ogVideoLink}/manifest/video.m3u8`}
                                    controls={true}
                                    light
                                    width={400}
                                    height={350}
                                    style={{
                                        borderRadius: '16px',
                                        overflow: 'hidden'
                                    }}
                                />
                    }
                    <Typography
                        sx={{
                            fontFamily: theme.typography.openSans,
                            mt: 0.5
                        }}
                    >
                        {item.ogTitle}
                    </Typography>
                    {
                        item.link ?
                            <Typography
                                onClick={() => window.open(item.link, '_blank')}
                                sx={{
                                    fontFamily: theme.typography.openSans,
                                    color: theme.palette.grey['500'],
                                    '&:hover': {
                                        color: theme.palette.grey['700'],
                                        cursor: 'pointer'
                                    }
                                }}
                            >
                                <LinkIcon
                                    sx={{
                                        verticalAlign: 'middle',
                                        mr: 1,
                                        transform: 'rotate(-45deg)',
                                        fontSize: 20
                                    }}
                                />
                                {item.link.split('/').length > 2 ? item.link.split('/')[2] : item.link}
                            </Typography> : null
                    }
                </Box>
                <Stack
                    sx={{
                        py: 3,
                        pl: 4,
                        borderLeft: `1px solid ${theme.palette.grey['400']}`,
                        minWidth: '400px',
                        maxWidth: '600px'
                    }}
                >
                    <Stack
                        direction="row"
                    >
                        <Typography
                            variant='body2'
                            sx={{
                                fontFamily: theme.typography.openSans
                            }}
                        >
                            Submitted By:
                            <Link
                                onClick={() => {
                                    navigateToProfile(item.userAddedBy)
                                    if (window.location.href.includes("profile")) {
                                        window.location.reload() // forces reload of the page when on profile already
                                    }
                                }}
                                sx={{
                                    ml: 0.5,
                                    "&:hover": {
                                        cursor: "pointer"
                                    }
                                }}
                            >
                                {item.displayName}
                            </Link>
                        </Typography>
                        <InfoOutlinedIcon
                            onClick={(e) => {
                                setShowInfo(e.currentTarget)
                            }}
                            sx={{
                                ml: 'auto',
                                color: theme.palette.grey['500'],
                                mb: 2,
                                '&:hover': {
                                    cursor: 'pointer'
                                }
                            }}
                        />
                    </Stack>
                    <Typography
                        sx={{
                            fontFamily: theme.typography.openSans,
                            fontWeight: 'bolder',
                            mb: 2
                        }}
                    >
                        {item.title}
                    </Typography>
                    <Typography
                        variant='body2'
                        sx={{
                            fontFamily: theme.typography.openSans
                        }}
                    >
                        {item.description ? item.description : 'No Description Provided'}
                    </Typography>

                    <Stack
                        sx={{
                            flexGrow: 1
                        }}
                    >
                        <Stack
                            direction='row'
                            sx={{
                                justifyContent: 'center',
                                my: 'auto'
                            }}
                        >
                            <Button
                                onClick={approveItem}
                                color='secondary'
                                variant='outlined'
                                sx={{
                                    width: 125,
                                    borderRadius: 8,
                                    fontFamily: theme.typography.openSans,
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    letterSpacing: 0.5,
                                    mr: 2,
                                    '&:hover': {
                                        backgroundColor: theme.palette.grey['300'],
                                    }
                                }}
                            >
                                Approve
                            </Button>
                            <Button
                                onClick={(e) => { openRejectionModal(e) }}
                                variant='outlined'
                                color='error'
                                sx={{
                                    width: 125,
                                    borderRadius: 8,
                                    fontFamily: theme.typography.openSans,
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    letterSpacing: 0.5,
                                    '&:hover': {
                                        backgroundColor: theme.palette.grey['300'],
                                    }
                                }}
                            >
                                Reject
                            </Button>
                        </Stack>
                    </Stack>

                    <Button
                        onClick={swapImage}
                        sx={{
                            display: item.ogVideoLink ? "none" : null,
                            borderRadius: 8,
                            fontFamily: theme.typography.openSans,
                            fontWeight: 'bold',
                            textTransform: 'none',
                            letterSpacing: 0.5,
                            mr: 2,
                            '&:hover': {
                                backgroundColor: theme.palette.grey['300'],
                            }
                        }}
                    >
                        Swap Image <ShuffleIcon sx={{ ml: 1 }} />
                    </Button>
                </Stack>

                <Popover
                    open={!!showInfo}
                    anchorEl={showInfo}
                    onClose={() => setShowInfo(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Box
                        width={200}
                        sx={{
                            p: 3
                        }}
                    >
                        <Typography
                            variant='body2'
                            sx={{
                                fontFamily: theme.typography.openSans,
                                fontWeight: 'bolder',
                                mb: 2
                            }}
                        >
                            You can decide to approve or reject this item.
                        </Typography>
                        <Typography
                            variant='body2'
                            sx={{
                                fontFamily: theme.typography.openSans
                            }}
                        >
                            If the item has an image you can choose to swap it out with the collection&apos;s default image.
                        </Typography>
                    </Box>
                </Popover>

                <ItemRejectionReasonModal
                    open={showRejectionReasonModal}
                    close={() => { setShowRejectionReasonModal(false) }}
                    modalLocation={modalLocation}
                    rejectItem={rejectItem}
                />
                <ImageModal
                    open={openImage}
                    onClose={() => setOpenImage(false)}
                    image={item.image}
                />
            </Stack>
        </Box>
    )
}