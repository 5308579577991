import {
    Button,
    Stack
} from '@mui/material'
import {
    Fragment,
    useState,
    useEffect
} from 'react'
import colors from '../../constants/colors'

const buttonStyle = {
    fontFamily: 'Open Sans',
    color: colors.gray,
    borderRadius: 0,
    px: 3,
    width: '100%',
    justifyContent: 'start',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: colors.darkGray,
        color: colors.lightGray
    }
}

const activeButtonStyle = {
    color: colors.primary
}

export default function SidebarFilterButtons(props) {
    const [activeFilter, setActiveFilter] = useState(props.defaultFilter)

    const handleActiveButtonStyle = (key) => {
        if (activeFilter === key) {
            return activeButtonStyle
        }
    }

    useEffect(() => {
        if (props.handleFilter) {
            props.handleFilter(activeFilter)
        } else {
            console.log('no filter is present')
        }
    }, [activeFilter])

    return (
        <Fragment>
            <Stack>
                {
                    props.filters.map((filter, ind) => {
                        return (
                            <Button
                                key={ind}
                                onClick={() => { setActiveFilter(filter) }}
                                sx={{
                                    ...buttonStyle,
                                    ...handleActiveButtonStyle(filter)
                                }}
                            >
                                {filter}
                            </Button>
                        )
                    })
                }
            </Stack>
        </Fragment>

    )
}