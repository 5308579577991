import {
    Modal,
    Button,
    IconButton,
    Typography,
    Box,
    Grid,
    TextField,
    Link,
    Avatar,
    Alert,
    useTheme
} from '@mui/material'
import {
    Fragment,
    useState
} from 'react'
import CloseIcon from '@mui/icons-material/Close'
import Checkbox from '@mui/material/Checkbox'
import ReasonsToJoin from '../../reasons-to-join/reasons-to-join'
import logo from '../../../assets/images/slyke-logo-cropped.png'
import colors from '../../../constants/colors'
import { useDispatch } from 'react-redux'
import {
    setIsLoggedIn,
    setUserId,
    setToken,
    setIdToken,
    setUsername,
    setDisplayName,
    setHandle,
    setRefreshToken
} from '../../../store/user/userSlice'
import axios from 'axios'
import apiRoutes from '../../../constants/api-routes'
import useMediaQuery from '@mui/material/useMediaQuery'

export default function SignInModal(props) {
    const {
        isMobile
    } = props;

    const dispatch = useDispatch()
    const theme = useTheme()
    const isBelowDesktop = useMediaQuery(theme.breakpoints.down('mdlg'), { noSsr: true })

    const [error, setError] = useState(false)
    const [userInfo, setUserInfo] = useState({
        username: '',
        password: '',
        staySignedIn: false
    })

    const formFields = [{
        label: 'Username',
        value: userInfo.username,
        onChange: (e) => { setUserInfo({ ...userInfo, username: e.target.value }) },
        type: 'username',
        sx: {
            maxWidth: !isMobile ? null : '90%'
        }
    }, {
        label: 'Password',
        value: userInfo.password,
        onChange: (e) => { setUserInfo({ ...userInfo, password: e.target.value }) },
        type: 'password',
        sx: {
            maxWidth: !isMobile ? null : '90%'
        }
    }]

    const modalBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            xs: '100%',
            sm: 660
        },
        maxHeight: '95%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 0,
        m: 0,
        borderRadius: 5,
        '&:active': {
            outline: 'none'
        },
        '&:focus': {
            outline: 'none'
        }
    }

    const rightTextStyle = {
        color: 'white',
        fontFamily: 'Open Sans'
    }

    const handleLogin = async () => {
        setError(false)
        let loginResponse = await axios.post(apiRoutes.login, {
            username: userInfo.username,
            password: userInfo.password
        }).catch((e) => {
            if (e.response.status === 401 && e.response.data.challengeName === 'NEW_PASSWORD_REQUIRED') {
                props.openChangePassword({
                    username: e.response.data.challengeParams.USER_ID_FOR_SRP,
                    handle: JSON.parse(e.response.data.challengeParams.userAttributes).name,
                    session: e.response.data.session
                })
                props.close()
            } else if (e.response.data.challengeName === 'FORGOT_PASSWORD') {
                handleForgotPassword()
            } else {
                setError('Login failed!')
            }
        })

        if (loginResponse.data) {
            if (userInfo.staySignedIn) {
                dispatch(setRefreshToken(loginResponse.data.refreshToken))
                dispatch(setToken(loginResponse.data.token))
                dispatch(setIdToken(loginResponse.data.idToken))
                dispatch(setUserId(loginResponse.data.userId))
                dispatch(setUsername(loginResponse.data.username))
                dispatch(setDisplayName(loginResponse.data.displayName))
                dispatch(setHandle(loginResponse.data.handle))
                dispatch(setIsLoggedIn(true))
            } else {
                dispatch(setToken(loginResponse.data.token))
                dispatch(setIdToken(loginResponse.data.idToken))
                dispatch(setUserId(loginResponse.data.userId))
                dispatch(setUsername(loginResponse.data.username))
                dispatch(setDisplayName(loginResponse.data.displayName))
                dispatch(setHandle(loginResponse.data.handle))
                dispatch(setIsLoggedIn(true))
            }
            props.close()
        }
    }

    const handleForgotPassword = () => {
        if (userInfo.username) {
            axios.post(apiRoutes.resetPassword, {
                username: userInfo.username
            })
            props.openChangePassword({
                forgotPassword: true
            })
            props.close()
        } else {
            setError('You must enter a username first!')
        }
    }

    return (
        <Modal
            open={props.open}
            onClose={() => {
                props.close()
                setError(false)
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={modalBoxStyle}
            >
                <Grid
                    container
                    direction='row'
                >
                    <Grid
                        item
                        xs={12}
                        sm={7}
                        sx={{
                            p: 2,
                            pl: {
                                xs: 5,
                                sm: 4
                            }
                        }}
                    >
                        <Typography
                            variant='h6'
                            sx={{
                                fontFamily: 'Open Sans',
                                borderBottom: '1px solid black',
                                width: 182,
                                fontWeight: 'bold',
                                mb: 3
                            }}
                        >
                            Sign In
                        </Typography>
                        <Typography
                            variant='body2'
                            sx={{
                                width: 250,
                                mb: 3
                            }}
                        >
                            Sign in for the full Slyke experience and to access your collections.
                        </Typography>
                        {
                            formFields.map((field) => {
                                return (
                                    <Fragment
                                        key={field.label}
                                    >
                                        <Grid
                                            container
                                            direction='row'
                                        >
                                            <Grid
                                                item
                                                xs={field.type === 'password' ? 6 : 12}
                                            >
                                                <Typography
                                                    variant='body1'
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        fontFamily: 'Open Sans'
                                                    }}
                                                >
                                                    {field.label}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={field.type === 'password' ? 6 : 0}
                                            >
                                                <Link
                                                    onClick={handleForgotPassword}
                                                    sx={{
                                                        display: field.type === 'password' ? 'block' : 'none',
                                                        fontSize: 12,
                                                        mr: 0,
                                                        ml: 2,
                                                        mt: 5 / 10,
                                                        color: colors.secondary,
                                                        textDecoration: 'none',
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            color: colors.primary
                                                        },
                                                        fontFamily: 'Open Sans'
                                                    }}
                                                >
                                                    Forgot Password?
                                                </Link>
                                            </Grid>
                                        </Grid>
                                        <TextField
                                            variant='outlined'
                                            type={field.type}
                                            value={field.value}
                                            onChange={field.onChange}
                                            name={field.type === 'password' ? 'password' : 'username'}
                                            autoComplete={field.type === 'password' ? 'password' : 'UserName'}
                                            sx={{
                                                p: 0,
                                                m: 0,
                                                '& .MuiOutlinedInput-root': {
                                                    height: 35
                                                },
                                                mb: 2,
                                                width: 300,
                                                ...field.sx
                                            }}
                                        />
                                    </Fragment>
                                )
                            })
                        }
                        <Grid
                            container
                            direction='row'
                        >
                            <Grid
                                item
                                xs={1}
                            >
                                <Checkbox
                                    checked={userInfo.staySignedIn}
                                    onChange={(e) => setUserInfo({ ...userInfo, staySignedIn: e.target.checked })}
                                />
                            </Grid>
                            <Grid
                                item
                                xs
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        mt: 1.5,
                                        ml: 2
                                    }}
                                >
                                    Keep me logged in
                                </Typography>
                            </Grid>
                        </Grid>
                        {
                            error ?
                                <Alert
                                    sx={{
                                        p: 0,
                                        mb: 3
                                    }}
                                    severity="error"
                                >
                                    {error}
                                </Alert> : null
                        }
                        <Button
                            onClick={handleLogin}
                            variant='contained'
                            sx={{
                                display: 'block',
                                width: !isMobile ? 300 : "90%",
                                color: 'white',
                                textTransform: 'none',
                                fontFamily: 'Open Sans',
                                mt: 1,
                                mb: 2
                            }}
                        >
                            Sign In
                        </Button>
                        <Grid
                            container
                            direction='row'
                        >
                            {/* <Grid
                                item
                                xs
                            >
                                <Typography
                                    sx={{
                                        mt: 1,
                                        fontSize: 16,
                                        fontFamily: 'Open Sans'
                                    }}
                                >
                                    or sign in with
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs
                            >
                                <IconButton>
                                    <GoogleIcon
                                        sx={{
                                            color: 'black',
                                            mr: 0,
                                            ml: 2
                                        }}
                                    />
                                </IconButton>
                                <IconButton>
                                    <FacebookIcon
                                        sx={{
                                            color: 'black'
                                        }}
                                    />
                                </IconButton>
                                <IconButton>
                                    <TwitterIcon
                                        sx={{
                                            color: 'black'
                                        }}
                                    />
                                </IconButton>
                            </Grid> */}
                        </Grid>
                        {
                            isBelowDesktop ?
                                <Box
                                    sx={{
                                        backgroundColor: theme.palette.grey[300],
                                        p: 1,
                                        borderRadius: 2,
                                        mt: 2,
                                        width: "85%",
                                    }}
                                >
                                    {
                                        !isMobile ?
                                            <Typography
                                                variant='body2'
                                                sx={{
                                                    ...rightTextStyle,
                                                    mt: 1,
                                                    mb: 2,
                                                }}>
                                                Don&apos;t have an account yet?
                                            </Typography> : null
                                    }
                                    <Link
                                        onClick={() => {
                                            props.close()
                                            props.openSignUp()
                                        }}
                                        sx={{
                                            ...rightTextStyle,
                                            color: theme.palette.primary.main,
                                            textDecoration: 'underline',
                                            fontSize: 12,
                                            '&:hover': {
                                                cursor: 'pointer',
                                                color: colors.secondary
                                            }
                                        }}
                                    >
                                        Get your Slyke account
                                    </Link>
                                </Box>
                                : null
                        }
                    </Grid>
                    <Grid
                        item
                        xs
                        sx={{
                            backgroundImage: 'linear-gradient(to bottom, #FF7300, #0033FF)',
                            p: 2,
                            borderRadius: '0 19px 19px 0',
                            display: {
                                xs: 'none',
                                sm: 'block'
                            }
                        }}
                    >
                        {
                            !isMobile ?
                                <Typography
                                    variant='body2'
                                    sx={{
                                        ...rightTextStyle,
                                        mt: 7,
                                        mb: 2
                                    }}>
                                    Don&apos;t have an account yet?
                                </Typography> : null
                        }
                        <Link
                            onClick={() => {
                                props.close()
                                props.openSignUp()
                            }}
                            sx={{
                                ...rightTextStyle,
                                textDecoration: 'underline',
                                fontSize: 18,
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: colors.secondary
                                }
                            }}
                        >
                            Get your Slyke account
                        </Link>
                        <Box
                            sx={{
                                mt: 4
                            }}
                        >
                            <ReasonsToJoin
                                color={'white'}
                                fontWeight={'lighter'}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 20,
                        margin: 'auto',
                        display: {
                            xs: 'none',
                            sm: 'block'
                        }
                    }}
                >
                    <Avatar
                        src={logo}
                        sx={{
                            width: 36,
                            height: 36,
                            mr: 1,
                            display: 'inline-flex'
                        }}
                    />
                    <Typography
                        variant='h3'
                        sx={{
                            display: 'inline-flex',
                            mb: 2,
                            color: 'white'
                        }}
                    >
                        SLYKE
                    </Typography>
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        top: {
                            xs: 9,
                            sm: 5
                        },
                        left: {
                            xs: 9,
                            sm: 5
                        },
                        margin: 'auto'
                    }}
                >
                    <IconButton
                        onClick={() => {
                            props.close()
                            setError(false)
                        }}
                        sx={{
                            m: 0,
                            p: 0
                        }}
                    >
                        <CloseIcon
                            sx={{
                                color: colors.gray
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>
        </Modal>
    )
}