import {
    Box,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import SlykeLogo from "./SlykeLogo/SlykeLogo";

export default function DesktopSideColumn(props) {
    const {
        isMobile,
        close,
        openSignUp
    } = props;

    const theme = useTheme();

    return (
        <Grid
            item
            xs
            sx={{
                backgroundImage: "linear-gradient(to bottom, #FF7300, #0033FF)",
                p: 2,
                borderRadius: "0 19px 19px 0",
                display: {
                    xs: "none",
                    sm: "block"
                }
            }}
        >
            {
                !isMobile ?
                    <Typography
                        variant='body2'
                        sx={{
                            mt: 7,
                            mb: 2,
                            color: theme.palette.common.white,
                        }}>
                        Don&apos;t have an account yet?
                    </Typography> : null
            }
            <Link
                onClick={() => {
                    close();
                    openSignUp();
                }}
                sx={{
                    color: theme.palette.common.white,
                    textDecoration: "underline",
                    fontFamily: theme.typography.fontFamily,
                    fontSize: 18,
                    "&:hover": {
                        cursor: "pointer",
                        color: theme.palette.secondary.main
                    }
                }}
            >
                Get your Slyke account
            </Link>
            <Box
                sx={{
                    mt: 4
                }}
            >
                <List
                    dense
                    sx={{
                        ml: -1
                    }}
                >
                    {
                        [
                            "Follow and Create Collections",
                            "Curate Collections",
                            "Comment on and Favorite Items",
                            "Chat",
                            "More!"
                        ].map((text) => {
                            return (
                                <ListItem key={text} sx={{ py: 0 }}>
                                    <ListItemIcon sx={{ minWidth: 0, mr: 1, pb: 2 / 10 }}>
                                        <CircleIcon
                                            sx={{
                                                fontSize: 6,
                                                color: theme.palette.common.white,
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        align='left'
                                        sx={{
                                            color: theme.palette.common.white,
                                            m: 0,
                                            mb: 2 / 10
                                        }}
                                    >
                                        {text}
                                    </ListItemText>
                                </ListItem>
                            );
                        })
                    }
                </List>
            </Box>

            <SlykeLogo
                isMobile={isMobile}
            />
        </Grid>
    );
}