import { useEffect, useState } from "react";

import BaseModal from "../BaseModal/BaseModal";
import AddComment from "./AddComment/AddComment";
import CommentsBody from "./CommentsBody/CommentsBody";
import CommentsHeader from "./CommentsHeader/CommentsHeader";

import useGetComments from "../../../hooks/data/useGetComments";

export default function CommentsModal(props) {
    /* 
    
    Item ID is the right way of handling comments.
    This needs to be changed on the backend.

    As the number of comments grows, passing in the entire array will harm performance.

    */

    const {
        open,
        onClose,
        isLoggedIn,
        comments,
        itemId,
        collectionId,
        openSignUpModal,
        openSignInModal,
        isMobile,
        refreshOverride
    } = props;

    const [stateComments, setStateComments] = useState(comments || []);
    const [page, setPage] = useState(1);

    const getComments = useGetComments();

    const v2Refresh = () => {
        setPage(1);
        setStateComments([]);
        getComments(itemId, setStateComments, 1);
    };

    useEffect(() => {
        // the legacy version will provide an array here instead
        if (!comments && open) {
            getComments(itemId, setStateComments, page);
        }

        if (!comments && !open) {
            setStateComments([]);
            setPage(1);
        }

        if (comments) {
            setStateComments(comments);
        }
    }, [comments, page, open]);

    return (
        <BaseModal
            open={open}
            onClose={onClose}
            closeButton
            sx={{
                height: isMobile ? "100%" : 600,
                width: isMobile ? "100%" : 660,
                borderRadius: isMobile ? 0 : 4,
                transform: isMobile ? "none" : "translate(-50%, -50%)",
                top: isMobile ? 0 : "50%",
                left: isMobile ? 0 : "50%",
                maxHeight: isMobile ? "100%" : 600,
                display: "flex",
                flexDirection: "column"
            }}
        >
            <CommentsHeader
                isLoggedIn={isLoggedIn}
                collectionId={collectionId}
                openSignUpModal={openSignUpModal}
                openSignInModal={openSignInModal}
                onClose={onClose}
                isMobile={isMobile}
            />
            <CommentsBody
                comments={stateComments}
                isMobile={isMobile}
                onInView={() => setPage(page + 1)}
            />
            <AddComment
                itemId={itemId}
                collectionId={collectionId}
                openSignUpModal={openSignUpModal}
                onClose={onClose}
                isMobile={isMobile}
                refreshOverride={refreshOverride}
                v2Refresh={v2Refresh}
            />
        </BaseModal>
    );
}