import { Box, Typography } from "@mui/material";

export default function CuratorComingSoon() {
    return (
        <Box
            textAlign="center"
            sx={{
                mx: 2,
                mb: 2
            }}
        >
            <Typography
                variant="caption"
                sx={{
                    fontStyle: "italic"
                }}
            >
                Curator controls coming soon!
            </Typography>
        </Box>
    );
}