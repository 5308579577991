import { Box, Grid, Typography, useTheme } from "@mui/material";
import useGetTimeAgo from "../../../../../hooks/data/useGetTimeAgo";
import useNavigateToProfile from "../../../../../hooks/navigation/useNavigateToProfile";
import { useRef } from "react";
import { useEffect } from "react";
import useIntersection from "../../../../../hooks/useIntersection";

export default function Comment(props) {
    const {
        commentText,
        commentedAt,
        commentUsername,
        commentUserId,
        isMobile,
        onInView
    } = props;

    const getTimeAgo = useGetTimeAgo();
    const navigateToProfile = useNavigateToProfile();
    const { setupObserver } = useIntersection();

    const theme = useTheme();
    const ref = useRef();

    useEffect(() => {
        if (onInView) {
            setupObserver(ref, onInView);
        }
    }, []);

    return (
        <Box
            ref={ref}
            sx={{
                my: 2,
                width: "100%"
            }}
        >
            <Grid
                container
                sx={{
                    my: 0.5
                }}
            >
                <Grid
                    item
                    xs={6}
                >
                    <Typography
                        variant={isMobile ? "caption" : "body1"}
                        onClick={() => navigateToProfile(commentUserId)}
                        sx={{
                            color: theme.palette.secondary.main,
                            "&:hover": {
                                color: theme.palette.primary.main,
                                cursor: "pointer"
                            }
                        }}
                    >
                        {commentUsername}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={6}
                    textAlign="right"
                >
                    <Typography
                        variant="caption"
                    >
                        {getTimeAgo(commentedAt)}
                    </Typography>
                </Grid>
            </Grid>
            <Typography
                sx={{
                    wordBreak: "break-word"
                }}
            >
                {commentText}
            </Typography>
        </Box>
    );
}