const validateUsername = (username) => {
    if (username.length < 3 || username.length > 23) {
        return false
    }

    if (username.match(/ /)) {
        return false
    }

    return true
}

export default validateUsername