import { useNavigate } from "react-router-dom";

export default function useNavigateToAbout() {
    const navigate = useNavigate();

    const navigateToAbout = () => {
        navigate("/about");
    };

    return navigateToAbout;
}