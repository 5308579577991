import {
    Box,
    useTheme
} from "@mui/material";
import Requirement from "./Requirement/Requirement";

export default function PasswordRequirements(props) {
    const {
        isMobile,
        isValidLength,
        isValidUppercase,
        isValidLowercase,
        isValidSymbol,
        isValidNumber,
        isMatching
    } = props;

    const theme = useTheme();

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.primary.light,
                width: !isMobile ? 270 : 200,
                p: 2,
                my: 2
            }}
        >
            <Requirement
                isMobile={isMobile}
                isValid={isValidLength}
                text="8 characters minimum"
            />
            <Requirement
                isMobile={isMobile}
                isValid={isValidUppercase}
                text="1 uppercase letter"
            />
            <Requirement
                isMobile={isMobile}
                isValid={isValidLowercase}
                text="1 lowercase letter"
            />
            <Requirement
                isMobile={isMobile}
                isValid={isValidSymbol}
                text="1 symbol: ! @ # $ % ^ & * - _ + = , . ?"
            />
            <Requirement
                isMobile={isMobile}
                isValid={isValidNumber}
                text="1 number"
            />
            <Requirement
                isMobile={isMobile}
                isValid={isMatching}
                text="Passwords must match"
            />
        </Box>
    );
}