import {
    useState
} from "react";
import {
    Box,
    Grid,
    Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { getUserData } from "../../../../../store/user/userSlice";

import BookmarkIcon from "../../../../../assets/icons/engagement/Bookmark.svg";
import BookmarkedIcon from "../../../../../assets/icons/engagement/Bookmarked.svg";
import EyeIcon from "../../../../../assets/icons/engagement/Eye.svg";
import LikeIcon from "../../../../../assets/icons/engagement/Like.svg";
import LikedIcon from "../../../../../assets/icons/engagement/Liked.svg";
import CommentIcon from "../../../../../assets/icons/engagement/Comment.svg";
import ShareIcon from "../../../../../assets/icons/engagement/Share.svg";

import BasicAlert from "../../../../Common/BasicAlert/BasicAlert";

import useEngagementMetrics from "../../../../../hooks/data/useEngagementMetrics";
import useHandleItemLike from "../../../../../hooks/data/useHandleItemLike";
import useLoginModal from "../../../../../hooks/state/useLoginModal";
import useRefreshSpecificCollection from "../../../../../hooks/data/useRefreshSpecificCollection";
import useHandleShare from "../../../../../hooks/data/useHandleShare";
import useCommentsModal from "../../../../../hooks/state/useCommentsModal";
import useHandleItemBookmark from "../../../../../hooks/data/useHandleItemBookmark";

export default function ItemEngagement(props) {
    /*

    This component is the engagement icons used in the item view mobile pages.

    - Views
    - Like
    - Comment
    - Share

    */

    const {
        item,
        views,
        likes,
        comments,
        bookmarks,
        title,
        collectionId,
        isMobile,
        shareLinkOverride,
        refreshOverride,
        commentsRefreshOverride
    } = props;

    const {
        isLoggedIn
    } = useSelector(getUserData);

    const [showSuccess, setShowSuccess] = useState(false);

    // this allows us to "fake" unbookarming an item
    // the database is updated, but it keeps the item present on the bookamrk view
    // this gives the user the opportunity to bookmark it again and is less jarring
    const [isBookmarked, setIsBookmarked] = useState(item.isBookmarked);

    // custom hooks
    const convertEngagementMetricToString = useEngagementMetrics();
    const handleItemLike = useHandleItemLike();
    const handleItemBookmark = useHandleItemBookmark();
    const refreshSpecificCollection = refreshOverride ? refreshOverride : useRefreshSpecificCollection();
    const handleShare = useHandleShare(shareLinkOverride);
    const {
        isSignUpModalOpen,
        openSignUpModal,
        closeSignUpModal,
        isSignInModalOpen,
        closeSignInModal,
        openSignInModal,
        AccountModalHandler
    } = useLoginModal();
    const {
        isCommentsModalOpen,
        openCommentsModal,
        closeCommentsModal,
        CommentsModal
    } = useCommentsModal();

    // holds the engagement icon data, style, and interactivity as an array of objects
    const icons = [
        {
            svg: EyeIcon,
            count: convertEngagementMetricToString(views),
            height: !isMobile ? 24 : 20,
            width: !isMobile ? 24 : 20,
        },
        {
            svg: isBookmarked ? BookmarkedIcon : BookmarkIcon,
            count: convertEngagementMetricToString(bookmarks),
            height: 24,
            width: 24,
            onClick: () => handleItemBookmark({
                isBookmarked,
                itemId: item.itemId
            }, () => {
                // fake it til you make it
                if (refreshOverride) {
                    setIsBookmarked(!isBookmarked);
                } else {
                    refreshSpecificCollection(collectionId);
                    setIsBookmarked(!isBookmarked);
                }
            }, openSignUpModal)
        },
        {
            svg: item.isLiked ? LikedIcon : LikeIcon,
            count: convertEngagementMetricToString(likes),
            height: !isMobile ? 20 : 16,
            width: !isMobile ? 20 : 16,
            onClick: () => handleItemLike(item, () => refreshSpecificCollection(collectionId), openSignUpModal)
        },
        {
            svg: CommentIcon,
            count: convertEngagementMetricToString(comments),
            height: !isMobile ? 20 : 16,
            width: !isMobile ? 20 : 16,
            pb: 0.75,
            onClick: () => openCommentsModal()
        },
        {
            svg: ShareIcon,
            height: !isMobile ? 20 : 16,
            width: !isMobile ? 20 : 16,
            onClick: () => handleShare(() => setShowSuccess(true))
        }
    ];

    return (
        <Grid
            container
            sx={{
                height: "100%",
                alignContent: "center",
                p: 1,
                py: 5
            }}
        >
            {
                icons.map((icon, ind) => {
                    return (
                        <Box
                            key={`eng-ico-${icon.count}-${title}-${ind}`}
                            onClick={icon.onClick}
                            sx={{
                                display: "block",
                                width: "100%",
                                textAlign: "center",
                                my: 3
                            }}
                        >
                            <img
                                src={icon.svg}
                                width={icon.width}
                                height={icon.height}
                                style={{
                                    marginLeft: icon.ml,
                                    mx: "auto"
                                }}
                            />
                            <Typography
                                textAlign="left"
                                sx={{
                                    fontSize: 12,
                                    textAlign: "center"
                                }}
                            >
                                {icon.count}
                            </Typography>
                        </Box>
                    );
                })
            }

            <BasicAlert
                showAlert={showSuccess}
                setShowAlert={setShowSuccess}
                alertText='Item link copied to clipboard!'
                severity="info"
            />

            <AccountModalHandler
                signUp={isSignUpModalOpen}
                onCloseSignUp={closeSignUpModal}
                signIn={isSignInModalOpen}
                onCloseSignIn={closeSignInModal}
                isMobile={isMobile}
            />

            <CommentsModal
                open={isCommentsModalOpen}
                onClose={closeCommentsModal}
                isLoggedIn={isLoggedIn}
                itemId={item.itemId}
                comments={item.comments}
                collectionId={collectionId}
                openSignUpModal={openSignUpModal}
                openSignInModal={openSignInModal}
                isMobile={isMobile}
                refreshOverride={commentsRefreshOverride}
            />
        </Grid>
    );
}