import {
    Box,
    Button
} from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import colors from '../../constants/colors'
import MobileFilterMenu from './mobile-filter-menu'

const buttonStyle = {
    fontFamily: 'Open Sans',
    backgroundColor: colors.lightGray,
    color: colors.gray,
    borderRadius: '24px',
    px: 3,
    ml: 4,
    my: 5,
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: colors.darkGray,
        color: colors.lightGray
    }
}

const activeButtonStyle = {
    backgroundColor: colors.lightPrimary,
    color: colors.primary,
    '&:hover': {
        backgroundColor: colors.primary,
        color: colors.lightPrimary
    }
}

export default function FilterButtons(props) {
    const [activeFilter, setActiveFilter] = useState(props.defaultFilter)
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleActiveButtonStyle = (key) => {
        if (activeFilter === key) {
            return activeButtonStyle
        }
    }

    useEffect(() => {
        if (props.handleFilter) {
            props.handleFilter(activeFilter)
        } else {
            console.log('no filter is present')
        }
    }, [activeFilter])

    return (
        <Fragment>
            <Box
                sx={{
                    display: {
                        xs: 'none',
                        md: 'flex'
                    }
                }}
            >
                {
                    props.filters.map((filter, ind) => {
                        if (ind + 1 < props.filters.length) {
                            return (
                                <Button
                                    key={ind}
                                    variant='contained'
                                    onClick={() => { setActiveFilter(filter) }}
                                    sx={{
                                        ...buttonStyle,
                                        ...handleActiveButtonStyle(filter)
                                    }}
                                >
                                    {filter}
                                </Button>
                            )
                        } else {
                            return (
                                <Box
                                    key={ind}
                                    sx={{
                                        flexGrow: 1
                                    }}
                                >
                                    <Button
                                        variant='contained'
                                        onClick={() => { setActiveFilter(filter) }}
                                        sx={{
                                            ...buttonStyle,
                                            ...handleActiveButtonStyle(filter)
                                        }}
                                    >
                                        {filter}
                                    </Button>
                                </Box>
                            )
                        }
                    })
                }
            </Box>
            <Box
                sx={{
                    display: {
                        xs: 'flex',
                        md: 'none'
                    }
                }}
            >
                <Box
                    sx={{
                        flexGrow: 1
                    }}
                >
                    <Button
                        variant='contained'
                        onClick={handleOpen}
                        sx={{
                            ...buttonStyle
                        }}
                    >
                        Filters
                    </Button>
                </Box>
            </Box>
            <MobileFilterMenu
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                filters={props.filters}
                setActiveFilter={setActiveFilter}
            />
        </Fragment>

    )
}