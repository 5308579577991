import {
    useSelector
} from "react-redux";
import axios from "axios";

import {
    getUserData
} from "../../store/user/userSlice";
import apiRoutes from "../../constants/api-routes";

export default function useHandleItemBookmark() {
    const {
        isLoggedIn,
        token
    } = useSelector(getUserData);

    const handleBookmark = (item, onSuccess, onFailure) => {
        if (isLoggedIn) {
            if (!item.isBookmarked) {
                // this v1 legacy route is a post route making the axios call a bit strange
                axios.post(apiRoutes.addBookmark,
                    {},
                    {
                        params: {
                            itemId: item.itemId
                        },
                        headers: {
                            "authorization": `Bearer ${token}`
                        }
                    }
                ).then(onSuccess);
            } else {
                axios.delete(apiRoutes.deleteBookmark, {
                    params: {
                        itemId: item.itemId
                    },
                    headers: {
                        "authorization": `Bearer ${token}`
                    }
                }).then(onSuccess);
            }
        } else {
            onFailure();
        }
    };

    return handleBookmark;
}