import { useNavigate } from "react-router-dom";

export default function useNavigateToRules() {
    const navigate = useNavigate();

    const navigateToRules = () => {
        navigate("/rules");
    };

    return navigateToRules;
}