import { useNavigate } from "react-router-dom";

export default function useNavigateToWhatIsSlyke() {
    const navigate = useNavigate();

    const navigateToWhatIsSlyke = () => {
        navigate("/what-is-slyke");
    };

    return navigateToWhatIsSlyke;
}