import {
    Box,
    Link,
    Typography,
    useTheme
} from "@mui/material";

export default function MobileSignInLink(props) {
    const {
        close,
        openSignIn
    } = props;

    const theme = useTheme();

    return (
        <Box
            sx={{
                display: {
                    xs: "block",
                    sm: "none"
                }
            }}
        >
            <Typography
                variant='h6'
                sx={{
                    mt: 3,
                    mb: 0,
                    width: 300,
                    fontSize: 14,
                    fontWeight: "bold"
                }}
            >
                Already Have An Account?&nbsp;
                <Link
                    onClick={() => {
                        close();
                        openSignIn();
                    }}
                    sx={{
                        textDecoration: "underline",
                        fontSize: 16,
                        color: "black",
                        fontFamily: theme.typography.fontFamily,
                        "&:hover": {
                            cursor: "pointer"
                        }
                    }}
                >
                    Sign In
                </Link>
            </Typography>
        </Box>
    );
}