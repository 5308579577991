import {
    Box,
    Typography
} from "@mui/material";
import PrimaryButton from "../../../Common/PrimaryButton/PrimaryButton";
import useCreateCollectionModal from "../../../../hooks/state/useCreateCollectionModal";
import useAddItemModal from "../../../../hooks/state/useAddItemModal";

export default function NoResults(props) {
    const {
        isMobile
    } = props;

    const {
        isCreateCollectionModalOpen,
        openCreateCollectionModal,
        closeCreateCollectionModal,
        CreateCollectionModal
    } = useCreateCollectionModal();
    const {
        isAddItemModalOpen,
        openAddItemModal,
        closeAddItemModal,
        AddItemModal
    } = useAddItemModal();

    return (
        <Box
            direction="row"
            sx={{
                textAlign: "center"
            }}
        >
            <Typography
                variant="h5"
            >
                No Search Results!
            </Typography>
            <PrimaryButton
                onClick={openCreateCollectionModal}
                text="Create A Collection"
                sx={{
                    width: 210,
                    mt: 1
                }}
            />

            <AddItemModal
                open={isAddItemModalOpen}
                close={closeAddItemModal}
                isMobile={isMobile}
            />
            <CreateCollectionModal
                open={isCreateCollectionModalOpen}
                close={closeCreateCollectionModal}
                openAddItem={openAddItemModal}
                isMobile={isMobile}
            />
        </Box>
    );
}