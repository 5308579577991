import {
    Grid,
    Typography,
    Button,
    Stack,
    TextField,
    Link,
    Box,
    Autocomplete
} from '@mui/material'
import colors from '../../constants/colors'
import {
    getUserId,
    getToken
} from '../../store/user/userSlice'
import {
    getCollectionByIndex
} from '../../store/collection/collectionSlice'
import { useSelector } from 'react-redux'
import ItemSubmitted from './item-submitted'
import ItemApproved from './item-approved'
import TagHandler from '../tag-handler/tag-handler'

// placeholder image
import placeholder from '../../assets/images/placeholder/placeholder.png'
import {
    useState
} from 'react'
import apiRoutes, { cfImageWorker } from '../../constants/api-routes'
import axios from 'axios'
import LoadingModal from '../loading-modal/loading-modal'
import { useNavigate } from 'react-router-dom'
import Title from './form/title'
import Collection from './form/collection'
import Description from './form/description'

const maxStringSizes = {
    description: 90,
    site: 30,
    title: 50
}

const handleMaxStringLengths = (string, type) => {
    const maxStringSize = maxStringSizes[type]
    if (string && string.length > maxStringSize) {
        return string.substring(0, maxStringSize) + '...'
    } else {
        return string
    }
}

export default function AddItemLink(props) {
    /*
    
    Instead of a complete rewrite, let's do an intermediate step here:
    1. Extract title, collection, desciption, and tags as components.
    2. Add validation to those components.
    3. Refactoring efforts later on will only need to focus on the type specific fields (ie link vs text)

    */


    const {
        itemLink,
        ogData,
        isMobile
    } = props
    const navigate = useNavigate()
    const userId = useSelector(getUserId)
    const token = useSelector(getToken)

    const [loading, setLoading] = useState(false)

    // open graph data
    const [ogImagePreview] = useState(ogData.image ? ogData.image : placeholder)
    const [ogItemSite] = useState(handleMaxStringLengths(ogData.url, 'site'))
    const [ogItemTitle] = useState(handleMaxStringLengths(ogData.title, 'title'))
    const [ogItemDescription] = useState(handleMaxStringLengths(ogData.description, 'description'))

    // available collections
    const [collections, setCollections] = useState([])

    // user provided data
    const [tagChoices, setTagChoices] = useState([])
    const [itemTitle, setItemTitle] = useState('')
    const [itemCollection, setItemCollection] = useState(
        props.collectionIndex || props.collectionIndex === 0 ?
            useSelector(getCollectionByIndex(props.collectionIndex)) : null
    )
    const [itemDescription, setItemDescription] = useState('')

    const textStyle = {
        fontFamily: 'Open Sans'
    }

    const buttonStyle = {
        width: '70%',
        textTransform: 'none',
        fontFamily: 'Open Sans',
        py: 1.5,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: colors.darkGray,
            color: colors.lightGray,
            borderColor: colors.darkGray
        }
    }

    const uploadImageToCf = async (imageUrl) => {
        let res = await axios.get(cfImageWorker)
        let uploadUrl = res.data.result.uploadURL

        const formData = new FormData()
        formData.append('url', imageUrl)

        return axios.post(uploadUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    const submitItem = () => {
        setLoading(true)
        if (!Object.keys(ogData).length === 0 || !ogData.image) {
            axios.post(apiRoutes.addItem, {
                itemTitle,
                itemCollection,
                itemDescription,
                tagChoices,
                ogData,
                userId
            }, {
                headers: {
                    'authorization': token ? `Bearer ${token}` : null
                }
            }).then((res) => {
                setLoading(false)
                if (res.data.autoApproved) {
                    props.setActiveComponent(<ItemApproved isMobile={isMobile} />)
                } else {
                    props.setActiveComponent(<ItemSubmitted isMobile={isMobile} />)
                }
            }).catch(() => {
                setLoading(false)
                alert('Failed to Add Item!')
            })
        } else {
            uploadImageToCf(ogData.image).then((cfRes) => {
                let cfImageHash = cfRes.data.result.id
                axios.post(apiRoutes.addItem, {
                    itemTitle,
                    itemCollection,
                    itemDescription,
                    tagChoices,
                    ogData,
                    userId,
                    cfImageHash
                }, {
                    headers: {
                        'authorization': token ? `Bearer ${token}` : null
                    }
                }).then((res) => {
                    setLoading(false)
                    if (res.data.autoApproved) {
                        props.setActiveComponent(<ItemApproved isMobile={isMobile} />)
                    } else {
                        props.setActiveComponent(<ItemSubmitted isMobile={isMobile} />)
                    }
                }).catch(() => {
                    setLoading(false)
                    alert('Failed to Add Item!')
                })
            }).catch(() => {
                setLoading(false)
                alert('Cloudflare image upload failed!')
            })
        }
    }

    const searchCollectionsByName = (searchString) => {
        axios.get(apiRoutes.searchCollectionsByName, {
            params: {
                searchString,
                comapct: 1
            }
        }).then((res) => {
            setCollections(res.data.matchingCollections)
        })
    }

    return (
        <Grid
            container
            sx={{
                px: !isMobile ? 6 : 1,
                py: !isMobile ? null : 4,
                overflowY: !isMobile ? null : "scroll",
                height: !isMobile ? null : "100vh"
            }}
        >
            <Grid
                item
                xs={12}
                sx={{
                    textAlign: 'center',
                    mt: 2,
                    mb: !isMobile ? 8 : 2
                }}
            >
                <Typography
                    variant='h5'
                    sx={{
                        ...textStyle,
                        fontWeight: 'bold'
                    }}
                >
                    Add New Item
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}
                sm={6}
                sx={{
                    mb: !isMobile ? 6 : 2
                }}
            >
                <Stack
                    sx={{
                        alignItems: !isMobile ? null : "center"
                    }}
                >
                    {/* This will hold the link preview info. */}
                    <img
                        src={ogImagePreview}
                        width={250}
                        height={200}
                        style={{
                            borderRadius: '8px 8px 0px 0px',
                            border: `1px solid ${colors.gray}`,
                            borderBottom: 'none'
                        }}
                    />
                    <Box
                        sx={{
                            backgroundColor: colors.white,
                            width: 250,
                            mt: -1,
                            p: 0,
                            borderRadius: '0px 0px 8px 8px',
                            border: `${colors.gray} 1px solid`,
                            borderTop: 'none'
                        }}
                    >
                        <Box
                            sx={{
                                mt: 1
                            }}
                        >
                            <Link
                                href={itemLink}
                                sx={{
                                    ...textStyle,
                                    fontSize: 13,
                                    textDecoration: 'none',
                                    color: colors.secondary,
                                    p: 2
                                }}
                            >
                                {ogItemSite || ''}
                            </Link>
                        </Box>
                        <Typography
                            variant='body1'
                            sx={{
                                ...textStyle,
                                pl: 2,
                                mt: 1
                            }}
                        >
                            {ogItemTitle || ''}
                        </Typography>
                        <Typography
                            variant='body2'
                            sx={{
                                ...textStyle,
                                pl: 2,
                                pb: 0.5
                            }}
                        >
                            {ogItemDescription || ''}
                        </Typography>
                    </Box>
                </Stack>
            </Grid>
            <Grid
                item
                xs={12}
                sm={6}
                sx={{
                    mb: 6
                }}
            >
                <Stack>
                    <Title
                        itemTitle={itemTitle}
                        setItemTitle={setItemTitle}
                    />
                    <Collection
                        collections={collections}
                        itemCollection={itemCollection}
                        setItemCollection={setItemCollection}
                        searchCollectionsByName={searchCollectionsByName}
                    />
                    <Description
                        itemDescription={itemDescription}
                        setItemDescription={setItemDescription}
                    />
                    <TagHandler
                        tagChoices={tagChoices}
                        setTagChoices={setTagChoices}
                        placeholderText={'Tags (optional)'}
                    />
                </Stack>
            </Grid>

            <Grid
                item
                xs={12}
                sx={{
                    textAlign: 'center',
                    mb: !isMobile ? 2 : 8
                }}
            >
                <Button
                    onClick={submitItem}
                    variant='contained'
                    color='primary'
                    sx={{
                        ...buttonStyle,
                        color: colors.white,
                        p: 1,
                        width: !isMobile ? '40%' : "64%"
                    }}
                >
                    Post
                </Button>
            </Grid>

            <Grid
                item
                xs={6}
                sx={{
                    textAlign: 'right',
                    mb: 2
                }}
            >
                <Button
                    onClick={() => navigate('/rules')}
                    variant='contained'
                    sx={{
                        ...buttonStyle,
                        backgroundColor: colors.white,
                        border: `${colors.secondary} solid 1px`,
                        color: colors.secondary,
                        p: 0.5,
                        width: !isMobile ? '38%' : "60%",
                        mr: 0.5
                    }}
                >
                    Rules
                </Button>
            </Grid>
            <Grid
                item
                xs={6}
                sx={{
                    textAlign: 'left',
                    mb: 2
                }}
            >
                <Button
                    onClick={() => navigate('/faq')}
                    variant='contained'
                    sx={{
                        ...buttonStyle,
                        backgroundColor: colors.white,
                        border: `${colors.secondary} solid 1px`,
                        color: colors.secondary,
                        p: 0.5,
                        width: !isMobile ? '38%' : "60%",
                        ml: 0.5
                    }}
                >
                    Need Help?
                </Button>
            </Grid>
            <LoadingModal
                open={loading}
            />
        </Grid>
    )
}