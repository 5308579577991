import {
    Grid,
} from "@mui/material";
import {
    Fragment
} from "react";

import useGetHeadCurator from "../../../../hooks/data/useGetHeadCurator";

import CollectionTitle from "../CollectionTitle/CollectionTitle";
import CuratedBy from "../CuratedBy/CuratedBy";
import MobileCTAs from "./MobileCTAs/MobileCTAs";
import FollowButton from "../../../Common/FollowButton/FollowButton";
import CuratorComingSoon from "./CuratorComingSoon/CuratorComingSoon";

export default function CollectionOverviewHeaderMobile(props) {
    const {
        isMobile,
        isCurator,
        collection,
        handleFollow,
        openSignUpModal,
        refreshCollection
    } = props;

    const getHeadCurator = useGetHeadCurator();
    const headCuratorDisplayName = getHeadCurator(collection).displayName;
    const headCuratorId = getHeadCurator(collection).id;

    return (
        <Fragment>
            <Grid
                container
                sx={{
                    width: "100%",
                    mx: 0,
                    textAlign: "center"
                }}
            >
                <Grid item xs={0.5} />
                <Grid
                    item
                    xs
                >
                    <CollectionTitle
                        collectionTitle={collection.collectionTitle}
                        collectionDescription={collection.collectionDescription}
                        isMobile={isMobile}
                    />
                </Grid>
                <Grid item xs={0.5} />
            </Grid>
            <Grid
                container
            >
                <Grid
                    item
                    xs
                >
                    <CuratedBy
                        headCuratorDisplayName={headCuratorDisplayName}
                        headCuratorId={headCuratorId}
                        curators={collection.curators}
                        creatorId={collection.creatorId}
                        isMobile={isMobile}
                    />
                    {
                        isCurator ? <CuratorComingSoon /> : null
                    }
                </Grid>
            </Grid>
            <Grid
                container
                textAlign="center"
                sx={{
                    mt: 1
                }}
            >
                <Grid
                    item
                    xs
                    textAlign="right"
                >
                    <FollowButton
                        isFollowing={collection.isFollowing}
                        collectionId={collection.collectionId}
                        handleFollow={handleFollow}
                        openSignUpModal={openSignUpModal}
                        refreshCollection={refreshCollection}
                        isMobile
                    />
                </Grid>
                <Grid
                    item
                    xs
                    textAlign="left"
                >
                    <MobileCTAs
                        collection={collection}
                        handleFollow={handleFollow}
                        openSignUpModal={openSignUpModal}
                        refreshCollection={refreshCollection}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
}