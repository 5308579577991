import {
    Box,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function DisplayName(props) {
    const {
        isMobile,
        displayName,
        setDisplayName,
        isDisplayNameValid,
        setIsDisplayNameValid
    } = props;

    const validateDisplayName = (displayName) => {
        /* 
        
        To be valid, a display name must:
        - be 3 to 35 characters long
        - contain only letters, number, spaces, periods, and hyphens
        
        */

        if (displayName.length < 3 || displayName.length > 35) {
            setIsDisplayNameValid(false);
            return;
        }

        if (displayName.match(/[a-zA-Z0-9 .-]+/)[0] !== displayName) {
            setIsDisplayNameValid(false);
            return;
        }

        setIsDisplayNameValid(true);
    };

    return (
        <Box>
            <Typography
                variant='body1'
                sx={{
                    fontWeight: "bold",
                    fontFamily: "Open Sans"
                }}
            >
                Display Name
                <Tooltip
                    title={
                        <Typography>
                            Display names must be 3-35 characters and can only contain alphanumeric characters, spaces, periods and hyphens. This will be your primary identity on Slyke and your real name is recommended.
                        </Typography>
                    }
                >
                    <InfoOutlinedIcon
                        fontSize="10"
                        sx={{
                            ml: 0.5,
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }}
                    />
                </Tooltip>
            </Typography>
            <TextField
                variant='outlined'
                placeholder="John Doe"
                type="text"
                value={displayName}
                onChange={(e) => {
                    validateDisplayName(e.target.value);
                    setDisplayName(e.target.value);
                }}
                error={!isDisplayNameValid}
                sx={{
                    p: 0,
                    m: 0,
                    "& .MuiOutlinedInput-root": {
                        height: 35
                    },
                    mb: 2,
                    width: 300,
                    maxWidth: !isMobile ? null : "90%"
                }}
            />
        </Box>
    );
}