import useIsMobile from "../../hooks/media/useIsMobile";

import BrowseCollectionsMobileBody from "../../components/BrowseCollections/BrowseCollectionsMobileBody/BrowseCollectionsMobileBody";
import BrowseCollections from "../../legacy-pages/browse-collections/browse-collections";

export default function BrowseCollectionsPage() {
    const isMobile = useIsMobile();

    if (isMobile) {
        return (
            <BrowseCollectionsMobileBody />
        );
    } else {
        return (
            <BrowseCollections />
        );
    }
}