import { Box, Typography } from "@mui/material";

export default function ModalHeader(props) {
    const {
        isMobile
    } = props;

    return (
        <Box>
            <Typography
                variant='h6'
                sx={{
                    fontFamily: "Open Sans",
                    borderBottom: "1px solid black",
                    width: 182,
                    fontWeight: "bold",
                    mb: 3
                }}
            >
                Sign In
            </Typography>
            {
                !isMobile ?
                    <Typography
                        variant='body2'
                        sx={{
                            width: 250,
                            mb: 3
                        }}
                    >
                        Sign in for the full Slyke experience and to access your collections.
                    </Typography> : null
            }
        </Box>
    );
}