import { Box, Stack, Typography, useTheme } from "@mui/material";
import useNavigateToProfile from "../../../../../hooks/navigation/useNavigateToProfile";

export default function PostedBy(props) {
    const {
        userId,
        username,
        datetimePosted
    } = props;

    const theme = useTheme();
    const navigateToProfile = useNavigateToProfile();
    return (
        <Box
            sx={{
                mt: 2
            }}
        >
            <Stack
                direction="row"
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: 14,
                    }}
                >
                    Added By:
                </Typography>
                <Typography
                    onClick={() => navigateToProfile(userId)}
                    variant="body2"
                    sx={{
                        fontSize: 14,
                        ml: 0.5,
                        color: theme.palette.secondary.main,
                        "&:hover": {
                            cursor: "pointer",
                            color: theme.palette.primary.main
                        }
                    }}
                >
                    {username}
                </Typography>
            </Stack>
            <Typography
                variant="body2"
                sx={{
                    fontSize: 14,
                    color: theme.palette.grey["500"]
                }}
            >
                {datetimePosted}
            </Typography>
        </Box>
    );
}