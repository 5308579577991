import { Box, Stack, Typography, useTheme } from "@mui/material";
import useNavigateToProfile from "../../../../../hooks/navigation/useNavigateToProfile";

export default function PostedBy(props) {
    const {
        userId,
        username,
        datetimePosted
    } = props;

    const theme = useTheme();
    const navigateToProfile = useNavigateToProfile();
    return (
        <Box>
            <Stack
                direction="row"
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: 12,
                    }}
                >
                    Added By:
                </Typography>
                <Typography
                    onClick={() => navigateToProfile(userId)}
                    variant="body2"
                    sx={{
                        fontSize: 12,
                        ml: 0.5,
                        color: theme.palette.secondary.main
                    }}
                >
                    {username}
                </Typography>
            </Stack>
            <Typography
                variant="body2"
                sx={{
                    fontSize: 12,
                    color: theme.palette.grey["500"]
                }}
            >
                {datetimePosted}
            </Typography>
        </Box>
    );
}