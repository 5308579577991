import { Box } from "@mui/material";
import useSetupPassword from "../../../../../hooks/data/useSetupPassword";
import useLoadingModal from "../../../../../hooks/state/useLoadingModal";
import PasswordForm from "../../PasswordForm/PasswordForm";

export default function FormBody(props) {
    const {
        isMobile,
        password,
        setPassword,
        repeatPassword,
        setRepeatPassword,
        setChangePassword,
        setChangePasswordSuccess,
        changePasswordCode,
        changePasswordUsername,
    } = props;

    const {
        loading,
        setLoading,
        LoadingModal
    } = useLoadingModal();
    const submitNewPassword = useSetupPassword();

    const handleSubmit = async () => {
        setLoading(true);
        let success = await submitNewPassword({
            newPassword: password,
            username: changePasswordUsername,
            changePasswordCode
        });

        setLoading(false);

        if (success) {
            setChangePassword(false);
            setChangePasswordSuccess(true);
        } else {
            alert("Something unexpected happened!");
        }
    };

    return (
        <Box>
            <PasswordForm
                isMobile={isMobile}
                password={password}
                setPassword={setPassword}
                repeatPassword={repeatPassword}
                setRepeatPassword={setRepeatPassword}
                handleSubmit={handleSubmit}
            />

            <LoadingModal
                open={loading}
                onClose={() => setLoading(false)}
            />
        </Box>
    );
}