import { useState } from "react";
import {
    SwipeableDrawer,
    Stack
} from "@mui/material";

import Copyright from "./Copyright/Copyright";
import LoggedOutBody from "./LoggedOutBody/LoggedOutBody";
import SocialLinks from "./SocialLinks/SocialLinks";
import SiteLinks from "./SiteLinks/SiteLinks";
import LoggedInBody from "./LoggedInBody/LoggedInBody";
import BasicAlert from "../../Common/BasicAlert/BasicAlert";

export default function Sidebar(props) {
    const {
        isSidebarOpen,
        setIsSidebarOpen,
        isLoggedIn,
        userId,
        isMobile
    } = props;

    const [showAlert, setShowAlert] = useState();

    return (
        <SwipeableDrawer
            anchor='left'
            open={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
            onOpen={() => { }}
            elevation={0}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "transparent"
                },
                "& .MuiPaper-root": {
                    width: {
                        xs: "100%",
                        md: 325
                    },
                    overflow: "hidden"
                }
            }}
        >
            <Stack
                sx={{
                    mt: !isMobile ? 12 : 8,
                    pb: 2,
                    px: 2,
                    overflowY: isMobile ? "scroll" : null
                }}
            >
                {
                    isLoggedIn ?
                        <LoggedInBody
                            userId={userId}
                            setShowAlert={setShowAlert}
                            setIsSidebarOpen={setIsSidebarOpen}
                        /> :
                        <LoggedOutBody
                            isMobile={isMobile}
                        />
                }
                <SocialLinks />
                <SiteLinks />
                <Copyright />

                <BasicAlert
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                    alertText='Coming Soon!'
                />
            </Stack>
        </SwipeableDrawer>
    );
}