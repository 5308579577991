import { Box } from "@mui/material";

import Comment from "./Comment/Comment";

export default function CommentsBody(props) {
    /* 
    
    This component is the main scrollable area where comments show up.

    */

    const {
        comments,
        isMobile,
        onInView
    } = props;

    return (
        <Box
            sx={{
                px: 4,
                overflowY: "scroll",
                display: "flex",
                flexDirection: "column",
                flexGrow: 1
            }}
        >
            {
                comments ? comments.map((comment, index) => {
                    return (
                        <Comment
                            key={`comment_${index}`}
                            commentText={comment.text}
                            commentedAt={comment.leftAt}
                            commentUsername={comment["user.username"]}
                            commentUserId={comment["user.id"]}
                            isMobile={isMobile}
                            onInView={index % 4 === 0 && index ? onInView : null}
                        />
                    );
                }) : null
            }
        </Box>
    );
}