import { useState } from "react";
import {
    Box
} from "@mui/material";

import BaseModal from "../Common/BaseModal/BaseModal";
import CloseButton from "../Common/BaseModal/CloseButton/CloseButton";
import Header from "./Header/Header";
import FormBody from "./FormBody/FormBody";
import Success from "./Success/Success";

export default function ContactUs(props) {
    const {
        showContactUs,
        setShowContactUs,
        isMobile
    } = props;

    const close = () => setShowContactUs(false);

    const [isSent, setIsSent] = useState(false);

    return (
        <BaseModal
            open={showContactUs}
            onClose={close}
            sx={{
                width: !isMobile ? 600 : 300,
                overflowY: !isMobile ? null : "scroll"
            }}
        >
            <Box
                sx={{
                    p: 4,
                    pl: !isMobile ? 6 : 2
                }}
            >
                {
                    isSent ?
                        <Success
                            isMobile={isMobile}
                        /> :
                        <Box>
                            <Header
                                isMobile={isMobile}
                            />
                            <FormBody
                                isMobile={isMobile}
                                setIsSent={setIsSent}
                            />
                        </Box>
                }
            </Box>

            <CloseButton
                isMobile={isMobile}
                onClose={close}
            />
        </BaseModal>
    );
}