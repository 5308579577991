import {
    Button, useTheme
} from "@mui/material";

export default function SecondaryButton(props) {
    const {
        text,
        onClick,
        sx,
        borderRadius,
        adornment
    } = props;

    const theme = useTheme();

    return (
        <Button
            color="secondary"
            variant="contained"
            onClick={onClick}
            sx={{
                color: theme.palette.common.white,
                textTransform: "none",
                fontSize: 16,
                py: 0.5,
                px: 1,
                my: "auto",
                borderRadius: borderRadius,
                "&:hover": {
                    backgroundColor: theme.palette.grey["900"]
                },
                ...sx,
            }}
        >
            {text}
            {adornment}
        </Button>
    );
}