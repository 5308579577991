import { Box, Typography, Grid, useTheme, Button, Link, IconButton } from '@mui/material'
import { styled } from '@mui/system'
import Footer from '../../legacy-components/footer/footer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import CircleIcon from '@mui/icons-material/Circle'
import SearchIcon from '@mui/icons-material/Search'
import CreateIcon from '@mui/icons-material/Create'
import AddIcon from '@mui/icons-material/Add'
import FAQAccordion from '../../legacy-components/faq-accordion/faq-accordion'
import faqs from '../faq/faqs'
import AccountModalHandler from '../../legacy-components/account-modals/account-modal'
import CreateCollectionModal from '../../legacy-components/create-collection-modal/create-collection-modal'
import AddItemModal from '../../legacy-components/add-item-modal/add-item-modal'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import {
    useState,
    useEffect
} from 'react'
import {
    getRandomCollections,
    clearCollections,
    getCollectionsWithImages,
    getPopularCollections
} from '../../store/collection/collectionSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getUserId } from '../../store/user/userSlice'
import { useNavigate } from 'react-router-dom'
import { cfImageRoot } from '../../constants/api-routes'
import Navbar from '../../components/Navbar/Navbar'

const StyledTypography = styled(Typography)({
    fontFamily: 'Open Sans'
})

export default function WhatIsSlyke() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const collections = useSelector(getCollectionsWithImages)
    const userId = useSelector(getUserId)
    const [showSignUp, setShowSignUp] = useState(false)
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [itemModalOpen, setItemModalOpen] = useState(false)
    const [collectionPreviewIndex, setCollectionPreviewIndex] = useState(0)

    useEffect(() => {
        if (collections.length < 10) {
            dispatch(getPopularCollections({ userId, segment: 1, overwrite: true }))
        }
    }, [])

    useEffect(() => {
        // makes sure we pick an item with an image
        if (collectionPreviewIndex < collections.length && collections.length && !collections[collectionPreviewIndex].items[0].image) {
            setCollectionPreviewIndex(collectionPreviewIndex + 1)
        }
    }, [collections, collectionPreviewIndex])

    return (
        <Box
            sx={{
                minWidth: 1280
            }}
        >
            <Navbar
                search
            />

            <StyledTypography
                mt={16}
                align='center'
                variant='h3'
                sx={{
                    fontWeight: 'bolder',
                    width: 800,
                    mx: 'auto'
                }}
            >
                When we work together, there&apos;s no limit to what we can do
            </StyledTypography>

            <Grid
                container
                my={12}
                mb={8}
                sx={{
                    backgroundColor: theme.palette.secondary.main,
                    py: 12
                }}
            >
                <Grid
                    item
                    xs={6}
                    align='center'
                >
                    <Box
                        width={400}
                        sx={{
                            borderRadius: 2,
                            position: 'relative',
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}>
                        <img
                            height={340}
                            width={400}
                            style={{
                                borderRadius: 10
                            }}
                            src={`${cfImageRoot}/${collections.length ? collections[collectionPreviewIndex].items[0].image : null}/itempreviewthumb`}
                        />
                        {
                            collectionPreviewIndex !== 0 ?
                                <IconButton
                                    onClick={() => setCollectionPreviewIndex(collectionPreviewIndex - 1)}
                                    sx={{
                                        position: 'absolute',
                                        left: 10,
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        backgroundColor: theme.palette.primary.main,
                                        borderRadius: 2,
                                        color: theme.palette.common.white,
                                        zIndex: 5000,
                                        '&:hover': {
                                            cursor: 'pointer',
                                            backgroundColor: theme.palette.grey['700']
                                        }
                                    }}
                                >
                                    <ArrowBackIosNewIcon />
                                </IconButton> : null
                        }

                        {
                            collectionPreviewIndex + 1 < collections.length ?
                                <IconButton
                                    onClick={() => setCollectionPreviewIndex(collectionPreviewIndex + 1)}
                                    sx={{
                                        position: 'absolute',
                                        right: 10,
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        backgroundColor: theme.palette.primary.main,
                                        borderRadius: 2,
                                        color: theme.palette.common.white,
                                        zIndex: 5000,
                                        '&:hover': {
                                            cursor: 'pointer',
                                            backgroundColor: theme.palette.grey['700']
                                        }
                                    }}
                                >
                                    <ArrowForwardIosIcon />
                                </IconButton> : null
                        }

                        <Box
                            onClick={() => {
                                window.location.href = `/collection?collectionId=${collections[collectionPreviewIndex].collectionId}`
                            }}
                            width={400}
                            height={340}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0
                            }}
                        >
                            <StyledTypography
                                width={240}
                                sx={{
                                    backgroundColor: theme.palette.common.white,
                                    opacity: 0.8,
                                    borderRadius: 2,
                                    p: 2,
                                    position: 'absolute',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    border: `1px solid ${theme.palette.grey['500']}`,
                                    ml: 8
                                }}
                            >
                                {collections.length ? collections[collectionPreviewIndex].collectionTitle : null}
                            </StyledTypography>
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    <StyledTypography
                        align='left'
                        variant='h3'
                        mb={8}
                        color={theme.palette.common.white}
                    >
                        WHAT IS SLYKE?
                    </StyledTypography>
                    <StyledTypography
                        width={500}
                        align='left'
                        color={theme.palette.common.white}
                        mb={4}
                        ml={0}
                    >
                        Slyke is a wiki-style content curation platform. Slyke is built around super-specific Collections of content all about the same specific subject. Collections are made up of unique links, photos, and videos referred to as Items
                    </StyledTypography>
                    <StyledTypography
                        width={500}
                        align='left'
                        color={theme.palette.common.white}
                        mb={2}
                        ml={0}
                    >
                        Each Collection is unique. Just like there&apos;s only one Wikipedia article for each thing, there is only one Slyke Collection that we all contribute to for each thing
                    </StyledTypography>
                </Grid>
            </Grid>

            <Grid
                container
            >
                <Grid
                    item
                    xs={6}
                    align='right'
                >
                    <StyledTypography
                        variant='h4'
                        width={580}
                        height='100%'
                        align='left'
                        my={4}
                    >
                        What will you curate with the Slyke community?
                    </StyledTypography>
                </Grid>
                <Grid
                    item
                    xs={6}
                    align='center'
                >
                    <List
                        sx={{
                            width: 600
                        }}
                    >
                        <ListItem disablePadding>
                            <ListItemIcon>
                                <CircleIcon
                                    fontSize='small'
                                    sx={{
                                        color: theme.palette.primary.main
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <StyledTypography>
                                        Every time ________ has happened
                                    </StyledTypography>
                                }
                            />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemIcon>
                                <CircleIcon
                                    fontSize='small'
                                    sx={{
                                        color: theme.palette.primary.main
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <StyledTypography>
                                        Stories about ________
                                    </StyledTypography>
                                }
                            />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemIcon>
                                <CircleIcon
                                    fontSize='small'
                                    sx={{
                                        color: theme.palette.primary.main
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <StyledTypography>
                                        Every example of ________
                                    </StyledTypography>
                                }
                            />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemIcon>
                                <CircleIcon
                                    fontSize='small'
                                    sx={{
                                        color: theme.palette.primary.main
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <StyledTypography>
                                        Anything and everything!
                                    </StyledTypography>
                                }
                            />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            <Box
                height={2}
                width='80%'
                sx={{
                    borderTop: '2px solid #c4cff5',
                    mx: 'auto',
                    my: 4
                }}
            />

            <StyledTypography
                align='center'
                variant='h4'
                sx={{
                    fontWeight: 'bolder',
                    mx: 'auto',
                    my: 10
                }}
            >
                How to use Slyke
            </StyledTypography>
            <Grid
                container
            >
                <Grid item xs={1.75} />
                <Grid
                    align='left'
                    item
                    xs
                >
                    <SearchIcon
                        fontSize='large'
                        sx={{
                            border: `3px solid ${theme.palette.secondary.light}`,
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.primary.main,
                            p: 1,
                            borderRadius: 8
                        }}
                    />
                    <StyledTypography
                        align='left'
                        variant='h5'
                        sx={{
                            fontWeight: 'bolder',
                            color: theme.palette.grey['600'],
                            my: 2
                        }}
                    >
                        Explore
                    </StyledTypography>
                    <StyledTypography
                        align='left'
                        sx={{
                            width: 350,
                            height: 125
                        }}
                    >
                        Discover and Search Collections. Treat it like Google, except you&apos;ll get super-specific, curated Collections!
                    </StyledTypography>
                    <Button
                        onClick={async () => {
                            await dispatch(clearCollections())
                            await dispatch(getRandomCollections({ userId }))
                            navigate('/collection?collectionId=0')
                        }}
                        variant='contained'
                        sx={{
                            backgroundColor: theme.palette.background.paper,
                            border: `${theme.palette.primary.main} solid 1px`,
                            color: theme.palette.primary.main,
                            fontFamily: theme.typography.openSans,
                            fontWeight: 'bold',
                            textTransform: 'none',
                            letterSpacing: 0.5,
                            borderRadius: 8,
                            px: 4,
                            '&:hover': {
                                cursor: 'pointer',
                                backgroundColor: theme.palette.grey['300'],
                            }
                        }}
                    >
                        Discover new collections
                    </Button>
                </Grid>
                <Grid
                    align='left'
                    item
                    xs
                >
                    <CreateIcon
                        fontSize='large'
                        sx={{
                            border: `3px solid ${theme.palette.secondary.light}`,
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.primary.main,
                            p: 1,
                            borderRadius: 8
                        }}
                    />
                    <StyledTypography
                        align='left'
                        variant='h5'
                        sx={{
                            fontWeight: 'bolder',
                            color: theme.palette.grey['600'],
                            my: 2
                        }}
                    >
                        Create
                    </StyledTypography>
                    <StyledTypography
                        align='left'
                        sx={{
                            width: 350,
                            height: 125
                        }}
                    >
                        If a Collection doesn&apos;t exist that you&apos;d like to see, Create it!
                    </StyledTypography>
                    <Button
                        onClick={() => setCreateModalOpen(true)}
                        variant='contained'
                        sx={{
                            backgroundColor: theme.palette.background.paper,
                            border: `${theme.palette.primary.main} solid 1px`,
                            color: theme.palette.primary.main,
                            fontFamily: theme.typography.openSans,
                            fontWeight: 'bold',
                            textTransform: 'none',
                            letterSpacing: 0.5,
                            borderRadius: 8,
                            px: 4,
                            '&:hover': {
                                cursor: 'pointer',
                                backgroundColor: theme.palette.grey['300'],
                            }
                        }}
                    >
                        Create a new collection
                    </Button>
                </Grid>
                <Grid
                    align='left'
                    item
                    xs
                >
                    <AddIcon
                        fontSize='large'
                        sx={{
                            border: `3px solid ${theme.palette.secondary.light}`,
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.primary.main,
                            p: 1,
                            borderRadius: 8
                        }}
                    />
                    <StyledTypography
                        align='left'
                        variant='h5'
                        sx={{
                            fontWeight: 'bolder',
                            color: theme.palette.grey['600'],
                            my: 2
                        }}
                    >
                        Contribute
                    </StyledTypography>
                    <StyledTypography
                        align='left'
                        sx={{
                            width: 350,
                            height: 125
                        }}
                    >
                        Add Items to Collections (Items are: links, media you upload, or even text if you have a story to tell). Your Item will be added once the curator approves it!
                    </StyledTypography>
                    <Button
                        onClick={() => setItemModalOpen(true)}
                        variant='contained'
                        sx={{
                            backgroundColor: theme.palette.background.paper,
                            border: `${theme.palette.primary.main} solid 1px`,
                            color: theme.palette.primary.main,
                            fontFamily: theme.typography.openSans,
                            fontWeight: 'bold',
                            textTransform: 'none',
                            letterSpacing: 0.5,
                            borderRadius: 8,
                            px: 4,
                            my: 'auto',
                            '&:hover': {
                                cursor: 'pointer',
                                backgroundColor: theme.palette.grey['300'],
                            },
                            flexGrow: 1
                        }}
                    >
                        Add an item to a collection
                    </Button>
                </Grid>
            </Grid>

            <Box
                mt={8}
                p={8}
                backgroundColor={theme.palette.secondary.light}
            >
                <StyledTypography
                    align='center'
                    variant='h4'
                    fontWeight='bolder'
                >
                    Why did we create Slyke?
                </StyledTypography>

                <Box
                    width={1000}
                    mx='auto'
                    mt={3}
                >
                    <StyledTypography
                        mb={3}
                    >
                        Stories, information, moments, content — they come in clusters. One buzzer-beating basket to win a championship in the NBA begets another decades later. One consumer brands launches a rewards program, and then others follow in the days and years to come.
                    </StyledTypography>
                    <StyledTypography
                        mb={3}
                    >
                        What if you want to see and keep track of all the championship buzzer beaters and brands with rewards programs from now and moving forward?
                    </StyledTypography>
                    <StyledTypography
                        mb={3}
                    >
                        That&apos;s where Slyke comes in. We can work together to organize and curate all the content, information, and stories that matter from the past, present, and future. Any category or subject or content cluster you want.
                    </StyledTypography>
                    <StyledTypography
                        mb={3}
                    >
                        <Link
                            onClick={() => { setShowSignUp(true) }}
                            sx={{
                                '&:hover': {
                                    cursor: 'pointer'
                                }
                            }}
                        >
                            Join Slyke
                        </Link>
                        &nbsp;and help us build a forever home of content, collections, curation, and community.
                    </StyledTypography>
                </Box>
            </Box>

            <Box
                width={700}
                mx='auto'
            >
                <StyledTypography
                    variant='h4'
                    align='center'
                    fontWeight='bolder'
                    mt={6}
                >
                    FAQ
                </StyledTypography>
                <FAQAccordion
                    faqs={faqs.slice(2, 4)}
                />
            </Box>
            <Link
                width='100%'
                href={'/faq'}
                sx={{
                    fontFamily: 'Open Sans',
                    textDecoration: 'none',
                    fontSize: 20,
                    display: 'block',
                    textAlign: 'center',
                    mt: 5
                }}
            >
                See more FAQs
            </Link>

            <Footer />
            <AccountModalHandler
                signUp={showSignUp}
                onCloseSignUp={() => {
                    setShowSignUp(false)
                }}
            />
            <CreateCollectionModal
                open={createModalOpen}
                close={() => { setCreateModalOpen(false) }}
                openAddItem={() => { setItemModalOpen(true) }}
            />
            <AddItemModal
                open={itemModalOpen}
                close={() => { setItemModalOpen(false) }}
            />
        </Box >
    )
}