import { useState } from "react";
import {
    Box,
    Typography
} from "@mui/material";

import BaseModal from "../../BaseModal/BaseModal";
import CloseButton from "../CloseButton/CloseButton";
import FormBody from "./FormBody/FormBody";

export default function ChangePassword(props) {
    /* 
    
    This modal allows users to change from the temporary password
    to a password of their choosing.

    */

    const {
        isMobile,
        changePassword,
        changePasswordCode,
        changePasswordUsername,
        setChangePassword,
        setChangePasswordSuccess
    } = props;

    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");

    return (
        <BaseModal
            open={changePassword}
            onClose={() => setChangePassword(false)}
            sx={{
                width: !isMobile ? 400 : 300,
                overflowY: !isMobile ? null : "scroll"
            }}
        >
            <Box
                sx={{
                    p: 4,
                    pl: 6
                }}
            >
                <Typography
                    variant='h6'
                    sx={{
                        fontFamily: "Open Sans",
                        borderBottom: "1px solid black",
                        width: 182,
                        fontWeight: "bold",
                        mb: !isMobile ? 3 : 1
                    }}
                >
                    Complete Sign Up
                </Typography>
                {
                    !isMobile ?
                        <Typography
                            variant='body2'
                            sx={{
                                width: 300,
                                mb: !isMobile ? 3 : 1
                            }}
                        >
                            Create a password for your account to complete sign up.
                        </Typography> : null
                }

                <FormBody
                    isMobile={isMobile}
                    password={password}
                    setPassword={setPassword}
                    repeatPassword={repeatPassword}
                    setRepeatPassword={setRepeatPassword}
                    setChangePassword={setChangePassword}
                    setChangePasswordSuccess={setChangePasswordSuccess}
                    changePasswordCode={changePasswordCode}
                    changePasswordUsername={changePasswordUsername}
                />
            </Box>

            <CloseButton
                onClose={() => setChangePassword(false)}
            />
        </BaseModal>
    );
}