import {
    Typography,
    useTheme
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import useCollectionDescriptionModal from "../../../../hooks/state/useCollectionDesciptionModal";
import { Fragment } from "react";

export default function CollectionTitle(props) {
    const {
        collectionTitle,
        collectionDescription,
        isMobile
    } = props;

    const {
        isCollectionDescriptionModalOpen,
        openCollectionDescriptionModal,
        closeCollectionDescriptionModal,
        CollectionDescriptionModal
    } = useCollectionDescriptionModal();

    const theme = useTheme();

    return (
        <Fragment>
            <Typography
                variant={!isMobile ? "h5" : "h6"}
                sx={{
                    fontWeight: "bold",
                    mb: 2,
                    px: isMobile ? 1 : null
                }}
            >
                {collectionTitle}
                <InfoOutlinedIcon
                    onClick={openCollectionDescriptionModal}
                    sx={{
                        fontSize: 16,
                        mb: 1,
                        ml: 1,
                        "&:hover": {
                            color: theme.palette.primary.main,
                            cursor: "pointer"
                        },
                        "&:active": {
                            color: theme.palette.secondary.main
                        }
                    }} />
            </Typography>

            <CollectionDescriptionModal
                open={isCollectionDescriptionModalOpen}
                close={closeCollectionDescriptionModal}
                description={collectionDescription}
            />
        </Fragment>
    );
}