import { useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import axios from 'axios'

import apiRoutes from '../../constants/api-routes'

export default function CollectionCreditsModal(props) {
    const {
        isMobile
    } = props;

    const theme = useTheme()

    const [creator, setCreator] = useState('')

    const modalBoxStyle = {
        position: 'absolute',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: !isMobile ? 400 : 300,
        bgcolor: 'background.paper',
        boxShadow: 24,
        py: 8,
        px: 1,
        m: 0,
        borderRadius: 5,
        '&:active': {
            outline: 'none'
        },
        '&:focus': {
            outline: 'none'
        }
    }

    useEffect(() => {
        if (props.creatorId) {
            axios.get(apiRoutes.getUserById, {
                params: {
                    userId: props.creatorId
                }
            }).then((res) => {
                setCreator(res.data.user)
            })
        }
    }, [props.creatorId])

    return (
        <Modal
            open={props.open || false}
            onClose={() => {
                props.close()
            }}
        >
            <Box
                sx={modalBoxStyle}
            >
                {/* Created By */}
                <Typography
                    sx={{
                        textAlign: 'center',
                        fontFamily: theme.typography.openSans,
                        fontWeight: 'bold',
                        mb: 2
                    }}
                >
                    Created By
                </Typography>
                <Typography
                    onClick={() => window.location.href = '/profile?' + 'userId=' + creator.userId}
                    sx={{
                        textAlign: 'center',
                        fontFamily: theme.typography.openSans,
                        color: theme.palette.primary.main,
                        mb: 2,
                        '&:hover': {
                            color: theme.palette.grey[600],
                            cursor: 'pointer'
                        }
                    }}
                >
                    {creator.displayName}
                </Typography>

                {/* Curators */}
                <Typography
                    sx={{
                        textAlign: 'center',
                        fontFamily: theme.typography.openSans,
                        fontWeight: 'bold',
                        mb: 2
                    }}
                >
                    Curators
                </Typography>
                {
                    props.curators.map((curator) => {
                        return (
                            <Typography
                                onClick={() => window.location.href = '/profile?' + 'userId=' + curator.id}
                                key={curator.id}
                                sx={{
                                    textAlign: 'center',
                                    fontFamily: theme.typography.openSans,
                                    color: theme.palette.secondary.main,
                                    mb: 1,
                                    '&:hover': {
                                        color: theme.palette.grey[600],
                                        cursor: 'pointer'
                                    }
                                }}
                            >
                                {curator.displayName}
                            </Typography>
                        )
                    })
                }

                {/* Close Icon */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: {
                            xs: 9,
                            sm: 5
                        },
                        left: {
                            xs: 9,
                            sm: 5
                        },
                        margin: 'auto'
                    }}
                >
                    <IconButton
                        onClick={() => {
                            props.close()
                        }}
                        sx={{
                            m: 0,
                            p: 0
                        }}
                    >
                        <CloseIcon
                            sx={{
                                color: theme.palette.grey['700']
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>
        </Modal>
    )
}