import {
    LinearProgress,
    linearProgressClasses
} from "@mui/material";
import {
    styled
} from "@mui/styles";

export default function ProgressBar(props) {
    const {
        currentItemIndex,
        totalItems
    } = props;

    const VerticalLinearProgress = styled(LinearProgress)(({ theme }) => ({
        position: "fixed",
        width: 10,
        height: "80%",
        left: 50,
        top: "52%",
        borderRadius: 12,
        [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: theme.palette.primary.light
        },
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.primary.main
        },
        transform: "translate(-50%, -50%)"
    }));

    return (
        <VerticalLinearProgress
            variant='determinate'
            value={(currentItemIndex + 1) / (totalItems) * 100}
            sx={{
                [`& .${linearProgressClasses.bar}`]: {
                    transform: `translateY(${(currentItemIndex + 1) / (totalItems) * 100}%)!important`
                }
            }}
        />
    );
}