import { useState } from "react";
import { Box, Typography } from "@mui/material";
import axios from "axios";

import BaseModal from "../../../BaseModal/BaseModal";
import CloseButton from "../../CloseButton/CloseButton";
import Username from "../../Username/Username";
import PrimaryButton from "../../../PrimaryButton/PrimaryButton";

import apiRoutes from "../../../../../constants/api-routes";
import useLoadingModal from "../../../../../hooks/state/useLoadingModal";

export default function ForgotPasswordInit(props) {
    /* 
    
    This modal lets the user request a password reset.

    */

    const {
        isMobile,
        forgotPasswordInit,
        close,
        setForgotPasswordInfo
    } = props;

    const [username, setUsername] = useState("");
    const [isUsernameValid, setIsUsernameValid] = useState(true);

    const {
        loading,
        setLoading,
        LoadingModal
    } = useLoadingModal();

    const handleForgotPassword = () => {
        if (username) {
            setLoading(true);
            axios.post(apiRoutes.resetPassword, {
                username: username
            }).then(() => {
                close();
                setForgotPasswordInfo(true);
                setLoading(false);
            }).catch(() => {
                setLoading(false);
                alert("Something unexpected happened");
            });
        }
    };

    const handleSubmit = () => {
        if (isUsernameValid) {
            handleForgotPassword();
        }
    };

    return (
        <BaseModal
            open={forgotPasswordInit}
            onClose={close}
            sx={{
                width: !isMobile ? 400 : 300,
            }}
        >
            <Box
                sx={{
                    p: 4,
                    pl: !isMobile ? 6 : 2
                }}
            >
                <Typography
                    variant='h6'
                    sx={{
                        fontFamily: "Open Sans",
                        borderBottom: "1px solid black",
                        width: 250,
                        fontWeight: "bold",
                        mb: !isMobile ? 3 : 1
                    }}
                >
                    Password Reset
                </Typography>
                <Typography
                    sx={{
                        width: 300,
                        mb: !isMobile ? 3 : 1
                    }}
                >
                    Please enter your username.
                </Typography>
                <Username
                    isMobile={isMobile}
                    username={username}
                    setUsername={setUsername}
                    isUsernameValid={isUsernameValid}
                    setIsUsernameValid={setIsUsernameValid}
                />
                <PrimaryButton
                    onClick={handleSubmit}
                    text="Submit"
                    sx={{
                        width: !isMobile ? 300 : 200,
                    }}
                />
            </Box>

            <LoadingModal
                open={loading}
                onClose={() => setLoading(false)}
            />

            <CloseButton
                isMobile={isMobile}
                onClose={close}
            />
        </BaseModal>
    );
}