import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useTheme
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

export default function ReasonsToJoin(props) {
    const theme = useTheme();

    const reasons = [
        "Follow and Create Collections",
        "Curate Collections",
        "Comment on and Favorite Items",
        "Chat",
        "More!"
    ];

    return (
        <List
            dense
            sx={{
                ml: -1
            }}
        >
            {
                reasons.map((text) => {
                    return (
                        <ListItem key={text} sx={{ py: 0 }}>
                            <ListItemIcon sx={{ minWidth: 0, mr: 1, pb: 2 / 10 }}>
                                <CircleIcon
                                    sx={{
                                        fontSize: 6,
                                        color: theme.palette.primary.main
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                align='left'
                                sx={{
                                    color: theme.palette.primary.main,
                                    "& .MuiTypography-root": {
                                        fontSize: 14,
                                        fontFamily: "Open Sans",
                                        fontWeight: props.fontWeight ? props.fontWeight : "bold"
                                    },
                                    m: 0,
                                    mb: 2 / 10
                                }}
                            >
                                {text}
                            </ListItemText>
                        </ListItem>
                    );
                })
            }
        </List>
    );
}