import {
    Modal,
    IconButton,
    Typography,
    Box,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import colors from '../../../constants/colors'

export default function SignUpSuccessModal(props) {
    const modalBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            xs: '95%',
            sm: 660
        },
        bgcolor: 'background.paper',
        boxShadow: 24,
        py: 5,
        px: 0,
        m: 0,
        borderRadius: 5,
        '&:active': {
            outline: 'none'
        },
        '&:focus': {
            outline: 'none'
        }
    }

    return (
        <Modal
            open={props.open}
            onClose={props.close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={modalBoxStyle}
            >
                <Typography
                    sx={{
                        ml: 4
                    }}
                >
                    Sign up succeeded! A temporary password will be emailed to you!
                </Typography>
                <Box
                    sx={{
                        position: 'absolute',
                        top: {
                            xs: 9,
                            sm: 5
                        },
                        left: {
                            xs: 9,
                            sm: 5
                        },
                        margin: 'auto'
                    }}
                >
                    <IconButton
                        onClick={props.close}
                        sx={{
                            m: 0,
                            p: 0
                        }}
                    >
                        <CloseIcon
                            sx={{
                                color: colors.gray
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>
        </Modal>
    )
}