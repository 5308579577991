import {
    Typography
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import DarkButton from "../../../../Common/DarkButton/DarkButton";
import { Fragment } from "react";

export default function SearchButton(props) {
    const {
        onClick
    } = props;

    return (
        <DarkButton
            onClick={onClick}
            text={
                <Fragment>
                    <SearchIcon />
                    <Typography
                        sx={{
                            p: 0.25,
                            px: 1
                        }}
                    >
                        Search
                    </Typography>
                </Fragment>
            }
            sx={{
                mt: -2.25
            }}
        />
    );
}