import { Box, Typography } from '@mui/material'
import './terms.css'
import Footer from '../../legacy-components/footer/footer'
import Navbar from '../../components/Navbar/Navbar'

export default function Terms() {
    return (
        <Box
            sx={{
                minWidth: 1280
            }}
        >
            <Navbar
                search
            />
            <Box
                sx={{
                    mt: 16,
                    width: 800,
                    mx: 'auto'
                }}
            >
                <Typography
                    variant='h4'
                    align='center'
                >
                    Terms and Conditions
                </Typography>

                <body className="c12 doc-content">
                    <p className="c0"><span className="c2">Hello Slyke users! We&rsquo;re glad to have you here. These Terms of Service
                        (&ldquo;Terms&rdquo;) govern your access to and use of the Slyke website, apps, widgets, APIs, emails,and
                        other online product and services (&lsquo;Products&rdquo;). By accessing or using Slyke, you agree to be
                        bound by these Terms, our Privacy Policy, and our Community Rules. If you&rsquo;re accessing our services on
                        behalf of a legal entity (like your employer), you agree that you have the authority to bind that entity to
                        these terms, and &ldquo;you&rdquo; and &ldquo;your&rdquo; will refer to that entity.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c9">What We Do</span></p>
                    <p className="c0 c1"><span className="c9"></span></p>
                    <p className="c0"><span className="c2">Slyke provides a platform to help individuals and groups crowdsource and curate
                        content from any source. It&rsquo;s amazing what we can accomplish when we work together. Here&rsquo;s how
                        Slyke works:</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c6">Distinctly Unique Collections</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Slyke is organized with Collections around super-specific categories. Collections are
                        made up of Items: links, videos, photos, or even text entries. You could create Collections curating things
                        as diverse as Elon Musk memes, epic MLB bat flips, scholarship opportunities for minority women, and
                        everything in between! </span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">The #1 rule &mdash; and part of what makes Slyke so valuable &mdash; is that
                        Collections are distinct and unique, there are no duplicates. No duplicate Collections and, within
                        Collections, no duplicate Items.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c6">Curation</span></p>
                    <p className="c0 c1"><span className="c6"></span></p>
                    <p className="c0"><span className="c2">Each Collection has somebody in charge of managing the Collection and any Items
                        submitted for inclusion. This is the Curator. The user that initially creates the Collection is the Curator
                        unless they forgo that opportunity. Then Slyke serves as the Curator until another user volunteers for the
                        role. Curators ensure all Items fit the category of the Collection are unique. They have the ability to
                        reject Items submitted. The user that submitted the rejected Items can appeal to Slyke.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c6">Interactive Communications</span></p>
                    <p className="c0 c1"><span className="c6"></span></p>
                    <p className="c0"><span className="c2">Slyke users are able to engage on the platform by commenting on Items and chatting in
                        the Talk rooms associated with each Collection. Users utilizing the comment and Talk functions must abide by
                        the policies stipulated in these Terms.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c6">Act Responsibly</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Slyke wants to empower its users to connect and curate credible collections of
                        content. (alliteration is fun, isn&rsquo;t it?). Slyke should be a community in which users feel safe and
                        excited. We have proactively built systems and oversight to detect misuse of our Products, harmful conduct
                        towards others, and situations where we may be able to help support or protect our community. If we learn of
                        content or conduct like this, we will take appropriate action - for example, offering help, removing
                        content, removing or restricting access to certain features, disabling an account, or contacting law
                        enforcement.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c4">Using Slyke</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span>You may use Slyke only if you can legally form a binding contract with Slyke, and only in
                        compliance with these Terms and all applicable laws. When you create your Slyke account, you must provide us
                        with accurate and complete information. You can&rsquo;t use Slyke if it would be prohibited by U.S.
                        sanctions, if you have been barred from using the Products under all applicable laws; or if you have been
                        permanently suspended or removed from Slyke&rsquo;s Products. Any use or access by anyone under the age of
                        13 is not allowed. If you&rsquo;re based in the EEA, you may only use Slyke if you are over the age at which
                        you can provide consent to data processing under the laws of your country.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c4">Content</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c2">You grant Slyke and our users a non-exclusive, royalty-free, transferable,
                        sublicensable, worldwide license to use, store, display, reproduce, save, modify, create derivative works,
                        perform, and distribute your User Content on Slyke solely for the purposes of operating, developing,
                        providing, and using Slyke. Nothing in these Terms restricts other legal rights Slyke may have to User
                        Content, for example under other licenses. We reserve the right to remove or modify User Content, or change
                        the way it&rsquo;s used in Slyke, for any reason. This includes User Content that we believe violates these
                        Terms, our Community Guidelines, or any other policies.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">You may not use our Products to do or share anything:</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <ul className="c13 lst-kix_ar9zn78s870h-0 start">
                        <li className="c0 c3 li-bullet-0"><span className="c2">That violates these Terms, our Community Standards, and other
                            terms and policies that apply to your use of our Products.</span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">That is unlawful, misleading, discriminatory or
                            fraudulent.</span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">That infringes or violates someone else&#39;s rights, including
                            their intellectual property rights.</span></li>
                    </ul>
                    <ul className="c13 lst-kix_ar9zn78s870h-1 start">
                        <li className="c0 c15 li-bullet-0"><span className="c2">By submitting Your Content to the Services, you represent and
                            warrant that you have all rights, power, and authority necessary to grant the rights to Your Content
                            contained within these Terms. Because you alone are responsible for Your Content, you may expose
                            yourself to liability if you post or share Content without all necessary rights.<br /></span></li>
                    </ul>
                    <p className="c0"><span className="c2">You may not upload viruses or malicious code or do anything that could disable,
                        overburden, or impair the proper working or appearance of our Products.</span></p>
                    <p className="c0"><span className="c2">You may not access or collect data from our Products using automated means (without
                        our prior permission) or attempt to access data you do not have permission to access.<br /></span></p>
                    <p className="c0"><span className="c2">We can remove or restrict access to content that is in violation of these
                        provisions.</span></p>
                    <p className="c0"><span className="c2">If we remove content that you have shared in violation of our Community Standards,
                        we&rsquo;ll let you know and explain any options you have to request another review, unless you seriously or
                        repeatedly violate these Terms or if doing so may expose us or others to legal liability; harm our community
                        of users; compromise or interfere with the integrity or operation of any of our services, systems or
                        Products; where we are restricted due to technical limitations; or where we are prohibited from doing so for
                        legal reasons.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">We also can remove or restrict access to your content, services or information if we
                        determine that doing so is reasonably necessary to avoid or mitigate adverse legal or regulatory impacts to
                        Slyke.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c4">Slyke&rsquo;s content<br /></span></p>
                    <p className="c0"><span className="c2">Our services include some content that belongs to us. You may use this content as
                        permitted by these terms, but we retain all intellectual property rights in our content.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c4">Other content<br /></span></p>
                    <p className="c0"><span className="c2">Our Products might also provide you with access to other people&rsquo;s content. You
                        may not use this content without that person&rsquo;s consent, or as allowed by law. Other people&rsquo;s
                        content is theirs and doesn&rsquo;t necessarily reflect Slyke&rsquo;s own views. Slyke doesn&rsquo;t endorse
                        or verify the accuracy or reliability of content shared by Slyke users. We don&rsquo;t endorse or assume any
                        responsibility for any such third party sites, information, materials, products, or services posted on
                        Slyke. If you access any third party website, service, or content from Slyke, you do so at your own risk and
                        you agree that Slyke has no liability arising from your use of or access to any third party website,
                        service, or content. We have the right, but not the obligation, to review such reports and block or remove
                        content at our discretion.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c4">Your Slyke account</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c2">To access the services on an ongoing basis, you will need to create a Slyke account.
                        You can provide a username and password, and a way of contacting you (such as an email address and/or phone
                        number). To access certain features or communities, you may need to verify your account or add other
                        information to your account. Our Privacy Policy discusses what information we collect and how we use this
                        information in more detail.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">You are responsible for the security of your account, and you agree to notify us
                        immediately if you believe your account has been compromised. If you use a password, it must be strong, and
                        we (strongly) recommend that you use that password only for your Slyke account. If your account is
                        compromised, we may not be able to restore it to you.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">You are also responsible for maintaining the accuracy of the contact information
                        associated with your account. If you get locked out of your account, we&rsquo;ll need to contact you at the
                        email or phone number associated with your account, and we may not be able to restore your Slyke account to
                        you if you no longer have access to that email account or phone number. We may also assume that any
                        communications we&rsquo;ve received from your account or the associated contact information have been made
                        by you.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span>You agree not to license, sell, or transfer your account without our prior written
                        approval.</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c4">User Permissions</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c2">By using Slyke, you give us permission to use your name and profile picture and
                        information about actions you have taken or content you have viewed on Slyke next to or in connection with
                        ads, offers, and other sponsored content that we display across our Products, without any compensation to
                        you. For example, we may, based on content you have viewed or created, show you custom ads that you are
                        interested in created by a brand that has paid us to display its ads on Slyke.</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c2">If you use our software, you give us permission to download and install updates to
                        the software where available.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c4">Slyke&rsquo;s User Permissions</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span>We need certain permissions from you to provide our services:</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">Permission to use content you create and share</span><span className="c2">: Some content
                        that you share or upload, such as photos or videos, may be protected by intellectual property laws.</span>
                    </p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">You own the intellectual property rights (things like copyright or trademarks) in any
                        such content that you create and share on Slyke. Nothing in these Terms takes away the rights you have to
                        your own content. You are free to share your content with anyone else, wherever you want.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">However, to provide our services we need you to give us some legal permissions (known
                        as a &lsquo;license&rsquo;) to use this content. This is solely for the purposes of providing and improving
                        our Slyke.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Specifically, when you share, post, or upload content that is covered by intellectual
                        property rights on or in connection with our Products, you grant us a non-exclusive, transferable,
                        sub-licensable, royalty-free, and worldwide license to host, use, distribute, modify, run, copy, publicly
                        perform or display, translate, and create derivative works of your content (consistent with your privacy and
                        application settings). This means, for example, that if you share a photo on Slyke, you give us permission
                        to store, copy, and share it with others such as service providers that support our service.This license
                        will end when your content is deleted from our systems.</span></p>
                    <p className="c0"><span className="c2">You can delete content individually or all at once by deleting your account.</span>
                    </p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">When you delete content, it&rsquo;s no longer visible to other users, however it may
                        continue to exist elsewhere on our systems where: </span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <ul className="c13 lst-kix_v8dosusv062r-0 start">
                        <li className="c0 c3 li-bullet-0"><span className="c2">Immediate deletion is not possible due to technical limitations
                            (in which case, your content will be deleted within a maximum of 180 days from when you delete it);
                        </span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">Your content has been used by others in accordance with this
                            license and they have not deleted it (in which case this license will continue to apply until that
                            content is deleted); </span></li>
                    </ul>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">or where immediate deletion would restrict our ability to:</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <ul className="c13 lst-kix_lpte7vjgnhrp-0 start">
                        <li className="c0 c3 li-bullet-0"><span className="c2">Investigate or identify illegal activity or violations of our
                            terms and policies (for example, to identify or investigate misuse of our Products or systems);</span>
                        </li>
                    </ul>
                    <p className="c0"><span className="c2">comply with a legal obligation, such as the preservation of evidence; or</span></p>
                    <p className="c0"><span className="c2">comply with a request of a judicial or administrative authority, law enforcement or a
                        government agency;</span></p>
                    <p className="c0"><span className="c2">in which case, the content will be retained for no longer than is necessary for the
                        purposes for which it has been retained (the exact duration will vary on a case-by-case basis).</span></p>
                    <p className="c0"><span className="c2">In each of the above cases, this license will continue until the content has been
                        fully deleted.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c4">Prohibited Uses of Slyke</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c2">When using or accessing Slyke, you must comply with these Terms and all applicable
                        laws, rules, and regulations. You may not do any of the following:</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <ul className="c13 lst-kix_4fh677x82l86-0 start">
                        <li className="c0 c3 li-bullet-0"><span className="c2">Use the Services in any manner that could interfere with,
                            disable, disrupt, overburden, or otherwise impair the Service;</span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">Gain access to (or attempt to gain access to) another
                            user&rsquo;s Account or any non-public portions of the Services, including the computer systems or
                            networks connected to or used together with the Services;</span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">Upload, transmit, or distribute to or through the Services any
                            viruses, worms, malicious code, or other software intended to interfere with the Services, including its
                            security-related features;</span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">Use the Services to violate applicable law or infringe any
                            person&rsquo;s or entity&#39;s intellectual property rights or any other proprietary rights;</span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">Access, search, or collect data from the Services by any means
                            (automated or otherwise) except as permitted in these Terms or in a separate agreement with Slyke (we
                            conditionally grant permission to crawl the Products, but scraping the Services without Slyke&rsquo;s
                            prior consent is prohibited); or</span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">Use the Services in any manner that we reasonably believe to be
                            an abuse of or fraud on Slyke or any payment system.</span></li>
                    </ul>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c4">Curators</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c2">Curating a Collection is an unofficial, voluntary position that may be available to
                        users of Slyke. Curators are the primary moderators of activity within their Collections, but remain subject
                        to Slyke administration overruling their decisions and activities. We are not responsible for actions taken
                        by the curators. We reserve the right to revoke or limit a user&rsquo;s ability to curate at any time and
                        for any reason or no reason, including for a breach of these Terms.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">If you choose to curate a Collection:</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <ul className="c13 lst-kix_8odphduysos4-0 start">
                        <li className="c0 c3 li-bullet-0"><span className="c2">You agree to follow the Curator Guidelines for Healthy
                            Communities;</span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">You agree that when you receive reports related to a Collection
                            you curate, you will take appropriate action, which may include removing content that violates policy
                            and/or promptly escalating to Slyke for review;</span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">You are not, and may not represent that you are, authorized to
                            act on behalf of Slyke;</span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">You may not enter into any agreement with a third party on behalf
                            of Slyke, or any Collections that you moderate, without our written approval;</span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">You may not perform moderation actions in return for any form of
                            compensation, consideration, gift, or favor from third parties;</span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">If you have access to non-public information as a result of
                            moderating a Collection, you will use such information only in connection with your performance as a
                            moderator; and</span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">You may create and enforce parameters for the Collections you
                            moderate, provided that such parameters do not conflict with these Terms, the Content Policy, or the
                            Curator Guidelines for Healthy Communities. Slyke reserves the right to overturn any parameters
                            established</span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">Slyke reserves the right, but has no obligation, to overturn any
                            action or decision of a curator if Slyke, in its sole discretion, believes that such action or decision
                            is not in the interest of Slyke or the Slyke community.</span></li>
                    </ul>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c4">Copyright</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c2">Slyke respects the intellectual property of others and requires that users of our
                        Products do the same. We have a policy that includes the removal of any infringing material from the
                        Services and for the termination, in appropriate circumstances, of users of our Products who are repeat
                        infringers. If you believe that anything on our Services infringes a copyright or a trademark that you own
                        or control, you may notify Slyke Designated Agent by filling out the Contact form on the site:</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Also, please note that if you knowingly misrepresent that any activity or material on
                        our Products is infringing, you may be liable to Slyke for certain costs and damages.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">If we remove Your Content in response to a copyright or trademark notice, we will
                        notify you. If you believe Your Content was wrongly removed due to a mistake or misidentification in a
                        copyright notice, you can send a counter notification by filling out the Contact Us form on the site. Please
                        see 17 U.S.C. &sect; 512(g)(3) for the requirements of a proper counter notification.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c4">Disclaimers</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c2">Our Service and all content on Slyke is provided on an &quot;as is&quot; basis
                        without warranty of any kind, whether express or implied.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Slyke specifically disclaims any and all warranties and conditions of
                        merchantability, fitness for a particular purpose, and non-infringement, and any warranties arising out of
                        course of dealing or usage of trade.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Slyke takes no responsibility and assumes no liability for any User Content that you
                        or any other person or third party posts or sends using our Service. You understand and agree that you may
                        be exposed to User Content that&rsquo;s inaccurate, objectionable, inappropriate for children, or otherwise
                        unsuited to your purpose.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">If you&#39;re a consumer in the EEA, we don&rsquo;t exclude or limit any liability
                        for gross negligence, intent, or death or personal injury caused by our negligence or willful misconduct.
                    </span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c4">Termination</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c5">Your right to terminate</span><span className="c2">: You&rsquo;re free to stop using
                        Slyke&rsquo;s Products at any time and for any reason. You can delete your Slyke account through the
                        Settings. You can also disable your account.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c5">Our right to terminate</span><span>:</span><span className="c2">&nbsp;Subject to
                        applicable law, we reserve the right to suspend or terminate your account and/or your access to some or all
                        of our services with or without notice, at our discretion, including if:</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <ul className="c13 lst-kix_lzhw1mi8i63x-0 start">
                        <li className="c0 c3 li-bullet-0"><span className="c2">You breach these terms, our policies, or additional terms that
                            apply to specific products.</span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">We&rsquo;re required to do so to comply with a legal requirement
                            or court order.</span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">We reasonably believe termination is necessary to prevent harm to
                            you, us, other users, or third parties.</span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">Your account has been inactive for more than three
                            years.<br /></span></li>
                    </ul>
                    <p className="c0"><span className="c2">However, we will give you advance notice if reasonable to do so or required by
                        applicable law. </span></p>
                    <p className="c0"><span className="c4">Intellectual Property</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c2">The Products are owned and operated by Slyke. The visual interfaces, graphics,
                        design, compilation, information, data, computer code, products, services, trademarks, and all other
                        elements (&ldquo;Materials&rdquo;) provided by Slyke are protected by intellectual property and other laws.
                        All Materials included in the Products are the property of Slyke or its third-party licensors. You
                        acknowledge and agree that you shall not acquire any ownership rights whatsoever by serving as a curator or
                        providing content to Slyke. Except as expressly authorized by Slyke, you may not make use of the Materials.
                        Slyke reserves all rights to the Materials not granted expressly in these Terms.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c4">Indemnity</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c2">Except to the extent prohibited by law, you agree to defend, indemnify, and hold
                        Slyke, its affiliates, and their respective, directors, officers, employees, affiliates, agents,
                        contractors, third-party service providers, and licensors (the &ldquo;Slyke Entities&rdquo;) harmless from
                        and against any claim or demand made by any third party, and any related liability, damage, loss, and
                        expense (including costs and attorneys&rsquo; fees) due to, arising out of, or in connection with: (a) your
                        use of the Products, (b) your violation of these Terms, (c) your violation of applicable laws or
                        regulations, or (d) Your Content. We reserve the right to control the defense of any matter for which you
                        are required to indemnify us, and you agree to cooperate with our defense of these claims.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c4">Limitations on liability</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c2">We work hard to provide the best Products we can and to specify clear guidelines for
                        everyone who uses them. Our Products, however, are provided &quot;as is,&quot; and we make no guarantees
                        that they always will be safe, secure, or error-free, or that they will function without disruptions,
                        delays, or imperfections. To the extent permitted by law, we also DISCLAIM ALL WARRANTIES, WHETHER EXPRESS
                        OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                        AND NON-INFRINGEMENT. We do not control or direct what people and others do or say, and we are not
                        responsible for their actions or conduct (whether online or offline) or any content they share (including
                        offensive, inappropriate, obscene, unlawful, and other objectionable content).</span></p>
                    <p className="c0"><span className="c2">We cannot predict when issues might arise with our Products. Accordingly, our
                        liability shall be limited to the fullest extent permitted by applicable law, and under no circumstance will
                        we be liable to you for any lost profits, revenues, information, or data, or consequential, special,
                        indirect, exemplary, punitive, or incidental damages arising out of or related to these Terms or the Slyke
                        Products, even if we have been advised of the possibility of such damages. Our aggregate liability arising
                        out of or relating to these Terms or the Slyke Products will not exceed the greater of $100 or the amount
                        you have paid us in the past twelve months.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c4">Arbitration</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c2">For any dispute you have with Slyke, you agree to first contact us and try to resolve
                        the dispute with us informally. If we need to contact you, we will do so at the email address on your Slyke
                        account. If Slyke hasn&rsquo;t been able to resolve the dispute with you informally, we each agree to
                        resolve any claim, dispute, or controversy (excluding claims for injunctive or other equitable relief)
                        arising out of or in connection with or relating to these Terms through binding arbitration or (for
                        qualifying claims) in small claims court.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Arbitration is a more informal way to resolve our disagreements than a lawsuit in
                        court. For instance, arbitration uses a neutral arbitrator instead of a judge or jury, involves more limited
                        discovery, and is subject to very limited review by courts. Although the process is more informal,
                        arbitrators can award the same damages and relief that a court can award. You agree that, by agreeing to
                        these Terms of Service, the U.S. Federal Arbitration Act governs the interpretation and enforcement of this
                        provision, and that you and Slyke are each waiving the right to a trial by jury or to participate in a class
                        action. The arbitrator has exclusive authority to resolve any dispute relating to the interpretation,
                        applicability, or enforceability of this binding arbitration agreement. This arbitration provision shall
                        survive termination of this Agreement and the termination of your Slyke account.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Any arbitration will be administered by the American Arbitration Association
                        (&quot;AAA&quot;) under the Consumer Arbitration Rules then in effect for the AAA, except as provided
                        herein. You can find their forms at www.adr.org. Unless you and Slyke agree otherwise, the arbitration will
                        be conducted in the county (or parish) where you reside. Each party will be responsible for paying any AAA
                        filing, administrative and arbitrator fees in accordance with AAA Rules, except that Slyke will pay for your
                        reasonable filing, administrative, and arbitrator fees if your claim for damages does not exceed $75,000 and
                        is non-frivolous (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)). If your
                        claim is for $10,000 or less, we agree that you may choose whether the arbitration will be conducted solely
                        on the basis of documents submitted to the arbitrator, through a telephonic hearing, or by an in-person
                        hearing as established by the AAA Rules. If your claim exceeds $10,000, the right to a hearing will be
                        determined by the AAA Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator
                        shall issue a reasoned written decision explaining the essential findings and conclusions on which the award
                        is based, and any judgment on the award rendered by the arbitrator may be entered in any court of competent
                        jurisdiction. Nothing in this Section shall prevent either party from seeking injunctive or other equitable
                        relief from the courts, including for matters related to data security, intellectual property or
                        unauthorized access to the Service. ALL CLAIMS MUST BE BROUGHT IN THE PARTIES&#39; INDIVIDUAL CAPACITY, AND
                        NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING, AND, UNLESS WE AGREE
                        OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON&#39;S CLAIMS. YOU AGREE THAT, BY ENTERING
                        INTO THESE TERMS, YOU AND Slyke ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS
                        ACTION.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">NOTHING IN THESE TERMS OF SERVICE SHALL AFFECT ANY NON-WAIVABLE STATUTORY RIGHTS THAT
                        APPLY TO YOU. To the extent any claim, dispute or controversy regarding Slyke or our Service isn&rsquo;t
                        arbitrable under applicable laws or otherwise: you and Slyke both agree that any claim or dispute regarding
                        Slyke will be resolved exclusively in accordance with Section 12 of these Terms.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">If you&#39;re a consumer in the EEA, Section 11 doesn&#39;t apply to you. </span></p>
                    <p className="c0"><span className="c4">&nbsp;</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c4">One more thing</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <ul className="c13 lst-kix_pzsbx3gdqpq0-0 start">
                        <li className="c0 c3 li-bullet-0"><span className="c2">If any portion of these Terms is found to be unenforceable, the
                            remaining portion will remain in full force and effect. If we fail to enforce any of these Terms, it
                            will not be considered a waiver. Any amendment to or waiver of these Terms must be made in writing and
                            signed by us.<br /></span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">You will not transfer any of your rights or obligations under
                            these Terms to anyone else without our consent.<br /></span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">You may designate a person (called a legacy contact) to manage
                            your account if it is memorialized. Only your legacy contact or a person who you have identified in a
                            valid will or similar document expressing clear consent to disclose your content upon death or
                            incapacity will be able to seek disclosure from your account after it is memorialized.<br /></span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">These Terms do not confer any third-party beneficiary rights. All
                            of our rights and obligations under these Terms are freely assignable by us in connection with a merger,
                            acquisition, or sale of assets, or by operation of law or otherwise.<br /></span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">You should know that we may need to change the username for your
                            account in certain circumstances (for example, if someone else claims the username and it appears
                            unrelated to the name you use in everyday life).<br /></span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">We always appreciate your feedback and other suggestions about
                            our products and services. But you should know that we may use them without any restriction or
                            obligation to compensate you, and we are under no obligation to keep them confidential.<br /></span></li>
                        <li className="c0 c3 li-bullet-0"><span className="c2">We reserve all rights not expressly granted to you.</span></li>
                    </ul>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <h1 className="c14" id="h.ujhevo30189m"><span className="c11">Privacy Policy</span></h1>
                    <p className="c0"><span className="c2">When you sign up for or use Slyke you voluntarily share certain information including
                        your name, email address, phone number, photos, Items, comments, and any other information you give us. You
                        can also choose to share your precise location using your device settings or through photos. We will still
                        use your IP address, which is used to approximate your location, even if you don&#39;t choose to share your
                        precise location. You will also have the option to share other information about yourself such as your
                        gender, age and preferred language.</span></p>
                    <p className="c0"><span className="c2">&nbsp; &nbsp; </span></p>
                    <p className="c0"><span className="c2">If you connect your Facebook, Google(opens in a new window) or other third-party
                        accounts to Slyke, we use information from those accounts (such as your friends or contacts) to improve your
                        Slyke experience. This is dependent on the privacy policies or settings for those accounts.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c5 c10">Information You Provide to Us</span></p>
                    <p className="c0"><span className="c2">We collect information you provide to us directly when you use the Services. This
                        includes:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">Account information&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        className="c2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                    <p className="c0"><span className="c2">If you create a Slyke account, we may require you to provide a username and password.
                        Your username is public, and it doesn&rsquo;t have to be related to your real name. You may also provide
                        other account information, like an email address, bio, or profile picture. We also store your user account
                        preferences and settings.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">Content you submit&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        className="c2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                    <p className="c0"><span className="c2">We collect the content you submit to the Services. This includes your posts and
                        comments including Items, Collections, Favorites, Views, your messages with other users (e.g., private
                        messages), and your reports and other communications with Curators and with us. Your content may include
                        text, links, images, gifs, and videos.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">Actions you take&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        className="c2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                    <p className="c0"><span className="c2">We collect information about the actions you take when using the Services. This
                        includes your interactions with content, like voting, saving, hiding, and reporting. It also includes your
                        interactions with other users, such as following, friending, and blocking. We collect your interactions with
                        communities, like your subscriptions or moderator
                        status.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7 c8">Other
                        information&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </p>
                    <p className="c0"><span className="c2">You may choose to provide other information directly to us. For example, we may
                        collect information when you fill out a form, participate in Slyke-sponsored activities or promotions, apply
                        for a job, request customer support, or otherwise communicate with us.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c10 c5">Technical Information</span></p>
                    <p className="c0 c1"><span className="c10 c5"></span></p>
                    <p className="c0"><span className="c7">Log data.</span><span className="c2">&nbsp;When you use Slyke, our servers record
                        information (&ldquo;log data&rdquo;), including information that your browser automatically sends whenever
                        you visit a website, or that your mobile app automatically sends when you&rsquo;re using it. This log data
                        includes your Internet Protocol address (which we use to infer your approximate location), the address of
                        and activity on websites you visit that incorporate Slyke features (like the &ldquo;Save&rdquo;
                        button&mdash;more details below), searches, browser type and settings, the date and time of your request,
                        how you used Slyke, cookie data and device data. You can learn more about the log data we collect
                        here.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">Cookie data. </span><span className="c2">We also use &ldquo;cookies&rdquo; (small text
                        files sent by your computer each time you visit our website, unique to your Slyke account or your browser)
                        or similar technologies to get log data. When we use cookies or other similar technologies, we use session
                        cookies (that last until you close your browser) or persistent cookies (that last until you or your browser
                        delete them). For example, we use cookies to store your language preferences or other settings so you
                        don&lsquo;t have to set them up every time you visit Slyke. Some of the cookies we use are associated with
                        your Slyke account (including information about you, such as the email address you gave us) and other
                        cookies are not..</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">Device information</span><span className="c2">. In addition to log data, we collect
                        information about the device you&rsquo;re using Slyke on, including the type of device, operating system,
                        settings, unique device identifiers and crash data that helps us understand when something breaks.</span>
                    </p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">Clickstream data and inferences.</span><span className="c2">&nbsp;When you&rsquo;re on
                        Slyke, we use your activity&mdash;such as which Items you click on, Collection you contribute to, and any
                        text that you add in a comment or description&mdash;along with information you provided when you first
                        signed up and information from our partners and advertisers to make inferences about you and your
                        preferences. For example, if you create a board about gaming, we may infer you are a gaming enthusiast. We
                        may also infer information about your education or professional experience based on your activity when you
                        link your account to accounts you have with third parties like Facebook or Google.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c4">How we use your information (details)</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c2">We use your information for the following purposes:</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">To meet our commitments to the Slyke community</span><span className="c2">. We work hard
                        to try to make Slyke a safe, positive, and inclusive place. To do so, we use your information to monitor for
                        and take action against users and content that violate our Terms of Service, Community Guidelines, and other
                        policies. This includes responding to user reports, detecting fraud and malware, and proactively scanning
                        attachments and other content for illegal or harmful content. We also use certain information, which may
                        include content reported to us and public posts, to develop and improve systems and models that can be
                        automated to more swiftly detect, categorize, and take action against prohibited content or conduct.</span>
                    </p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">To personalize the product.</span><span className="c2">&nbsp;We use your information to
                        provide, personalize and improve our services. This information powers our discovery surfaces (so that you
                        see relevant Collections or content first), and it helps us surface Slyke messages and promotions from us
                        and our partners that may be of interest to you.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">To contact you.</span><span className="c2">&nbsp;We use your information to contact you
                        in connection with your account, such as to verify or secure it with two-factor authentication. We may also
                        use your information to contact you about important product or policy changes or just to let you know about
                        new products, content, or features we think you&rsquo;ll like. You may opt-out of receiving marketing
                        communications. Where local law requires, we will obtain your consent before sending such
                        communications.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">To provide customer service.</span><span className="c2">&nbsp;We use your information to
                        respond to your questions about our products and services, and to investigate bugs or other issues.</span>
                    </p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">To protect our services</span><span className="c2">. We use information to keep our
                        services secure, to prevent misuse, and to enforce our Terms of Service and other policies against users who
                        violate them.</span></p>
                    <p className="c0"><span className="c2">To report on our company&rsquo;s performance. We use your information to track the
                        fundamental metrics of our business, to perform financial reporting, to respond to regulatory obligations,
                        and to debug billing issues.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">To improve our services. </span><span className="c2">We use your information to help us
                        understand how users interact with our services, what features or products users may want, or to otherwise
                        understand and improve our services. This includes information about how you use our services and how
                        servers are structured. We may also use public posts to better understand, for example, what topics public
                        servers cover and what content is most interesting within those servers.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">To advertise our services on other platforms.</span><span className="c2">&nbsp;We are
                        proud of the product we&#39;ve built and spend money advertising it on other platforms in order to bring
                        more users to Slyke. As part of that, we use certain information to assist in the delivery of our
                        advertising, to measure the effectiveness of advertisements for our own products, and to improve such
                        advertisements in the future.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c8 c7">To comply with our legal obligations</span></p>
                    <p className="c0"><span className="c2">We retain and use your information in connection with potential legal claims when
                        necessary and for compliance, regulatory, and auditing purposes. For example, we retain information where we
                        are required by law or if we are compelled to do so by a court order or regulatory body. Also, when you
                        exercise any of your applicable legal rights to access, amend, or delete your personal information, we may
                        request identification and verification documents from you for the purpose of confirming your
                        identity.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">With your consent, we may also collect and use personal information with your
                        consent. You can revoke your consent at any time (mostly through our services directly), though note that
                        you might not be able to use any service or feature that requires collection or use of that personal
                        information.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">To protect someone&rsquo;s vital interests, we may collect or share personal data if
                        we think someone&rsquo;s life is in danger&mdash;for example, to help resolve an urgent medical
                        situation.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">In addition, we may use your information to:</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">Suggest Collections for you to follow based on your activity and
                        engagement.</span><span className="c2">&nbsp;For example, if you follow collections related to sports, we may
                            suggest other sports collections that you might like.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">Help your friends and contacts find you on Slyke. </span><span className="c2">For
                        example, if you sign up using a Facebook account, we can help your Facebook friends find you on Slyke when
                        they first sign up for Slyke. Or people can search for your account on Slyke using your email.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">Work with law enforcement and keep Slyke safe</span><span className="c2">. We may get
                        requests for account information from law enforcement authorities like the police or courts. To find out
                        more about how we respond to law enforcement requests, please see our Law Enforcement Guidelines.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">Review your messages on Slyke </span><span className="c2">to detect activity that poses a
                        risk to the safety of you, our community and/or members of the public.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Conduct analytics and research on who is using Slyke and what they are doing. For
                        example, by logging how often people use two different versions of a feature on Slyke, we can understand
                        which version is better.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Improve Slyke and offer new features.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Advertise Slyke products and services to you on other sites. You can choose for us
                        not to share your information with other sites to personalize those ads as described in the &ldquo;Choices
                        you have about your information&rdquo; section below.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Send you updates (such as when certain activity, like new items or comments, happens
                        on Slyke) and news by email or push notification, depending on your settings. For example, we send weekly
                        updates that include Items you may like. You can decide to stop getting these notifications by updating your
                        settings.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c8 c7">We have a legitimate interest in delivering ads that are relevant, interesting and
                        personal to you in order to generate revenue. To further these interests we use the information we collect
                        to:</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c5">Decide which ads to show you</span><span className="c2">. For example, if you show an
                        interest in food on Slyke, we may show you ads for meal kits and delivery services. We customize the ad
                        content we show you by identifying your interests based on your onsite and offsite activities, as well as by
                        using information we receive from ad partners or other third parties. Where we use cookies to identify your
                        offsite interests, we&rsquo;ll get your consent where we need to. Where ad partners or other third parties
                        share information with us about you, we rely on the consent they already obtained.</span></p>
                    <p className="c0"><span className="c2">Tell our ad partners how their Slyke ads are doing, and how to make them better. Some
                        of this information is aggregated. For example, we would report to an advertiser that a certain percentage
                        of people who viewed a Promoted Item went on to visit that advertiser&#39;s site. In other instances, this
                        information isn&rsquo;t aggregated. For example, we would let an advertiser know that a particular Promoted
                        Item has been favorited by certain people.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">We have a legitimate interest in using information we collect to customize your Slyke
                        experience based on your offsite behavior. For example, if you visit websites that sell electric guitars, we
                        may suggest music-related Collections to you. When we identify your interests based on your offsite behavior
                        with cookies, we (or our partners) will obtain any consent that we may need. </span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c4">How We Protect Your Information</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c2">We take measures to help protect information about you from loss, theft, misuse and
                        unauthorized access, disclosure, alteration, and destruction. For example, we use HTTPS while information is
                        being transmitted. We also enforce technical and administrative access controls to limit which of our
                        employees have access to nonpublic personal information.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">You can help maintain the security of your account by configuring two-factor
                        authentication.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">We store the information we collect for as long as it is necessary for the purpose(s)
                        for which we originally collected it. We may retain certain information for legitimate business purposes or
                        as required by law.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c8 c7">Your Choices</span></p>
                    <p className="c0"><span className="c2">You have choices about how to protect and limit the collection, use, and sharing of
                        information about you when you use the Products. Some of these choices are available to everyone who uses
                        Slyke, while others only apply if you have a Slyke account.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Accessing and Changing Your
                        Information&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </p>
                    <p className="c0"><span className="c2">You can access and change certain information through your Slyke profile
                        settings.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">Deleting Your Account&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        className="c2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                    <p className="c0"><span className="c2">You may delete your account information at any time from the user preferences page.
                        You can also submit a request to delete the personal information Slyke maintains about you by following the
                        process described in the Your Rights - Data Subject and Consumer Information Requests section below. When
                        you delete your account, your profile is no longer visible to other users and disassociated from content you
                        posted under that account. Please note, however, that the posts, comments, and messages you submitted prior
                        to deleting your account will still be visible to others unless you first delete the specific content. We
                        may also retain certain information about you as required by law or for legitimate business purposes after
                        you delete your
                        account.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">Controlling the Use of Cookies</span><span
                        className="c2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </p>
                    <p className="c0"><span className="c2">Most web browsers are set to accept cookies by default. If you prefer, you can
                        usually choose to set your browser to remove or reject first- and third-party cookies. Please note that if
                        you choose to remove or reject cookies, this could affect the availability and functionality of our
                        Services.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">Controlling Advertising and
                        Analytics&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                            className="c2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                    <p className="c0"><span className="c2">Some analytics providers we partner with may provide specific opt-out mechanisms and
                        we may provide, as needed and as available, additional tools and third-party services that allow you to
                        better understand cookies and how you can opt out.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">Do Not Track&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        className="c2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                    <p className="c0"><span className="c2">Most modern web browsers give you the option to send a Do Not Track signal to the
                        sites you visit, indicating that you do not wish to be tracked. However, there is no accepted standard for
                        how a site should respond to this signal, and we do not take any action in response to this signal. Instead,
                        in addition to publicly available third-party tools, we offer you the choices described in this policy to
                        manage the collection and use of information about
                        you.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c7">Controlling Promotional Communications</span><span
                        className="c2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </p>
                    <p className="c0"><span className="c2">You may opt out of receiving some or all categories of promotional communications
                        from us by adjusting your email settings. If you opt out of promotional communications, we may still send
                        you non-promotional communications, such as information about your account or your use of the
                        Products.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c8 c7">Your Rights</span></p>
                    <p className="c0"><span className="c2">Data Subject and Consumer Information Requests</span></p>
                    <p className="c0"><span className="c2">Requests for a copy of the information Slyke has about your account&mdash;including
                        EU General Data Protection Regulation (&ldquo;GDPR&rdquo;) data subject access requests and California
                        Consumer Privacy Act (&ldquo;CCPA&rdquo;) consumer information requests&mdash;can be submitted using the
                        site&rsquo;s contact form.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Before we process a request from you about your personal information, we need to
                        verify the request via your access to your Slyke account or to a verified email address associated with your
                        Slyke account. You may also designate an authorized agent to exercise these rights on your behalf. Slyke
                        does not discriminate against users for exercising their rights under data protection laws to make requests
                        regarding their personal information.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c4">How long we keep your information</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c2">We keep your information only so long as we need it to provide Slyke to you and
                        fulfill the purposes described in this policy. This is also the case for anyone that we share your
                        information with and who carries out services on our behalf. When we no longer need to use your information
                        and there is no need for us to keep it to comply with our legal or regulatory obligations, we&rsquo;ll
                        either remove it from our systems or depersonalize it so that we can&#39;t identify you.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c8 c7">Our policy on children&rsquo;s information</span></p>
                    <p className="c0"><span className="c2">Children under 13 are not allowed to use Slyke. If you are based in the EEA you may
                        only use Slyke if you are over the age at which you can provide consent to data processing under the laws of
                        your country.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c4">California residents</span></p>
                    <p className="c0 c1"><span className="c4"></span></p>
                    <p className="c0"><span className="c2">The California Consumer Privacy Act (CCPA) requires us to disclose categories of
                        Personal Information we collect and how we use it, the categories of sources from whom we collect Personal
                        Information, and the third parties with whom we share it, which we have explained above.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">In the preceding twelve months, we have collected Personal Information from you, your
                        devices, our partners, third parties, and from categories of sources otherwise described in the &ldquo;We
                        collect information in a few different ways&rdquo; section of this Privacy Policy.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">We are also required to communicate information about rights California residents
                        have under California law. You may exercise the following rights:</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Right to Know and Access. You may submit a verifiable request for information
                        regarding the: </span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">(1) categories of Personal Information we collect, use, or share; (2) purposes for
                        which categories of Personal Information are collected or used by us; (3) categories of sources from which
                        we collect Personal Information; and (4) specific pieces of Personal Information we have collected about
                        you.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Right to Equal Service. We will not discriminate against you if you exercise your
                        privacy rights.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Right to Delete. You may submit a verifiable request to close your account and we
                        will delete </span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Personal Information about you that we have collected.</span></p>
                    <p className="c0"><span className="c2">In order to verify your identity when you make a request, you will be required to log
                        in to your password-protected account or respond to an email verification request.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">We do not sell the Personal Information of our users.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c4">Changes to This Policy</span></p>
                    <p className="c0"><span className="c2">We may change this Privacy Policy from time to time. If we do, we will let you know
                        by revising the date at the top of the policy. If the changes, in our sole discretion, are material, we may
                        also notify you by sending an email to the address associated with your account (if you have chosen to
                        provide an email address) or by otherwise providing notice through our Services. We encourage you to review
                        the Privacy Policy whenever you access or use our Services or otherwise interact with us to stay informed
                        about our information practices and the ways you can help protect your privacy. By continuing to use our
                        Services after Privacy Policy changes go into effect, you agree to be bound by the revised policy.</span>
                    </p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <h1 className="c14" id="h.kil94d77nwj4"><span className="c11">Community Guidelines</span></h1>
                    <p className="c0"><span className="c2">We created Slyke to be a place where it&rsquo;s easy to communicate genuinely, build
                        relationships, and have fun hanging out. Our Community Guidelines ensure everyone finds belonging, but not
                        at the expense of anyone else.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">These guidelines explain what is and isn&rsquo;t allowed on Slyke. Everyone on Slyke
                        must follow these rules, and they apply to all parts of our platform, including your content, behaviors,
                        servers, and bots. We may consider relevant off-platform behavior when assessing for violations of specific
                        Community Guidelines.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Our Trust &amp; Safety team reviews reports by users, moderators, or trusted reports.
                        When someone violates these guidelines we may take a number of enforcement steps against them including:
                        issuing warnings; removing content; suspending or removing the accounts and/or servers responsible; and
                        potentially reporting them to law enforcement.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">If you come across a user, message, or server that appears to break these guidelines,
                        please report it to us.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c8 c7">Respect Each Other</span></p>
                    <p className="c0"><span className="c2">Do not harass others or organize, promote, or participate in harassment.
                        Disagreements happen and are normal, but making continuous, repetitive, or severe negative comments or
                        circumventing a block or ban can cross the line into harassment and is not okay.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Do not organize, promote, or participate in hate speech or hateful conduct.
                        It&rsquo;s unacceptable to attack a person or a community based on attributes such as their race, ethnicity,
                        caste, national origin, sex, gender identity, gender presentation, sexual orientation, religious
                        affiliation, age, serious illness, disabilities, or other protected classifications.</span></p>
                    <p className="c0"><span className="c2">Do not make threats of violence or threaten to harm others. This includes indirect or
                        suggestive threats, as well as sharing or threatening to share someone&rsquo;s personally identifiable
                        information (also known as doxxing).</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Do not use Slyke for the organization, promotion, or support of violent extremism.
                        This also includes glorifying violent events, the perpetrators of violent acts, or similar behaviors.</span>
                    </p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Do not sexualize children in any way. You cannot share content or links which depict
                        children in a pornographic, sexually suggestive, or violent manner, including illustrated or digitally
                        altered pornography that depicts children (such as lolicon, shotacon, or cub) and conduct grooming
                        behaviors. We report illegal content and grooming to the National Center for Missing and Exploited
                        Children.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">We strongly discourage and may take action against vigilante behavior, as it can
                        interfere with our investigation and ability to report to law enforcement.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Do not make adult content available to anyone under the age of 18. You must be age 18
                        or older to participate in adult content on Slyke. You must apply the age-restricted label to any channels
                        or servers if they contain adult content or other restricted content such as violent content.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Do not use adult content in avatars, server banners, server icons, invite splashes,
                        emoji, stickers, or any other space that cannot be age-restricted.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Do not share sexually explicit content of other people without their consent, or
                        promote the sharing of non-consensual intimate materials (images, video, or audio), sometimes known as
                        revenge porn.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Do not share content that glorifies or promotes suicide or self-harm, including any
                        encouragement to others to cut themselves or embrace eating disorders such as anorexia or bulimia.</span>
                    </p>
                    <p className="c0"><span className="c2">Self-harm threats used as a form of emotional manipulation or coercion are also
                        prohibited.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Do not share real media depicting gore, excessive violence, or animal harm,
                        especially with the intention to harass or shock others.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Do not share content that violates anyone&#39;s intellectual property or other
                        rights.</span></p>
                    <p className="c0"><span className="c2">Be Honest</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Do not share false or misleading information (otherwise known as misinformation).
                        Content that is false, misleading, and can lead to significant risk of physical or societal harm may not be
                        shared on Slyke. We may remove content if we reasonably believe its spread could result in damage to
                        physical infrastructure, injury of others, obstruction of participation in civic processes, or the
                        endangerment of public health.</span></p>
                    <p className="c0"><span className="c2">Do not coordinate or participate in malicious impersonation of an individual or an
                        organization. Satire and parody are okay.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Do not engage in activities intended to cause damage or gain unauthorized access to
                        another user&#39;s account, network, or system. This includes impersonating Slyke staff, distributing
                        malware, authentication token theft, phishing, DDOS, and other hacking or social engineering
                        techniques.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Do not distribute or provide access to content involving the hacking, cracking, or
                        distribution of stolen goods, pirated content, or accounts. This includes sharing or selling game cheats or
                        hacks.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c8 c7">Respect Slyke</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Do not use Slyke to spam, manipulate engagement, or disrupt other people&rsquo;s
                        experience, including trying to to influence or disrupt conversations using bots, fake accounts, multiple
                        accounts, or other automation. This includes purchasing or selling methods of artificially </span></p>
                    <p className="c0"><span className="c2">increasing membership, such as via advertisements or botting.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Do not organize, promote, or engage in any illegal or dangerous behavior, such as
                        sexual solicitation, human trafficking, and selling or facilitating the sale of prohibited or potentially
                        dangerous goods (firearms, ammunition, drugs, and controlled substances). These activities are likely to get
                        you kicked off Slyke, and may get you reported to law enforcement.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Do not abuse Slyke products in any way, such as selling or purchasing an account or
                        server, or participating in fraudulent Nitro incentives or Boosting activities.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Do not use self-bots or user-bots. Each account must be associated with a human, not
                        a bot. Self-bots put strain on Slyke&rsquo;s infrastructure and our ability to run our services. For more
                        information, you can read our Developer Policies here.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Do not mislead Slyke&rsquo;s support teams. Do not make false or malicious reports to
                        Trust &amp; Safety or Customer Support; send multiple reports about the same issue; or ask a group of users
                        to report the same content or issue. These behaviors may result in action being taken on your
                        account.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">If you see any activity that violates these guidelines, our Terms of Service, or our
                        other policies, please report it to us using the Slyke contact form.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">These guidelines will continue to evolve over time. This means we may take action
                        against a user, server, or content that violates the spirit of these guidelines when we encounter a new
                        threat or harm that is not explicitly covered in the current version.</span></p>
                    <p className="c0"><span className="c2">We will always make our best effort to notify you when we update these guidelines,
                        but it is up to you to follow the spirit of them: keep Slyke safe and a place for everyone to belong. Thanks
                        for doing your part.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <h1 className="c14" id="h.z4816spzhlmq"><span>Slyke</span><span className="c11">&nbsp;Content Policy</span></h1>
                    <p className="c0"><span>Slyke</span><span className="c2">&nbsp;is a vast network of collections that are created, run, and
                        populated by you, the Slyke users.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Through these collections, you can post, comment, chat, learn and connect with people
                        who share your interests, and we encourage you to find&mdash;or even activate&mdash;your interests on
                        Slyke.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Everyone on Slyke should have an expectation of privacy and safety, so please respect
                        the privacy and safety of others. Every collection on Slyke is defined by its curators. These users help
                        manage the collection.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Slyke is what we make of it together, and it can all only exist if we operate by a
                        shared set of rules. We ask that you abide by not just the letter of these rules, but the spirit as
                        well.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c8 c7">Rules</span></p>
                    <p className="c0 c1"><span className="c8 c7"></span></p>
                    <p className="c0"><span className="c2">Rule 1</span></p>
                    <p className="c0"><span className="c2">Remember the human. Slyke is a place for working together to curate content and
                        connect and discuss it, not for attacking marginalized or vulnerable groups of people. Everyone has a right
                        to use Slyke free of harassment, bullying, and threats of violence. Users that incite violence or that
                        promote hate based on identity or vulnerability will be banned.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Rule 2</span></p>
                    <p className="c0"><span className="c2">Post authentic content into collections where you have a personal interest, and do
                        not cheat or engage in content manipulation (including spamming, vote manipulation, ban evasion, or
                        subscriber fraud) or otherwise interfere with or disrupt Slyke collections.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Rule 3</span></p>
                    <p className="c0"><span className="c2">Respect the privacy of others. Instigating harassment, for example by revealing
                        someone&rsquo;s personal or confidential information, is not allowed. Never post or threaten to post
                        intimate or sexually-explicit media of someone without their consent.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Rule 4</span></p>
                    <p className="c0"><span className="c2">Do not post or encourage the posting of sexual or suggestive content involving
                        minors.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Rule 5</span></p>
                    <p className="c0"><span className="c2">You don&rsquo;t have to use your real name to use Slyke, but don&rsquo;t impersonate
                        an individual or an entity in a misleading or deceptive manner.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Rule 6</span></p>
                    <p className="c0"><span className="c2">Ensure people have predictable experiences on Slyke by properly labeling content and
                        communities, particularly content that is graphic, sexually-explicit, or offensive.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Rule 7</span></p>
                    <p className="c0"><span className="c2">Keep it legal, and avoid posting illegal content or soliciting or facilitating
                        illegal or prohibited transactions.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Rule 8</span></p>
                    <p className="c0"><span className="c2">Don&rsquo;t break the site or do anything that interferes with normal use of
                        Slyke.</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Enforcement</span></p>
                    <p className="c0"><span className="c2">We have a variety of ways of enforcing our rules, including, but not limited
                        to:</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                    <p className="c0"><span className="c2">Asking you nicely to knock it off</span></p>
                    <p className="c0"><span className="c2">Asking you less nicely</span></p>
                    <p className="c0"><span className="c2">Temporary or permanent suspension of accounts</span></p>
                    <p className="c0"><span className="c2">Removal of privileges from, or adding restrictions to, accounts such revoking
                        curation capability and messaging</span></p>
                    <p className="c0"><span className="c2">Removal of content</span></p>
                    <p className="c0"><span className="c2">Banning of Slyke collections</span></p>
                    <p className="c0 c1"><span className="c2"></span></p>
                </body>
            </Box>
            <Footer />
        </Box>
    )
}