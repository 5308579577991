import {
    Modal,
    IconButton,
    Box,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import colors from '../../constants/colors'
import {
    useEffect,
    useState
} from 'react'
import ButFirst from './but-first'
import CollectionCreated from './collection-created'

export default function CreateCollectionModal(props) {
    const {
        isMobile
    } = props;

    const [activeComponent, setActiveComponent] = useState(null)

    useEffect(() => {
        if (props.collectionCreated) {
            setActiveComponent(
                <CollectionCreated
                    setActiveComponent={setActiveComponent}
                    isMobile={isMobile}
                />
            )
        } else {
            setActiveComponent(
                <ButFirst
                    setActiveComponent={setActiveComponent}
                    isMobile={isMobile}
                    openAddItem={props.openAddItem}
                    close={props.close}
                />
            )
        }
    }, [])

    const modalBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            xs: '95%',
            sm: 660
        },
        maxHeight: '95%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 0,
        m: 0,
        borderRadius: 5,
        '&:active': {
            outline: 'none'
        },
        '&:focus': {
            outline: 'none'
        }
    }

    return (
        <Modal
            open={props.open || false}
            onClose={() => {
                props.close()
                setActiveComponent(
                    <ButFirst
                        setActiveComponent={setActiveComponent}
                        isMobile={isMobile}
                        openAddItem={props.openAddItem}
                        close={props.close}
                    />
                )
            }}
        >
            <Box
                sx={modalBoxStyle}
            >
                {/* Content */}
                {activeComponent}

                {/* Close Icon */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: {
                            xs: 9,
                            sm: 5
                        },
                        left: {
                            xs: 9,
                            sm: 5
                        },
                        margin: 'auto'
                    }}
                >
                    <IconButton
                        onClick={() => {
                            props.close()
                            setActiveComponent(
                                <ButFirst
                                    setActiveComponent={setActiveComponent}
                                    isMobile={isMobile}
                                    openAddItem={props.openAddItem}
                                    close={props.close}
                                />
                            )
                        }}
                        sx={{
                            m: 0,
                            p: 0
                        }}
                    >
                        <CloseIcon
                            sx={{
                                color: colors.gray
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>

        </Modal>
    )
}