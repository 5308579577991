import {
    Grid,
    Typography,
    Button,
    TextField,
    Box,
    CircularProgress
} from '@mui/material'
import colors from '../../constants/colors'
import LinkIcon from '@mui/icons-material/Link'
import UploadIcon from '@mui/icons-material/Upload'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import AddItemLink from './add-item-link'
import AddItemMedia from './add-item-media'
import AddItemText from './add-item-text'
import { useState } from 'react'
import apiRoutes from '../../constants/api-routes'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export default function AddItemStepOne(props) {
    const {
        isMobile
    } = props;

    const navigate = useNavigate()
    const [itemLink, setItemLink] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const textStyle = {
        fontFamily: 'Open Sans'
    }

    const buttonStyle = {
        width: !isMobile ? '70%' : '95%',
        textTransform: 'none',
        fontFamily: 'Open Sans',
        py: 1.5,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: colors.darkGray,
            color: colors.lightGray,
            borderColor: colors.darkGray
        }
    }

    const submitItemLink = () => {
        if (itemLink) {
            setLoading(true)
            axios.get(apiRoutes.getOpenGraphData, {
                params: {
                    itemLink
                }
            }).then((res) => {
                setLoading(false)
                let ogData = res.data.ogData
                ogData = ogData.url ? ogData : { url: itemLink, ...ogData }
                props.setActiveComponent(
                    <AddItemLink
                        setActiveComponent={props.setActiveComponent}
                        itemLink={itemLink}
                        ogData={ogData}
                        collectionIndex={props.collectionIndex}
                        isMobile={isMobile}
                    />
                )
            }).catch(() => {
                setLoading(false)
                setError(true)
                setItemLink(null)
            })
        }
    }

    return (
        <Box
            sx={{
                textAlign: 'center',
                height: !isMobile ? 454 : "100%"
            }}
        >
            {
                loading ?
                    <>
                        <Typography
                            variant='h5'
                            sx={{
                                ...textStyle,
                                pt: 15,
                                pb: 5
                            }}
                        >
                            Gathering your link&apos;s data! This shouldn&apos;t take long!
                        </Typography>
                        <CircularProgress
                            size={100}
                        />
                    </> :
                    <Grid
                        container
                        sx={{
                            px: !isMobile ? 6 : 1
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                textAlign: 'center',
                                mt: 2,
                                mb: 8
                            }}
                        >
                            <Typography
                                variant='h5'
                                sx={{
                                    ...textStyle,
                                    fontWeight: 'bold'
                                }}
                            >
                                Add New Item
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{
                                textAlign: 'center'
                            }}
                        >
                            <TextField
                                error={error}
                                onClick={() => {
                                    setItemLink('')
                                }}
                                onBlur={() => {
                                    if (itemLink === '') {
                                        setItemLink(null)
                                    }
                                }}
                                onInput={(e) => {
                                    setItemLink(e.target.value)
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        submitItemLink()
                                    }
                                }}
                                InputLabelProps={{
                                    sx: {
                                        textAlign: 'center',
                                        mx: 'auto',
                                        width: '100%'
                                    },
                                    shrink: false
                                }}
                                label={
                                    itemLink === null ?
                                        <Box
                                            sx={{
                                                display: 'block',
                                                width: '100%'
                                            }}
                                        >
                                            <LinkIcon
                                                sx={{
                                                    display: 'inline',
                                                    verticalAlign: 'top',
                                                    pr: 1
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    display: 'inline'
                                                }}
                                            >
                                                {error ? 'Provided link invalid... Try again!' : 'Paste a Link...'}
                                            </Typography>
                                        </Box> : null
                                }
                                sx={{
                                    width: !isMobile ? '70%' : '95%',
                                    textTransform: 'none',
                                    fontFamily: 'Open Sans',
                                    color: colors.darkGray,
                                    backgroundColor: colors.white,
                                    textAlign: 'center'
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                textAlign: 'center',
                                my: 2
                            }}
                        >
                            <Button
                                variant='contained'
                                onClick={() => {
                                    props.setActiveComponent(
                                        <AddItemMedia
                                            setActiveComponent={props.setActiveComponent}
                                            collectionIndex={props.collectionIndex}
                                            isMobile={isMobile}
                                        />
                                    )
                                }}
                                sx={{
                                    ...buttonStyle,
                                    color: colors.white,
                                    backgroundColor: colors.secondary
                                }}
                            >
                                <UploadIcon />&nbsp;
                                Upload Media
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                textAlign: 'center',
                                mb: 6
                            }}
                        >
                            <Button
                                variant='contained'
                                onClick={() => {
                                    props.setActiveComponent(
                                        <AddItemText
                                            setActiveComponent={props.setActiveComponent}
                                            collectionIndex={props.collectionIndex}
                                            isMobile={isMobile}
                                        />
                                    )
                                }}
                                sx={{
                                    ...buttonStyle,
                                    color: colors.darkGray,
                                    backgroundColor: colors.white,
                                    border: `${colors.lightGray} solid 1px`,
                                    pr: 5
                                }}
                            >
                                <TextFieldsIcon />&nbsp;
                                Share Text
                            </Button>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{
                                textAlign: 'center',
                                mb: 2
                            }}
                        >
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={submitItemLink}
                                sx={{
                                    ...buttonStyle,
                                    color: colors.white,
                                    p: 1,
                                    width: !isMobile ? '40%' : '64%',
                                }}
                            >
                                Post
                            </Button>
                        </Grid>

                        <Grid
                            item
                            xs={6}
                            sx={{
                                textAlign: 'right',
                                mb: 2
                            }}
                        >
                            <Button
                                onClick={() => navigate('/rules')}
                                variant='contained'
                                sx={{
                                    ...buttonStyle,
                                    backgroundColor: colors.white,
                                    border: `${colors.secondary} solid 1px`,
                                    color: colors.secondary,
                                    p: 0.5,
                                    width: !isMobile ? '38%' : '60%',
                                    mr: 0.5
                                }}
                            >
                                Rules
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sx={{
                                textAlign: 'left',
                                mb: 2
                            }}
                        >
                            <Button
                                onClick={() => navigate('/faq')}
                                variant='contained'
                                sx={{
                                    ...buttonStyle,
                                    backgroundColor: colors.white,
                                    border: `${colors.secondary} solid 1px`,
                                    color: colors.secondary,
                                    p: 0.5,
                                    width: !isMobile ? '38%' : '60%',
                                    ml: 0.5
                                }}
                            >
                                Need Help?
                            </Button>
                        </Grid>
                    </Grid >
            }
        </Box>
    )
}