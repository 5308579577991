import { Box, Modal } from "@mui/material";
import CloseButton from "./CloseButton/CloseButton";

export default function BaseModal(props) {
    const {
        children,
        open,
        onClose,
        closeButton,
        sx
    } = props;

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: {
                        xs: "95%",
                        sm: 660
                    },
                    maxHeight: "98%",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 0,
                    m: 0,
                    borderRadius: 5,
                    "&:active": {
                        outline: "none"
                    },
                    "&:focus": {
                        outline: "none"
                    },
                    ...sx
                }}
            >
                {children}

                {/* Optional Close Button */}
                {
                    closeButton ?
                        <CloseButton
                            onClose={onClose}
                        /> : null
                }
            </Box>
        </Modal>
    );
}