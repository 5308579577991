const faqs = [
    {
        title: 'How does Slyke work?',
        body: 'Slyke is organized with Collections around specific categories. Collections are made up of Items: links, videos, photos, or even text entries all about the same thing. The main rule — and part of what makes Slyke so valuable — is that Collections are distinct and unique, there are no duplicates. No duplicate Collections and, within Collections, no duplicate Items.'
    },
    {
        title: 'How do these content Collections get made and moderated?',
        body: 'YOU - the users help build Slyke in a communal, wiki-style crowdsourcing of content collection and curation from around the world. Each Collection has one or more users in charge of managing the Collection and any Items submitted for inclusion. This is the Curator. The user that initially creates the Collection is the Curator unless they forgo that opportunity. Then Slyke serves as the Curator until another user volunteers for the role. Curators ensure that all Items fit the category of the Collection and are unique. They have the ability to accept or reject Items submitted.'
    },
    {
        title: 'What exactly is the policy for duplicate Collections?',
        body: 'The beauty of Slyke is how clean it is with singularly unique Collections. If there\'s a Collection of cold opens from The Office, any others would be removed, rejected, or merged.'
    },
    {
        title: 'Who moderates the Collections?',
        body: 'The user that creates the Collection (that could be you!) has the right to the curator and can add others who volunteer. If the creator doesn\'t want to curate, Slyke does it until a user applies to curate.'
    },
    {
        title: 'How specific do Collections need to be?',
        body: 'Collections can be as broad or narrow as you\'d like, as long as they are unique from other Collections.'
    },
    {
        title: 'I submitted an Item and I don\'t see it in the Collection. What\'s up with that?',
        body: 'To keep the Collections clean and effective, each Item is reviewed and approved by the curator. When your item gets approved, you\'ll be notified and it will be live in the Collection! If it gets rejected, you\'ll be notified and given a reason why (the Item already exists in the Collection, not a credible source, it doesn\'t  fit the criteria, etc.) '
    }
]

export default faqs