import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import colors from '../../constants/colors'

export default function ReasonsToJoin(props) {
    return (
        <List
            dense
            sx={{
                ml: -1
            }}
        >
            {
                [
                    'Follow and Create Collections',
                    'Curate Collections',
                    'Comment on and Favorite Items',
                    'Chat',
                    'More!'
                ].map((text) => {
                    return (
                        <ListItem key={text} sx={{ py: 0 }}>
                            <ListItemIcon sx={{ minWidth: 0, mr: 1, pb: 2 / 10 }}>
                                <CircleIcon
                                    sx={{
                                        fontSize: 6,
                                        color: props.color ? props.color : colors.primary
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                align='left'
                                sx={{
                                    color: props.color ? props.color : colors.primary,
                                    '& .MuiTypography-root': {
                                        fontSize: 14,
                                        fontFamily: 'Open Sans',
                                        fontWeight: props.fontWeight ? props.fontWeight : 'bold'
                                    },
                                    m: 0,
                                    mb: 2 / 10
                                }}
                            >
                                {text}
                            </ListItemText>
                        </ListItem>
                    )
                })
            }
        </List>
    )
}