import React from "react";
import ReactDOM from "react-dom";

import { ThemeProvider } from "@mui/material/styles";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import ReactGA from "react-ga4";

import { store, persistor } from "./store/store";
import mainTheme from "./themes/main-theme";
import "./index.css";
import RouteSetup from "./routes";

const TRACKING_ID = "G-CSY9YW7QWL";
ReactGA.initialize(TRACKING_ID);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={mainTheme}>
                <RouteSetup />
            </ThemeProvider>
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);