import { Fragment } from "react";

import ItemViewCard from "../../../../ItemView/ItemViewBodyMobile/ItemViewCard/ItemViewCard";
import useNavigateToItem from "../../../../../hooks/navigation/useNavigateToItem";
import { Typography } from "@mui/material";
import useNavigateToCollection from "../../../../../hooks/navigation/useNavigateToCollection";

export default function ItemBookmarks(props) {
    /* 
    
    This component handles the user profile bookmark feed.

    */

    const {
        bookmarks,
        bookmarksPerPage,
        segment,
        setSegment,
        updateBookmarkItem
    } = props;

    const navigateToItem = useNavigateToItem();
    const navigateToCollection = useNavigateToCollection();

    return (
        <Fragment>
            {
                bookmarks.length === 0 ?
                    <Typography
                        variant="h4"
                        sx={{
                            textAlign: "center",
                            mt: 4
                        }}
                    >
                        You have no bookmarks yet! Bookmarked items will show up here!
                    </Typography> :
                    bookmarks.map((bookmark, index) => {
                        const itemType = bookmark.itemMedia.itemType;
                        const itemData = {
                            bodyText: bookmark.description,
                            title: bookmark.title,
                            views: bookmark.viewCount,
                            likes: bookmark.likeCount,
                            commentsCount: bookmark.commentCount,
                            bookmarks: bookmark.bookmarkCount,
                            itemId: bookmark.itemId,
                            userAddedBy: bookmark.userAddedBy,
                            username: bookmark.userAddedByUsername,
                            timePosted: bookmark.timePosted,
                            itemType,
                            link: bookmark.itemMedia.data.link,
                            image: bookmark.itemMedia.link,
                            ogDescription: itemType === "text" ? bookmark.itemMedia.data.text : null,
                            ogTitle: bookmark.itemMedia.data.ogTitle,
                            ogVideoLink: itemType === "media" || bookmark.itemMedia.mediaType === "video" ? bookmark.itemMedia.link : null,
                            isLiked: bookmark.isLiked,
                            isBookmarked: bookmark.isBookmarked
                        };
                        return (
                            <Fragment
                                key={index}
                            >
                                <Typography
                                    onClick={() => {
                                        navigateToCollection(bookmark.collectionId);
                                    }}
                                    variant='h5'
                                    sx={{
                                        fontFamily: "Open Sans",
                                        mb: 1,
                                        mt: 4,
                                        maxWidth: 300,
                                        mx: "auto"
                                    }}
                                >
                                    {bookmark.collectionTitle}
                                </Typography>
                                <ItemViewCard
                                    itemIndex={index}
                                    updateItemIndex={() => { }}
                                    item={itemData}
                                    collectionId={bookmark.collectionId}
                                    onClick={() => navigateToItem(bookmark.collectionId, bookmark.itemIndex, { "bookmarked": bookmark.itemIndex })}
                                    shareLinkOverride={`www.slyke.co/item?collectionId=${bookmark.collectionId}&itemIndex=${bookmark.itemIndex}`}
                                    onInView={
                                        (index + 1) % bookmarksPerPage === 0 && index !== 0 ?
                                            () => setSegment(segment + 1) : null
                                    }
                                    refreshOverride={() => updateBookmarkItem(index)}
                                    commentsRefreshOverride={() => updateBookmarkItem(index)}
                                />
                            </Fragment>
                        );
                    })
            }
        </Fragment>
    );
}