import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'
import apiRoutes from '../../constants/api-routes'
import { useEffect } from 'react'

export default function Reject(props) {
    const theme = useTheme()
    const [searchParams] = useSearchParams()

    const submitRejection = async () => {
        return axios.post(apiRoutes.updatePendingRequestToCurate, {
            requestingUsername: searchParams.get('username'),
            collectionId: props.collection ? props.collection.collectionId : null,
            isApproved: 0
        }, {
            headers: {
                'authorization': `Bearer ${props.token}`
            }
        })
    }

    useEffect(() => {
        if (searchParams.get('rejectRequest') && props.token && props.collection && props.collection.collectionId && props.collection.curators.length > 0) {
            submitRejection().catch(() => {
                props.setShowSignIn(true)
                props.close()
            })
        } else if (searchParams.get('rejectRequest') && props.collection && props.collection.collectionId && props.collection.curators.length > 0) {
            props.setShowSignIn(true)
            props.close()
        }
    }, [])

    const username = searchParams.get('username')
    const modalBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            xs: '95%',
            sm: 660
        },
        bgcolor: 'background.paper',
        boxShadow: 24,
        py: 8,
        px: 1,
        m: 0,
        borderRadius: 5,
        '&:active': {
            outline: 'none'
        },
        '&:focus': {
            outline: 'none'
        }
    }

    return (
        <Modal
            open={props.open || false}
            onClose={() => {
                props.close()
            }}
        >
            <Box
                sx={modalBoxStyle}
            >
                <Typography
                    variant='h5'
                    sx={{
                        fontFamily: theme.typography.openSans,
                        width: '70%',
                        mx: 'auto'
                    }}
                >
                    You have rejected {username}&apos;s request to become a curator!
                </Typography>

                {/* Close Icon */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: {
                            xs: 9,
                            sm: 5
                        },
                        left: {
                            xs: 9,
                            sm: 5
                        },
                        margin: 'auto'
                    }}
                >
                    <IconButton
                        onClick={() => {
                            props.close()
                        }}
                        sx={{
                            m: 0,
                            p: 0
                        }}
                    >
                        <CloseIcon
                            sx={{
                                color: theme.palette.grey['700']
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>
        </Modal>
    )
}