const colors = {
    primary: '#FF7300',
    lightPrimary: '#FFE6CF',
    secondary: '#0033FF',
    error: '#DC3545',
    success: '#66CA97',
    lightGray: '#CED4DA',
    gray: '#555555',
    darkGray: '#222425',
    white: '#FFFFFF',
    warning: '#DC3545'
}

export default colors