import FilterButtons from '../../legacy-components/filter-buttons/filter-buttons'
import { Fragment, useEffect, useState, useMemo } from 'react'
import {
    useSelector,
    useDispatch
} from 'react-redux'
import {
    getCollections,
    getFollowedCollections,
    getCuratedCollections
} from '../../store/collection/collectionSlice'
import {
    getUserId,
    getToken
} from '../../store/user/userSlice'
import {
    Grid,
    Typography,
    Button,
    Avatar,
    IconButton,
    Menu,
    MenuItem,
    Box,
    useTheme
} from '@mui/material'
import colors from '../../constants/colors'
import CollectionThumbnailWithSide from '../../legacy-components/collection-thumbnail-with-side/collection-thumbnail-with-side'
import UserEngagementBox from '../../legacy-components/user-engagement-box/user-engagement-box'
import GppGoodIcon from '@mui/icons-material/GppGood'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import apiRoutes from '../../constants/api-routes'
import ItemApproval from '../../legacy-components/item-approval-queue/item-approval-queue'
import Navbar from '../../components/Navbar/Navbar'
import BasicAlert from '../../components/Common/BasicAlert/BasicAlert'
import ItemViewCard from '../../components/ItemView/ItemViewBodyDesktop/ItemViewCard/ItemViewCard'
import useNavigateToItem from '../../hooks/navigation/useNavigateToItem'
import useGetBookmarks from '../../hooks/data/useGetBookmarks'

function useQuery() {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
}

export default function UserProfile() {
    const theme = useTheme()
    const navigate = useNavigate()
    const query = useQuery()
    const dispatch = useDispatch()
    const userId = query.get('userId')
    const loggedInUserId = useSelector(getUserId)
    const collections = useSelector(getCollections)
    const token = useSelector(getToken)

    const getBookmarks = useGetBookmarks();
    const bookmarksPerPage = 3;

    const [selectedFilter, setSelectedFilter] = useState(query.get('filter') || 'following collections')
    const [segment, setSegment] = useState(1)
    const [anchorEl, setAnchorEl] = useState(null)
    const [queue, setQueue] = useState([])
    const [bookmarks, setBookmarks] = useState([])
    const [bookmarksPage, setBookmarksPage] = useState(1)
    const [userCollectionsFollowed, setUserCollectionsFollowed] = useState(0)
    const [userItemsPosted, setUserItemsPosted] = useState(0)
    const [userItemsLiked, setUserItemsLiked] = useState(0)
    const [displayName, setDisplayName] = useState('')
    const [handle, setHandle] = useState('')
    const [isUserSelf, setIsUserSelf] = useState(false)
    const [showComingSoon, setShowComingSoon] = useState(false)
    const [showSuccess, setShowSuccess] = useState()

    const navigateToItem = useNavigateToItem();

    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const filterToDispatchMap = {
        'following collections': getFollowedCollections,
        'curated collections': getCuratedCollections
    }

    const menuItemTextStyle = {
        fontFamily: 'Open Sans',
        fontSize: 14
    }

    const getMoreCollections = (ref, onView) => {
        const observer = new IntersectionObserver(handleObserver)
        function handleObserver([entry]) {
            if (entry.isIntersecting) {
                onView()
                observer.unobserve(entry.target)
            }
        }

        observer.observe(ref.current)
        return observer
    }

    const getInitials = () => {
        if (!displayName) {
            return
        }

        let splitDisplayName = displayName.split(' ')
        if (splitDisplayName.length > 1) {
            return splitDisplayName[0][0].toUpperCase() + splitDisplayName[1][0].toUpperCase()
        } else {
            return splitDisplayName[0][0].toUpperCase()
        }
    }

    const getQueue = () => {
        axios.get(apiRoutes.getQueue, {
            params: {
                userId
            },
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(res => {
            setQueue(res.data.queue)
        })
    }

    const updateBookmarkItem = (index) => {
        /*
        
        Using the item's index, update it to reflect changes in state.
        We can use the API's pagination feature to accomplish this.

        */

        getBookmarks(
            1,
            index + 1,
            (res) => {
                setBookmarks(prev => {
                    // make a copy and update the element at the given index
                    let copy = [...prev]
                    copy[index] = res.data.data[0]

                    return copy
                })
            }
        )
    }

    const handleFilter = (filter) => {
        setSelectedFilter(filter)
        if (filter === 'my queue') {
            getQueue()
        } else if (filter === 'bookmarked items') {
            if (bookmarks.length === 0) {
                getBookmarks(bookmarksPerPage, bookmarksPage, (res) => setBookmarks(prev => [...prev, ...res.data.data]))
            }
            setSegment(1)
            setSelectedFilter(filter)
            getQueue()
        } else {
            setSegment(1)

            /* 
            
            this is the hack of the century...
            
            it appears that when the feed is changed, it does not properly trigger 
            the observer view anymore, so no new requests are sent out

            this hack somehow makes changing filters behave as expected

            I believe this is due to some kind of interaction between redux,
            react, and the intersection observer API

            Notably, the mobile version uses all three of these and does not have this
            problem. My best guess is that the more modular/DRY setup of the mobile components
            is causing the re-renders to actually setup the observers again. I believe on desktop 
            it doesn't properly setup the observer for the first segment after switching filters.
            
            */
            setTimeout(() => {
                setSegment(2)
            }, 50)

            setSelectedFilter(filter)
        }
    }

    useEffect(() => {
        axios.get(apiRoutes.getUserEngagementStats, {
            params: {
                userId
            }
        }).then(res => {
            setUserItemsLiked(res.data.engagementStats.ItemsLiked)
            setUserItemsPosted(res.data.engagementStats.ItemsAdded)
            setUserCollectionsFollowed(res.data.engagementStats.CollectionsFollowed)
        })

        axios.get(apiRoutes.getUserById, {
            params: {
                userId
            }
        }).then(res => {
            const {
                displayName,
                handle
            } = res.data.user
            setDisplayName(displayName)
            setHandle(handle)
        })
    }, [])

    useEffect(() => {
        if (segment !== 1 && filterToDispatchMap[selectedFilter]) {
            dispatch(filterToDispatchMap[selectedFilter]({ userId, segment }))
        }
    }, [segment])

    useEffect(() => {
        if (bookmarksPage !== 1) {
            getBookmarks(bookmarksPerPage, bookmarksPage, (res) => setBookmarks(prev => [...prev, ...res.data.data]))
        }
    }, [bookmarksPage])

    useEffect(() => {
        setSegment(1);
        if (filterToDispatchMap[selectedFilter]) {
            dispatch(filterToDispatchMap[selectedFilter]({ userId, segment, overwrite: true }))
        }
    }, [selectedFilter])

    useEffect(() => {
        if (loggedInUserId === +userId) {
            setIsUserSelf(true)
        }
    }, [loggedInUserId])

    useEffect(() => {
        if (filterToDispatchMap[selectedFilter]) {
            dispatch(filterToDispatchMap[selectedFilter]({ userId, segment, overwrite: true }))
        }

        if (loggedInUserId === +userId) {
            setIsUserSelf(true)
        } else {
            setIsUserSelf(false)
        }
    }, [loggedInUserId])

    return (
        <Fragment>
            <Navbar
                search
            />

            <Grid
                container
                sx={{
                    mt: 12
                }}
            >
                {/* name, avatar, and handle */}
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                    >
                        <Grid
                            item
                            xs={5.5}
                        >
                            <Avatar
                                sx={{
                                    backgroundColor: colors.darkGray,
                                    color: colors.lightGray,
                                    fontFamily: 'Open Sans',
                                    p: 2,
                                    ml: 'auto',
                                    my: 2,
                                    mr: 2
                                }}
                            >
                                {getInitials()}
                            </Avatar>
                        </Grid>
                        <Grid
                            item
                            xs={6.5}
                        >
                            <Typography
                                variant='h4'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: 'bold',
                                    mt: 2
                                }}
                            >
                                {displayName}
                            </Typography>
                            <Typography
                                variant='display1'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    mt: 2
                                }}
                            >
                                {handle}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {/* favorite, curator status, message, other */}
                <Grid
                    item
                    xs={12}
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    <GppGoodIcon
                        sx={{
                            color: 'green',
                            fontSize: 30,
                            ml: -10
                        }}
                    />
                    <StarBorderIcon
                        sx={{
                            color: colors.error,
                            fontSize: 30,
                            mr: 4
                        }}
                    />
                    {
                        isUserSelf ?
                            <Button
                                onClick={() => setShowComingSoon(true)}
                                variant='contained'
                                sx={{
                                    color: 'white',
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    py: 1,
                                    px: 2,
                                    textTransform: 'none',
                                    mb: 2.4
                                }}
                            >
                                Edit Profile
                            </Button> :
                            <Button
                                onClick={() => setShowComingSoon(true)}
                                variant='contained'
                                sx={{
                                    color: 'white',
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,
                                    py: 1,
                                    px: 2,
                                    textTransform: 'none',
                                    mb: 2.4
                                }}
                            >
                                Message
                            </Button>
                    }
                    <IconButton
                        onClick={handleClick}
                        variant='contained'
                        sx={{
                            fontFamily: 'Open Sans',
                            py: 0.5,
                            px: 2,
                            textTransform: 'none',
                            mb: 2.3,
                            ml: 2,
                            border: `${colors.lightGray} solid 1px`,
                            borderRadius: 2
                        }}
                    >
                        <MoreHorizIcon
                            sx={{
                                fontSize: 30,
                                fontWeight: 'bold'
                            }}
                        />
                    </IconButton>
                </Grid>

                {/* Engagement Metrics */}
                <Grid item xs={4} />
                <Grid
                    item
                    xs={4}
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    <UserEngagementBox
                        collectionsFollowed={userCollectionsFollowed}
                        itemsPosted={userItemsPosted}
                        itemsLiked={userItemsLiked}
                    />
                </Grid>
                <Grid item xs={4} />

                {/* Filters */}
                <Grid
                    item
                    xs={12}
                    sx={{
                        mb: -8
                    }}
                >
                    <FilterButtons
                        filters={isUserSelf ? [
                            'following collections',
                            'curated collections',
                            'bookmarked items',
                            'my queue'
                        ] : [
                            'following collections',
                            'curated collections'
                        ]}
                        defaultFilter={selectedFilter}
                        handleFilter={handleFilter}
                    />
                </Grid>

                {
                    selectedFilter === 'bookmarked items' ?
                        <Box
                            sx={{
                                mx: "auto",
                                width: "100%",
                                mt: 5
                            }}
                        >
                            {
                                bookmarks.length === 0 ?
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            textAlign: "center",
                                            mt: 4
                                        }}
                                    >
                                        You have no bookmarks yet! Bookmarked items will show up here!
                                    </Typography> :
                                    bookmarks.map((item, index) => {
                                        const itemType = item.itemMedia.itemType
                                        const itemData = {
                                            bodyText: item.description,
                                            title: item.title,
                                            itemType: item.itemType,
                                            views: item.viewCount,
                                            likes: item.likeCount,
                                            commentsCount: item.commentCount,
                                            bookmarks: item.bookmarkCount,
                                            itemId: item.itemId,
                                            userAddedBy: item.userAddedBy,
                                            username: item.userAddedByUsername,
                                            timePosted: item.timePosted,
                                            itemType,
                                            link: item.itemMedia.data.link,
                                            image: item.itemMedia.link,
                                            ogDescription: itemType === "text" ? item.itemMedia.data.text : null,
                                            ogTitle: item.itemMedia.data.ogTitle,
                                            ogVideoLink: itemType === "media" || item.itemMedia.mediaType === "video" ? item.itemMedia.link : null,
                                            isLiked: item.isLiked,
                                            isBookmarked: item.isBookmarked
                                        };

                                        return (
                                            <Fragment
                                                key={index}
                                            >
                                                <Typography
                                                    onClick={() => {
                                                        navigate(`/collection?collectionId=${item.collectionId}`)
                                                    }}
                                                    variant='h5'
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        mb: 2,
                                                        mt: 4,
                                                        maxWidth: 1150,
                                                        mx: "auto",
                                                        '&:hover': {
                                                            color: theme.palette.primary.main,
                                                            cursor: 'pointer'
                                                        }
                                                    }}
                                                >
                                                    {item.collectionTitle}
                                                </Typography>
                                                <ItemViewCard
                                                    item={itemData}
                                                    itemIndex={index}
                                                    collectionId={item.collectionId}
                                                    onClick={() => navigateToItem(item.collectionId, item.itemIndex, { 'bookmarked': item.itemIndex })}
                                                    shareLinkOverride={`www.slyke.co/item?collectionId=${item.collectionId}&itemIndex=${item.itemIndex}`}
                                                    onInView={
                                                        (index + 1) % bookmarksPerPage === 0 && index !== 0 ?
                                                            () => setBookmarksPage(bookmarksPage + 1) : null
                                                    }
                                                    updateItemIndex={() => { }}
                                                    refreshOverride={() => updateBookmarkItem(index)}
                                                    commentsRefreshOverride={() => updateBookmarkItem(index)}
                                                />
                                            </Fragment>
                                        )
                                    })
                            }
                        </Box> : null
                }

                {/* Collection Carousels */}
                {
                    selectedFilter !== 'my queue' && selectedFilter !== 'bookmarked items' ?
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mt: 4
                            }}
                        >
                            <Grid
                                container
                                sx={{
                                    mt: 1
                                }}
                            >
                                {
                                    collections.map((collection, index) => {
                                        return (
                                            <Grid
                                                key={index}
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                mdlg={5}
                                                lg={4}
                                                lgxl={4}
                                                xl={3}
                                                sx={{
                                                    mb: 3
                                                }}
                                            >
                                                <CollectionThumbnailWithSide
                                                    collection={collection}
                                                    collectionIndex={index}
                                                    getMoreCollections={index % 8 === 0 && index > 0 ? getMoreCollections : null}
                                                    onView={() => setSegment((prev) => prev + 1)}
                                                />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                            {
                                collections.length === 0 ?
                                    <Grid
                                        container
                                    >
                                        <Grid
                                            item
                                            xs={3}
                                            sx={{
                                                textAlign: 'right'
                                            }}
                                        >
                                            <img
                                                src='https://imagedelivery.net/4jZu4HPxc6tlbuYZlGIsgQ/53b10a80-b488-4a63-960f-a191b58fd600/collectionpagethumb'
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs
                                        >
                                            <Grid
                                                container
                                                direction='column'
                                                sx={{
                                                    height: '100%'
                                                }}
                                            >
                                                <Grid item xs={5}></Grid>
                                                <Grid item xs={3}>
                                                    <Typography
                                                        variant='h4'
                                                        sx={{
                                                            fontFamily: 'Open Sans',
                                                            color: colors.gray
                                                        }}
                                                    >
                                                        You currently have no {selectedFilter}.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid> : null
                            }
                        </Grid> :
                        <Box
                            sx={{
                                mt: 8,
                                mx: 'auto'
                            }}
                        >
                            {
                                queue.map((item) => {
                                    return (
                                        <Fragment
                                            key={item.itemId}
                                        >
                                            <Typography
                                                onClick={() => {
                                                    navigate(`/collection?collectionId=${item.collectionId}`)
                                                }}
                                                variant='h4'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    mb: 2,
                                                    '&:hover': {
                                                        color: theme.palette.primary.main,
                                                        cursor: 'pointer'
                                                    }
                                                }}
                                            >
                                                {item.collectionTitle}
                                            </Typography>
                                            <ItemApproval
                                                key={item.itemId}
                                                item={item}
                                                showPendingItems={getQueue}
                                            />
                                        </Fragment>
                                    )
                                })
                            }
                        </Box>

                }
            </Grid>

            {/* More User Menu */}
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transitionDuration={0}
                sx={{
                    ml: -2,
                    p: 1,
                    pr: 3
                }}
            >
                <MenuItem
                    onClick={() => {
                        navigator.clipboard.writeText(window.location)
                        setShowSuccess(true)
                        handleClose()
                    }}
                    disableRipple
                    sx={{
                        ...menuItemTextStyle
                    }}
                >
                    Share Profile
                </MenuItem>
                {/* {
                    !isUserSelf ?
                        <MenuItem
                            onClick={handleClose}
                            disableRipple
                            sx={{
                                ...menuItemTextStyle
                            }}
                        >
                            Report Or Block User
                        </MenuItem> : null
                } */}
            </Menu>

            <BasicAlert
                showAlert={showComingSoon}
                setShowAlert={setShowComingSoon}
                alertText='Coming soon!'
            />

            <BasicAlert
                showAlert={showSuccess}
                setShowAlert={setShowSuccess}
                alertText='Profile link copied to clipboard!'
                severity="info"
                sx={{
                    position: "absolute",
                    bottom: -75
                }}
            />
        </Fragment>
    )
}