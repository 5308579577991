import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getSearchedCollections } from "../../store/collection/collectionSlice";
import { getUserData } from "../../store/user/userSlice";


export default function useSearchCollectionsFromParam() {
    const { userId } = useSelector(getUserData);

    const [searchParams] = useSearchParams();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSearchedCollections({
            searchString: searchParams.get("search"),
            userId
        }));
    }, [searchParams.get("search")]);

    return !!searchParams.get("search");
}