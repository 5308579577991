import { TextField } from "@mui/material";
import BaseModal from "../../../Common/BaseModal/BaseModal";

export default function SearchCollectionsMobileModal(props) {
    const {
        openMobileModal,
        setOpenMobileModal,
        search,
        searchString,
        setSearchString
    } = props;

    return (
        <BaseModal
            open={openMobileModal}
            onClose={() => setOpenMobileModal(false)}
            sx={{
                height: 50,
                textAlign: "center",
                top: 80
            }}
        >
            <TextField
                variant='standard'
                placeholder={"What are you looking for?"}
                value={searchString}
                onInput={(e) => setSearchString(e.target.value)}
                onKeyDown={(e) => {
                    if (e.code === "Enter") {
                        search();
                    }
                }}
                sx={{
                    width: 200,
                    mt: 0.5
                }}
            />
        </BaseModal>
    );
}