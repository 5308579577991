import {
    useSelector
} from "react-redux";
import axios from "axios";

import {
    getUserId
} from "../../store/user/userSlice";

import apiRoutes from "../../constants/api-routes";

export default function useRecordItemView() {
    const userId = useSelector(getUserId);

    const recordItemView = (itemId) => {
        axios.post(apiRoutes.addItemView, {
            itemId,
            userId
        });
    };

    return recordItemView;
}