import {
    Box
} from "@mui/material";
import {
    useSelector
} from "react-redux";

import {
    getUserData
} from "../../../store/user/userSlice";
import {
    getCollections
} from "../../../store/collection/collectionSlice";
import FeedButtons from "../../Common/FeedButtons/FeedButtons";

import useActiveFeed from "../../../hooks/state/useActiveFeed";
import Navbar from "../../Navbar/Navbar";
import CollectionPreview from "./CollectionPreview/CollectionPreview";
import useSearchCollectionsFromParam from "../../../hooks/data/useSearchCollectionsFromParam";
import NoResults from "./NoResults/NoResults";

export default function BrowseCollectionsMobileBody() {
    const {
        userId
    } = useSelector(getUserData);
    const collections = useSelector(getCollections);

    const isSearch = useSearchCollectionsFromParam();
    const {
        activeFeed,
        setActiveFeed,
        incrementSegment
    } = useActiveFeed(userId, false, isSearch);

    return (
        <Box>
            <Navbar
                isMobile
            />
            <Box
                sx={{
                    mt: 6
                }}
            >
                <FeedButtons
                    isMobile
                    activeFeed={activeFeed}
                    setActiveFeed={setActiveFeed}
                    userId={userId}
                />
            </Box>
            <Box
                sx={{
                    mt: 3
                }}
            >
                {
                    collections.length === 0 ?
                        <NoResults
                            isMobile
                        /> : null
                }
                {
                    collections.map((collection, ind) => {
                        return (
                            <CollectionPreview
                                key={collection.collectionId}
                                collection={collection}
                                onInView={() => {
                                    if (ind % 8 === 0 && ind > 0) {
                                        incrementSegment();
                                    }
                                }}
                            />
                        );
                    })
                }
            </Box>
        </Box>
    );
}