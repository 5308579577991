import {
    Box
} from "@mui/material";
import ReactPlayer from "react-player";

import { cfVideoRoot } from "../../../../../constants/api-routes";


export default function UploadedVideoItemBody(props) {
    const {
        videoUrl
    } = props;

    return (
        <Box
            sx={{
                height: 400
            }}
        >
            <ReactPlayer
                url={`${cfVideoRoot}/${videoUrl}/manifest/video.m3u8`}
                light
                pip
                controls={true}
                width={600}
                height={400}
                style={{
                    borderRadius: "16px",
                    overflow: "hidden"
                }}
            />
        </Box>
    );
}