import {
    Grid,
    Typography,
    Box,
    List,
    ListItem,
    ListItemIcon,
    Icon
} from '@mui/material'
import { Fragment, useRef, useEffect } from 'react'
import colors from '../../constants/colors'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined'
import Share from '../../assets/icons/share.svg'
import { useNavigate } from 'react-router-dom'
import {
    cfImageRoot
} from '../../constants/api-routes'

export default function CollectionThumbnailWithSide(props) {
    const navigate = useNavigate()
    const ref = useRef()

    const barIcons = [
        [<VisibilityOutlinedIcon key='views' />, props.collection.totalItemViews],
        [<FavoriteBorderOutlinedIcon key='likes' />, props.collection.totalItemLikes],
        [<CommentOutlinedIcon key='comments' />, props.collection.totalItemComments],
        [<Icon key='shares'><img src={Share} /></Icon>, props.collection.totalItemShares]
    ]

    // this is a temporary solution until curators can pick the cover photo
    const item = props.collection.items[0]
    if (item && item.image) {
        var thumbnailUrl = `${cfImageRoot}/${item.image}/itempreviewthumb`
    } else {
        // if there is no picture available
        thumbnailUrl = 'https://imagedelivery.net/4jZu4HPxc6tlbuYZlGIsgQ/9d71d3f3-a5b2-4f57-dba8-3d74f3e9fc00/collectionpagethumb'
    }

    useEffect(() => {
        if (props.getMoreCollections) {
            const observer = props.getMoreCollections(ref, props.onView)
            return () => { observer.disconnect() }
        }
    }, [])

    return (
        <Box
            ref={ref}
            onClick={() => {
                navigate(`/collection?collectionId=${props.collection.collectionId}`, { state: { collectionIndex: props.collectionIndex } })
            }}
            sx={{
                width: {
                    xs: 400,
                    sm: 500
                }
            }}
        >
            <Grid
                container
            >
                <Grid
                    item
                    xs={9}
                    sx={{
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}
                >
                    <Box
                        sx={{
                            mt: 2,
                            height: 400,
                            backgroundImage: `url(${thumbnailUrl})`,
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '8px 0 0 8px',
                            ml: 2
                        }}
                    >
                        <Typography
                            variant='h5'
                            sx={{
                                m: 'auto',
                                width: '60%',
                                textAlign: 'center',
                                fontFamily: 'Open Sans',
                                color: colors.white,
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                borderRadius: 2,
                                p: 3
                            }}
                        >
                            {props.collection.collectionTitle}
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={1.5}
                    sx={{
                        mt: 2,
                        pt: 1,
                        backgroundColor: colors.white,
                        border: `${colors.lightGray} solid 1px`,
                        borderRadius: '0 8px 8px 0',
                        textAlign: 'center'
                    }}
                >
                    <List
                        sx={{
                            width: '100%'
                        }}
                    >
                        {
                            barIcons.map((icon, index) => {
                                return (
                                    <Fragment key={index}>
                                        <ListItem
                                            sx={{
                                                my: 4,
                                                p: 0,
                                                width: '100%',
                                                display: 'block',
                                                textAlign: 'center'
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    width: '100%',
                                                    textAlign: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                {icon[0]}
                                            </ListItemIcon>
                                            <Typography
                                                variant='caption'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    width: '100%',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {icon[1]}
                                            </Typography>
                                        </ListItem>
                                    </Fragment>
                                )
                            })
                        }
                    </List>
                </Grid>
            </Grid>
        </Box>
    )
}