export default function useIntersection() {
    const setupObserver = (ref, callback) => {
        const observer = new IntersectionObserver(handleObserver);
        function handleObserver([entry]) {
            if (entry.isIntersecting) {
                callback();
                observer.unobserve(entry.target);
            }
        }

        observer.observe(ref.current);
        return observer;
    };

    return {
        setupObserver
    };
}