import {
    Box,
    Link,
    Typography,
    useTheme
} from "@mui/material";

export default function MobileSignUpCTA(props) {
    const {
        close,
        openSignUp
    } = props;

    const theme = useTheme();

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.grey[300],
                p: 1,
                borderRadius: 2,
                mt: 2,
                width: "85%",
            }}
        >
            <Typography
                variant='body2'
                sx={{
                    mt: 1,
                    mb: 2,
                }}>
                Don&apos;t have an account yet?
            </Typography>
            <Link
                onClick={() => {
                    close();
                    openSignUp();
                }}
                sx={{
                    color: theme.palette.primary.main,
                    textDecoration: "underline",
                    fontSize: 12,
                    fontFamily: theme.typography.fontFamily,
                    "&:hover": {
                        cursor: "pointer",
                        color: theme.palette.secondary.main
                    }
                }}
            >
                Get your Slyke account
            </Link>
        </Box>
    );
}