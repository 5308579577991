import axios from 'axios'
import apiRoutes from '../constants/api-routes'

export default async function deleteFollow(userId, collectionId, token) {
    if (!userId) {
        alert('please login to unfollow this collection')
        throw 'user not logged in'
    } else if (!collectionId) {
        alert('unexpected error, collection ID is null')
        throw 'collectionId is null'
    } else {
        return axios.post(apiRoutes.deleteCollectionFollow, {
            userId,
            collectionId
        }, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        })
    }
}