import { Box } from "@mui/material";
import { useState } from "react";

import PasswordTextField from "../../PasswordTextField/PasswordTextField";
import UsernameTextField from "./UsernameTextField/UsernameTextField";
import StayLoggedInCheckbox from "./StayLoggedInCheckbox/StayLoggedInCheckbox";
import PrimaryButton from "../../../PrimaryButton/PrimaryButton";
import MobileSignUpCTA from "./MobileSignUpCTA/MobileSignUpCTA";
import Error from "../../../Error/Error";

import useHandleLogin from "../../../../../hooks/data/useHandleLogin";
import useLoadingModal from "../../../../../hooks/state/useLoadingModal";

export default function FormBody(props) {
    /* 
    
    This is the main form body of the sign in modal.

    */

    const {
        isMobile,
        openForgotPassword,
        onCloseSignIn,
        openForcePasswordReset,
        openSignUp
    } = props;

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [stayLoggedIn, setStayLoggedIn] = useState(false);
    const [error, setError] = useState(false);

    const handleLogin = useHandleLogin();
    const {
        loading,
        setLoading,
        LoadingModal
    } = useLoadingModal();

    const onError = () => {
        setError(true);
        setLoading(false);
    };

    const onForgotPassword = () => {
        openForcePasswordReset();
        setLoading(false);
    };

    const onSuccess = () => {
        onCloseSignIn();
        setLoading(false);
    };

    return (
        <Box>
            <UsernameTextField
                isMobile={isMobile}
                username={username}
                setUsername={setUsername}
            />
            <PasswordTextField
                isMobile={isMobile}
                password={password}
                setPassword={setPassword}
                openForgotPassword={openForgotPassword}
            />
            <StayLoggedInCheckbox
                isMobile={isMobile}
                stayLoggedIn={stayLoggedIn}
                setStayLoggedIn={setStayLoggedIn}
            />
            {
                error ?
                    <Error
                        isMobile={isMobile}
                        text="Unable to sign in."
                    /> : null
            }
            <PrimaryButton
                onClick={() => {
                    setLoading(true);
                    handleLogin(
                        {
                            username,
                            password,
                            stayLoggedIn
                        },
                        onError,
                        () => { },
                        onForgotPassword,
                        onSuccess
                    );
                }}
                sx={{
                    width: !isMobile ? 300 : "90%",
                    my: !isMobile ? 2 : 0
                }}
                text="Sign In"
            />
            {
                isMobile ?
                    <MobileSignUpCTA
                        close={() => onCloseSignIn(false)}
                        openSignUp={openSignUp}
                    /> : null
            }

            <LoadingModal
                open={loading}
                onClose={() => setLoading(false)}
            />
        </Box>
    );
}