import {
    IconButton
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

export default function Hamburger(props) {
    const {
        isSidebarShowing,
        setIsSidebarShowing,
        isMobile,
        sx
    } = props;

    return (
        <IconButton
            onClick={() => setIsSidebarShowing(!isSidebarShowing)}
            color="secondary"
            sx={{
                px: isMobile ? 0 : null,
                ...sx
            }}
        >
            {
                isSidebarShowing ? <CloseIcon /> : <MenuIcon />
            }
        </IconButton>
    );
}