import {
    useState
} from "react";

import CollectionCreditsModal from "../../legacy-components/collection-credits-modal/collection-credits-modal";

export default function useCollectionCreditsModal() {
    const [isCollectionCreditsModalOpen, setIsCollectionCreditsModalOpen] = useState(false);

    const openCollectionCreditsModal = () => {
        setIsCollectionCreditsModalOpen(true);
    };

    const closeCollectionCreditsModal = () => {
        setIsCollectionCreditsModalOpen(false);
    };

    return {
        isCollectionCreditsModalOpen,
        openCollectionCreditsModal,
        closeCollectionCreditsModal,
        CollectionCreditsModal
    };
}