import { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    useSearchParams
} from 'react-router-dom'
import {
    Grid,
    Stack,
    Typography,
    useTheme
} from '@mui/material'
import {
    getCollections,
    getTrendingCollections,
    getPopularCollections,
    getRandomCollections,
    getRecentlyAddedCollections,
    getForYouCollections,
    getSearchedCollections
} from '../../store/collection/collectionSlice'
import {
    getUserId,
    getIsLoggedIn
} from '../../store/user/userSlice'
import SidebarFilterButtons from '../../legacy-components/sidebar-filter-buttons/sidebar-filter-buttons'
import CollectionPreview from '../../legacy-components/collection-preview/collection-preview'
import AccountModalHandler from '../../legacy-components/account-modals/account-modal'
import useMediaQuery from '@mui/material/useMediaQuery'
import Navbar from '../../components/Navbar/Navbar'
import PrimaryButton from '../../components/Common/PrimaryButton/PrimaryButton'
import useCreateCollectionModal from '../../hooks/state/useCreateCollectionModal'
import useAddItemModal from '../../hooks/state/useAddItemModal'

export default function BrowseCollections() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const [searchParams, setSearchParams] = useSearchParams()
    const collections = useSelector(getCollections)
    const [selectedFilter, setSelectedFilter] = useState(
        searchParams.get('search') ? '' :
            searchParams.get('filter') ? searchParams.get('filter') : 'trending')
    const [segment, setSegment] = useState(1)
    const [showSignUp, setShowSignUp] = useState(false)
    const userId = useSelector(getUserId)
    const isLoggedIn = useSelector(getIsLoggedIn)

    const {
        isCreateCollectionModalOpen,
        openCreateCollectionModal,
        closeCreateCollectionModal,
        CreateCollectionModal
    } = useCreateCollectionModal();
    const {
        isAddItemModalOpen,
        openAddItemModal,
        closeAddItemModal,
        AddItemModal
    } = useAddItemModal();

    const isMedium = useMediaQuery(theme.breakpoints.up('mdlg'), { noSsr: true })
    const isMediumLarge = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true })
    const isLargeXl = useMediaQuery(theme.breakpoints.up('lgxl'), { noSsr: true })
    const isXl = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true })

    const filterToDispatchMap = {
        trending: getTrendingCollections,
        'most popular': getPopularCollections,
        random: getRandomCollections,
        'recently added': getRecentlyAddedCollections,
        'for you': getForYouCollections
    }

    const getMoreCollections = (ref) => {
        const observer = new IntersectionObserver(handleObserver)
        function handleObserver([entry]) {
            if (entry.isIntersecting) {
                setSegment((prev) => prev + 1)
                observer.unobserve(entry.target)
            }
        }

        observer.observe(ref.current)
        return observer
    }

    const handleFilterSwitch = (activeFilter) => {
        setSelectedFilter(activeFilter)
        setSegment(1)
    }

    useEffect(() => {
        // guard against duplicate requests on page load
        if (segment !== 1) {
            if (filterToDispatchMap[selectedFilter]) {
                dispatch(filterToDispatchMap[selectedFilter]({ userId, segment }))
            }
        }
    }, [segment])

    useEffect(() => {
        if (selectedFilter === 'for you' && !isLoggedIn) {
            setShowSignUp(true)
        }
        else if (filterToDispatchMap[selectedFilter]) {
            // setSearchParams({})
            dispatch(filterToDispatchMap[selectedFilter]({ userId, segment, overwrite: true }))
        }
    }, [selectedFilter])

    useEffect(() => {
        if (filterToDispatchMap[selectedFilter]) {
            dispatch(filterToDispatchMap[selectedFilter]({ userId, segment, overwrite: true }))
        }
    }, [userId])

    useEffect(() => {
        dispatch(getSearchedCollections({
            searchString: searchParams.get('search'),
            userId
        }))
    }, [searchParams.get('search')])

    return (
        <Fragment>
            <Navbar
                search
            />

            <Grid
                container
                sx={{
                    mt: 8
                }}
            >
                {/* Filters */}
                <Grid
                    item
                    xs={3}
                    sx={{
                        px: 2,
                        height: '100vh',
                        overflowY: 'hidden',
                        mt: 4,
                        position: 'fixed',
                        ml: isXl ? 24 :
                            isLargeXl ? 4 :
                                isMedium ? -4 : 0
                    }}
                >
                    <SidebarFilterButtons
                        filters={[
                            'trending',
                            'most popular',
                            'for you',
                            'recently added',
                            'random'
                        ]}
                        defaultFilter={
                            searchParams.get('search') ? '' :
                                searchParams.get('filter') ? searchParams.get('filter') : 'trending'
                        }
                        handleFilter={handleFilterSwitch}
                    />
                </Grid>

                {/* Scroll Area */}
                <Grid xl={3} lgxl={2} md={1.75} mdlg={1.35} />
                <Grid
                    item
                    xs
                >
                    {
                        collections.length > 0 ?
                            collections.map((collection, ind) => {
                                if (!collection.collectionTitle) {
                                    return null
                                }
                                if (ind % 8 === 0 && ind !== 0) {
                                    return (
                                        <CollectionPreview
                                            key={collection.collectionTitle}
                                            collection={collection}
                                            collectionIndex={ind}
                                            getMoreCollections={getMoreCollections}
                                        />
                                    )
                                } else {
                                    return (
                                        <CollectionPreview
                                            key={collection.collectionTitle}
                                            collection={collection}
                                            collectionIndex={ind}
                                        />
                                    )
                                }
                            }) :
                            <Stack
                                direction="row"
                            >
                                <Typography
                                    variant='h4'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        ml: 20,
                                        mt: 10
                                    }}
                                >
                                    No Matching Collections Found!
                                </Typography>
                                <PrimaryButton
                                    onClick={openCreateCollectionModal}
                                    text="Create A Collection"
                                    sx={{
                                        mt: 10.8,
                                        ml: 5
                                    }}
                                />
                            </Stack>
                    }
                </Grid>

                {/* Right Side Bar */}
                <Grid
                    xs={3}
                    sx={{
                        px: 2,
                        height: '100vh',
                        overflowY: 'hidden',
                        mt: 4,
                        position: 'fixed',
                        right: 0,
                        mr: 2
                    }}
                >
                </Grid>
            </Grid>

            <AccountModalHandler
                signUp={showSignUp}
                onCloseSignUp={() => {
                    setShowSignUp(false)
                }}
            />
            <AddItemModal
                open={isAddItemModalOpen}
                close={closeAddItemModal}
            />
            <CreateCollectionModal
                open={isCreateCollectionModalOpen}
                close={closeCreateCollectionModal}
                openAddItem={openAddItemModal}
            />
        </Fragment>
    )
}