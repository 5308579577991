import { Box, Stack, SvgIcon, Tooltip, Typography, tooltipClasses, useTheme } from "@mui/material";
import { Fragment } from "react";

export default function ItemsToReviewIcon(props) {
    const {
        pendingItemCount,
        isMobile
    } = props;

    const theme = useTheme();

    return (
        <Stack
            direction="row"
        >
            <Tooltip
                componentsProps={{
                    popper: {
                        sx: {
                            [`& .${tooltipClasses.arrow}`]: {
                                color: (theme) => theme.palette.common.black
                            },
                            [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) => theme.palette.common.black
                            }
                        }
                    }
                }}
                title={
                    <Fragment>
                        <Typography>
                            You have new item submissions to review!
                        </Typography>
                    </Fragment>
                }
            >
                <Box
                    onClick={() => {
                        // setEditState(true);
                        // setActiveEditState({
                        //     "Current": false,
                        //     "Pending": true,
                        //     "Rejected": false
                        // });
                        // showPendingItems();
                    }}
                    sx={{
                        display: "inherit",
                        "&:hover": {
                            cursor: "pointer"
                        }
                    }}
                >
                    {
                        !isMobile ?
                            <Typography
                                sx={{
                                    color: theme.palette.secondary.main,
                                    mt: !isMobile ? 0.5 : 0,
                                    mr: 0.5,
                                    ml: !isMobile ? null : 0.5,
                                    fontSize: !isMobile ? null : 14
                                }}
                            >
                                {pendingItemCount}
                            </Typography> : null
                    }
                    <SvgIcon
                        fontSize={!isMobile ? "large" : "medium"}
                        htmlColor='none'
                        viewBox='0 0 34 34'
                        sx={{
                            fill: "none",
                            mr: 3,
                            ml: isMobile ? 2 : null
                        }}
                    >
                        <path d="M29.1739 11.3226L33 15.4516L29.6184 17.172M29.1739 11.3226L17 17.172M29.1739 11.3226L17 4.78495M29.1739 11.3226L32.3043 7.53763L20.4783 1L17 4.78495M17 17.172L20.8261 21.6452L29.6184 17.172M17 17.172L5.17391 10.6344M17 17.172L13.1739 21.6452L4.63285 17.172M17 17.172V33M5.17391 10.6344L17 4.78495M5.17391 10.6344L1 7.53763L13.1739 1L17 4.78495M5.17391 10.6344L1.34783 15.4516L4.63285 17.172M17 33L4.47826 25.7742L4.63285 17.172M17 33L29.5217 26.4624L29.6184 17.172" stroke="#335CFF" />
                    </SvgIcon>
                </Box>
            </Tooltip>
        </Stack>
    );
}