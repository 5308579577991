import { useEffect } from "react";
import { useState } from "react";

export default function useTextChunking(text, chunkSize) {
    /*
    
    This hook handles displaying text items based upon a chunk size.

    The incoming text is stripped of all unsafe HTML and broken into chunks for display.

    */

    const [chunks, setChunks] = useState([""]);
    const [currentChunkIndex, setCurrentChunkIndex] = useState(0);

    const chunkText = (text) => {
        const regex = /<(?!br\s*\/?>)([^>]+)>/ig;

        // Split the text into an array of words
        const words = text.replace(regex, " ").replace(/(&nbsp;)+/ig, " ").split(" ");

        // Use reduce() to create an array of chunks
        const chunks = words.reduce((result, word) => {
            // If the current chunk is empty or adding the current word would exceed the chunk size, start a new chunk
            if (result.length === 0 || result[result.length - 1].length + word.length + 1 > chunkSize) {
                result.push(word);
            } else if (result[result.length - 1].split("<br>").length > 8) {
                result.push(word);
            } else {  // Otherwise, add the current word to the current chunk
                result[result.length - 1] += " " + word;
            }
            return result;
        }, []);

        setChunks(chunks);
    };

    useEffect(() => {
        chunkText(text);
    }, []);

    return {
        chunks,
        currentChunkIndex,
        setCurrentChunkIndex
    };
}