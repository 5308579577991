import {
    useTheme
} from "@mui/material";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

export default function ContentCardAdornment(props) {
    const {
        isText,
        isVideo
    } = props;

    const theme = useTheme();

    if (isText) {
        return (
            <TextFieldsIcon
                sx={{
                    fontSize: 50,
                    color: theme.palette.common.white
                }}
            />
        );
    } else if (isVideo) {
        return (
            <PlayArrowIcon
                sx={{
                    fontSize: 50,
                    color: theme.palette.common.white,
                    border: `solid 3px ${theme.palette.common.white}`,
                    borderRadius: 8,
                    my: "auto"
                }}
            />
        );
    } else {
        return null;
    }
}