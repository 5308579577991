import {
    Box,
    Typography
} from "@mui/material";

export default function ModalHeader(props) {
    const {
        isMobile
    } = props;

    return (
        <Box>
            <Typography
                variant='h6'
                sx={{
                    fontFamily: "Open Sans",
                    borderBottom: "1px solid black",
                    width: 182,
                    fontWeight: "bold",
                    mb: 3
                }}
            >
                Sign Up
            </Typography>
        </Box>
    );
}