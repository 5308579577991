import {
    Fragment
} from "react";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import { Typography, useTheme } from "@mui/material";

export default function User(props) {
    const {
        isMobile
    } = props;

    const theme = useTheme();

    return (
        <Fragment>
            <MailOutlinedIcon
                sx={{
                    verticalAlign: "baseline",
                    color: theme.palette.grey["900"],
                    pt: !isMobile ? 2.8 : 0,
                    ml: !isMobile ? 3 : 0,
                    mt: !isMobile ? 0.5 : 3.25,
                    pr: !isMobile ? 0 : 1,
                    fontSize: 24
                }}
            />
            <Typography
                variant='body2'
                sx={{
                    display: "inline-flex",
                    fontFamily: "Open Sans",
                    verticalAlign: "super",
                    color: theme.palette.grey["900"],
                    ml: !isMobile ? 1 : 0,
                    mt: !isMobile ? 3.5 : 0
                }}
            >
                {
                    !isMobile ?
                        "Message Curator" :
                        "Message"
                }
            </Typography>
        </Fragment>
    );
}