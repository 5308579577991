import { Typography } from "@mui/material";

import BaseModal from "../../BaseModal/BaseModal";
import CloseButton from "../CloseButton/CloseButton";

export default function SignUpSuccess(props) {
    const {
        isMobile,
        signUpSuccess,
        setSignUpSuccess
    } = props;

    return (
        <BaseModal
            open={signUpSuccess}
            onClose={() => setSignUpSuccess(false)}
            sx={{
                overflowY: isMobile ? "scroll" : null,
                p: !isMobile ? 3 : 1
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    ml: !isMobile ? 4 : 2,
                    my: 4,
                    textAlign: "center"
                }}
            >
                You&apos;re almost finished!
            </Typography>
            <Typography
                variant="h6"
                sx={{
                    ml: !isMobile ? 4 : 2,
                    my: 1,
                    textAlign: "center"
                }}
            >
                A link to complete sign up has been sent to your email.
            </Typography>
            <Typography
                variant="body2"
                sx={{
                    ml: !isMobile ? 4 : 2,
                    my: 1,
                    mt: 6,
                    pb: 3,
                    textAlign: "center"
                }}
            >
                Email verification helps us keep Slyke safe from spam.
            </Typography>

            <CloseButton
                onClose={() => setSignUpSuccess(false)}
            />
        </BaseModal>
    );
}