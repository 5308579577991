import { useState } from "react";
import {
    Grid,
    IconButton,
    InputAdornment,
    Link,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function PasswordTextField(props) {
    const {
        isMobile,
        password,
        setPassword,
        openForgotPassword,
        text,
        setPasswordCallback
    } = props;

    const theme = useTheme();

    const [isVisible, setIsVisible] = useState(false);

    return (
        <Grid
            container
            direction='row'
        >
            <Grid
                item
                xs={7}
            >
                <Typography
                    variant='body1'
                    sx={{
                        fontWeight: "bold",
                        fontFamily: "Open Sans"
                    }}
                >
                    {text || "Password"}
                </Typography>
            </Grid>
            {
                openForgotPassword ?
                    <Grid
                        item
                        xs
                    >
                        <Link
                            onClick={openForgotPassword}
                            sx={{
                                fontSize: 12,
                                mr: 0,
                                ml: 2,
                                mt: 5 / 10,
                                color: theme.palette.secondary.main,
                                textDecoration: "none",
                                "&:hover": {
                                    cursor: "pointer",
                                    color: theme.palette.primary.main
                                },
                                fontFamily: "Open Sans"
                            }}
                        >
                            Forgot Password?
                        </Link>
                    </Grid> : null
            }
            <Grid
                item
                xs={12}
            >
                <TextField
                    variant='outlined'
                    type={isVisible ? "text" : "password"}
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                        if (setPasswordCallback) {
                            setPasswordCallback(e.target.value);
                        }
                    }}
                    name={"password"}
                    autoComplete={"password"}
                    sx={{
                        p: 0,
                        m: 0,
                        "& .MuiOutlinedInput-root": {
                            height: 35
                        },
                        mb: 2,
                        width: 300,
                        maxWidth: !isMobile ? null : "90%"
                    }}
                    InputProps={{ // <-- This is where the toggle button is added.
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setIsVisible(!isVisible)}
                                    onMouseDown={() => setIsVisible(!isVisible)}
                                >
                                    {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
        </Grid>
    );
}