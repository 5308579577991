import { Fragment } from "react";
import {
    Typography
} from "@mui/material";

import ReasonsToJoin from "./ReasonsToJoin/ReasonsToJoin";

import SecondaryButton from "../../../Common/SecondaryButton/SecondaryButton";
import PrimaryButton from "../../../Common/PrimaryButton/PrimaryButton";

import useLoginModal from "../../../../hooks/state/useLoginModal";

export default function LoggedOutBody(props) {
    const {
        isMobile
    } = props;

    const {
        isSignUpModalOpen,
        openSignUpModal,
        closeSignUpModal,
        isSignInModalOpen,
        openSignInModal,
        closeSignInModal,
        AccountModalHandler
    } = useLoginModal();

    return (
        <Fragment>
            <Typography
                variant='subtitle1'
                sx={{
                    fontSize: 16,
                    p: 1
                }}
            >
                You don&apos;t need an account to enjoy Slyke, but...
            </Typography>
            <Typography
                variant='h5'
                sx={{
                    fontWeight: "bold",
                    mt: 2,
                    ml: 1,
                    letterSpacing: -0.75
                }}
            >
                Get your account to:
            </Typography>
            <ReasonsToJoin />
            {
                !isMobile ?
                    null :
                    <PrimaryButton
                        onClick={openSignInModal}
                        borderRadius={0.5}
                        text="Sign In"
                        sx={{
                            my: 0.5,
                            width: "fit-content",
                            px: 5
                        }}
                    />
            }
            <SecondaryButton
                onClick={openSignUpModal}
                borderRadius={0.5}
                text="Join Slyke"
                sx={{
                    my: !isMobile ? 2 : 0.5,
                    width: "fit-content",
                    px: 5
                }}
            />
            <AccountModalHandler
                isMobile={isMobile}
                signUp={isSignUpModalOpen}
                signIn={isSignInModalOpen}
                onCloseSignUp={closeSignUpModal}
                onCloseSignIn={closeSignInModal}
            />
        </Fragment>
    );
}