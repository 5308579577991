import {
    Fragment,
    useEffect,
    useState
} from "react";

import SignIn from "./SignIn/SignIn";
import SignUp from "./SignUp/SignUp";
import SignUpSuccess from "./SignUpSuccess/SignUpSuccess";
import ChangePassword from "./ChangePassword/ChangePassword";
import ChangePasswordSuccess from "./ChangePasswordSuccess/ChangePasswordSuccess";
import ForgotPassword from "./ForgotPassword/ForgotPassword/ForgotPassword";
import ForgotPasswordInfo from "./ForgotPassword/ForgotPasswordInfo/ForgotPasswordInfo";
import ForgotPasswordInit from "./ForgotPassword/ForgotPasswordInit/ForgotPasswordInit";

export default function AccountHandler(props) {
    /* 
    
    This component handles all of the modals that handle the sign in and sign up processes.

    */

    const {
        isMobile,
        onCloseSignIn,
        onCloseSignUp,
        changePasswordCode,
        username,
        forgotPasswordCode,
        createCollection,
        addItem
    } = props;

    const [signIn, setSignIn] = useState(props.signIn || false);
    const [signUp, setSignUp] = useState(props.signUp || false);
    const [signUpSuccess, setSignUpSuccess] = useState(props.signUp || false);
    const [changePassword, setChangePassword] = useState(props.changePassword || false);
    const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(props.forgotPassword || false);
    const [forgotPasswordInit, setForgotPasswordInit] = useState(false);
    const [forgotPasswordInfo, setForgotPasswordInfo] = useState(false);

    // below use effects handle updating state on prop changes
    useEffect(() => {
        setSignIn(props.signIn || false);
    }, [props.signIn]);

    useEffect(() => {
        setSignUp(props.signUp || false);
    }, [props.signUp]);

    useEffect(() => {
        setChangePassword(props.changePassword || false);
    }, [props.changePassword]);

    useEffect(() => {
        setForgotPassword(props.forgotPassword || false);
    }, [props.forgotPassword]);

    return (
        <Fragment>
            <SignIn
                isMobile={isMobile}
                signIn={signIn}
                onCloseSignIn={() => {
                    if (onCloseSignIn) {
                        onCloseSignIn();
                    }
                    setSignIn(false);
                }}
                openForgotPassword={() => {
                    setForgotPasswordInit(true);
                    if (onCloseSignIn) {
                        onCloseSignIn();
                    }
                    setSignIn(false);
                }}
                openForcePasswordReset={() => {
                    setForgotPasswordInfo(true);
                    if (onCloseSignIn) {
                        onCloseSignIn();
                    }
                    setSignIn(false);
                }}
                openSignUp={() => setSignUp(true)}
            />

            <SignUp
                isMobile={isMobile}
                signUp={signUp}
                onCloseSignUp={() => {
                    if (onCloseSignUp) {
                        onCloseSignUp();
                    }
                    setSignUp(false);
                }}
                openSignIn={() => setSignIn(true)}
                openSignUpSuccess={() => setSignUpSuccess(true)}
            />
            <SignUpSuccess
                isMobile={isMobile}
                signUpSuccess={signUpSuccess}
                setSignUpSuccess={setSignUpSuccess}
            />

            <ChangePassword
                isMobile={isMobile}
                changePassword={changePassword}
                changePasswordCode={changePasswordCode}
                changePasswordUsername={username}
                setChangePassword={setChangePassword}
                setChangePasswordSuccess={setChangePasswordSuccess}
            />
            <ChangePasswordSuccess
                isMobile={isMobile}
                changePasswordSuccess={changePasswordSuccess}
                setChangePasswordSuccess={setChangePasswordSuccess}
                createCollection={createCollection}
                addItem={addItem}
            />

            <ForgotPassword
                isMobile={isMobile}
                forgotPassword={forgotPassword}
                close={() => setForgotPassword(false)}
                forgotPasswordCode={forgotPasswordCode}
            />
            <ForgotPasswordInit
                isMobile={isMobile}
                forgotPasswordInit={forgotPasswordInit}
                setForgotPasswordInfo={setForgotPasswordInfo}
                close={() => setForgotPasswordInit(false)}
            />
            <ForgotPasswordInfo
                isMobile={isMobile}
                forgotPasswordInfo={forgotPasswordInfo}
                close={() => setForgotPasswordInfo(false)}
            />
        </Fragment>
    );
}