import {
    ListItem,
    ListItemIcon,
    ListItemText,
    useTheme
} from "@mui/material";

export default function CTA(props) {
    const {
        onClick,
        hover,
        icon,
        text
    } = props;

    const theme = useTheme();

    return (
        <ListItem
            onClick={onClick}
            sx={{
                pl: 0,
                color: theme.palette.grey["800"],
                "&:hover": {
                    color: hover ? theme.palette.primary.main : null,
                    cursor: hover ? "pointer" : null
                }
            }}
        >
            <ListItemIcon
                sx={{
                    minWidth: "32px",
                    color: "inherit"
                }}
            >
                {icon}
            </ListItemIcon>
            <ListItemText
                sx={{
                    "& .MuiTypography-root": {

                    }
                }}
            >
                {text}
            </ListItemText>
        </ListItem>
    );
}