import {
    Grid,
    Typography,
    Button
} from "@mui/material";
import ReactGA from "react-ga4";
import BaseModal from "../Common/BaseModal/BaseModal";
import { useCookies } from "react-cookie";
import { useEffect } from "react";

export default function AddItemPopup(props) {
    const {
        isMobile,
        openAddItem,
        isOpen,
        close
    } = props;

    const [cookies, setCookie] = useCookies(["slyke-item-cta"]);
    const textStyle = {
        fontFamily: "Open Sans"
    };

    useEffect(() => {
        if (isOpen && !cookies.shown) {
            const TRACKING_ID = "G-CSY9YW7QWL";
            ReactGA.initialize(TRACKING_ID);
            ReactGA.event("add-item-cta-shown");

            setCookie("shown", true);
        }
    }, [isOpen]);

    return (
        <BaseModal
            open={cookies.shown ? false : isOpen}
            onClose={close}
            closeButton
        >
            <Grid
                container
                sx={{
                    px: !isMobile ? 6 : 2,
                    pb: !isMobile ? 6 : 4,
                    pt: !isMobile ? 4 : 2
                }}
            >
                <Grid
                    item
                    xs={12}
                    sx={{
                        textAlign: "center",
                        mt: 2,
                        mb: !isMobile ? 1 : 2
                    }}
                >
                    <Typography
                        variant={!isMobile ? "h5" : "h6"}
                        sx={{
                            ...textStyle,
                            fontWeight: "bold"
                        }}
                    >
                        Got something to add?
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        mb: !isMobile ? 1 : 2
                    }}
                >
                    <Typography
                        variant={!isMobile ? "h6" : "body1"}
                        sx={{
                            ...textStyle,
                            fontWeight: "bold",
                            textAlign: "center"
                        }}
                    >
                        Contribute an item!
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        mb: !isMobile ? 1 : 2
                    }}
                >
                    <Typography
                        variant={!isMobile ? "body1" : "body2"}
                        sx={{
                            ...textStyle,
                            px: !isMobile ? 0 : 3,
                            textAlign: "center",
                        }}
                    >
                        Slyke is the platform for content curation and crowdsourcing.
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        textAlign: "center",
                        mt: !isMobile ? 1 : 2
                    }}
                >
                    <Button
                        variant='contained'
                        onClick={() => {
                            openAddItem();
                            close();
                        }}
                        sx={{
                            color: "white",
                            fontFamily: "Open Sans",
                            fontSize: 16,
                            textTransform: "none",
                            px: 8
                        }}
                    >
                        Add an Item
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        mb: !isMobile ? 4 : 2,
                        textAlign: "center"
                    }}
                >
                    <Typography
                        variant={!isMobile ? "caption" : "caption"}
                        sx={{
                            ...textStyle,
                            fontStyle: "italic"
                        }}
                    >
                        No registration required to participate
                    </Typography>
                </Grid>
            </Grid>
        </BaseModal>
    );
}