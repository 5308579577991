import {
    useState
} from "react";

import CollectionDescriptionModal from "../../legacy-components/collection-description-modal/collection-description-modal";

export default function useCollectionDescriptionModal() {
    const [isCollectionDescriptionModalOpen, setIsCollectionDescriptionModalOpen] = useState(false);

    const openCollectionDescriptionModal = () => {
        setIsCollectionDescriptionModalOpen(true);
    };

    const closeCollectionDescriptionModal = () => {
        setIsCollectionDescriptionModalOpen(false);
    };

    return {
        isCollectionDescriptionModalOpen,
        openCollectionDescriptionModal,
        closeCollectionDescriptionModal,
        CollectionDescriptionModal
    };
}