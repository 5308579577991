import { useNavigate } from "react-router-dom";

export default function useNavigateToBrowseCollections() {
    const navigate = useNavigate();

    const navigateToAbout = () => {
        navigate("/browse-collections");
    };

    return navigateToAbout;
}