import {
    Fragment
} from "react";
import {
    Avatar,
    Button,
    useTheme
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function UserMenuButton(props) {
    const {
        displayName,
        setAnchor,
        setMenuOpen,
        isMobile
    } = props;

    const theme = useTheme();

    return (
        <Button
            onClick={(e) => {
                setMenuOpen(true);
                setAnchor(e.currentTarget);
            }}
            sx={{
                color: theme.palette.common.black,
                textTransform: "none",
                fontSize: 16,
                py: 0.5,
                px: 1,
                my: "auto",
                "&:hover": {
                    color: theme.palette.primary.main
                },
                "&:active": {
                    color: theme.palette.secondary.main
                }
            }}
            disableRipple
        >
            <Avatar
                sx={{
                    mr: 1,
                    backgroundColor: theme.palette.common.black
                }}
            >
                {displayName[0].toUpperCase()}
            </Avatar>
            {
                !isMobile ?
                    <Fragment>
                        {displayName}
                        <KeyboardArrowDownIcon />
                    </Fragment> : null
            }
        </Button>
    );
}