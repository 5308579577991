import { useState } from 'react'
import {
    Autocomplete,
    TextField,
    useTheme
} from '@mui/material'
import constants from '../../constants/constants'
import apiRoutes from '../../constants/api-routes'
import EditIcon from '@mui/icons-material/Edit'

import axios from 'axios'

const textStyle = {
    fontFamily: 'Open Sans'
}

export default function TagHandler(props) {
    const theme = useTheme()
    const [tags, setTags] = useState([])
    const [userTyping, setUserTyping] = useState('')

    const hasDuplicateTag = (n) => {
        const ids = new Set()

        for (const tag of n) {
            if (ids.has(tag.id)) {
                return true
            }

            ids.add(tag.id)
        }
    }

    const removeDuplicateTags = (n) => {
        let dedupedTags = []

        for (const tag of n) {
            let dupe = props.tagChoices.filter(searchTag => searchTag.id === tag.id)
            if (dupe.length === 0) {
                dedupedTags.push(tag)
            }
        }

        return dedupedTags
    }

    const updateTagList = (searchString) => {
        axios.get(apiRoutes.searchTags, {
            params: {
                searchString,
                limit: 10
            }
        }).then((res) => {
            const dedupedTags = removeDuplicateTags(res.data.matchingTags)
            setTags(dedupedTags)
        })
    }

    const handleTagUpdate = (n) => {
        if (n.length <= constants.maxTagLength) {
            if (hasDuplicateTag(n)) {
                console.log("repeats")
                return
            }

            let filteredTags = tags
            n.forEach((pickedTag) => {
                filteredTags = filteredTags.filter((tag) => tag.tag !== pickedTag.tag)
            })
            setTags(filteredTags)
            props.setTagChoices(n)
        }
    }

    return (
        <Autocomplete
            sx={{
                mt: 1,
                border: props.error ? 'red 1px solid' : 'none',
                borderRadius: 0,
                '& .MuiAutocomplete-popupIndicatorOpen': {
                    transform: 'none'
                },
                '&::after': {
                    border: 'none'
                },
                '&::before': {
                    border: 'none'
                }
            }}
            popupIcon={<EditIcon />}
            multiple
            options={tags}
            value={props.tagChoices}
            onChange={(e, n) => {
                handleTagUpdate(n)
            }}
            onKeyDown={(e, n) => {
                if (e.key === 'Enter') {
                    if (!tags.includes(userTyping)) {
                        axios.post(apiRoutes.addTag, {
                            tag: userTyping
                        }).then(() => {
                            axios.get(apiRoutes.searchTags, {
                                params: {
                                    searchString: userTyping,
                                    limit: 10
                                }
                            }).then((res) => {
                                // this is a hack... sort tags by ID so we take the most recently added
                                // this allows us to *probably* get the newly added tag
                                // search needs to be fixed to have exact matches return first
                                res.data.matchingTags.sort((a, b) => b.id - a.id)
                                props.setTagChoices([...props.tagChoices, res.data.matchingTags[0]])
                            })
                        })
                    }
                }
            }}
            getOptionLabel={(option) => option.tag}
            renderInput={(params) => (
                <TextField
                    {...params}
                    onChange={(e) => {
                        setUserTyping(e.target.value)
                        updateTagList(e.target.value)
                    }}
                    placeholder={props.placeholderText}
                    sx={{
                        '& .MuiAutocomplete-tag': {
                            backgroundColor: theme.palette.grey['300'],
                            color: theme.palette.grey['600'],
                            letterSpacing: '0.5px',
                            fontSize: '16px',
                            borderRadius: 4,
                            p: 1
                        },
                        '& .MuiChip-label': {
                            ...textStyle,
                            p: 2
                        },
                        '& .MuiInput-input': {
                            ...textStyle,
                            ml: 1
                        }
                    }}
                />
            )}
        />
    )
}