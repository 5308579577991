import { useState } from "react";
import {
    Box
} from "@mui/material";
import {
    useSelector
} from "react-redux";

import Navbar from "../../Navbar/Navbar";
import UserAvatar from "./UserAvatar/UserAvatar";
import UserDisplayName from "./UserDisplayName/UserDisplayName";
import UserStats from "./UserStats/UserStats";
import UserInteractionButtons from "./UserInteractionButtons/UserInteractionButtons";
import UserFeed from "./UserFeed/UserFeed";
import BasicAlert from "../../Common/BasicAlert/BasicAlert";

import {
    getCollections
} from "../../../store/collection/collectionSlice";

import useGetUserFromParam from "../../../hooks/data/useGetUserFromParam";
import useEngagementMetrics from "../../../hooks/data/useEngagementMetrics";


export default function UserProfileMobileBody() {
    const collections = useSelector(getCollections);

    const {
        userId,
        displayName,
        handle,
        userItemsPosted,
        userCollectionsFollowed
    } = useGetUserFromParam();
    const convertEngagementMetricToString = useEngagementMetrics();

    const [showAlert, setShowAlert] = useState();

    const getInitials = () => {
        if (displayName) {
            let splitDisplayName = displayName.split(" ");
            if (splitDisplayName.length > 1) {
                return splitDisplayName[0][0].toUpperCase() + splitDisplayName[1][0].toUpperCase();
            } else {
                return splitDisplayName[0][0].toUpperCase();
            }
        }
    };

    return (
        <Box>
            <Navbar />
            <Box
                sx={{
                    mt: 10
                }}
            >
                <UserAvatar
                    avatarLetters={getInitials()}
                />
                <UserDisplayName
                    displayName={displayName}
                    handle={handle}
                />
                <UserStats
                    followingCount={convertEngagementMetricToString(userCollectionsFollowed)}
                    itemsPostedCount={convertEngagementMetricToString(userItemsPosted)}
                />
                <UserInteractionButtons
                    setShowAlert={setShowAlert}
                />
                <UserFeed
                    collections={collections}
                    profileUserId={userId}
                />

                <BasicAlert
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                    alertText='Coming Soon!'
                />
            </Box>
        </Box>
    );
}