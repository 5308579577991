import {
    Typography,
    Box
} from '@mui/material'
import colors from '../../constants/colors'
import CircleIcon from '@mui/icons-material/Circle'

export default function UserEngagementBox(props) {
    return (
        <Box
            sx={{
                border: `${colors.lightGray} solid 1px`,
                borderRadius: 1,
                p: 1
            }}
        >
            <Typography
                variant='body2'
                sx={{
                    fontFamily: 'Open Sans',
                    display: 'inline-flex'
                }}
            >
                Following {props.collectionsFollowed} Collections
            </Typography>
            <CircleIcon
                sx={{
                    fontSize: 6,
                    mx: 1,
                    color: colors.darkGray,
                    verticalAlign: 'middle'
                }}
            />
            <Typography
                variant='body2'
                sx={{
                    fontFamily: 'Open Sans',
                    display: 'inline-flex'
                }}
            >
                Posted {props.itemsPosted} Items
            </Typography>
            <CircleIcon
                sx={{
                    fontSize: 6,
                    mx: 1,
                    color: colors.darkGray,
                    verticalAlign: 'middle'
                }}
            />
            <Typography
                variant='body2'
                sx={{
                    fontFamily: 'Open Sans',
                    display: 'inline-flex'
                }}
            >
                Liked {props.itemsLiked} Items
            </Typography>
        </Box>
    )
}