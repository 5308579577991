import {
    Box, IconButton, useTheme
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import PrimaryButton from "../../../Common/PrimaryButton/PrimaryButton";

export default function UserInteractionButtons(props) {
    const {
        setShowAlert
    } = props;

    const theme = useTheme();

    return (
        <Box
            textAlign="center"
        >
            <PrimaryButton
                onClick={() => setShowAlert(true)}
                text="Message"
                isMobile
                sx={{
                    px: 2
                }}
            />
            <IconButton
                onClick={() => setShowAlert(true)}
                sx={{
                    border: `${theme.palette.grey["500"]} solid 1px`,
                    borderRadius: 2,
                    p: 0,
                    py: 0.75,
                    px: 1.5,
                    ml: 2
                }}
            >
                <MoreHorizIcon />
            </IconButton>
        </Box>
    );
}