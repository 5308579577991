import {
    Box,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import {
    useState,
    useEffect
} from "react";
import {
    useRef
} from "react";

import ContentCard from "../../../Common/ContentCard/ContentCard";
import FollowButton from "../../../Common/FollowButton/FollowButton";

import useGetImageLink from "../../../../hooks/data/useGetImageLink";
import useCardBreakpoints from "../../../../hooks/media/useCardBreakpoints";
import useNavigateToCollection from "../../../../hooks/navigation/useNavigateToCollection";
import useHandleFollow from "../../../../hooks/data/useHandleFollow";
import useLoginModal from "../../../../hooks/state/useLoginModal";
import useNavigateToItem from "../../../../hooks/navigation/useNavigateToItem";

export default function CollectionPreview(props) {
    const {
        collection,
        isMobile,
        incrementSegment,
        setupObserver,
        needsObserver,
        refreshCollection
    } = props;

    const theme = useTheme();
    const getImageLink = useGetImageLink();
    const navigateToCollection = useNavigateToCollection();
    const navigateToItem = useNavigateToItem();
    const handleFollow = useHandleFollow();
    const {
        isSignUpModalOpen,
        openSignUpModal,
        closeSignUpModal,
        AccountModalHandler
    } = useLoginModal();

    const ref = useRef();

    const [firstItemIndex, setFirstItemIndex] = useState(0);
    const [lastItemIndex, setLastItemIndex] = useState(0);
    const [forceRender, setForceRender] = useState(0);

    const {
        threeCards,
        twoCards,
        oneCard
    } = useCardBreakpoints(theme);

    const rightButtonOnClick = () => {
        setFirstItemIndex(firstItemIndex + 1);
        setLastItemIndex(lastItemIndex + 1);
    };

    const leftButtonOnClick = () => {
        setFirstItemIndex(firstItemIndex - 1);
        setLastItemIndex(lastItemIndex - 1);
    };

    useEffect(() => {
        window.addEventListener("resize", () => setForceRender(forceRender + 1));
        return () => {
            window.removeEventListener("resize", () => setForceRender(forceRender + 1));
        };
    }, []);

    useEffect(() => {
        if (needsObserver) {
            setupObserver(ref, () => incrementSegment());
        }
    }, [collection]);

    useEffect(() => {
        if (oneCard) {
            setLastItemIndex(0);
        } else if (twoCards) {
            setLastItemIndex(1);
        } else if (threeCards) {
            setLastItemIndex(2);
        } else {
            setLastItemIndex(3);
        }
    }, []);

    const collectionItemsSlice = collection.items.slice(firstItemIndex, lastItemIndex + 1);

    return (
        <Box
            ref={ref}
            sx={{
                px: !isMobile ? 2 : 1,
                my: 3
            }}
        >
            <Stack
                direction="row"
                sx={{
                    justifyContent: !isMobile ? null : "center"
                }}
            >
                <Typography
                    onClick={() => navigateToCollection(collection.collectionId)}
                    variant={!isMobile ? "h4" : "body1"}
                    sx={{
                        fontWeight: "bold",
                        pl: !isMobile ? 1 : 0,
                        mt: 2,
                        mb: 1,
                        "&:hover": {
                            cursor: "pointer",
                            color: theme.palette.primary.main
                        },
                        "&:active": {
                            cursor: "pointer",
                            color: theme.palette.secondary.main
                        },
                        width: isMobile ? "190px" : "fit-content",
                        display: "inline-block"
                    }}
                >
                    {collection.collectionTitle}
                </Typography>
                <FollowButton
                    isFollowing={collection.isFollowing}
                    collectionId={collection.collectionId}
                    handleFollow={handleFollow}
                    openSignUpModal={openSignUpModal}
                    refreshCollection={refreshCollection}
                    isMobile={isMobile}
                />
            </Stack>
            <Stack
                direction="row"
                sx={{
                    justifyContent: !isMobile ? null : "center"
                }}
            >
                {
                    collectionItemsSlice.map((item, ind) => {
                        return (
                            <ContentCard
                                key={`item-preview-${item.title}-${ind}`}
                                itemType={item.itemType}
                                isVideo={!!item.ogVideoLink}
                                title={item.title}
                                collectionId={collection.collectionId}
                                itemIndex={ind}
                                imageURL={getImageLink({
                                    image: item.image,
                                    variant: "itempreviewthumb",
                                    itemType: item.itemType,
                                    ogVideoLink: item.ogVideoLink,
                                    height: 400,
                                    width: 400
                                })}
                                leftButton={ind === 0 && firstItemIndex !== 0}
                                leftButtonOnClick={leftButtonOnClick}
                                rightButtonOnClick={rightButtonOnClick}
                                rightButton={ind === lastItemIndex - firstItemIndex && lastItemIndex < collection.items.length - 1}
                                isMobile={isMobile}
                                onClick={() => navigateToItem(collection.collectionId, firstItemIndex + ind)}
                            />
                        );
                    })
                }
            </Stack>

            <AccountModalHandler
                signUp={isSignUpModalOpen}
                onCloseSignUp={closeSignUpModal}
                isMobile={isMobile}
            />
        </Box>
    );
}