import {
    Box,
    MenuItem,
    Stack,
    TextField,
    useTheme
} from "@mui/material";
import { useState } from "react";
import axios from "axios";

import PrimaryButton from "../../Common/PrimaryButton/PrimaryButton";

import useValidateEmail from "../../../hooks/validation/useValidateEmail";
import apiRoutes from "../../../constants/api-routes";

export default function FormBody(props) {
    const {
        setIsSent,
        isMobile
    } = props;

    const theme = useTheme();

    const validateEmail = useValidateEmail();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [category, setCategory] = useState("");
    const [message, setMessage] = useState("");

    const [nameValid, setNameValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [categoryValid, setCategoryValid] = useState(true);
    const [messageValid, setMessageValid] = useState(true);

    const validateFields = () => {
        let isValid = true;

        setNameValid(name.length === 0 ? false : true);
        setCategoryValid(category.length === 0 ? false : true);
        setMessageValid(message.length === 0 ? false : true);

        validateEmail(
            email,
            () => {
                setEmailValid(false);
                isValid = false;
            },
            () => {
                setEmailValid(true);
                isValid = true;
            }
        );

        // get an answer now without waiting for state to update
        if (name.length === 0 || category.length === 0 || message.length === 0) {
            isValid = false;
        }

        if (isValid) {
            return true;
        }

        return false;
    };

    const handleSubmit = () => {
        let isValid = validateFields();

        if (isValid) {
            axios.get(apiRoutes.contact, {
                params: {
                    name,
                    email,
                    category,
                    userMessage: message
                }
            }).then(() => {
                setIsSent(true);
            });
        }
    };

    return (
        <Box>
            <Stack
                direction="column"
            >
                <TextField
                    placeholder="Name"
                    sx={{
                        mb: 2
                    }}
                    color="secondary"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                        setNameValid(e.target.value.length === 0 ? false : true);
                    }}
                    error={!nameValid}
                />
                <TextField
                    placeholder="Email"
                    sx={{
                        mb: 2
                    }}
                    color="secondary"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        validateEmail(
                            e.target.value,
                            () => setEmailValid(false),
                            () => setEmailValid(true)
                        );
                    }}
                    error={!emailValid}
                />
                <TextField
                    select
                    placeholder="Category"
                    sx={{
                        mb: 2
                    }}
                    color="secondary"
                    SelectProps={{
                        displayEmpty: true,
                        renderValue: (value) => value ? value : <span>Category</span>
                    }}
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    error={!categoryValid}
                >
                    <MenuItem value={"Site Issue"}>Site Issue</MenuItem>
                    <MenuItem value={"General Feedback"}>General Feedback</MenuItem>
                    <MenuItem value={"Question"}>Question</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                </TextField>
                <TextField
                    placeholder="Your message"
                    multiline
                    rows={7}
                    sx={{
                        mb: 3
                    }}
                    color="secondary"
                    value={message}
                    onChange={(e) => {
                        setMessage(e.target.value);
                        setMessageValid(e.target.value.length === 0 ? false : true);
                    }}
                    error={!messageValid}
                />
            </Stack>

            <PrimaryButton
                onClick={handleSubmit}
                text="Send"
                sx={{
                    width: "100%",
                    backgroundColor: theme.palette.grey["600"],
                    borderRadius: 5,
                    mb: 2
                }}
            />
        </Box>
    );
}