import {
    Modal,
    Box,
    IconButton,
    useTheme
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import useGetImageLink from "../../../hooks/data/useGetImageLink";

export default function ImageModal(props) {
    /*
    
    This component is a modal that shows an original image.

    */

    const {
        showImageModal,
        setShowImageModal,
        image,
        isMobile
    } = props;

    const theme = useTheme();
    const getImageLink = useGetImageLink();

    return (
        <Modal
            open={showImageModal}
            onClose={() => setShowImageModal(false)}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    px: !isMobile ? 6 : 2,
                    py: 6,
                    m: 0,
                    maxHeight: !isMobile ? "90%" : "50%",
                    maxWidth: !isMobile ? "90%" : "100%",
                    minWidth: !isMobile ? null : "90%",
                    "&:active": {
                        outline: "none"
                    },
                    "&:focus": {
                        outline: "none"
                    },
                    borderRadius: !isMobile ? 4 : 0,
                    overflowY: "scroll"
                }}
            >
                <img
                    src={getImageLink({ image, variant: "public" })}
                    style={{
                        maxWidth: "100%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        mb: 3
                    }}
                />

                {/* Close Icon */}
                <Box
                    sx={{
                        position: "absolute",
                        top: 15,
                        left: 15,
                        margin: "auto",
                        border: `2px solid ${theme.palette.grey["500"]}`,
                        borderRadius: 14
                    }}
                >
                    <IconButton
                        onClick={() => setShowImageModal(false)}
                        sx={{
                            m: 0,
                            p: 0
                        }}
                    >
                        <CloseIcon
                            sx={{
                                color: theme.palette.grey["500"]
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>
        </Modal>
    );
}