import {
    Box,
    Typography
} from "@mui/material";

import PrimaryButton from "../../../PrimaryButton/PrimaryButton";
import SecondaryButton from "../../../SecondaryButton/SecondaryButton";

export default function LoginCTA(props) {
    const {
        openSignUpModal,
        openSignInModal,
        onClose,
        isMobile
    } = props;

    return (
        <Box
            sx={{
                mt: 2
            }}
        >
            <Typography
                variant={isMobile ? "body2" : "body1"}
                sx={{
                    pb: 1,
                    display: "block"
                }}
            >
                To leave a comment Log in or Sign up
            </Typography>
            <PrimaryButton
                text="Log In"
                onClick={() => {
                    onClose();
                    openSignInModal();
                }}
                sx={{
                    width: 100,
                    mr: 2
                }}
            />
            <SecondaryButton
                text="Sign Up"
                onClick={() => {
                    onClose();
                    openSignUpModal();
                }}
                sx={{
                    width: 100
                }}
            />
        </Box>
    );
}