import { useEffect, useRef } from "react";
import {
    Card
} from "@mui/material";

import CollectionTitle from "./CollectionTitle/CollectionTitle";
import CollectionDescription from "./CollectionDescription/CollectionDescription";
import CollectionStats from "./CollectionStats/CollectionStats";
import ItemCarousel from "./ItemCarousel/ItemCarousel";
import ViewCollectionButton from "./ViewCollectionButton/ViewCollectionButton";
import useIntersection from "../../../../hooks/useIntersection";

export default function CollectionPreview(props) {
    const {
        collection,
        onInView
    } = props;

    const ref = useRef();

    const { setupObserver } = useIntersection();

    useEffect(() => {
        if (onInView) {
            setupObserver(ref, onInView);
        }
    }, []);

    return (
        <Card
            ref={ref}
            variant="outlined"
            sx={{
                m: 1,
                mb: 4,
                p: 1
            }}
        >
            <CollectionTitle
                collectionTitle={collection.collectionTitle}
            />
            <CollectionDescription
                collectionDescription={collection.collectionDescription}
            />
            <CollectionStats
                itemCount={collection.items.length}
                followerCount={collection.followerCount}
            />
            <ItemCarousel
                items={collection.items}
                collectionId={collection.collectionId}
            />
            <ViewCollectionButton
                collectionId={collection.collectionId}
            />
        </Card>
    );
}