import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'

const TypographyFAQ = styled(Typography)({
    my: 2,
    fontFamily: 'Open Sans',
    textAlign: 'center'
})

export default function FAQAccordion(props) {
    return (
        <Box>
            {
                props.faqs.map((faq) => {
                    return (
                        <Accordion
                            key={faq.title}
                            disableGutters={true}
                            elevation={0}
                            sx={{
                                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                    transform: 'rotate(90deg)',
                                },
                                '&:before': {
                                    display: 'none',
                                },
                                '&:after': {
                                    display: 'none',
                                },
                                mb: 2
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ArrowRightIcon />}
                            >
                                <TypographyFAQ
                                    variant='h6'
                                >
                                    {faq.title}
                                </TypographyFAQ>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TypographyFAQ
                                    sx={{
                                        textAlign: 'left'
                                    }}
                                >
                                    {faq.body}
                                </TypographyFAQ>
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
        </Box >
    )
}