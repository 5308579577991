import {
    MenuItem,
    ListItemText,
    useTheme
} from "@mui/material";

export default function UserMenuItem(props) {
    const {
        icon,
        text,
        setMenuOpen,
        onClick
    } = props;

    const theme = useTheme();

    return (
        <MenuItem
            onClick={() => {
                onClick();
                setMenuOpen(false);
            }}
            sx={{
                color: theme.palette.secondary.main,
                "&:hover": {
                    backgroundColor: theme.palette.grey["800"],
                    color: theme.palette.common.white
                }
            }}
        >
            {icon}
            <ListItemText
                sx={{
                    pl: 2
                }}
            >
                {text}
            </ListItemText>
        </MenuItem>
    );
}