import { useEffect } from 'react'
import {
    Fragment,
    useState
} from 'react'
import ChangePasswordModal from './change-password/change-password'
import ChangePasswordSuccess from './change-password/change-password-success'
import SignInModal from './sign-in-modal/sign-in-modal'
import SignUpModal from './sign-up-modal/sign-up-modal'
import SignUpSuccessModal from './sign-up-modal/sign-up-success'

export default function AccountModalHandler(props) {
    const {
        signUp,
        signIn,
        onCloseSignUp,
        onCloseSignIn,
        changePassword,
        changePasswordCode,
        changePasswordUsername,
        isMobile
    } = props

    if (signUp && signIn) {
        return (
            null
        )
    }

    const [showChangePasswordSuccess, setShowChangePasswordSuccess] = useState(false)
    const [showSignUpSuccess, setShowSignUpSuccess] = useState(false)
    const [showSignUp, setShowSignUp] = useState(false)
    const [showSignIn, setShowSignIn] = useState(false)
    const [showChangePassword, setShowChangePassword] = useState(false)
    const [propData, setPropData] = useState({})

    const openSignUpSuccess = () => {
        setShowSignUpSuccess(true)
    }

    const openChangePasswordSuccess = () => {
        setShowChangePasswordSuccess(true)
    }

    useEffect(() => {
        if (signUp) {
            setShowSignUp(true)
        } else if (signIn) {
            setShowSignIn(true)
        } else if (changePassword) {
            setShowChangePassword(true)
        }
    }, [props.signIn, props.signUp, props.changePassword])

    return (
        <Fragment>
            <SignUpModal
                isMobile={isMobile}
                open={showSignUp}
                close={() => {
                    setShowSignUp(!showSignUp)
                    if (onCloseSignUp) {
                        onCloseSignUp()
                    }
                }}
                openSignIn={() => setShowSignIn(true)}
                openSignUpSuccess={openSignUpSuccess}
            />
            <SignInModal
                isMobile={isMobile}
                open={showSignIn}
                close={() => {
                    setShowSignIn(!showSignIn)
                    if (onCloseSignIn) {
                        onCloseSignIn()
                    }
                }}
                openSignUp={() => setShowSignUp(true)}
                openChangePassword={(propData) => {
                    setShowChangePassword(true)
                    setPropData(propData)
                }}
            />
            <SignUpSuccessModal
                isMobile={isMobile}
                open={showSignUpSuccess}
                close={() => {
                    setShowSignUpSuccess(false)
                }}
            />
            <ChangePasswordModal
                isMobile={isMobile}
                open={showChangePassword}
                close={() => {
                    setShowChangePassword(!showChangePassword)
                    if (onCloseSignUp) {
                        onCloseSignUp()
                    }
                }}
                openSignUpSuccess={openSignUpSuccess}
                username={propData.username}
                handle={propData.handle}
                session={propData.session}
                forgotPassword={propData.forgotPassword}
                openChangePasswordSuccess={openChangePasswordSuccess}
                changePasswordCode={changePasswordCode}
                changePasswordUsername={changePasswordUsername}
            />
            <ChangePasswordSuccess
                isMobile={isMobile}
                open={showChangePasswordSuccess}
                close={() => {
                    setShowChangePasswordSuccess(false)
                }}
            />
        </Fragment>
    )
}