import {
    Box,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function Username(props) {
    const {
        isMobile,
        username,
        setUsername,
        isUsernameValid,
        setIsUsernameValid
    } = props;

    const validateUsername = (username) => {
        if (username.length < 3 || username.length > 23) {
            setIsUsernameValid(false);
            return;
        }

        setIsUsernameValid(true);
    };

    return (
        <Box>
            <Typography
                variant='body1'
                sx={{
                    fontWeight: "bold",
                    fontFamily: "Open Sans"
                }}
            >
                Username
                <Tooltip
                    title={
                        <Typography>
                            Usernames must be 3-23 characters and can only contain alphanumeric characters and symbols. This will be used to login to Slyke.
                        </Typography>
                    }
                >
                    <InfoOutlinedIcon
                        fontSize="10"
                        sx={{
                            ml: 0.5,
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }}
                    />
                </Tooltip>
            </Typography>
            <TextField
                variant='outlined'
                placeholder="jdoe1997"
                type="text"
                error={!isUsernameValid}
                value={username}
                onChange={(e) => {
                    setUsername(e.target.value);
                    validateUsername(e.target.value);
                }}
                sx={{
                    p: 0,
                    m: 0,
                    "& .MuiOutlinedInput-root": {
                        height: 35
                    },
                    mb: 2,
                    width: 300,
                    maxWidth: !isMobile ? null : "90%"
                }}
            />
        </Box>
    );
}