import {
    Button, useTheme
} from "@mui/material";

export default function PrimaryButton(props) {
    const {
        _ref,
        text,
        onClick,
        sx,
        borderRadius,
        adornment,
        onMouseEnter,
        onMouseLeave,
        disabled
    } = props;

    const theme = useTheme();

    return (
        <Button
            ref={_ref}
            disabled={disabled}
            color="primary"
            variant="contained"
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            sx={{
                color: theme.palette.common.white,
                textTransform: "none",
                fontSize: 16,
                py: 0.5,
                px: 1,
                my: "auto",
                borderRadius: borderRadius,
                "&:hover": {
                    backgroundColor: theme.palette.grey["900"]
                },
                ...sx
            }}
        >
            {text}
            {adornment}
        </Button>
    );
}