import {
    Grid,
    Typography,
    Button,
    Stack,
    TextField,
    Autocomplete
} from '@mui/material'
import ItemSubmitted from './item-submitted'
import ItemApproved from './item-approved'
import colors from '../../constants/colors'
import { useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {
    getUserId,
    getToken
} from '../../store/user/userSlice'
import {
    getCollectionByIndex
} from '../../store/collection/collectionSlice'
import { useSelector } from 'react-redux'
import apiRoutes from '../../constants/api-routes'
import axios from 'axios'
import TagHandler from '../tag-handler/tag-handler'

import './react-quill.css'
import { useNavigate } from 'react-router-dom'
import Title from './form/title'
import Collection from './form/collection'
import Description from './form/description'

export default function AddItemText(props) {
    const {
        isMobile
    } = props;

    const navigate = useNavigate()
    const userId = useSelector(getUserId)
    const token = useSelector(getToken)

    const [convertedText, setConvertedText] = useState('')

    // available collections
    const [collections, setCollections] = useState([])

    // user provided data
    const [itemTitle, setItemTitle] = useState('')
    const [itemCollection, setItemCollection] = useState(
        props.collectionIndex || props.collectionIndex === 0 ?
            useSelector(getCollectionByIndex(props.collectionIndex)) : null
    )
    const [itemDescription, setItemDescription] = useState('')
    const [tagChoices, setTagChoices] = useState([])

    const textStyle = {
        fontFamily: 'Open Sans'
    }

    const textFieldStyle = {
        '& .MuiOutlinedInput-input': {
            py: 1
        }
    }

    const buttonStyle = {
        width: '70%',
        textTransform: 'none',
        fontFamily: 'Open Sans',
        py: 1.5,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: colors.darkGray,
            color: colors.lightGray,
            borderColor: colors.darkGray
        }
    }

    const submitItem = () => {
        axios.post(apiRoutes.addItem, {
            itemTitle,
            itemCollection,
            itemDescription,
            tagChoices,
            userId,
            textItemText: convertedText
        }, {
            headers: {
                'authorization': token ? `Bearer ${token}` : null
            }
        }).then((res) => {
            if (res.data.autoApproved) {
                props.setActiveComponent(<ItemApproved isMobile={isMobile} />)
            } else {
                props.setActiveComponent(<ItemSubmitted isMobile={isMobile} />)
            }
        }).catch(() => {
            alert('Failed to Add Item!')
        })
    }

    const searchCollectionsByName = (searchString) => {
        axios.get(apiRoutes.searchCollectionsByName, {
            params: {
                searchString,
                comapct: 1
            }
        }).then((res) => {
            setCollections(res.data.matchingCollections)
        })
    }

    return (
        <Grid
            container
            sx={{
                px: !isMobile ? 6 : 1,
                py: !isMobile ? null : 4,
                overflowY: !isMobile ? null : "scroll",
                height: !isMobile ? null : "100vh"
            }}
        >
            <Grid
                item
                xs={12}
                sx={{
                    textAlign: 'center',
                    mt: 2,
                    mb: !isMobile ? 8 : 2
                }}
            >
                <Typography
                    variant='h5'
                    sx={{
                        ...textStyle,
                        fontWeight: 'bold'
                    }}
                >
                    Add New Item
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}
                sm={6}
                sx={{
                    mb: 6
                }}
            >
                <ReactQuill
                    placeholder='Type or paste in text'
                    theme='snow'
                    value={convertedText}
                    onChange={setConvertedText}
                    formats={[

                    ]}
                    modules={{
                        toolbar: false
                    }}
                    style={{
                        marginTop: 5,
                        marginRight: !isMobile ? 15 : 0,
                        maxHeight: 600
                    }}
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={6}
                sx={{
                    mb: 6
                }}
            >
                <Stack>
                    <Title
                        itemTitle={itemTitle}
                        setItemTitle={setItemTitle}
                    />
                    <Collection
                        collections={collections}
                        itemCollection={itemCollection}
                        setItemCollection={setItemCollection}
                        searchCollectionsByName={searchCollectionsByName}
                    />
                    <Description
                        itemDescription={itemDescription}
                        setItemDescription={setItemDescription}
                    />
                    <TagHandler
                        tagChoices={tagChoices}
                        setTagChoices={setTagChoices}
                        placeholderText={'Tags (optional)'}
                    />
                </Stack>
            </Grid>

            <Grid
                item
                xs={12}
                sx={{
                    textAlign: 'center',
                    mb: 2
                }}
            >
                <Button
                    onClick={submitItem}
                    variant='contained'
                    color='primary'
                    sx={{
                        ...buttonStyle,
                        color: colors.white,
                        p: 1,
                        width: !isMobile ? '40%' : '64%'
                    }}
                >
                    Post
                </Button>
            </Grid>

            <Grid
                item
                xs={6}
                sx={{
                    textAlign: 'right',
                    mb: !isMobile ? 2 : 8
                }}
            >
                <Button
                    onClick={() => navigate('/rules')}
                    variant='contained'
                    sx={{
                        ...buttonStyle,
                        backgroundColor: colors.white,
                        border: `${colors.secondary} solid 1px`,
                        color: colors.secondary,
                        p: 0.5,
                        width: !isMobile ? '38%' : "60%",
                        mr: 0.5
                    }}
                >
                    Rules
                </Button>
            </Grid>

            <Grid
                item
                xs={6}
                sx={{
                    textAlign: 'left',
                    mb: 2
                }}
            >
                <Button
                    onClick={() => navigate('/faq')}
                    variant='contained'
                    sx={{
                        ...buttonStyle,
                        backgroundColor: colors.white,
                        border: `${colors.secondary} solid 1px`,
                        color: colors.secondary,
                        p: 0.5,
                        width: !isMobile ? '38%' : "60%",
                        ml: 0.5
                    }}
                >
                    Need Help?
                </Button>
            </Grid>
        </Grid>
    )
}