import {
    Modal,
    IconButton,
    Box,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import colors from '../../constants/colors'
import {
    useEffect,
    useState
} from 'react'
import AddItemStepOne from './add-item-step-1'

export default function AddItemModal(props) {
    const {
        isMobile
    } = props;
    const [activeComponent, setActiveComponent] = useState(null)

    useEffect(() => {
        setActiveComponent(
            <AddItemStepOne
                setActiveComponent={setActiveComponent}
                collectionIndex={props.collectionIndex}
                isMobile={isMobile}
            />
        )
    }, [])

    const modalBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: !isMobile ? 660 : "100%",
        height: !isMobile ? null : "100%",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 0,
        m: 0,
        borderRadius: !isMobile ? 5 : 0,
        '&:active': {
            outline: 'none'
        },
        '&:focus': {
            outline: 'none'
        }
    }

    return (
        <Modal
            open={props.open || false}
            onClose={() => {
                props.close()
                setActiveComponent(
                    <AddItemStepOne
                        setActiveComponent={setActiveComponent}
                        collectionIndex={props.collectionIndex}
                        isMobile={isMobile}
                    />
                )
            }}
        >
            <Box
                sx={modalBoxStyle}
            >
                {/* Content */}
                {activeComponent}

                {/* Close Icon */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: {
                            xs: 9,
                            sm: 5
                        },
                        left: {
                            xs: 9,
                            sm: 5
                        },
                        margin: 'auto'
                    }}
                >
                    <IconButton
                        onClick={() => {
                            props.close()
                            setActiveComponent(
                                <AddItemStepOne
                                    setActiveComponent={setActiveComponent}
                                    collectionIndex={props.collectionIndex}
                                    isMobile={isMobile}
                                />
                            )
                        }}
                        sx={{
                            m: 0,
                            p: 0
                        }}
                    >
                        <CloseIcon
                            sx={{
                                color: colors.gray
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>

        </Modal>
    )
}