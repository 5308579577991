import {
    Typography,
    Box
} from '@mui/material'
import colors from '../../constants/colors'
import CircleIcon from '@mui/icons-material/Circle'

export default function EngagementBox(props) {
    return (
        <Box
            sx={{
                border: `${colors.lightGray} solid 1px`,
                borderRadius: 1,
                p: 1
            }}
        >
            <Typography
                variant='body2'
                sx={{
                    fontFamily: 'Open Sans',
                    display: 'inline-flex'
                }}
            >
                {props.followers} Followers
            </Typography>
            <CircleIcon
                sx={{
                    fontSize: 6,
                    mx: 1,
                    color: colors.darkGray,
                    verticalAlign: 'middle'
                }}
            />
            <Typography
                variant='body2'
                sx={{
                    fontFamily: 'Open Sans',
                    display: 'inline-flex'
                }}
            >
                {props.likes} Likes
            </Typography>
            <CircleIcon
                sx={{
                    fontSize: 6,
                    mx: 1,
                    color: colors.darkGray,
                    verticalAlign: 'middle'
                }}
            />
            <Typography
                variant='body2'
                sx={{
                    fontFamily: 'Open Sans',
                    display: 'inline-flex'
                }}
            >
                {props.itemCount} Items
            </Typography>
            <CircleIcon
                sx={{
                    fontSize: 6,
                    mx: 1,
                    color: colors.darkGray,
                    verticalAlign: 'middle'
                }}
            />
            <Typography
                variant='body2'
                sx={{
                    fontFamily: 'Open Sans',
                    display: 'inline-flex'
                }}
            >
                {props.views} Views
            </Typography>
        </Box>
    )
}