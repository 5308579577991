import {
    Grid,
    Icon,
    Typography
} from "@mui/material";

import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import RedditIcon from "@mui/icons-material/Reddit";
import TikTokIcon from "../../../../assets/icons/tiktok.svg";

import useNavigateToExternalSite from "../../../../hooks/navigation/useNavigateToExternalSite";

export default function SocialLinks() {
    const navigateToExternalSite = useNavigateToExternalSite();

    const iconStyle = {
        fontSize: 24,
        margin: 0,
        backgroundColor: "white",
        borderRadius: 1,
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "orange"
        }
    };

    const links = [
        <InstagramIcon
            key="instagram"
            sx={iconStyle}
            onClick={
                () => navigateToExternalSite("https://www.instagram.com/slyke_22/")
            }
        />,
        <TwitterIcon
            key="twitter"
            sx={iconStyle}
            onClick={() => navigateToExternalSite("https://twitter.com/SLYKE__22")}
        />,
        <FacebookIcon
            key="facebook"
            sx={iconStyle}
            onClick={() => navigateToExternalSite("https://www.facebook.com/profile.php?id=100077222005575")}
        />,
        <RedditIcon
            key="reddit"
            sx={iconStyle}
            onClick={() => navigateToExternalSite("https://www.reddit.com/user/slyke__22")}
        />,
        <Icon
            key="tiktok"
            sx={iconStyle}
            onClick={() => navigateToExternalSite("https://www.tiktok.com/@slyke__22")} >
            <img
                src={TikTokIcon}
                style={{
                    height: "100%",
                    width: "100%"
                }}
            />
        </Icon>
    ];

    return (
        <Grid
            container
            direction='row'
            sx={{
                my: 2
            }}
        >
            <Grid
                item
                xs={12}
            >
                <Typography>
                    Follow Slyke
                </Typography>
            </Grid>
            {
                links.map((icon, ind) => {
                    return (
                        <Grid
                            key={`social-link-${ind}`}
                            item
                            xs={1.4}
                        >
                            {icon}
                        </Grid>
                    );
                })
            }
        </Grid>
    );
}