import {
    useEffect,
    useRef
} from "react";
import {
    Card,
    Grid,
    useTheme
} from "@mui/material";

import ItemTitle from "./ItemTitle/ItemTitle";
import ItemDescription from "./ItemDescription/ItemDescription";
import LinkItemBody from "./LinkItemBody/LinkItemBody";
import PhotoItemBody from "./PhotoItemBody/PhotoItemBody";
import PostedBy from "./PostedBy/PostedBy";
import ItemEngagement from "./ItemEngagement/ItemEngagement";
import VideoItemBody from "./VideoItemBody/VideoItemBody";
import TextItemBody from "./TextItemBody/TextItemBody";

import useRecordItemView from "../../../../hooks/data/useRecordItemView";
import UploadedVideoItemBody from "./UploadedVideoItemBody/UploadedVideoItemBody";
import useIntersection from "../../../../hooks/useIntersection";

export default function ItemViewCard(props) {
    const {
        item,
        itemIndex,
        updateItemIndex,
        collectionId,
        onClick,
        shareLinkOverride,
        onInView,
        refreshOverride,
        commentsRefreshOverride
    } = props;

    const itemRef = useRef();
    const theme = useTheme();

    const recordItemView = useRecordItemView();
    const { setupObserver } = useIntersection();

    const itemBody = () => {
        switch (item.itemType) {
            case "media":
                if (item.ogVideoLink) {
                    return (
                        <UploadedVideoItemBody
                            videoUrl={item.ogVideoLink}
                        />
                    );
                } else {
                    return (
                        <PhotoItemBody
                            image={item.image}
                        />
                    );
                }
            case "link":
                if (item.ogVideoLink) {
                    return (
                        <VideoItemBody
                            videoUrl={item.ogVideoLink}
                            link={item.link}
                            ogTitle={item.ogTitle}
                        />
                    );
                } else {
                    return (
                        <LinkItemBody
                            image={item.image}
                            link={item.link}
                            ogTitle={item.ogTitle}
                        />
                    );
                }
            case "text":
                return <TextItemBody
                    text={item.ogDescription}
                />;
        }
    };

    useEffect(() => {
        /* 
        
        Setups intersection observer that updates the itemIndex as the user scrolls. 
        
        */

        let viewTimeout = null;
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                // when the item is in view
                if (entry.isIntersecting) {
                    updateItemIndex();

                    /* 
                        set a timeout to record the item view 
                        only happens if the user stays on the item long enough 
                    */
                    const viewThreshold = 2000;
                    viewTimeout = setTimeout(() => {
                        recordItemView(item.itemId);
                    }, viewThreshold);
                } else {
                    // when user scrolls away, clear interval
                    if (viewTimeout) {
                        clearTimeout(viewTimeout);

                    }
                }
            });
        }, {
            threshold: 0.75
        });
        observer.observe(itemRef.current);

        return () => {
            if (itemRef.current) {
                observer.unobserve(itemRef.current);
            }
        };
    }, []);

    useEffect(() => {
        /* 
        
        Sets up the passed in onInView function, if provided.

        */

        if (onInView) {
            const observer = setupObserver(itemRef, onInView);

            return () => {
                if (itemRef.current) {
                    observer.unobserve(itemRef.current);
                }
            };
        }
    }, []);

    return (
        <Card
            ref={itemRef}
            id={`item_${itemIndex}`}
            variant="outlined"
            sx={{
                m: 1,
                mb: 4,
                mx: 2,
                p: 0,
                borderRadius: 2,
                maxWidth: "400px"
            }}
        >
            <Grid
                container
            >
                <Grid
                    xs={10}
                    item
                    sx={{
                        borderRight: `1px solid ${theme.palette.grey["300"]}`,
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <ItemTitle
                        onClick={onClick}
                        itemTitle={item.title}
                    />
                    <ItemDescription
                        itemDescription={item.bodyText}
                    />

                    {itemBody()}
                    <PostedBy
                        userId={item.userAddedBy}
                        username={item.username}
                        datetimePosted={item.timePosted}
                    />
                </Grid>
                <Grid
                    xs={2}
                    item
                >
                    <ItemEngagement
                        item={item}
                        views={item.views}
                        likes={item.likes}
                        comments={
                            // support both the legacy way of passing comments array and passing a commentsCount
                            item.comments ?
                                item.comments.length : item.commentsCount ?
                                    item.commentsCount : 0
                        }
                        bookmarks={item.bookmarks}
                        title={item.title}
                        collectionId={collectionId}
                        isMobile
                        shareLinkOverride={shareLinkOverride}
                        refreshOverride={refreshOverride}
                        commentsRefreshOverride={commentsRefreshOverride}
                    />
                </Grid>
            </Grid>
        </Card>
    );
}