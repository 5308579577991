import {
    Grid,
    Typography,
    Button,
    Stack,
    TextField,
    Autocomplete,
    Box,
    Alert,
    IconButton,
    Collapse
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ItemSubmitted from './item-submitted'
import ItemApproved from './item-approved'
import LoadingModal from '../loading-modal/loading-modal'
import { FileUploader } from 'react-drag-drop-files'
import colors from '../../constants/colors'
import {
    getUserId,
    getToken
} from '../../store/user/userSlice'
import {
    getCollectionByIndex
} from '../../store/collection/collectionSlice'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import apiRoutes, { cfImageWorker } from '../../constants/api-routes'
import axios from 'axios'
import videoPlaceholder from '../../assets/images/placeholder/video-player-placeholder.png'
import TagHandler from '../tag-handler/tag-handler'
import { useNavigate } from 'react-router-dom'
import blankImage from '../../assets/images/placeholder/blank.png'
import Title from './form/title'
import Collection from './form/collection'
import Description from './form/description'

export default function AddItemMedia(props) {
    const {
        isMobile
    } = props;

    const validTypes = [
        "image/apng",
        "image/avif",
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/webp"
    ]

    const navigate = useNavigate()
    const userId = useSelector(getUserId)
    const token = useSelector(getToken)

    const [loading, setLoading] = useState(false)

    // available collections
    const [collections, setCollections] = useState([])

    // user provided data
    const [itemTitle, setItemTitle] = useState('')
    const [itemCollection, setItemCollection] = useState(
        props.collectionIndex || props.collectionIndex === 0 ?
            useSelector(getCollectionByIndex(props.collectionIndex)) : null
    )
    const [itemDescription, setItemDescription] = useState('')
    const [tagChoices, setTagChoices] = useState([])

    const [file, setFile] = useState(null)
    const [isVideo, setIsVideo] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (!file) {
            const img = document.getElementById('preview-image')
            img.src = blankImage
        }
    }, [file])

    const handleChange = async (file) => {
        if (file.type.toLowerCase().includes('video')) {
            const durationIsValid = await checkIfVideoDurationIsValid(file)
            if (!durationIsValid) {
                setError('Video is too long. Maximum duration is 30 seconds.')
                setFile(null)
                return
            }

            const img = document.getElementById('preview-image')
            img.src = videoPlaceholder
            setIsVideo(true)
        } else {
            if (
                !validTypes.includes(file.type)
            ) {
                setError('Invalid file type!')
                setFile(null)
                return
            }
            // set preview image
            file.arrayBuffer().then(buffer => {
                const blob = new Blob([buffer])
                const url = URL.createObjectURL(blob)
                const img = document.getElementById('preview-image')
                img.src = url
                // So the Blob can be Garbage Collected
                img.onload = () => URL.revokeObjectURL(url)
            })
        }

        setFile(file)
    }

    const textStyle = {
        fontFamily: 'Open Sans'
    }

    const textFieldStyle = {
        '& .MuiOutlinedInput-input': {
            py: 1
        }
    }

    const buttonStyle = {
        width: '70%',
        textTransform: 'none',
        fontFamily: 'Open Sans',
        py: 1.5,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: colors.darkGray,
            color: colors.lightGray,
            borderColor: colors.darkGray
        }
    }

    const checkIfVideoDurationIsValid = async (file) => {
        return new Promise((resolve) => {
            const url = URL.createObjectURL(file)
            const video = document.createElement('video')
            video.src = url
            video.addEventListener('loadedmetadata', () => {
                const duration = video.duration.toFixed(2)
                if (duration < 31) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
        })
    }

    const uploadMediaToCf = async () => {
        if (isVideo) {
            let res = await axios.get(cfImageWorker, {
                params: {
                    video: true
                }
            })
            let uploadUrl = res.data.result.uploadURL
            let hash = res.data.result.uid

            let formData = new FormData()

            formData.append('file', file)

            return new Promise((resolve, reject) => {
                axios.post(uploadUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(() => {
                    resolve(hash)
                }).catch(() => {
                    reject()
                })
            })
        } else {
            let res = await axios.get(cfImageWorker)
            let uploadUrl = res.data.result.uploadURL

            let formData = new FormData()
            formData.append('file', file)

            return new Promise((resolve, reject) => {
                axios.post(uploadUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res) => {
                    resolve(res.data.result.id)
                }).catch(() => {
                    reject()
                })
            })
        }
    }

    const validateItemSubmission = () => {
        if (!file) {
            setError('You must choose a file to upload!')
            return true
        }

        if (!itemTitle) {
            setError('You must add a title for this item!')
            return true
        }

        if (!itemCollection) {
            setError('You must choose a collection to add this item to!')
            return true
        }

        return false

    }

    const submitItem = () => {
        if (validateItemSubmission()) {
            return
        }

        setLoading(true)

        // also handle videos
        uploadMediaToCf().then((cfRes) => {
            setError(null)

            let cfImageHash = cfRes
            axios.post(apiRoutes.addItem, {
                itemTitle,
                itemCollection,
                itemDescription,
                tagChoices,
                userId,
                mediaItem: {
                    hash: cfImageHash,
                    type: isVideo ? 'video' : 'image'
                }
            }, {
                headers: {
                    'authorization': token ? `Bearer ${token}` : null
                }
            }).then((res) => {
                setLoading(false)
                if (res.data.autoApproved) {
                    props.setActiveComponent(<ItemApproved isMobile={isMobile} />)
                } else {
                    props.setActiveComponent(<ItemSubmitted isMobile={isMobile} />)
                }
            }).catch(() => {
                setFile(null)
                setLoading(false)
                setError('Failed to Add Item!')
            })
        }).catch((err) => {
            setLoading(false)
            setError(err)
        })

    }

    const searchCollectionsByName = (searchString) => {
        axios.get(apiRoutes.searchCollectionsByName, {
            params: {
                searchString,
                comapct: 1
            }
        }).then((res) => {
            setCollections(res.data.matchingCollections)
        })
    }

    return (
        <Grid
            container
            sx={{
                px: !isMobile ? 6 : 1,
                py: !isMobile ? null : 4,
                overflowY: !isMobile ? null : "scroll",
                height: !isMobile ? null : "100vh"
            }}
        >
            <Grid
                item
                xs={12}
                sx={{
                    textAlign: 'center',
                    mt: 2,
                    mb: !isMobile ? 8 : 2
                }}
            >
                <Typography
                    variant='h5'
                    sx={{
                        ...textStyle,
                        fontWeight: 'bold'
                    }}
                >
                    Add New Item
                </Typography>
            </Grid>

            {
                error ?
                    <Grid
                        item
                        xs={12}
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        <Collapse in={error}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setError(null)
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                severity="error"
                                variant="filled"
                                sx={{
                                    fontFamily: 'Open Sans',
                                    mb: 2
                                }}
                            >
                                {error}
                            </Alert>
                        </Collapse>
                    </Grid> : null
            }

            <Grid
                item
                xs={12}
                sm={6}
                sx={{
                    mb: 1
                }}
            >
                <Stack>
                    <Box
                        sx={{
                            border: `1px solid ${colors.darkGray}`,
                            width: !isMobile ? 252 : "100%",
                            height: 200,
                            marginTop: 1,
                            marginBottom: 1,
                            padding: 0,
                            borderRadius: 2
                        }}
                    >
                        <img
                            id='preview-image'
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                            }}
                        />
                    </Box>

                    <Box
                        sx={{
                            border: `${colors.gray} 1px dashed`,
                            borderRadius: 2,
                            width: !isMobile ? 250 : "100%",
                            '& .file-upload': {
                                minWidth: 200,
                                maxWidth: 250,
                                '&:hover': {
                                    cursor: 'pointer'
                                }
                            }
                        }}
                    >
                        <FileUploader
                            label='Upload Media File'
                            classes='file-upload'
                            handleChange={handleChange}
                            maxSize={25}
                            onSizeError={() => {
                                setError('The uploaded file is too large.')
                                setFile(null)
                            }}
                            // eslint-disable-next-line react/no-children-prop
                            children={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <Typography
                                        variant='body1'
                                        sx={{
                                            ...textStyle,
                                            textAlign: 'center',
                                            py: 2
                                        }}
                                    >
                                        {
                                            !isMobile ? "Choose a file or drag it here" : "Select a file to upload"
                                        }
                                    </Typography>
                                    <Button
                                        variant='contained'
                                        sx={{
                                            ...buttonStyle,
                                            color: colors.white,
                                            backgroundColor: colors.darkGray,
                                            p: 1,
                                            width: '50%',
                                            '&:hover': {
                                                cursor: 'pointer',
                                                backgroundColor: colors.secondary,
                                                color: colors.lightGray
                                            },
                                            m: 'auto',
                                            mb: 2
                                        }}
                                    >
                                        Choose File
                                    </Button>
                                </Box>
                            }
                        />
                    </Box>
                </Stack>
            </Grid>
            <Grid
                item
                xs={12}
                sm={6}
                sx={{
                    mb: 6
                }}
            >
                <Stack>
                    <Title
                        itemTitle={itemTitle}
                        setItemTitle={setItemTitle}
                    />
                    <Collection
                        collections={collections}
                        itemCollection={itemCollection}
                        setItemCollection={setItemCollection}
                        searchCollectionsByName={searchCollectionsByName}
                    />
                    <Description
                        itemDescription={itemDescription}
                        setItemDescription={setItemDescription}
                    />
                    <TagHandler
                        tagChoices={tagChoices}
                        setTagChoices={setTagChoices}
                        placeholderText={'Tags (optional)'}
                    />
                </Stack>
            </Grid>

            {
                !isMobile ?
                    <Grid
                        xs
                        item
                        sx={{
                            mb: 3
                        }}
                    >
                        <Typography
                            variant='caption'
                            sx={{
                                ...textStyle
                            }}
                        >
                            The uploaded media must be no larger than 25 MB in size. <br />
                            Videos must be no longer than 30 seconds in duration. <br />
                            Slyke supports most common image and video formats files.
                        </Typography>
                    </Grid> : null
            }

            <Grid
                item
                xs={12}
                sx={{
                    textAlign: 'center',
                    mb: 2
                }}
            >
                <Button
                    onClick={submitItem}
                    variant='contained'
                    color='primary'
                    sx={{
                        ...buttonStyle,
                        color: colors.white,
                        p: 1,
                        width: !isMobile ? '40%' : "64%"
                    }}
                >
                    Post
                </Button>
            </Grid>

            <Grid
                item
                xs={6}
                sx={{
                    textAlign: 'right',
                    mb: !isMobile ? 2 : 8
                }}
            >
                <Button
                    onClick={() => navigate('/rules')}
                    variant='contained'
                    sx={{
                        ...buttonStyle,
                        backgroundColor: colors.white,
                        border: `${colors.secondary} solid 1px`,
                        color: colors.secondary,
                        p: 0.5,
                        width: !isMobile ? '38%' : "60%",
                        mr: 0.5
                    }}
                >
                    Rules
                </Button>
            </Grid>
            <Grid
                item
                xs={6}
                sx={{
                    textAlign: 'left',
                    mb: 2
                }}
            >
                <Button
                    onClick={() => navigate('/faq')}
                    variant='contained'
                    sx={{
                        ...buttonStyle,
                        backgroundColor: colors.white,
                        border: `${colors.secondary} solid 1px`,
                        color: colors.secondary,
                        p: 0.5,
                        width: !isMobile ? '38%' : "60%",
                        ml: 0.5
                    }}
                >
                    Need Help?
                </Button>
            </Grid>
            <LoadingModal
                open={loading}
            />
        </Grid>
    )
}