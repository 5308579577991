import {
    Fragment,
    useState
} from "react";
import {
    IconButton,
    useTheme
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import MobileCTAsModal from "./MobileCTAsModal/MobileCTAsModal";

export default function MobileCTAs(props) {
    const {
        collection,
        handleFollow,
        openSignUpModal,
        refreshCollection
    } = props;

    const theme = useTheme();

    const [openMobileModal, setOpenMobileModal] = useState(false);

    return (
        <Fragment>
            <IconButton
                onClick={() => setOpenMobileModal(true)}
                sx={{
                    border: `${theme.palette.grey["500"]} solid 1px`,
                    borderRadius: 2,
                    p: 0,
                    py: 0.5,
                    px: 2.5,
                    ml: 2
                }}
            >
                <MoreHorizIcon />
            </IconButton>

            <MobileCTAsModal
                openMobileModal={openMobileModal}
                setOpenMobileModal={setOpenMobileModal}
                handleFollow={handleFollow}
                openSignUpModal={openSignUpModal}
                refreshCollection={refreshCollection}
                collection={collection}
            />
        </Fragment>
    );
}