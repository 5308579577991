import {
    Avatar,
    Typography,
    Stack,
    useTheme
} from "@mui/material";
import {
    useNavigate
} from "react-router-dom";

import logo from "../../../assets/images/slyke-logo-cropped.png";

export default function HomeLink(props) {
    const {
        isMobile
    } = props;

    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <Stack
            direction="row"
            sx={{
                ml: !isMobile ? 2 : 1
            }}
        >
            <Avatar
                src={logo}
                onClick={() => {
                    navigate("/");
                }}
                sx={{
                    width: isMobile ? 32 : null,
                    height: isMobile ? 32 : null,
                    mt: isMobile ? 0.5 : null,
                    "&:hover": {
                        cursor: "pointer"
                    }
                }}
            />
            {
                !isMobile ?
                    <Typography
                        onClick={() => {
                            navigate("/");
                        }}
                        variant="h4"
                        color="primary"
                        sx={{
                            fontFamily: theme.typography.serifFontFamily,
                            ml: 1,
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }}
                    >
                        SLYKE
                    </Typography> :
                    null
            }
        </Stack>
    );
}