import {
    Autocomplete,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import { Fragment } from "react";

export default function Collection(props) {
    const {
        collections,
        itemCollection,
        setItemCollection,
        searchCollectionsByName
    } = props;

    const theme = useTheme();

    return (
        <Fragment>
            <Stack
                direction='row'
                sx={{
                    mt: 1
                }}
            >
                <Typography
                    sx={{
                        flexGrow: 1
                    }}
                >
                    Collection
                </Typography>
            </Stack>
            <Autocomplete
                sx={{
                    mb: 2
                }}
                options={collections}
                value={itemCollection}
                onChange={(e, n) => {
                    setItemCollection(n)
                }}
                onInput={(e) => {
                    searchCollectionsByName(e.target.value)
                }}
                getOptionLabel={(option) => option.collectionTitle}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant='standard'
                        placeholder='Start Typing!'
                        sx={{
                            '& .MuiAutocomplete-tag': {
                                backgroundColor: theme.palette.grey[700],
                                borderRadius: 0,
                                border: `1px solid ${theme.palette.grey[500]}`
                            },
                            '& .MuiChip-label': {
                                p: 2
                            },
                            '& .MuiInput-input': {
                                ml: 1
                            }
                        }}
                    />
                )}
            />
        </Fragment>
    );
}