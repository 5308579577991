import {
    Card,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { Fragment } from "react";

const CircleIconStyled = (props) => {
    const {
        isMobile
    } = props;

    const theme = useTheme();

    return (
        <CircleIcon
            sx={{
                fontSize: 6,
                mx: 1,
                color: theme.palette.grey["900"],
                verticalAlign: "middle",
                mt: !isMobile ? 1.25 : 0.75
            }}
        />
    );
};

export default function EngagementMetrics(props) {
    const {
        followers,
        likes,
        items,
        views,
        isMobile
    } = props;

    const typographyStyle = {
        fontSize: !isMobile ? 14 : 12
    };

    return (
        <Card
            variant="outlined"
            sx={{
                py: 1,
                px: !isMobile ? 18 : 10,
                mx: "auto",
                width: "fit-content"
            }}
        >
            <Stack
                direction="row"
                justifyContent={isMobile ? "center" : null}
            >
                <Typography
                    sx={typographyStyle}
                >
                    {followers} Followers
                </Typography>
                <CircleIconStyled
                    isMobile={isMobile}
                />
                {
                    !isMobile ?
                        <Fragment>
                            <Typography
                                sx={typographyStyle}
                            >
                                {likes} Likes
                            </Typography>
                            <CircleIconStyled
                                isMobile={isMobile}
                            />
                        </Fragment> : null
                }
                <Typography
                    sx={typographyStyle}
                >
                    {items} Items
                </Typography>
                {
                    !isMobile ?
                        <Fragment>
                            <CircleIconStyled
                                isMobile={isMobile}
                            />
                            <Typography
                                sx={typographyStyle}
                            >
                                {views} Views
                            </Typography>
                        </Fragment> : null
                }
            </Stack>
        </Card>
    );
}