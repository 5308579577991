import {
    createSlice,
    createAsyncThunk
} from "@reduxjs/toolkit";
import axios from "axios";
import apiRoutes from "../../constants/api-routes";

const initialState = {
    collections: [
    ]
};

export const getTrendingCollections = createAsyncThunk("/collection/getTrendingCollections", async (data) => {
    let res = await axios.get(apiRoutes.getTrendingCollections, {
        params: {
            userId: data.userId,
            segment: data.segment
        }
    });
    let payload = {
        overwrite: data.overwrite,
        collections: res.data
    };

    return payload;
});

export const getPopularCollections = createAsyncThunk("/collection/getPopularCollections", async (data) => {
    let res = await axios.get(apiRoutes.getPopularCollections, {
        params: {
            userId: data.userId,
            segment: data.segment
        }
    });
    let payload = {
        overwrite: data.overwrite,
        collections: res.data
    };

    return payload;
});

export const getForYouCollections = createAsyncThunk("/collection/getForYouCollections", async (data) => {
    let res = await axios.get(apiRoutes.getForYouCollections, {
        params: {
            userId: data.userId,
            segment: data.segment
        }
    });
    let payload = {
        overwrite: data.overwrite,
        collections: res.data
    };

    return payload;
});

export const getCuratedCollections = createAsyncThunk("/collection/getCuratedCollections", async (data) => {
    let res = await axios.get(apiRoutes.getCuratedCollections, {
        params: {
            userId: data.userId,
            segment: data.segment
        }
    });
    let payload = {
        overwrite: data.overwrite,
        collections: res.data
    };

    return payload;
});

export const getFollowedCollections = createAsyncThunk("/collection/getFollowedCollections", async (data) => {
    let res = await axios.get(apiRoutes.getFollowedCollections, {
        params: {
            userId: data.userId,
            segment: data.segment
        }
    });
    let payload = {
        overwrite: data.overwrite,
        collections: res.data
    };

    return payload;
});

export const getRecentlyAddedCollections = createAsyncThunk("/collection/getRecentlyAddedCollections", async (data) => {
    let res = await axios.get(apiRoutes.getRecentlyAddedCollections, {
        params: {
            userId: data.userId,
            segment: data.segment
        }
    });
    let payload = {
        overwrite: data.overwrite,
        collections: res.data
    };

    return payload;
});

export const getRandomCollections = createAsyncThunk("/collection/getRandomCollections", async (data) => {
    let res = await axios.get(apiRoutes.getRandomCollections, {
        params: {
            userId: data.userId,
            segment: data.segment
        }
    });
    let payload = {
        overwrite: data.overwrite,
        collections: res.data
    };

    return payload;
});

export const getSpecificCollection = createAsyncThunk("/collection/getSpecificCollection", async (data) => {
    let res = await axios.get(apiRoutes.getSpecificCollection, {
        params: {
            collectionId: data.collectionId,
            userId: data.userId
        }
    });
    return res.data;
});

export const getSearchedCollections = createAsyncThunk("/collection/getSearchedCollections", async (data) => {
    let res = await axios.get(apiRoutes.searchCollectionsByName, {
        params: {
            searchString: data.searchString,
            userId: data.userId
        }
    });
    return res.data.matchingCollections;
});

export const updateSpecificCollection = createAsyncThunk("/collection/updateSpecificCollection", async (data) => {
    let res = await axios.get(apiRoutes.getSpecificCollection, {
        params: {
            collectionId: data.collectionId,
            userId: data.userId
        }
    });
    return res.data;
});

export const collectionSlice = createSlice({
    name: "collection",
    initialState,
    reducers: {
        setCollections: (state, action) => {
            state.collections = action.payload;
        },
        clearCollections: (state) => {
            state.collections = [];
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getTrendingCollections.fulfilled, (state, action) => {
                if (!action.payload.overwrite) {
                    state.collections = [...state.collections, ...action.payload.collections];
                } else {
                    state.collections = action.payload.collections;
                }
            })
            .addCase(getForYouCollections.fulfilled, (state, action) => {
                if (!action.payload.overwrite) {
                    state.collections = [...state.collections, ...action.payload.collections];
                } else {
                    state.collections = action.payload.collections;
                }
            })
            .addCase(getFollowedCollections.fulfilled, (state, action) => {
                if (!action.payload.overwrite) {
                    state.collections = [...state.collections, ...action.payload.collections];
                } else {
                    state.collections = action.payload.collections;
                }
            })
            .addCase(getCuratedCollections.fulfilled, (state, action) => {
                if (!action.payload.overwrite) {
                    state.collections = [...state.collections, ...action.payload.collections];
                } else {
                    state.collections = action.payload.collections;
                }
            })
            .addCase(getRecentlyAddedCollections.fulfilled, (state, action) => {
                if (!action.payload.overwrite) {
                    state.collections = [...state.collections, ...action.payload.collections];
                } else {
                    state.collections = action.payload.collections;
                }
            })
            .addCase(getPopularCollections.fulfilled, (state, action) => {
                if (!action.payload.overwrite) {
                    state.collections = [...state.collections, ...action.payload.collections];
                } else {
                    state.collections = action.payload.collections;
                }
            })
            .addCase(getRandomCollections.fulfilled, (state, action) => {
                if (!action.payload.overwrite) {
                    state.collections = [...state.collections, ...action.payload.collections];
                } else {
                    state.collections = action.payload.collections;
                }
            })
            .addCase(getSpecificCollection.fulfilled, (state, action) => {
                state.collections = [action.payload];
            })
            .addCase(getSearchedCollections.fulfilled, (state, action) => {
                state.collections = action.payload;
            })
            .addCase(updateSpecificCollection.fulfilled, (state, action) => {
                const collectionId = action.payload.collectionId;
                let collectionToUpdateIndex = state.collections.findIndex(c => c.collectionId === collectionId);
                state.collections[collectionToUpdateIndex] = action.payload;
            });
    }
});

export const {
    setCollections,
    clearCollections
} = collectionSlice.actions;

export const getCollections = (state) => state.collection.collections;
export const getCollectionsWithImages = (state) => !state.collection.collections || state.collection.collections.length === 0 ? [] : state.collection.collections.filter((collection => collection.items[0] ? collection.items[0].image : false));

export const getItemFromCollectionByIndices = (collectionIndex, itemIndex) => (state) => {
    return state.collection.collections[collectionIndex].items[itemIndex];
};
export const getCollectionNameByIndex = (collectionIndex) => (state) => {
    return state.collection.collections[collectionIndex].collectionTitle;
};
export const getCollectionByIndex = (collectionIndex) => (state) => {
    return state.collection.collections[collectionIndex];
};

export default collectionSlice.reducer;