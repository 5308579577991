import { parse } from "date-fns";

export default function useGetTimeAgo() {
    function parseCustomDateTime(dateTimeStr) {
        // Split the input string into date and time parts
        const [datePart, timePart] = dateTimeStr.split(" at ");

        // Parse the date and time separately and combine them
        const parsedDate = parse(datePart, "d MMMM yyyy", new Date());
        const parsedTime = parse(timePart, "hh:mma", new Date());

        // Set the time part of the parsedDate to match the time from parsedTime
        parsedDate.setHours(parsedTime.getHours());
        parsedDate.setMinutes(parsedTime.getMinutes());

        return parsedDate;
    }

    const getTimeAgo = (dateTimeStr) => {
        const dateTime = parseCustomDateTime(dateTimeStr);
        const currentDateTime = new Date();
        const timeDifference = currentDateTime - dateTime;

        // Calculate the difference in days and hours
        const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hoursAgo = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

        if (daysAgo > 0) {
            return `${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`;
        } else if (hoursAgo > 0) {
            return `${hoursAgo} hour${hoursAgo === 1 ? "" : "s"} ago`;
        } else {
            return "Just now";
        }
    };

    return getTimeAgo;
}