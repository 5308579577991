import {
    Typography,
    Box,
    useTheme,
    IconButton
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useNavigateToCollection from "../../../../hooks/navigation/useNavigateToCollection";

export default function ItemViewNavbar(props) {
    const {
        collectionTitle,
        collectionId
    } = props;

    const theme = useTheme();

    const navigateToCollection = useNavigateToCollection();

    return (
        <Box
            sx={{
                p: 2
            }}
        >
            <IconButton
                onClick={() => navigateToCollection(collectionId)}
                variant="contained"
                sx={{
                    backgroundColor: theme.palette.grey["300"],
                    borderRadius: 1,
                    mb: 2
                }}
            >
                <ArrowBackIosNewIcon
                    sx={{
                        pr: 0.25,
                        fontSize: 16
                    }}
                />
            </IconButton>
            <Typography
                onClick={() => navigateToCollection(collectionId)}
                variant="h6"
                sx={{
                    color: theme.palette.grey["700"],
                    fontWeight: "bold"
                }}
            >
                {collectionTitle}
            </Typography>
        </Box>
    );
}