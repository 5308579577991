import {
    useSelector
} from "react-redux";
import axios from "axios";
import ReactGA from "react-ga4";

import {
    getUserData
} from "../../store/user/userSlice";
import apiRoutes from "../../constants/api-routes";

export default function useHandleFollow() {
    const {
        userId,
        token
    } = useSelector(getUserData);

    const follow = (collectionId, callback) => {
        axios.post(apiRoutes.addCollectionFollow, {
            userId,
            collectionId
        }, {
            headers: {
                "authorization": `Bearer ${token}`
            }
        }).then(() => {
            callback();
        });
    };

    const unfollow = (collectionId, callback) => {
        axios.post(apiRoutes.deleteCollectionFollow, {
            userId,
            collectionId
        }, {
            headers: {
                "authorization": `Bearer ${token}`
            }
        }).then(() => {
            callback();
        });
    };

    const handleFollow = (isFollowing, collectionId, openSignUpModal, callback) => {
        const TRACKING_ID = "G-CSY9YW7QWL";
        ReactGA.initialize(TRACKING_ID);
        ReactGA.event("follow-clicked");

        if (!userId || !token) {
            openSignUpModal();
            return;
        }

        if (isFollowing) {
            unfollow(collectionId, callback);
        } else {
            follow(collectionId, callback);
        }
    };

    return handleFollow;
}