import {
    Link, useTheme
} from "@mui/material";

import useNavigateToWhatIsSlyke from "../../../hooks/navigation/useNavigateToWhatIsSlyke";

export default function WhatIsSlykeLink(props) {
    const {
        sx
    } = props;

    const theme = useTheme();
    const navigateToWhatIsSlyke = useNavigateToWhatIsSlyke();

    return (
        <Link
            onClick={navigateToWhatIsSlyke}
            color={theme.palette.common.black}
            sx={{
                ml: 1,
                mb: 0.75,
                alignSelf: "self-end",
                textDecoration: "none",
                fontStyle: "italic",
                fontSize: 12,
                fontFamily: theme.typography.fontFamily,
                letterSpacing: 0.5,
                "&:hover": {
                    cursor: "pointer",
                    color: theme.palette.primary.main
                },
                ...sx
            }}
        >
            What Is Slyke?
        </Link>
    );
}