import { Box, Typography, useTheme } from "@mui/material";

export default function UserDisplayName(props) {
    const {
        displayName,
        handle
    } = props;

    const theme = useTheme();

    return (
        <Box>
            <Typography
                variant="h4"
                sx={{
                    mt: 2,
                    mx: "auto",
                    fontWeight: "bold",
                    textAlign: "center",
                    width: 300
                }}
            >
                {displayName}
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    mx: "auto",
                    textAlign: "center",
                    width: 300,
                    color: theme.palette.grey["500"]
                }}
            >
                {handle}
            </Typography>
        </Box>
    );
}