import {
    Alert,
    Snackbar,
    Typography
} from "@mui/material";

export default function BasicAlert(props) {
    const {
        severity,
        showAlert,
        setShowAlert,
        alertText,
        sx
    } = props;

    return (
        <Snackbar
            open={showAlert}
            autoHideDuration={3000}
            onClose={() => setShowAlert(false)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            sx={{ ...sx }}
        >
            <Alert
                onClose={() => setShowAlert(false)}
                severity={severity ? severity : "warning"}
                sx={{
                    width: "100%"
                }}
            >
                <Typography

                >
                    {alertText}
                </Typography>
            </Alert>
        </Snackbar>
    );
}