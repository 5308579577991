import {
    Box,
    Card, Typography, useTheme
} from "@mui/material";
import {
    useState
} from "react";

import useGetImageLink from "../../../../../hooks/data/useGetImageLink";

import ItemCarouselButtons from "./ItemCarouselButtons/ItemCarouselButtons";
import ItemCarouselIcons from "./ItemCarouselIcons/ItemCarouselIcons";
import ViewCollectionCTA from "./ViewCollectionCTA/ViewCollectionCTA";
import useNavigateToItem from "../../../../../hooks/navigation/useNavigateToItem";

export default function ItemCarousel(props) {
    const {
        items,
        collectionId
    } = props;

    const theme = useTheme();
    const getImageLink = useGetImageLink();
    const navigateToItem = useNavigateToItem();

    const [itemPreviewIndex, setItemPreviewIndex] = useState(0);

    if (items.length === 0) {
        return null;
    } else {
        const item = items[itemPreviewIndex];
        return (
            <Card
                variant="outlined"
                sx={{
                    p: 1,
                    my: 3
                }}
            >
                {
                    items.length !== itemPreviewIndex + 1 && itemPreviewIndex !== 3 ?
                        <Typography
                            variant="body1"
                            sx={{
                                px: 1,
                                color: theme.palette.grey["500"]
                            }}
                        >
                            {item.title}
                        </Typography> : null
                }
                <Box
                    sx={{
                        mt: 2,
                        textAlign: "center",
                        position: "relative"
                    }}
                >
                    {
                        items.length === itemPreviewIndex + 1 || itemPreviewIndex === 3 ?
                            <ViewCollectionCTA
                                collectionId={collectionId}
                            /> :
                            <img
                                onClick={() => navigateToItem(collectionId, itemPreviewIndex)}
                                width={250}
                                src={getImageLink({
                                    image: item.image,
                                    variant: "itempreviewthumb",
                                    itemType: item.itemType,
                                    ogVideoLink: item.ogVideoLink,
                                    height: 400,
                                    width: 400
                                })}
                                style={{
                                    borderRadius: "16px"
                                }}
                            />
                    }
                    <ItemCarouselButtons
                        leftButton={itemPreviewIndex > 0 && !(items.length === itemPreviewIndex + 1 || itemPreviewIndex === 3)}
                        leftButtonOnClick={() => setItemPreviewIndex(itemPreviewIndex - 1)}
                        rightButton={itemPreviewIndex < items.length && itemPreviewIndex < 3}
                        rightButtonOnClick={() => setItemPreviewIndex(itemPreviewIndex + 1)}
                    />
                    <ItemCarouselIcons
                        previewItemCount={items.length >= 3 ? 3 : items.length}
                        itemPreviewIndex={itemPreviewIndex}
                    />
                </Box>
            </Card>
        );
    }
}