import { configureStore } from '@reduxjs/toolkit'
import userSlice from './user/userSlice'
import collectionSlice from './collection/collectionSlice'
import storage from 'redux-persist/lib/storage'
import {
    persistReducer,
    persistStore
} from 'redux-persist'

const persistConfig = {
    key: 'root',
    storage,
}
const persistedUserSlice = persistReducer(persistConfig, userSlice)
// const persistedCollectionSlice = persistReducer(persistConfig, collectionSlice)

export const store = configureStore({
    reducer: {
        user: persistedUserSlice,
        collection: collectionSlice
    }
})

export const persistor = persistStore(store)