import {
    Grid,
    Typography,
    Button,
    TextField,
    useTheme
} from "@mui/material";
import ReactGA from "react-ga4";
import BaseModal from "../Common/BaseModal/BaseModal";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import useValidateEmail from "../../hooks/validation/useValidateEmail";
import axios from "axios";
import apiRoutes from "../../constants/api-routes";
import CheckIcon from "@mui/icons-material/Check";

export default function PopupA(props) {
    const {
        isMobile,
        isOpen,
        close
    } = props;

    const validateEmail = useValidateEmail();
    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(true);
    const [submitted, setSubmitted] = useState(false);

    const [cookies, setCookie] = useCookies(["slyke-item-cta"]);
    const theme = useTheme();
    const textStyle = {
        fontFamily: "Open Sans"
    };

    useEffect(() => {
        if (isOpen && !cookies.shown) {
            const TRACKING_ID = "G-CSY9YW7QWL";
            ReactGA.initialize(TRACKING_ID);
            ReactGA.event("popup-a-shown");

            setCookie("shown", true);
        }
    }, [isOpen]);

    const handleSubmit = () => {
        if (email && emailValid) {
            axios.get(apiRoutes.contact, {
                params: {
                    name: "Interested Curator",
                    email,
                    vategory: "Curator Request",
                    userMessage: "I responded to popup modal A"
                }
            }).then(() => {
                setSubmitted(true);
            });
        }
    };

    return (
        <BaseModal
            open={cookies.shown ? false : isOpen}
            onClose={close}
            closeButton
        >
            {
                !submitted ?
                    <Grid
                        container
                        sx={{
                            px: !isMobile ? 6 : 2,
                            pb: !isMobile ? 6 : 4,
                            pt: !isMobile ? 4 : 2
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                textAlign: "center",
                                mt: 2,
                                mb: !isMobile ? 1 : 2
                            }}
                        >
                            <Typography
                                variant={!isMobile ? "h5" : "h6"}
                                sx={{
                                    ...textStyle,
                                    fontWeight: "bold"
                                }}
                            >
                                Are you a creator?
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: !isMobile ? 1 : 2
                            }}
                        >
                            <Typography
                                variant={!isMobile ? "h6" : "body1"}
                                sx={{
                                    ...textStyle,
                                    fontWeight: "bold",
                                    textAlign: "center"
                                }}
                            >
                                Join our small circle of creators to help shape Slyke
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: !isMobile ? 1 : 2
                            }}
                        >
                            <Typography
                                variant={!isMobile ? "body1" : "body2"}
                                sx={{
                                    ...textStyle,
                                    px: !isMobile ? 4 : 3,
                                    textAlign: "center",
                                }}
                            >
                                We’re inviting a select group of passionate individuals to join Slyke as a power curator. If you’re passionate about your interests and want to have influence on Slyke, apply now for a power curator role.
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: !isMobile ? 1 : 1,
                                textAlign: "center"
                            }}
                        >
                            <Typography
                                variant={!isMobile ? "body1" : "body2"}
                                sx={{
                                    ...textStyle,
                                }}
                            >
                                There are currently <span style={{ textDecoration: "underline" }}>four</span> spots remaining.
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{
                                textAlign: "center",
                                mt: !isMobile ? 1 : 2
                            }}
                        >
                            <TextField
                                sx={{
                                    p: 1,
                                    minWidth: 250
                                }}
                                placeholder="email"
                                name="email"
                                type="email"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    validateEmail(
                                        e.target.value,
                                        () => setEmailValid(false),
                                        () => setEmailValid(true)
                                    );
                                }}
                                error={!emailValid}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                textAlign: "center",
                                mt: !isMobile ? 1 : 2
                            }}
                        >
                            <Button
                                variant='contained'
                                onClick={() => {
                                    handleSubmit();
                                }}
                                sx={{
                                    color: "white",
                                    fontFamily: "Open Sans",
                                    fontSize: 16,
                                    textTransform: "none",
                                    minWidth: 250
                                }}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid> :
                    <Grid>
                        <Grid
                            container
                            sx={{
                                px: !isMobile ? 6 : 2,
                                pb: !isMobile ? 6 : 4,
                                pt: !isMobile ? 4 : 2
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    textAlign: "center",
                                    mt: 2,
                                    mb: !isMobile ? 1 : 2
                                }}
                            >
                                <CheckIcon
                                    sx={{
                                        fontSize: 75,
                                        color: theme.palette.common.white,
                                        backgroundColor: "#6685FF",
                                        borderRadius: 5,
                                        mb: isMobile ? 1 : 2,
                                        mt: isMobile ? 1 : 2
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    textAlign: "center",
                                    mt: 2,
                                    mb: !isMobile ? 1 : 2
                                }}
                            >
                                <Typography
                                    variant={!isMobile ? "h5" : "h6"}
                                    sx={{
                                        ...textStyle,
                                        fontWeight: "bold"
                                    }}
                                >
                                    Thank you for your interest, we’ll be in touch!
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    textAlign: "center",
                                    mt: 2,
                                    mb: !isMobile ? 1 : 2
                                }}
                            >
                                <Typography
                                    variant={!isMobile ? "body1" : "body2"}
                                    sx={{
                                        ...textStyle,
                                        fontWeight: "bold",
                                        maxWidth: 400,
                                        mx: "auto"
                                    }}
                                >
                                    You can start creating collections on Slyke now! Click the ‘Create’ button on the menu.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
            }
        </BaseModal>
    );
}