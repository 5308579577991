import {
    useState
} from "react";

import CommentsModal from "../../components/Common/CommentsModal/CommentsModal";

export default function useCommentsModal() {
    const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);

    const openCommentsModal = () => {
        setIsCommentsModalOpen(true);
    };

    const closeCommentsModal = () => {
        setIsCommentsModalOpen(false);
    };

    return {
        isCommentsModalOpen,
        openCommentsModal,
        closeCommentsModal,
        CommentsModal
    };
}