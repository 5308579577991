import {
    Box,
    Typography
} from "@mui/material";

export default function Header(props) {
    const {
        isMobile
    } = props;

    return (
        <Box>
            <Typography
                variant='h4'
                sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    mb: 3
                }}
            >
                Contact Slyke
            </Typography>
            <Typography
                sx={{
                    textAlign: "center",
                    mb: 1
                }}
            >
                Fill out the fields below to send us a message.
            </Typography>
            <Typography
                sx={{
                    textAlign: "center",
                    mb: 5
                }}
            >
                You can also DM us on our social accounts if you prefer!
            </Typography>
        </Box>
    );
}