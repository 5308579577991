import { Box } from "@mui/material";
import { useState } from "react";

import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import PasswordRequirements from "./PasswordRequirements/PasswordRequirements";
import PasswordTextField from "../PasswordTextField/PasswordTextField";

export default function PasswordForm(props) {
    const {
        isMobile,
        password,
        setPassword,
        repeatPassword,
        setRepeatPassword,
        handleSubmit
    } = props;

    const [isValidLength, setIsValidLength] = useState(false);
    const [isValidUppercase, setIsValidUppercase] = useState(false);
    const [isValidLowercase, setIsValidLowercase] = useState(false);
    const [isValidSymbol, setIsValidSymbol] = useState(false);
    const [isValidNumber, setIsValidNumber] = useState(false);
    const [isMatching, setIsMatching] = useState(false);

    const validateLength = (password) => {
        if (password.length >= 8 && password.length <= 256) {
            setIsValidLength(true);
        } else {
            setIsValidLength(false);
        }
    };

    const validateUppercase = (password) => {
        if (/[A-Z]/.test(password)) {
            setIsValidUppercase(true);
        } else {
            setIsValidUppercase(false);
        }
    };

    const validateLowercase = (password) => {
        if (/[a-z]/.test(password)) {
            setIsValidLowercase(true);
        } else {
            setIsValidLowercase(false);
        }
    };

    const validateSymbol = (password) => {
        const symbolRegex = /[!@#$%^&*\-_+=,.?]/;

        if (symbolRegex.test(password)) {
            setIsValidSymbol(true);
        } else {
            setIsValidSymbol(false);
        }
    };

    const validateNumber = (password) => {
        if (/[0-9]/.test(password)) {
            setIsValidNumber(true);
        } else {
            setIsValidNumber(false);
        }
    };

    const validateMatching = (eventPassword) => {
        if (eventPassword === password || eventPassword === repeatPassword) {
            setIsMatching(true);
        } else {
            setIsMatching(false);
        }
    };

    const validatePassword = (password) => {
        validateLength(password);
        validateUppercase(password);
        validateLowercase(password);
        validateSymbol(password);
        validateNumber(password);

        validateMatching(password);
    };

    return (
        <Box>
            <PasswordTextField
                isMobile={isMobile}
                password={password}
                setPassword={setPassword}
                setPasswordCallback={validatePassword}
                text="New Password"
            />
            <PasswordTextField
                isMobile={isMobile}
                password={repeatPassword}
                setPassword={setRepeatPassword}
                setPasswordCallback={(password) => validateMatching(password)}
                text="Confirm New Password"
            />
            <PasswordRequirements
                isMobile={isMobile}
                isValidLength={isValidLength}
                isValidUppercase={isValidUppercase}
                isValidLowercase={isValidLowercase}
                isValidSymbol={isValidSymbol}
                isValidNumber={isValidNumber}
                isMatching={isMatching}
            />
            <PrimaryButton
                onClick={handleSubmit}
                disabled={
                    !isValidLength ||
                    !isValidUppercase ||
                    !isValidLowercase ||
                    !isValidSymbol ||
                    !isValidNumber ||
                    !isMatching
                }
                text="Submit"
                sx={{
                    width: !isMobile ? 300 : "90%",
                    mt: 1,
                    mb: 2
                }}
            />
        </Box>
    );
}