import {
    Grid
} from "@mui/material";
import { useEffect } from "react";
import ReactGA from "react-ga4";

import BaseModal from "../../BaseModal/BaseModal";
import CloseButton from "../CloseButton/CloseButton";
import ModalHeader from "../SignUp/ModalHeader/ModalHeader";
import FormBody from "./FormBody/FormBody";
import DesktopSideColumn from "./DesktopSideColumn/DesktopSideColumn";

export default function SignUp(props) {
    const {
        isMobile,
        signUp,
        onCloseSignUp,
        openSignIn,
        openSignUpSuccess
    } = props;

    useEffect(() => {
        if (signUp) {
            const TRACKING_ID = "G-CSY9YW7QWL";
            ReactGA.initialize(TRACKING_ID);
            ReactGA.event("sign-up-modal-opened");
        }
    }, [signUp]);

    return (
        <BaseModal
            open={signUp}
            onClose={onCloseSignUp}
            sx={{
                overflowY: isMobile ? "scroll" : null
            }}
        >
            <Grid
                container
                direction='row'
            >
                <Grid
                    item
                    xs={12}
                    sm={7}
                    sx={{
                        p: 2,
                        pl: {
                            xs: 5,
                            sm: 4
                        }
                    }}
                >
                    <ModalHeader
                        isMobile={isMobile}
                    />
                    <FormBody
                        isMobile={isMobile}
                        onCloseSignUp={onCloseSignUp}
                        openSignUpSuccess={openSignUpSuccess}
                        openSignIn={openSignIn}
                    />
                </Grid>

                <DesktopSideColumn
                    close={onCloseSignUp}
                    openSignIn={openSignIn}
                />
            </Grid>

            <CloseButton
                isMobile={isMobile}
                onClose={onCloseSignUp}
            />
        </BaseModal>
    );
}