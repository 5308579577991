import {
    Button, useTheme
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import useNavigateToRandomCollection from "../../../../hooks/navigation/useNavigateToRandomCollection";

export default function RandomCollectionButton(props) {
    const {
        userId
    } = props;

    const theme = useTheme();

    const navigateToRandomCollection = useNavigateToRandomCollection();

    return (
        <Button
            onClick={() => navigateToRandomCollection(userId)}
            color='secondary'
            variant='contained'
            sx={{
                textTransform: "none",
                height: 25,
                my: 0.5,
                py: 2,
                fontSize: 14,
                color: theme.palette.common.white,
                borderRadius: 0.5,
                "&:hover": {
                    backgroundColor: theme.palette.grey["900"]
                }
            }}
        >
            Take Me To A Random Collection
            <ArrowForwardIcon
                style={{
                    fontSize: 22,
                    margin: 4
                }}
            />
        </Button>
    );
}