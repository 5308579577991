import {
    useSelector
} from "react-redux";
import axios from "axios";
import ReactGA from "react-ga4";

import {
    getUserData
} from "../../store/user/userSlice";
import apiRoutes from "../../constants/api-routes";

export default function useHandleItemLike() {
    const {
        isLoggedIn,
        userId,
        token
    } = useSelector(getUserData);

    const handleLike = (item, onSuccess, onFailure) => {
        const TRACKING_ID = "G-CSY9YW7QWL";
        ReactGA.initialize(TRACKING_ID);
        ReactGA.event("like-clicked");

        if (isLoggedIn) {
            if (!item.isLiked) {
                axios.post(apiRoutes.likeItem, {
                    itemId: item.itemId,
                    userId
                }, {
                    headers: {
                        "authorization": `Bearer ${token}`
                    }
                }).then(onSuccess);
            } else {
                axios.post(apiRoutes.unlikeItem, {
                    itemId: item.itemId,
                    userId
                }, {
                    headers: {
                        "authorization": `Bearer ${token}`
                    }
                }).then(onSuccess);
            }
        } else {
            onFailure();
        }
    };

    return handleLike;
}