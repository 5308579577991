import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import { Link, useTheme } from '@mui/material'
import acceptedEmoji from '../../assets/icons/accepted-invite-emoji.png'
import EditIcon from '@mui/icons-material/Edit'

export default function CuratorInviteAccepted(props) {
    const theme = useTheme()

    const modalBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            xs: '95%',
            sm: 660
        },
        bgcolor: 'background.paper',
        boxShadow: 24,
        py: 8,
        px: 1,
        m: 0,
        borderRadius: 5,
        '&:active': {
            outline: 'none'
        },
        '&:focus': {
            outline: 'none'
        },
        textAlign: 'center'
    }

    return (
        <Modal
            open={props.open || false}
            onClose={() => {
                props.close()
            }}
        >
            <Box
                sx={modalBoxStyle}
            >
                <img
                    src={acceptedEmoji}
                />
                <Typography
                    align='center'
                    variant='h4'
                    sx={{
                        fontFamily: 'Open Sans',
                        mb: 4,
                        width: 400,
                        mx: 'auto'
                    }}
                >
                    You are now a Curator of this Collection
                </Typography>
                <Typography
                    align='center'
                    sx={{
                        fontFamily: 'Open Sans',
                        width: 500,
                        mx: 'auto'
                    }}
                >
                    You will be notified when new items are submitted to review. You can also view pending Items in “My Queue” on your My Slyke page
                </Typography>
                <Typography
                    align='center'
                    sx={{
                        fontFamily: 'Open Sans',
                        width: 500,
                        mx: 'auto',
                        mt: 4
                    }}
                >
                    On the collection page, you can click the <EditIcon sx={{ fontSize: 14 }} /> Button to toggle view pending items, too.
                </Typography>

                <Button
                    variant='contained'
                    sx={{
                        backgroundColor: theme.palette.primary.light,
                        border: `${theme.palette.primary.light} solid 1px`,
                        color: theme.palette.common.white,
                        fontFamily: theme.typography.openSans,
                        fontWeight: 'bold',
                        textTransform: 'none',
                        letterSpacing: 0.5,
                        borderRadius: 8,
                        px: 4,
                        '&:hover': {
                            cursor: 'pointer',
                            backgroundColor: theme.palette.grey['700'],
                            border: `${theme.palette.grey['700']} solid 1px`
                        },
                        mt: 6
                    }}
                >
                    Got it
                </Button>

                <Typography
                    variant='subtitle2'
                    align='center'
                    sx={{
                        fontFamily: 'Open Sans',
                        width: 500,
                        mx: 'auto',
                        mt: 4
                    }}
                >
                    More Questions?&nbsp;
                    <Link
                        href={'/faq'}
                        sx={{
                            color: theme.palette.secondary.main,
                            textDecorationColor: theme.palette.secondary.main
                        }}
                    >
                        Visit the FAQ Page
                    </Link>
                </Typography>
                {/* Close Icon */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: {
                            xs: 9,
                            sm: 5
                        },
                        left: {
                            xs: 9,
                            sm: 5
                        },
                        margin: 'auto'
                    }}
                >
                    <IconButton
                        onClick={() => {
                            props.close()
                        }}
                        sx={{
                            m: 0,
                            p: 0
                        }}
                    >
                        <CloseIcon
                            sx={{
                                color: theme.palette.grey['700']
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>
        </Modal>
    )
}