import { Box, Typography, useTheme } from "@mui/material";

export default function ItemTitle(props) {
    const {
        itemTitle,
        onClick
    } = props;

    const theme = useTheme();

    return (
        <Box
            onClick={onClick}
            sx={{
                width: "95%"
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    color: theme.palette.grey["900"],
                    fontWeight: "bolder",
                    mb: 1,
                    "&:hover": {
                        cursor: onClick ? "pointer" : null,
                        color: onClick ? theme.palette.primary.main : null
                    }
                }}
            >
                {itemTitle}
            </Typography>
        </Box>
    );
}