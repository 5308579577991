import {
    Typography,
    Box
} from '@mui/material'
import colors from '../../constants/colors'

export default function ItemSubmitted(props) {
    const {
        isMobile
    } = props;

    const textStyle = {
        fontFamily: 'Open Sans'
    }

    return (
        <Box
            sx={{
                p: 4
            }}
        >
            <Typography
                variant='h4'
                sx={{
                    ...textStyle,
                    textAlign: 'center',
                    mb: 2
                }}
            >
                Item submitted to the collection!
            </Typography>
            <Box
                sx={{
                    textAlign: 'center'
                }}
            >
                <Box
                    sx={{
                        m: 'auto',
                        width: !isMobile ? 450 : "100%"
                    }}
                >
                    <Typography
                        variant='body1'
                        sx={{
                            ...textStyle,
                            textAlign: 'center',
                            color: colors.gray
                        }}
                    >
                        Item is pending review by the curator and we&apos;ll notify you when that&apos;s done. This helps keep Slyke safe, organized and awesome!
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}


