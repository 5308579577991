import {
    Typography,
    Box,
    IconButton,
    Link
} from '@mui/material'
import colors from '../../constants/colors'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'

export default function BlankItemThumbnail(props) {
    const textStyle = {
        fontFamily: 'Open Sans',
        fontSize: 18
    }

    return (
        <Box>
            <Box
                onClick={props.setItemModalOpen}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    width: 360,
                    height: 360,
                    border: `${colors.lightGray} solid 20px`,
                    borderRadius: 4,
                    margin: 'auto',
                    '&:before': {
                        border: `${colors.lightGray} dashed 2px`,
                        content: '" "',
                        width: 356,
                        height: 356,
                        position: 'absolute'
                    }
                }}
            >
                <IconButton>
                    <AddOutlinedIcon
                        sx={{
                            fontSize: 30,
                            border: `${colors.gray} solid 2px`,
                            borderRadius: 1,
                            color: colors.gray,
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}
                    />
                </IconButton>
                <Box
                    sx={{
                        textAlign: 'center',
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}
                >
                    <Link
                        component='button'
                        sx={{
                            textAlign: 'center',
                            ...textStyle,
                            color: colors.secondary,
                            textDecorationColor: colors.secondary
                        }}
                    >
                        Add an Item
                    </Link>
                </Box>
                <Typography
                    sx={{
                        textAlign: 'center',
                        ...textStyle
                    }}
                >
                    to get this collection started!
                </Typography>
                <Typography
                    sx={{
                        textAlign: 'center',
                        ...textStyle
                    }}
                >
                    Empty Slyke Collections will get removed.
                </Typography>
            </Box>
        </Box>
    )
}