import {
    Typography,
    TextField,
    Grid
} from "@mui/material";
import { Fragment } from "react";

export default function Description(props) {
    const {
        itemDescription,
        setItemDescription
    } = props;

    const charactersRemaining = itemDescription ? 250 - itemDescription.length : 250

    return (
        <Fragment>
            <Grid
                container
            >
                <Grid
                    item
                    xs
                >
                    <Typography>
                        Description
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={7}
                    textAlign="right"
                >
                    <Typography
                        variant='caption'
                        sx={{
                            verticalAlign: "sub"
                        }}
                    >
                        {charactersRemaining} Characters Remaining
                    </Typography>
                </Grid>
            </Grid>
            <TextField
                value={itemDescription}
                onInput={(e) => {
                    setItemDescription(e.target.value)
                }}
                multiline
                rows={4}
                inputProps={{ maxLength: 250 }}
                sx={{
                    '& .MuiOutlinedInput-input': {
                        py: 1
                    },
                    '& .MuiOutlinedInput-input': {
                        p: 0
                    },
                    '& .MuiOutlinedInput-root': {
                        p: 0.8
                    },
                    mb: 1
                }}
            />
        </Fragment>
    );
}