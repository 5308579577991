import {
    useState,
    useEffect
} from "react";
import { useDispatch } from "react-redux";

import {
    getForYouCollections,
    getPopularCollections,
    getRandomCollections,
    getRecentlyAddedCollections,
    getTrendingCollections,
    getFollowedCollections,
    getCuratedCollections,
    updateSpecificCollection
} from "../../store/collection/collectionSlice";

export default function useActiveFeed(userId, disableUpdates, isSearch) {
    /* 
    
    This hook handles the feeds that users browse.

    userId - the logged in user's ID or null if not logged in

    disableUpdates - disables any updates to the feeds, used when
    a component needs the refresh function and not to control feeds

    isSearch - when true, it disables the first update of the active feed
    this prevents the race condition between search and active feed updates

    */

    const [activeFeed, setActiveFeed] = useState("Trending");
    const [segment, setSegment] = useState(1);
    const [firstRender, setFirstRender] = useState(isSearch);
    const dispatch = useDispatch();

    const feedMapping = {
        "Trending": getTrendingCollections,
        "Most Popular": getPopularCollections,
        "For You": getForYouCollections,
        "Recently Added": getRecentlyAddedCollections,
        "Random": getRandomCollections,
        "Followed Collections": getFollowedCollections,
        "Curated Collections": getCuratedCollections
    };

    const incrementSegment = () => {
        setSegment(segment + 1);
    };

    const refreshCollection = (collectionId) => {
        dispatch(updateSpecificCollection({ userId, collectionId }));
    };

    useEffect(() => {
        if (!disableUpdates && !firstRender && feedMapping[activeFeed]) {
            setSegment(1);
            dispatch(feedMapping[activeFeed]({ userId, segment: 1, overwrite: true }));
        }

        setFirstRender(false);
    }, [activeFeed]);

    useEffect(() => {
        if (!disableUpdates && !firstRender && segment !== 1 && feedMapping[activeFeed]) {
            dispatch(feedMapping[activeFeed]({ userId, segment, overwrite: false }));
        }

        setFirstRender(false);
    }, [segment]);

    return {
        activeFeed,
        setActiveFeed,
        segment,
        setSegment,
        incrementSegment,
        refreshCollection
    };
}