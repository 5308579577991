import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Box } from "@mui/material";

import ContentCard from "../../../Common/ContentCard/ContentCard";
import FeedButtons from "../../../Common/FeedButtons/FeedButtons";
import ItemBookmarks from "./ItemBookmarks/ItemBookmarks";

import useNavigateToCollection from "../../../../hooks/navigation/useNavigateToCollection";
import useGetImageLink from "../../../../hooks/data/useGetImageLink";
import useActiveFeed from "../../../../hooks/state/useActiveFeed";
import useGetBookmarks from "../../../../hooks/data/useGetBookmarks";

export default function UserFeed(props) {
    const {
        collections,
        profileUserId
    } = props;

    const navigateToCollection = useNavigateToCollection();
    const getImageLink = useGetImageLink();
    const getBookmarks = useGetBookmarks();

    const [searchParams] = useSearchParams();

    const [bookmarks, setBookmarks] = useState([]);

    const bookmarksPerPage = 3;

    const {
        activeFeed,
        setActiveFeed,
        segment,
        setSegment,
        incrementSegment
    } = useActiveFeed(profileUserId);

    const updateBookmarkItem = (index) => {
        /*
        
        Using the item's index, update it to reflect changes in state.
        We can use the API's pagination feature to accomplish this.

        */

        getBookmarks(
            1,
            index + 1,
            (res) => {
                setBookmarks(prev => {
                    // make a copy and update the element at the given index
                    let copy = [...prev];
                    copy[index] = res.data.data[0];

                    return copy;
                });
            }
        );
    };

    useEffect(() => {
        /* 
        
        On profile load and param change, change feed to followed collections.

        */

        if (searchParams.get("filter") === "curated collections") {
            setActiveFeed("Curated Collections");
        } else if (searchParams.get("filter") === "bookmarked items") {
            setActiveFeed("Bookmarks");
        } else {
            setActiveFeed("Followed Collections");
        }
    }, [searchParams.get("filter")]);

    useEffect(() => {
        /* 

            since bookmarks do not use redux, they are not easily included in the active filter hook
            once redux is eliminated, it will be much easier to include this within that hook 

        */

        if (activeFeed === "Bookmarks") {
            getBookmarks(bookmarksPerPage, segment, (res) => {
                setBookmarks(res.data.data);
            });
        }
    }, [activeFeed]);

    useEffect(() => {
        if (segment !== 1 && activeFeed === "Bookmarks") {
            getBookmarks(bookmarksPerPage, segment, (res) => setBookmarks(prev => [...prev, ...res.data.data]));
        }
    }, [segment]);

    return (
        <Box>
            <FeedButtons
                activeFeed={activeFeed}
                setActiveFeed={setActiveFeed}
                manualFeeds={[
                    { feedName: "Followed Collections" },
                    { feedName: "Curated Collections" },
                    { feedName: "Bookmarks" }
                ]}
                isMobile
            />

            {
                activeFeed === "Bookmarks" ?
                    <ItemBookmarks
                        bookmarks={bookmarks}
                        bookmarksPerPage={bookmarksPerPage}
                        setSegment={setSegment}
                        segment={segment}
                        updateBookmarkItem={updateBookmarkItem}
                    /> :
                    collections.map((collection, ind) => {
                        if (collection.items.length) {
                            return (
                                <Box
                                    key={`co-preview-${collection.collectionTitle}-${ind}`}
                                    sx={{
                                        my: 2
                                    }}
                                >
                                    <ContentCard
                                        title={collection.collectionTitle}
                                        collectionId={collection.collectionId}
                                        imageURL={getImageLink({
                                            image: collection.items[0].image,
                                            variant: "mobileitemview",
                                            itemType: collection.items[0].itemType,
                                            ogVideoLink: collection.items[0].ogVideoLink,
                                            height: 200,
                                            width: 200
                                        })}
                                        isMobile
                                        onClick={() => navigateToCollection(collection.collectionId)}
                                        onInView={() => {
                                            if (ind % 8 === 0 && ind > 0) {
                                                incrementSegment();
                                            }
                                        }}
                                        centered
                                    />
                                </Box>
                            );
                        }
                    })
            }
        </Box>
    );
}