import {
    Modal,
    IconButton,
    Box,
    Typography,
    useTheme,
    TextField,
    Button,
    Chip,
    Grid,
    Checkbox,
    Popover
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SendIcon from '@mui/icons-material/Send'
import colors from '../../constants/colors'
import TagHandler from '../tag-handler/tag-handler'
import { useState } from 'react'
import apiRoutes, { cfImageRoot } from '../../constants/api-routes'
import axios from 'axios'
import { cfImageWorker } from '../../constants/api-routes'
import { FileUploader } from 'react-drag-drop-files'
import { getToken } from '../../store/user/userSlice'
import { useSelector } from 'react-redux'
import { Fragment } from 'react'
import {
    getUserId
} from '../../store/user/userSlice'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

export default function CollectionSettingsModal(props) {
    const theme = useTheme()
    const navigate = useNavigate()
    const token = useSelector(getToken)
    const userId = useSelector(getUserId)
    const [tagChoices, setTagChoices] = useState(props.collection ? props.collection.tags : [])
    const [file, setFile] = useState(null)
    const [removalReason, setRemovalReason] = useState('')
    const [removalCurator, setRemovalCurator] = useState('')
    const [removalFailedModal, setRemovalFailedModal] = useState(null)
    const [removalReasonModal, setRemovalReasonModal] = useState(null)
    const [removalReasonModal2, setRemovalReasonModal2] = useState(null)
    const [curators, setCurators] = useState(props.collection ? props.collection.curators : [])
    const modalBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            xs: '95%',
            sm: 660
        },
        height: window.innerHeight < 800 ? 650 : 'auto',
        overflowY: window.innerHeight < 800 ? 'scroll' : 'none',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
        px: 5,
        m: 0,
        borderRadius: 5,
        '&:active': {
            outline: 'none'
        },
        '&:focus': {
            outline: 'none'
        }
    }

    const uploadMediaToCf = async () => {
        let res = await axios.get(cfImageWorker)
        let uploadUrl = res.data.result.uploadURL

        let formData = new FormData()
        formData.append('file', file)

        return new Promise((resolve, reject) => {
            axios.post(uploadUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                resolve(res.data.result.id)
            }).catch(() => {
                reject()
            })
        })
    }

    const handleChange = (file) => {
        if (file.type.toLowerCase().includes('video')) {
            alert('invalid media format')
            return
        } else {
            // set preview image
            file.arrayBuffer().then(buffer => {
                const blob = new Blob([buffer])
                const url = URL.createObjectURL(blob)
                const img = document.getElementById('preview-image')
                img.src = url
                // So the Blob can be Garbage Collected
                img.onload = () => URL.revokeObjectURL(url)
            })
        }

        setFile(file)
    }

    const handleSave = async () => {
        await axios.post(apiRoutes.updateCollectionTags, {
            collectionId: props.collection.collectionId,
            tags: tagChoices.map(tag => { return { tagId: tag.id } })
        }, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        })

        if (file) {
            let defaultImageHash = await uploadMediaToCf()
            await axios.post(apiRoutes.updateCollectionDefaultImage, {
                collectionId: props.collection.collectionId,
                defaultImage: defaultImageHash
            }, {
                headers: {
                    'authorization': `Bearer ${token}`
                }
            })
        }
        props.close()
    }

    useEffect(() => {
        if (props.collection && props.collection.curators && props.collection.curators !== curators) {
            setCurators(props.collection.curators)
        }
    })

    useEffect(() => {
        if (props.collection) {
            setTagChoices(props.collection.tags)
        }
    }, [props.collection])

    return (
        <Fragment>
            <Modal
                open={props.open || false}
                onClose={() => {
                    props.close()
                }}
            >
                <Box
                    sx={modalBoxStyle}
                >
                    {/* Modal Title */}
                    <Typography
                        variant='h5'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            my: 1,
                            mb: 4,
                            pb: 1.5,
                            fontWeight: 'bold',
                            letterSpacing: '1px',
                            boxShadow: `0 2px 2px -2px ${theme.palette.grey['600']}`
                        }}
                    >
                        COLLECTION SETTINGS
                    </Typography>

                    {/* Collection Title */}
                    <Typography
                        variant='body1'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            my: 1,
                            mb: 1,
                            letterSpacing: '1px',
                            color: theme.palette.grey['500'],
                            fontWeight: 'bold',
                        }}
                    >
                        Collection Name
                    </Typography>
                    <TextField
                        disabled
                        value={props.collection ? props.collection.collectionTitle : null}
                        fullWidth
                        sx={{
                            mb: 1
                        }}
                    />

                    {/* Collection Description */}
                    <Typography
                        variant='body1'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            my: 1,
                            mb: 1,
                            letterSpacing: '1px',
                            color: theme.palette.grey['500'],
                            fontWeight: 'bold',
                        }}
                    >
                        Collection Description
                    </Typography>
                    <TextField
                        disabled
                        value={props.collection ? props.collection.collectionDescription : null}
                        fullWidth
                        multiline
                        minRows={3}
                        sx={{
                            mb: 1
                        }}
                    />

                    {/* Collection Tags */}
                    <Typography
                        variant='body1'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            my: 1,
                            letterSpacing: '1px',
                            color: theme.palette.grey['500'],
                            fontWeight: 'bold',
                        }}
                    >
                        Tags (At least 3)
                    </Typography>
                    <TagHandler
                        tagChoices={tagChoices}
                        setTagChoices={setTagChoices}
                        placeholderText={'Tags (optional)'}
                    />

                    {/* Collection Default Image */}
                    <Typography
                        variant='body1'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            mt: 1,
                            mb: 1,
                            letterSpacing: '1px',
                            color: theme.palette.grey['900'],
                            fontWeight: 'bold',
                        }}
                    >
                        Default Image
                    </Typography>
                    <img
                        id='preview-image'
                        src={props.collection ? `${cfImageRoot}/${props.collection.defaultImage}/settingsthumb` : null}
                        height={80}
                        width={80}
                        style={{
                            borderRadius: '6px'
                        }}
                    />
                    <FileUploader
                        label='Upload Media File'
                        classes='file-upload'
                        handleChange={handleChange}
                        maxSize={25}
                        onSizeError={() => { alert('file is too large!') }}
                        // eslint-disable-next-line react/no-children-prop
                        children={
                            <Button
                                variant='contained'
                                sx={{
                                    mb: 4,
                                    ml: 2,
                                    px: 4,
                                    borderRadius: 8,
                                    backgroundColor: theme.palette.grey['400'],
                                    color: theme.palette.grey['200'],
                                    fontFamily: theme.typography.openSans,
                                    textTransform: 'none',
                                    letterSpacing: 0.5,
                                    '&:hover': {
                                        cursor: 'pointer',
                                        backgroundColor: theme.palette.grey['300'],
                                        color: theme.palette.grey['600']
                                    }
                                }}
                            >
                                Choose Image
                            </Button>
                        }
                    />


                    {/* Collection Curators */}
                    <Typography
                        variant='body1'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            mb: 1,
                            mt: -2,
                            letterSpacing: '1px',
                            color: theme.palette.grey['500'],
                            fontWeight: 'bold',
                        }}
                    >
                        Collection Curators
                    </Typography>
                    <Grid
                        container
                        alignItems='flex-start'
                        direction='column'
                    >
                        {
                            curators ? curators.map((curator, index) => {
                                return (
                                    <Grid
                                        item
                                        key={`curator-${index}`}
                                        xs={12}
                                        sx={{
                                            width: '100%'
                                        }}
                                    >
                                        <Grid
                                            container
                                        >
                                            <Grid
                                                item
                                                xs={4}
                                            >
                                                <Chip
                                                    label={curator.displayName.length > 20 ? curator.displayName.substring(0, 20) + '...' : curator.displayName}
                                                    sx={{
                                                        backgroundColor: theme.palette.grey['300'],
                                                        color: theme.palette.grey['600'],
                                                        px: 1,
                                                        my: 0.8
                                                    }}
                                                    icon={
                                                        <CloseIcon
                                                            onClick={(e) => {
                                                                if (curator.isHeadCurator && props.collection.curators.length === 3) {
                                                                    setRemovalFailedModal(e.currentTarget)
                                                                } else {
                                                                    setRemovalReasonModal(e.currentTarget)
                                                                    setRemovalCurator(curator)
                                                                }
                                                            }}
                                                            sx={{
                                                                fontSize: 18,
                                                                marginRight: 2,
                                                                '&:hover': {
                                                                    backgroundColor: theme.palette.primary.main,
                                                                    color: theme.palette.grey['300'],
                                                                    cursor: 'pointer',
                                                                    borderRadius: 4
                                                                },
                                                                '&:active': {
                                                                    backgroundColor: theme.palette.secondary.main,
                                                                    color: theme.palette.grey['300'],
                                                                    cursor: 'pointer',
                                                                    borderRadius: 4
                                                                }
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={1}
                                            >
                                                <Checkbox
                                                    checked={!!curators.filter(selectedCurator => selectedCurator.id === curator.id)[0].isHeadCurator}
                                                    onClick={() => {
                                                        setCurators(curators.map((itCurator) => {
                                                            if (itCurator.id === curator.id) {
                                                                return { ...itCurator, isHeadCurator: true }
                                                            } else {
                                                                return { ...itCurator, isHeadCurator: false }
                                                            }
                                                        }))
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs
                                            >
                                                {
                                                    curator.isHeadCurator ? <Typography
                                                        sx={{
                                                            color: theme.palette.grey['500'],
                                                            fontFamily: theme.typography.openSans,
                                                            textTransform: 'none',
                                                            fontWeight: 'bold',
                                                            letterSpacing: 0.5,
                                                            mt: 0.8
                                                        }}
                                                    >
                                                        Head Curator
                                                    </Typography> : null
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }) : null
                        }
                    </Grid>

                    {/* Save and Cancel Buttons */}
                    <Button
                        onClick={handleSave}
                        variant='contained'
                        sx={{
                            mr: 2,
                            mt: 2,
                            p: 1,
                            px: 6,
                            borderRadius: 8,
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.grey['200'],
                            fontFamily: theme.typography.openSans,
                            fontWeight: 'bold',
                            textTransform: 'none',
                            letterSpacing: 0.5
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={props.close}
                        variant='contained'
                        sx={{
                            mt: 2,
                            p: 1,
                            px: 6,
                            borderRadius: 8,
                            backgroundColor: theme.palette.grey['400'],
                            color: theme.palette.grey['600'],
                            fontFamily: theme.typography.openSans,
                            fontWeight: 'bold',
                            textTransform: 'none',
                            letterSpacing: 0.5,
                            '&:hover': {
                                cursor: 'pointer',
                                backgroundColor: theme.palette.grey['300'],
                            }
                        }}
                    >
                        Cancel
                    </Button>

                    {/* Close Icon */}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: {
                                xs: 9,
                                sm: 5
                            },
                            left: {
                                xs: 9,
                                sm: 5
                            },
                            margin: 'auto'
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                props.close()
                            }}
                            sx={{
                                m: 0,
                                p: 0
                            }}
                        >
                            <CloseIcon
                                sx={{
                                    color: colors.gray
                                }}
                            />
                        </IconButton>
                    </Box>
                </Box>
            </Modal >

            <Popover
                open={!!removalFailedModal}
                anchorEl={removalFailedModal}
                close={() => setRemovalFailedModal(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{
                    mt: -10
                }}
            >
                <Box
                    sx={{
                        p: 4,
                        width: 250,
                        textAlign: 'center'
                    }}
                >
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontFamily: theme.typography.openSans
                        }}
                    >
                        You must give head curator to another curator before leaving the collection!
                    </Typography>
                    <Button
                        onClick={() => {
                            setRemovalFailedModal(null)
                        }}
                        variant='contained'
                        sx={{
                            p: 1,
                            px: 6,
                            borderRadius: 8,
                            backgroundColor: theme.palette.primary.light,
                            color: theme.palette.grey['200'],
                            fontFamily: theme.typography.openSans,
                            fontWeight: 'bold',
                            textTransform: 'none',
                            letterSpacing: 0.5,
                            mt: 2
                        }}
                    >
                        OK
                    </Button>
                </Box>
            </Popover>

            {/* Removal Reason Modal */}
            <Popover
                open={!!removalReasonModal}
                anchorEl={removalReasonModal}
                close={() => setRemovalReasonModal(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{
                    mt: -10
                }}
            >
                {
                    curators.length ?
                        <Box
                            sx={{
                                p: 4,
                                width: 250,
                                textAlign: 'center'
                            }}
                        >
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    fontFamily: theme.typography.openSans
                                }}
                            >
                                Are you sure you want to remove {removalCurator.id === userId ? 'yourself' : removalCurator.displayName}?
                            </Typography>
                            <Button
                                onClick={() => {
                                    setRemovalReasonModal2(removalReasonModal)
                                    setRemovalReasonModal(null)
                                }}
                                variant='contained'
                                sx={{
                                    backgroundColor: theme.palette.secondary.light,
                                    border: `${theme.palette.secondary.light} solid 1px`,
                                    color: theme.palette.common.white,
                                    fontFamily: theme.typography.openSans,
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    letterSpacing: 0.5,
                                    borderRadius: 8,
                                    px: 4,
                                    mt: 2,
                                    '&:hover': {
                                        cursor: 'pointer',
                                        backgroundColor: theme.palette.secondary.dark,
                                    }
                                }}
                            >
                                Yes
                            </Button>
                            <Button
                                onClick={() => { setRemovalReasonModal(null) }}
                                variant='contained'
                                sx={{
                                    backgroundColor: theme.palette.background.paper,
                                    border: `${theme.palette.primary.light} solid 1px`,
                                    color: theme.palette.primary.light,
                                    fontFamily: theme.typography.openSans,
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    letterSpacing: 0.5,
                                    borderRadius: 8,
                                    px: 4,
                                    ml: 2,
                                    mt: 2,
                                    '&:hover': {
                                        cursor: 'pointer',
                                        backgroundColor: theme.palette.grey['300'],
                                    }
                                }}
                            >
                                No
                            </Button>
                        </Box> : null
                }
            </Popover>

            <Popover
                open={!!removalReasonModal2}
                anchorEl={removalReasonModal2}
                close={() => setRemovalReasonModal2(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                sx={{
                    mt: -10
                }}
            >
                {
                    curators.length ?
                        <Box
                            sx={{
                                p: 4,
                                width: 350,
                                textAlign: 'center'
                            }}
                        >
                            <Typography
                                variant='h5'
                                sx={{
                                    textAlign: 'center',
                                    fontFamily: theme.typography.openSans
                                }}
                            >
                                {
                                    removalCurator.id === userId ? 'You have been removed as a curator' :
                                        removalCurator.displayName + ' has been removed as a curator'
                                }
                            </Typography>
                            {
                                removalCurator.id === userId ?
                                    <Button
                                        onClick={() => {
                                            setRemovalReasonModal2(null)
                                            axios.post(apiRoutes.deleteCurator, {
                                                collectionId: props.collection.collectionId,
                                                curatorToDeleteId: removalCurator.id,
                                                removalReason
                                            }, {
                                                headers: {
                                                    'authorization': `Bearer ${token}`
                                                }
                                            }).then(() => {
                                                navigate('/')
                                            })
                                        }}
                                        variant='contained'
                                        sx={{
                                            p: 1,
                                            px: 6,
                                            borderRadius: 8,
                                            backgroundColor: theme.palette.primary.light,
                                            color: theme.palette.grey['200'],
                                            fontFamily: theme.typography.openSans,
                                            fontWeight: 'bold',
                                            textTransform: 'none',
                                            letterSpacing: 0.5,
                                            mt: 2
                                        }}
                                    >
                                        OK
                                    </Button> :
                                    <Fragment>
                                        <TextField
                                            placeholder={`Send a message to ${removalCurator.displayName}`}
                                            multiline
                                            value={removalReason}
                                            onChange={(e) => setRemovalReason(e.target.value)}
                                            minRows={5}
                                            sx={{
                                                width: '100%',
                                                mt: 2
                                            }}
                                        />
                                        <Button
                                            onClick={() => {
                                                setRemovalReasonModal2(null)
                                                setCurators(curators.filter(deletedCurator => deletedCurator.id !== removalCurator.id))
                                                axios.post(apiRoutes.deleteCurator, {
                                                    collectionId: props.collection.collectionId,
                                                    curatorToDeleteId: removalCurator.id,
                                                    removalReason
                                                }, {
                                                    headers: {
                                                        'authorization': `Bearer ${token}`
                                                    }
                                                })
                                            }}
                                            variant='contained'
                                            sx={{
                                                backgroundColor: theme.palette.background.paper,
                                                border: `${theme.palette.grey['500']} solid 1px`,
                                                color: theme.palette.grey['500'],
                                                fontFamily: theme.typography.openSans,
                                                fontWeight: 'bold',
                                                textTransform: 'none',
                                                letterSpacing: 0.5,
                                                borderRadius: 8,
                                                px: 4,
                                                mt: 2,
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    border: `${theme.palette.primary.light} solid 1px`,
                                                    backgroundColor: theme.palette.grey['300'],
                                                }
                                            }}
                                        >
                                            Send
                                            <SendIcon
                                                sx={{
                                                    ml: 2,
                                                    color: theme.palette.grey['500']
                                                }}
                                            />
                                        </Button>
                                        <br />
                                        <br />
                                        <Typography
                                            variant='caption'
                                        >
                                            (optional but recommended)
                                        </Typography>
                                    </Fragment>
                            }
                        </Box> : null
                }
            </Popover>
        </Fragment>
    )
}