import {
    Box,
    Typography,
    useTheme
} from "@mui/material";

import ContentCardAdornment from "../ContentCardAdornment/ContentCardAdornment";
import ContentCardButtons from "../ContentCardButtons/ContentCardButtons";

export default function ContentCardPreview(props) {
    const {
        isMobile,
        isEngagement,
        title,
        onClick,
        imageURL,
        itemType,
        isVideo,
        leftButton,
        leftButtonOnClick,
        rightButton,
        rightButtonOnClick
    } = props;

    const theme = useTheme();

    const mobileWidth = isEngagement ? 200 : 300;
    const desktopWidth = isEngagement ? 300 : 400;

    return (
        <Box
            onClick={onClick}
            sx={{
                position: "relative",
                height: !isMobile ? desktopWidth : mobileWidth,
                width: !isMobile ? desktopWidth : mobileWidth,
                borderRadius: "8px 8px",
                backgroundColor: itemType === "text" ? theme.palette.grey["900"] : "transparent",
                mx: "auto",
                "&:hover": {
                    cursor: "pointer"
                }
            }}
        >
            <img
                src={imageURL}
                loading='lazy'
                height={!isMobile ? desktopWidth : mobileWidth}
                width={!isMobile ? desktopWidth : mobileWidth}
                style={{
                    objectFit: "cover",
                    borderRadius: "8px",
                    border: `${theme.palette.grey["900"]} solid 1px`,
                    display: itemType === "text" ? "none" : null
                }}
            />

            <Box
                sx={{
                    position: "absolute",
                    top: -1,
                    backgroundColor: "rgba(0, 0, 0, 0.56)",
                    color: theme.palette.common.white,
                    width: !isMobile ? desktopWidth + 2 : mobileWidth + 2,
                    textAlign: "center",
                    py: isMobile ? 1 : 2,
                    borderRadius: "8px 8px 0px 0px"
                }}
            >
                <Typography
                    sx={{
                        px: isMobile ? 1 : 2
                    }}
                >
                    {title}
                </Typography>
            </Box>

            <Box
                sx={{
                    position: "absolute",
                    bottom: -1,
                    width: "100%",
                    textAlign: "center"
                }}
            >
                <ContentCardAdornment
                    isText={itemType === "text"}
                    isVideo={isVideo}
                />
            </Box>

            <ContentCardButtons
                leftButton={leftButton}
                leftButtonOnClick={leftButtonOnClick}
                rightButton={rightButton}
                rightButtonOnClick={rightButtonOnClick}
                isMobile={isMobile}
            />
        </Box>
    );
}