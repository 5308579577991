import {
    Link,
    useTheme
} from "@mui/material";
import {
    Fragment
} from "react";

import PanToolOutlinedIcon from "@mui/icons-material/PanToolOutlined";

export default function ApplyToCurate(props) {
    const {
        isMobile
    } = props;

    const theme = useTheme();

    return (
        <Fragment>
            <PanToolOutlinedIcon
                sx={{
                    fontSize: 14,
                    color: theme.palette.secondary.main,
                    ml: !isMobile ? 3 : 0,
                    mr: 1,
                    mt: 0.5
                }}
            />
            <Link
                onClick={() => { }}
                sx={{
                    color: theme.palette.secondary.main,
                    textDecorationColor: theme.palette.secondary.main,
                    fontFamily: "Open Sans",
                    "&:hover": {
                        cursor: "pointer",
                    }
                }}
            >
                Apply To Curate
            </Link>
        </Fragment>
    );
}