import {
    Card,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

export default function CollectionStats(props) {
    const {
        itemCount,
        followerCount
    } = props;

    const theme = useTheme();

    return (
        <Card
            variant="outlined"
            sx={{
                my: 2,
                py: 1
            }}
        >
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                    mx: "auto"
                }}
            >
                <ContentCopyIcon
                    fontSize="small"
                    sx={{
                        mr: 0.5
                    }}
                />
                <Typography>
                    {itemCount}
                </Typography>
                <Typography
                    sx={{
                        color: theme.palette.grey["500"],
                        ml: 1,
                        mr: 2
                    }}
                >
                    Items
                </Typography>

                <PeopleAltIcon
                    fontSize="small"
                    sx={{
                        mr: 0.5
                    }}
                />
                <Typography>
                    {followerCount}
                </Typography>
                <Typography
                    sx={{
                        color: theme.palette.grey["500"],
                        ml: 1
                    }}
                >
                    Followers
                </Typography>
            </Stack>
        </Card>
    );
}