export default function useEngagementMetrics() {
    const convertEngagementMetricToString = (value) => {
        if (value < 1000) {
            return value;
        } else if (value > 1000 && value < 1000000) {
            return `${Math.round(value / 1000 * 100) / 100}k`;
        } else {
            return `${Math.round(value / 1000000 * 100) / 100}m`;
        }
    };

    return convertEngagementMetricToString;
}