import ReactGA from "react-ga4";

export default function useHandleShare(shareLinkOverride) {
    /* 
    
    This hooks copies the current URL to clipboard and calls and optional callback.

    */

    const handleShare = (callback) => {
        const TRACKING_ID = "G-CSY9YW7QWL";
        ReactGA.initialize(TRACKING_ID);
        ReactGA.event("shared");

        navigator.clipboard.writeText(shareLinkOverride || window.location);
        if (callback) {
            callback();
        }
    };

    return handleShare;
}