import {
    useState
} from "react";

import AddItemModal from "../../legacy-components/add-item-modal/add-item-modal";

export default function useAddItemModal() {
    const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false);

    const openAddItemModal = () => {
        setIsAddItemModalOpen(true);
        return true;
    };

    const closeAddItemModal = () => {
        setIsAddItemModalOpen(false);
        return true;
    };

    return {
        isAddItemModalOpen,
        openAddItemModal,
        closeAddItemModal,
        AddItemModal
    };
}