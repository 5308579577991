import {
    useState
} from "react";
import {
    useSelector
} from "react-redux";

import {
    getUserData
} from "../../store/user/userSlice";

import CreateCollectionModal from "../../legacy-components/create-collection-modal/create-collection-modal";

export default function useCreateCollectionModal() {
    const {
        isLoggedIn
    } = useSelector(getUserData);
    const [isCreateCollectionModalOpen, setIsCreateCollectionModalOpen] = useState(false);

    const openCreateCollectionModal = () => {
        if (isLoggedIn) {
            setIsCreateCollectionModalOpen(true);
            return true;
        }

        return false;
    };

    const closeCreateCollectionModal = () => {
        if (isLoggedIn) {
            setIsCreateCollectionModalOpen(false);
            return true;
        }

        return false;
    };

    return {
        isCreateCollectionModalOpen,
        openCreateCollectionModal,
        closeCreateCollectionModal,
        CreateCollectionModal
    };
}