import {
    Grid,
    Typography,
    Box,
    List,
    ListItem,
    ListItemIcon,
    Icon,
    useTheme,
    Popover,
    Stack
} from '@mui/material'
import { Fragment, useState } from 'react'
import colors from '../../constants/colors'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined'
import Share from '../../assets/icons/share.svg'
import { useNavigate } from 'react-router-dom'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { cfImageRoot, cfVideoRoot } from '../../constants/api-routes'
import apiRoutes from '../../constants/api-routes'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { getToken } from '../../store/user/userSlice'

export default function ItemThumbnail(props) {
    const navigate = useNavigate()
    const theme = useTheme()
    const token = useSelector(getToken)

    const [editPopoverOpen, setEditPopoverOpen] = useState(null)

    const barIcons = [
        [<VisibilityOutlinedIcon key={0} />, props.item.views],
        [<FavoriteBorderOutlinedIcon key={1} sx={{ color: props.item.isLiked ? colors.warning : 'inherit' }} />, props.item.likes],
        [<CommentOutlinedIcon key={2} />, props.item.comments ? props.item.comments.length : 0],
        [<Icon key='shares'><img src={Share} /></Icon>, props.item.shares]
    ]

    // very ugly way to get preview/thumbnail image
    let imageLink
    if (props.item.itemType === 'media' && props.item.ogVideoLink) {
        imageLink = `${cfVideoRoot}/${props.item.ogVideoLink}/thumbnails/thumbnail.jpg?time=1s&height=400&width=400`
    } else {
        if (!props.item.image && props.item.itemType === 'link') {
            imageLink = 'https://imagedelivery.net/4jZu4HPxc6tlbuYZlGIsgQ/d4145aef-518b-4299-8df6-6311aa53e800/itempreviewthumb'
        } else {
            imageLink = `${cfImageRoot}/${props.item.image}/itempreviewthumb`
        }
    }

    const deleteItem = () => {
        axios.get(apiRoutes.deleteItem, {
            params: {
                collectionId: props.collectionId,
                itemId: props.item.itemId
            },
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(() => {
            props.refresh()
        })
    }

    return (
        <Box
            sx={{
                width: {
                    xs: 400,
                    sm: 500
                }
            }}
        >
            <Grid
                container
                onClick={() => {
                    if (!props.isRejected) {
                        navigate(`/item?collectionId=${props.collectionId}&itemIndex=${props.itemIndex}`)
                    }
                }}
            >
                <Grid
                    item
                    xs={9}
                    sx={{
                        '&:hover': {
                            cursor: props.isRejected ? null : 'pointer'
                        }
                    }}
                >
                    <Box
                        sx={{
                            mt: 2,
                            height: 400,
                            backgroundImage: `url(${imageLink})`,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderRadius: '8px 0 0 8px',
                            ml: 2,
                            backgroundColor: props.item.itemType === 'text' ? colors.darkGray : null
                        }}
                    >
                        <Typography
                            variant='h5'
                            sx={{
                                m: 'auto',
                                width: '60%',
                                textAlign: 'center',
                                fontFamily: 'Open Sans',
                                color: colors.white,
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                borderRadius: 2,
                                p: 3
                            }}
                        >
                            {props.item.title}
                        </Typography>
                        {
                            props.item.itemType === 'text' ?
                                <TextFieldsIcon
                                    sx={{
                                        color: colors.white,
                                        fontSize: 50
                                    }}
                                /> : null
                        }
                        {
                            props.item.ogVideoLink ?
                                <PlayArrowIcon
                                    sx={{
                                        position: 'absolute',
                                        zIndex: 1299,
                                        mt: 40,
                                        borderRadius: 8,
                                        fontSize: 50,
                                        color: colors.white,
                                        border: `solid 3px ${colors.white}`
                                    }}
                                /> : null
                        }
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={1.5}
                    sx={{
                        mt: 2,
                        pt: 1,
                        backgroundColor: colors.white,
                        border: `${colors.lightGray} solid 1px`,
                        borderRadius: '0 8px 8px 0',
                        textAlign: 'center'
                    }}
                >
                    <List
                        sx={{
                            width: '100%'
                        }}
                    >
                        {
                            !props.editState ?
                                barIcons.map((icon, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <ListItem
                                                sx={{
                                                    my: 4,
                                                    p: 0,
                                                    width: '100%',
                                                    display: 'block',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        width: '100%',
                                                        textAlign: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    {icon[0]}
                                                </ListItemIcon>
                                                <Typography
                                                    variant='caption'
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        width: '100%',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {icon[1]}
                                                </Typography>
                                            </ListItem>
                                        </Fragment>
                                    )
                                }) :
                                !props.isRejected ?
                                    <MoreVertIcon
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setEditPopoverOpen(e.currentTarget)
                                        }}
                                        sx={{
                                            color: theme.palette.grey['600'],
                                            my: 3,
                                            p: 1,
                                            '&:hover': {
                                                cursor: 'pointer',
                                                color: theme.palette.primary.main,
                                                backgroundColor: theme.palette.grey['300'],
                                                borderRadius: 8
                                            }
                                        }}
                                    /> : null
                        }
                    </List>
                </Grid>
            </Grid>

            {/* Admin Options */}
            <Popover
                open={!!editPopoverOpen}
                anchorEl={editPopoverOpen}
                onClose={() => setEditPopoverOpen(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Stack>
                    <Typography
                        onClick={deleteItem}
                        sx={{
                            p: 2,
                            fontFamily: 'Open Sans',
                            '&:hover': {
                                color: theme.palette.primary.main,
                                cursor: 'pointer'
                            },
                            '&:active': {
                                color: theme.palette.secondary.main
                            }
                        }}
                    >
                        Remove
                    </Typography>
                </Stack>
            </Popover>
        </Box>
    )
}