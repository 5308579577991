export default function useGetHeadCurator() {
    const getHeadCurator = (collection) => {
        if (collection && collection.curators.length) {
            const headCurator = collection.curators.filter((curator) => {
                return curator.isHeadCurator === 1;
            })[0];

            return headCurator;
        } else {
            return {};
        }
    };

    return getHeadCurator;
}