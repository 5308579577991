import {
    useEffect
} from "react";
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import {
    useSelector
} from "react-redux";

// LEGACY
import About from "./legacy-pages/about/about";
import Terms from "./legacy-pages/terms/terms";
import Rules from "./legacy-pages/rules/rules";
import FAQ from "./legacy-pages/faq/faq";
import WhatIsSlyke from "./legacy-pages/what-is-slyke/what-is-slyke";

// NEW
import LandingPage from "./pages/Landing/Landing";
import CollectionOverviewPage from "./pages/CollectionOverview/CollectionOverview";
import ItemView from "./pages/ItemView/ItemView";
import BrowseCollectionsPage from "./pages/BrowseCollections/BrowseCollections";
import UserProfilePage from "./pages/UserProfile/UserProfile";

import {
    getUserData
} from "./store/user/userSlice";

import useCheckTokenValidity from "./hooks/data/useCheckTokenValidity";

export default function RouteSetup() {
    const { token } = useSelector(getUserData);

    const checkTokenValidity = useCheckTokenValidity();

    useEffect(() => {
        checkTokenValidity(token);
    }, []);

    useEffect(() => {
        checkTokenValidity(token);
    });

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<LandingPage />} />
                <Route path='/item' element={<ItemView />} />
                <Route path='/browse-collections' element={<BrowseCollectionsPage />} />
                <Route path='/collection' element={<CollectionOverviewPage />} />
                <Route path='/about' element={<About />} />
                <Route path='/profile' element={<UserProfilePage />} />
                <Route path='/terms' element={<Terms />} />
                <Route path='/rules' element={<Rules />} />
                <Route path='/faq' element={<FAQ />} />
                <Route path='/what-is-slyke' element={<WhatIsSlyke />} />
            </Routes>
        </BrowserRouter>

    );
}