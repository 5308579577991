import { Card, Stack, Typography, useTheme } from "@mui/material";

export default function UserStats(props) {
    const {
        followingCount,
        itemsPostedCount
    } = props;

    const theme = useTheme();

    return (
        <Card
            variant="outlined"
            sx={{
                my: 2,
                mx: 0.5,
                py: 1
            }}
        >
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                    mx: "auto"
                }}
            >

                <Typography
                    variant="body2"
                >
                    {followingCount}
                </Typography>
                <Typography
                    variant="caption"
                    sx={{
                        color: theme.palette.grey["500"],
                        ml: 1,
                        mr: 1,
                        mt: 0.25
                    }}
                >
                    Collections Followed
                </Typography>

                <Typography
                    variant="body2"
                >
                    {itemsPostedCount}
                </Typography>
                <Typography
                    variant="caption"
                    sx={{
                        color: theme.palette.grey["500"],
                        ml: 1,
                        mt: 0.25
                    }}
                >
                    Items Posted
                </Typography>
            </Stack>
        </Card>
    );
}