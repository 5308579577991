import { useTheme } from "@emotion/react";
import {
    Card,
    Grid,
    Typography
} from "@mui/material";
import ForwardSharpIcon from "@mui/icons-material/ForwardSharp";
import useNavigateToCollection from "../../../../../../hooks/navigation/useNavigateToCollection";

export default function ViewCollectionCTA(props) {
    const {
        collectionId
    } = props;

    const theme = useTheme();
    const navigateToCollection = useNavigateToCollection();

    return (
        <Card
            onClick={() => navigateToCollection(collectionId)}
            variant="outlined"
            sx={{
                p: 1,
                my: 3,
                mx: "auto",
                height: 250,
                width: 250,
                backgroundColor: theme.palette.secondary.main,
                borderRadius: "16px"
            }}
        >
            <Grid
                container
                sx={{
                    height: "100%"
                }}
            >
                <Grid
                    item
                    xs={9}
                    textAlign="center"
                    alignSelf="center"
                >
                    <Typography
                        variant="h5"
                        color={theme.palette.common.white}
                    >
                        VIEW FULL COLLECTION
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={3}
                    textAlign="center"
                    alignSelf="center"
                >
                    <ForwardSharpIcon
                        sx={{
                            my: "auto",
                            fontSize: 40,
                            ml: 2,
                            color: theme.palette.common.white
                        }}
                    />
                </Grid>
            </Grid>
        </Card>
    );
}