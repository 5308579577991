import {
    Stack,
    useTheme
} from "@mui/material";

import PrimaryButton from "../../PrimaryButton/PrimaryButton";

export default function DesktopFeedButtons(props) {
    const {
        feeds,
        activeFeed,
        setActiveFeed
    } = props;

    const theme = useTheme();

    return (
        <Stack
            direction="row"
            sx={{
                mt: 4
            }}
        >
            {
                feeds.map((feed) => {
                    return (
                        <PrimaryButton
                            key={`feed-${feed.feedName}`}
                            text={feed.feedName}
                            onClick={() => { setActiveFeed(feed.feedName); }}
                            sx={{
                                "&:hover": {
                                    color: theme.palette.common.white,
                                    backgroundColor: theme.palette.grey["900"]
                                },
                                my: 2,
                                mx: 2,
                                px: 4,
                                backgroundColor: activeFeed === feed.feedName ?
                                    theme.palette.primary.light : theme.palette.grey["400"],
                                color: activeFeed === feed.feedName ?
                                    theme.palette.primary.main : theme.palette.grey["800"]
                            }}
                            borderRadius={6}
                        />
                    );
                })
            }
        </Stack>
    );
}