import { Fragment } from "react";
import { Tooltip, useTheme } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";

export default function Curator(props) {
    const {
        editState,
        isMobile
    } = props;

    const theme = useTheme();

    return (
        <Fragment>
            <Tooltip title='edit items'>
                <EditIcon
                    onClick={() => { }}
                    sx={{
                        pl: !isMobile ? 3 : 1,
                        mt: 3,
                        "&:hover": {
                            color: theme.palette.primary.main,
                            cursor: "pointer"
                        },
                        "&:active": {
                            color: theme.palette.secondary.main
                        },
                        color: editState ? theme.palette.secondary.main : theme.palette.common.black
                    }}
                />
            </Tooltip>
            <Tooltip title='collection settings'>
                <SettingsIcon
                    onClick={() => { }}
                    sx={{
                        pl: 1,
                        mt: 3,
                        "&:hover": {
                            color: theme.palette.primary.main,
                            cursor: "pointer"
                        },
                        "&:active": {
                            color: theme.palette.secondary.main
                        }
                    }}
                />
            </Tooltip>
        </Fragment>
    );
}