import {
    Stack, useTheme
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

export default function ItemCarouselIcons(props) {
    const {
        previewItemCount,
        itemPreviewIndex
    } = props;

    const theme = useTheme();

    return (
        <Stack
            direction='row'
            justifyContent="center"
            sx={{
                mt: 1
            }}
        >
            {
                [...Array(previewItemCount), "cta"].map((_, index) => {
                    return (
                        <CircleIcon
                            key={`circle-icon-${index}`}
                            sx={{
                                color: itemPreviewIndex === index ? theme.palette.secondary.main : theme.palette.secondary.light,
                                fontSize: 16,
                                mx: 1
                            }}
                        />
                    );
                })
            }
        </Stack>
    );
}