import { useState } from "react";
import { Box, Typography } from "@mui/material";
import axios from "axios";

import BaseModal from "../../../BaseModal/BaseModal";
import CloseButton from "../../CloseButton/CloseButton";
import PasswordForm from "../../PasswordForm/PasswordForm";

import useHandleLogin from "../../../../../hooks/data/useHandleLogin";

import apiRoutes from "../../../../../constants/api-routes";
import Username from "../../Username/Username";
import useLoadingModal from "../../../../../hooks/state/useLoadingModal";

export default function ForgotPassword(props) {
    const {
        isMobile,
        forgotPassword,
        close,
        forgotPasswordCode
    } = props;

    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [username, setUsername] = useState("");
    const [isUsernameValid, setIsUsernameValid] = useState("");

    const handleLogin = useHandleLogin();
    const {
        loading,
        setLoading,
        LoadingModal
    } = useLoadingModal();

    const handleSubmit = async () => {
        setLoading(true);
        let changeResponse = await axios.post(apiRoutes.confirmResetPassword, {
            username: username,
            password: password,
            code: forgotPasswordCode
        }).catch(() => {
            alert("Something went wrong!");
        });

        if (changeResponse.status === 200) {
            handleLogin(
                {
                    username,
                    password,
                    staySignedIn: false
                },
                () => { },
                () => { },
                () => { },
                () => {
                    close();
                    setLoading(false);
                }
            );
        }
    };

    return (
        <BaseModal
            open={forgotPassword}
            onClose={close}
            sx={{
                width: !isMobile ? 400 : 300,
                overflowY: isMobile ? "scroll" : null
            }}
        >
            <Box
                sx={{
                    p: 4,
                    pl: !isMobile ? 6 : 2
                }}
            >
                <Typography
                    variant='h6'
                    sx={{
                        fontFamily: "Open Sans",
                        borderBottom: "1px solid black",
                        width: !isMobile ? 250 : 200,
                        fontWeight: "bold",
                        mb: !isMobile ? 3 : 1
                    }}
                >
                    Complete Password Reset
                </Typography>
                {
                    !isMobile ?
                        <Typography
                            variant='body2'
                            sx={{
                                width: 300,
                                mb: !isMobile ? 3 : 1
                            }}
                        >
                            Create a new password for your account.
                        </Typography> : null
                }

                <Username
                    isMobile={isMobile}
                    username={username}
                    setUsername={setUsername}
                    isUsernameValid={isUsernameValid}
                    setIsUsernameValid={setIsUsernameValid}
                />
                <PasswordForm
                    isMobile={isMobile}
                    username={username}
                    setUsername={setUsername}
                    password={password}
                    setPassword={setPassword}
                    repeatPassword={repeatPassword}
                    setRepeatPassword={setRepeatPassword}
                    handleSubmit={handleSubmit}
                />
            </Box>

            <LoadingModal
                open={loading}
                onClose={() => setLoading(false)}
            />

            <CloseButton
                isMobile={isMobile}
                onClose={close}
            />
        </BaseModal>
    );
}