import { Box, Typography, useTheme } from "@mui/material";

export default function ItemTitle(props) {
    const {
        itemTitle,
        onClick
    } = props;

    const theme = useTheme();

    return (
        <Box>
            <Typography
                onClick={onClick}
                sx={{
                    color: theme.palette.grey["900"],
                    fontWeight: "bolder",
                    mb: 1,
                    maxWidth: 300,
                    minWidth: 200
                }}
            >
                {itemTitle}
            </Typography>
        </Box>
    );
}