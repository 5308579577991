import { useDispatch } from "react-redux";
import axios from "axios";

import apiRoutes from "../../constants/api-routes";
import {
    setDisplayName,
    setHandle,
    setIdToken,
    setIsLoggedIn,
    setRefreshToken, setToken, setUserId, setUsername
} from "../../store/user/userSlice";

export default function useHandleLogin() {
    /*
    
    This hook handles logging in a user.

    userData: contains username, password, and whether the user wishes to remain logged in after leaving site
    onError: callback function to handle login errors
    onForPasswordChange: callback function to handle forced password changes
    onForgotPassword: callback function to handle users taht have attempted to reset password
    onSuccess: callback function to handle successful login
    
    */
    const dispatch = useDispatch();

    const handleLogin = async (userData, onError, onForcePasswordChange, onForgotPassword, onSuccess) => {
        const {
            username,
            password,
            staySignedIn
        } = userData;

        let loginResponse = await axios.post(apiRoutes.login, {
            username: username,
            password: password
        }).catch((e) => {
            if (e.response.status === 401 && e.response.data.challengeName === "NEW_PASSWORD_REQUIRED") {
                onForcePasswordChange();
            } else if (e.response.data.challengeName === "FORGOT_PASSWORD") {
                onForgotPassword();
            } else {
                onError();
            }

            return;
        });

        if (loginResponse.data) {
            if (staySignedIn) {
                dispatch(setRefreshToken(loginResponse.data.refreshToken));
                dispatch(setToken(loginResponse.data.token));
                dispatch(setIdToken(loginResponse.data.idToken));
                dispatch(setUserId(loginResponse.data.userId));
                dispatch(setUsername(loginResponse.data.username));
                dispatch(setDisplayName(loginResponse.data.displayName));
                dispatch(setHandle(loginResponse.data.handle));
                dispatch(setIsLoggedIn(true));
            } else {
                dispatch(setToken(loginResponse.data.token));
                dispatch(setIdToken(loginResponse.data.idToken));
                dispatch(setUserId(loginResponse.data.userId));
                dispatch(setUsername(loginResponse.data.username));
                dispatch(setDisplayName(loginResponse.data.displayName));
                dispatch(setHandle(loginResponse.data.handle));
                dispatch(setIsLoggedIn(true));
            }

            onSuccess();
        }
    };

    return handleLogin;
}