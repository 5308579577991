import { Box, CircularProgress, Modal } from '@mui/material'

export default function LoadingModal(props) {
    return (
        <Modal
            open={props.open || false}
            onClose={() => {
                props.close()
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <CircularProgress
                    size={80}
                />
            </Box>
        </Modal>
    )
}