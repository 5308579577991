import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    Typography,
    Link,
    ListItemText,
    useTheme
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import SlykeLogo from "./SlykeLogo/SlykeLogo";

export default function DesktopSideColumn(props) {
    const {
        close,
        openSignIn
    } = props;

    const theme = useTheme();

    return (
        <Grid
            item
            xs
            sx={{
                backgroundImage: "linear-gradient(to bottom, #FF7300, #0033FF)",
                p: 2,
                borderRadius: "0 19px 19px 0",
                display: {
                    xs: "none",
                    sm: "block"
                }
            }}
        >
            <Typography
                sx={{
                    mt: 7,
                    mb: 2,
                    color: theme.palette.common.white,
                    fontSize: 18
                }}>
                Slyke is where everyone works together to gather and organize specific clusters of content.
            </Typography>

            <Box
                sx={{
                    mt: 2
                }}
            >
                <List
                    dense
                    sx={{
                        ml: -1
                    }}
                >
                    {
                        [
                            "Create unique collections",
                            "Invite others to contribute",
                            "Add to Existing collections",
                            "Explore others’ collections"
                        ].map((text) => {
                            return (
                                <ListItem
                                    key={text}
                                    sx={{
                                        py: 0
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: 1,
                                            pb: 0.2
                                        }}
                                    >
                                        <CircleIcon
                                            sx={{
                                                fontSize: 6,
                                                color: theme.palette.common.white
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        align='left'
                                        sx={{
                                            "& .MuiTypography-root": {
                                                fontSize: 14,
                                                fontFamily: "Open Sans",
                                            },
                                            m: 0,
                                            mb: 0.2,
                                            color: theme.palette.common.white,
                                        }}
                                    >
                                        {text}
                                    </ListItemText>
                                </ListItem>
                            );
                        })
                    }
                </List>
            </Box>

            <SlykeLogo />
        </Grid>
    );
}