import { useNavigate } from "react-router-dom";

export default function useNavigateToProfile() {
    const navigate = useNavigate();

    let navigateToProfile = null;
    if (window.location.href.includes("/profile")) {
        navigateToProfile = (userId, filter) => {
            if (!filter) {
                window.location.href = `/profile?userId=${userId}`;
            } else {
                window.location.href = `/profile?userId=${userId}&filter=${filter}`;
            }
        };
    } else {
        navigateToProfile = (userId, filter) => {
            if (!filter) {
                navigate(`/profile?userId=${userId}`);
            } else {
                navigate(`/profile?userId=${userId}&filter=${filter}`);
            }
        };
    }

    return navigateToProfile;
}