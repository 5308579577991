import {
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import {
    Fragment,
    useRef,
    useState
} from "react";
import FeedIcon from "@mui/icons-material/Feed";


export default function MobileFeedButtons(props) {
    const {
        feeds,
        activeFeed,
        setActiveFeed
    } = props;

    const theme = useTheme();
    const ref = useRef();
    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <Stack
                direction="row"
                sx={{
                    mt: 4,
                }}
            >
                <IconButton
                    ref={ref}
                    text="Feeds"
                    onClick={() => setOpen(true)}
                    sx={{
                        "&:hover": {
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.grey["900"]
                        },
                        mx: 2,
                        backgroundColor: theme.palette.grey["300"],
                        color: theme.palette.grey["800"]
                    }}
                >
                    <FeedIcon />
                </IconButton>

                <Typography
                    variant="body2"
                    sx={{
                        mt: 1.25,
                        textOverflow: "ellipsis"
                    }}
                >
                    Viewing:
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        mt: 1.25,
                        ml: 0.5,
                        color: theme.palette.primary.main
                    }}
                >
                    {activeFeed}
                </Typography>
            </Stack>

            <Menu
                id="basic-menu"
                open={open}
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
            >
                {
                    feeds.map((feed) => {
                        return (
                            <MenuItem
                                key={`feed-${feed.feedName}`}
                                onClick={() => {
                                    setActiveFeed(feed.feedName);
                                    setOpen(false);
                                }}
                                sx={{
                                    backgroundColor: activeFeed === feed.feedName ?
                                        theme.palette.primary.light : null,
                                    color: activeFeed === feed.feedName ?
                                        theme.palette.primary.main : null
                                }}
                            >
                                {feed.feedName}
                            </MenuItem>
                        );
                    })
                }
            </Menu>
        </Fragment>
    );
}