import { useSelector } from "react-redux";
import { getUserData } from "../../store/user/userSlice";

export default function useIsCollectionCurator(curators) {
    /* 
    
    This hook takes the given array of collection curators 
    and returns whether the logged in user is one of them.

    */

    let isCurator = false;

    const {
        userId
    } = useSelector(getUserData);

    if (userId && curators) {
        const matches = curators.filter((curator) => curator.id === userId);
        isCurator = matches.length === 1 ? true : false;
    }

    return isCurator;
}