import { Fragment } from "react";
import {
    Link,
    Typography,
    useTheme
} from "@mui/material";
import OpenInFull from "@mui/icons-material/OpenInFull";

import useCollectionCreditsModal from "../../../../hooks/state/useCollectionCreditsModal";
import useNavigateToProfile from "../../../../hooks/navigation/useNavigateToProfile";

export default function CuratedBy(props) {
    const {
        headCuratorDisplayName,
        headCuratorId,
        isMobile,
        curators,
        creatorId
    } = props;

    const theme = useTheme();

    const {
        isCollectionCreditsModalOpen,
        openCollectionCreditsModal,
        closeCollectionCreditsModal,
        CollectionCreditsModal
    } = useCollectionCreditsModal();
    const navigateToProfile = useNavigateToProfile();

    return (
        <Fragment>
            <Typography
                variant='body1'
                align={!isMobile ? "right" : "center"}
                sx={{
                    fontFamily: "Open Sans",
                    mb: isMobile ? 1 : null
                }}
            >
                Curated By&nbsp;
                <Link
                    onClick={() => navigateToProfile(headCuratorId)}
                    sx={{
                        display: "inline",
                        "&:hover": {
                            cursor: "pointer"
                        }
                    }}
                >
                    {headCuratorDisplayName}
                </Link>
                <OpenInFull
                    onClick={openCollectionCreditsModal}
                    sx={{
                        fontSize: 15,
                        verticalAlign: "center",
                        color: "#94908f",
                        ml: 1,
                        transform: "rotate(90deg)",
                        "&:hover": {
                            color: theme.palette.primary.main,
                            cursor: "pointer"
                        },
                        "&:active": {
                            color: theme.palette.secondary.main
                        }
                    }}
                />
            </Typography>

            <CollectionCreditsModal
                open={isCollectionCreditsModalOpen}
                close={closeCollectionCreditsModal}
                curators={curators}
                creatorId={creatorId}
                isMobile
            />
        </Fragment>
    );
}