import {
    Box
} from "@mui/material";
import ReactPlayer from "react-player";

export default function UploadedVideoItemBody(props) {
    const {
        videoUrl
    } = props;

    return (
        <Box
            sx={{
                height: 300
            }}
        >
            <ReactPlayer
                url={videoUrl}
                light
                pip
                controls={true}
                width='100%'
                height={300}
                style={{
                    borderRadius: "16px",
                    overflow: "hidden"
                }}
            />
        </Box>
    );
}