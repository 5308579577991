import { useDispatch } from "react-redux";

import {
    setIsLoggedIn,
    setDisplayName,
    setHandle,
    setToken,
    setUserId,
    setUsername,
    setRefreshToken,
    setIdToken
} from "../../store/user/userSlice";

export default function useLogout() {
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(setIsLoggedIn(false));
        dispatch(setDisplayName(null));
        dispatch(setHandle(null));
        dispatch(setToken(null));
        dispatch(setUserId(null));
        dispatch(setUsername(null));
        dispatch(setRefreshToken(null));
        dispatch(setIdToken(null));
    };

    return logout;
}