import {
    Button, useTheme
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import {
    useNavigate
} from "react-router-dom";

export default function BrowseCollectionsLink() {
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <Button
            color='secondary'
            onClick={() => navigate("/browse-collections")}
            sx={{
                textTransform: "none",
                fontSize: 16,
                borderRadius: 6,
                "&:hover": {
                    backgroundColor: theme.palette.grey["800"],
                    color: theme.palette.common.white
                }
            }}
        >
            <LanguageIcon
                sx={{
                    fontSize: 24,
                    mr: 1,
                }}
            />
            Browse Collections
        </Button>
    );
}