import {
    Fragment
} from "react";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useTheme
} from "@mui/material";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import useNavigateToProfile from "../../../../hooks/navigation/useNavigateToProfile";

export default function LoggedInBody(props) {
    const {
        userId,
        setShowAlert,
        setIsSidebarOpen
    } = props;

    const theme = useTheme();

    const navigateToProfile = useNavigateToProfile();

    const bottomIconStyle = {
        color: theme.palette.primary.main
    };

    const handleListStyle = (listItem) => {

        if (Object.keys(listItem).length) {
            return {
                "&:hover": {
                    cursor: "pointer",
                    border: `${theme.palette.grey["300"]} solid 1px`,
                    borderRadius: 8,
                    width: "90%"
                },
                "&:active": {
                    cursor: "pointer",
                    border: `${theme.palette.grey["300"]} solid 1px`,
                    backgroundColor: theme.palette.grey["300"],
                    borderRadius: 8,
                    width: "90%"
                }
            };
        } else {
            return {};
        }
    };

    const listItems = [
        {
            icon: <PeopleOutlineOutlinedIcon />,
            text: "Collections I Follow",
            onClick: () => {
                navigateToProfile(userId);
                setIsSidebarOpen(false);
            }
        },
        {
            icon: <AdminPanelSettingsOutlinedIcon />,
            text: "Collections I Curate",
            onClick: () => {
                navigateToProfile(userId, "curated%20collections");
                setIsSidebarOpen(false);
            }
        },
        {
            icon: <BookmarkBorderIcon />,
            text: "Bookmarked Items",
            onClick: () => {
                navigateToProfile(userId, "bookmarked%20items");
                setIsSidebarOpen(false);
            }
        },
        {},
        {
            icon: <PersonOutlineOutlinedIcon sx={{ ...bottomIconStyle }} />,
            text: "My Slyke",
            color: theme.palette.primary.main,
            onClick: () => {
                navigateToProfile(userId);
                setIsSidebarOpen(false);
            }
        },
        {
            icon: <EmailOutlinedIcon sx={{ ...bottomIconStyle }} />,
            text: "Messages",
            color: theme.palette.primary.main,
            onClick: () => { setShowAlert(true); }
        },
        {
            icon: <NotificationsActiveOutlinedIcon sx={{ ...bottomIconStyle }} />,
            text: "Notifications",
            color: theme.palette.primary.main,
            onClick: () => { setShowAlert(true); }
        },
        {
            icon: <SettingsOutlinedIcon sx={{ ...bottomIconStyle }} />,
            text: "Settings",
            color: theme.palette.primary.main,
            onClick: () => { setShowAlert(true); }
        }
    ];

    const renderList = () => {
        return listItems.map((listItem, ind) => {
            return (
                <ListItem
                    onClick={listItem.onClick}
                    key={`side-drawer-${ind}`}
                    sx={
                        handleListStyle(listItem)
                    }
                >
                    <ListItemIcon>
                        {listItem.icon}
                    </ListItemIcon>
                    <ListItemText
                        sx={{
                            "& .MuiTypography-root": {
                                fontFamily: "Open Sans",
                                color: listItem.color ? listItem.color : theme.palette.grey["800"]
                            }
                        }}
                    >
                        {listItem.text}
                    </ListItemText>
                </ListItem>
            );
        });
    };

    return (
        <Fragment>
            <List
                sx={{
                    mt: 3
                }}
            >
                {renderList()}
            </List>
        </Fragment>
    );
}