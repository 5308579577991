import { Box, Typography } from '@mui/material'
import Footer from '../../legacy-components/footer/footer'
import Navbar from '../../components/Navbar/Navbar'

export default function Rules() {
    return (
        <Box
            sx={{
                minWidth: 1280
            }}
        >
            <Navbar
                search
            />
            <Box
                sx={{
                    mt: 16,
                    width: 800,
                    mx: 'auto'
                }}
            >
                <Typography
                    variant='h4'
                    align='center'
                    mb={4}
                >
                    Slyke Content Policy
                </Typography>

                <Typography
                    align='center'
                    my={2}
                >
                    Slyke is a vast network of collections that are created, run, and populated by you, the Slyke users.
                </Typography>
                <Typography
                    align='center'
                    my={2}
                >
                    Through these collections, you can post, comment, chat, learn and connect with people who share your interests, and we encourage you to find—or even activate—your interests on Slyke.
                </Typography>
                <Typography
                    align='center'
                    my={2}
                >
                    Everyone on Slyke should have an expectation of privacy and safety, so please respect the privacy and safety of others. Every collection on Slyke is defined by its curators. These users help manage the collection.
                </Typography>
                <Typography
                    align='center'
                    my={2}
                >
                    Slyke is what we make of it together, and it can all only exist if we operate by a shared set of rules. We ask that you abide by not just the letter of these rules, but the spirit as well.
                </Typography>

                <Typography
                    variant='h5'
                    align='center'
                    my={4}
                >
                    Rules
                </Typography>

                <Typography
                    align='center'
                    my={2}
                >
                    <strong>Rule 1</strong> - Remember the human. Slyke is a place for working together to curate content and connect and discuss it, not for attacking marginalized or vulnerable groups of people. Everyone has a right to use Slyke free of harassment, bullying, and threats of violence. Users that incite violence or that promote hate based on identity or vulnerability will be banned.
                </Typography>

                <Typography
                    align='center'
                    my={2}
                >
                    <strong>Rule 2</strong> - Post authentic content into collections where you have a personal interest, and do not cheat or engage in content manipulation (including spamming, vote manipulation, ban evasion, or subscriber fraud) or otherwise interfere with or disrupt Slyke collections.
                </Typography>

                <Typography
                    align='center'
                    my={2}
                >
                    <strong>Rule 3</strong> - Respect the privacy of others. Instigating harassment, for example by revealing someone’s personal or confidential information, is not allowed. Never post or threaten to post intimate or sexually-explicit media of someone without their consent.
                </Typography>

                <Typography
                    align='center'
                    my={2}
                >
                    <strong>Rule 4</strong> - Do not post or encourage the posting of sexual or suggestive content involving minors.
                </Typography>

                <Typography
                    align='center'
                    my={2}
                >
                    <strong>Rule 5</strong> - You don’t have to use your real name to use Slyke, but don’t impersonate an individual or an entity in a misleading or deceptive manner.
                </Typography>

                <Typography
                    align='center'
                    my={2}
                >
                    <strong>Rule 6</strong> - Ensure people have predictable experiences on Slyke by properly labeling content and communities, particularly content that is graphic, sexually-explicit, or offensive.
                </Typography>

                <Typography
                    align='center'
                    my={2}
                >
                    <strong>Rule 7</strong> - Keep it legal, and avoid posting illegal content or soliciting or facilitating illegal or prohibited transactions.
                </Typography>

                <Typography
                    align='center'
                    my={2}
                >
                    <strong>Rule 8</strong> - Don’t break the site or do anything that interferes with normal use of Slyke.
                </Typography>

                <Typography
                    variant='h5'
                    align='center'
                    my={4}
                >
                    Enforcement
                </Typography>

                <Typography
                    my={2}
                >
                    We have a variety of ways of enforcing our rules, including, but not limited to:
                </Typography>

                <ul>
                    <li>
                        <Typography
                            my={2}
                        >
                            Asking you nicely to knock it off
                        </Typography>
                    </li>

                    <li>
                        <Typography
                            my={2}
                        >
                            Asking you less nicely
                        </Typography>
                    </li>

                    <li>
                        <Typography
                            my={2}
                        >
                            Temporary or permanent suspension of accounts
                        </Typography>
                    </li>

                    <li>
                        <Typography
                            my={2}
                        >
                            Removal of privileges from, or adding restrictions to, accounts such revoking curation capability and messaging
                        </Typography>
                    </li>

                    <li>
                        <Typography
                            my={2}
                        >
                            Removal of content
                        </Typography>
                    </li>

                    <li>
                        <Typography
                            my={2}
                        >
                            Banning of Slyke collections
                        </Typography>
                    </li>

                </ul>
            </Box>
            <Footer />
        </Box>
    )
}