import {
    Grid,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import Share from '../../assets/icons/share.svg'
import CelebrationIcon from '@mui/icons-material/Celebration'
import colors from '../../constants/colors'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'

export default function CollectionCreated(props) {
    const {
        isMobile
    } = props;

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    const textStyle = {
        fontFamily: 'Open Sans'
    }

    useEffect(() => {
        if (props.collectionId) {
            if (window.location.href.includes('/collection?')) {
                setSearchParams({
                    collectionId: props.collectionId,
                    collectionCreated: true
                })
            } else {
                navigate(`/collection?collectionId=${props.collectionId}&collectionCreated=true`)
            }
        }
    }, [])

    return (
        <Grid
            container
            sx={{
                px: 6
            }}
        >
            <Grid
                item
                xs={12}
                sx={{
                    textAlign: 'center',
                    mt: 2,
                    mb: 4
                }}
            >
                <Typography
                    variant='h5'
                    sx={{
                        ...textStyle,
                        fontWeight: 'bold'
                    }}
                >
                    All Set!
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}
                sx={{
                    mt: 2,
                    mb: 4
                }}
            >
                <Typography
                    variant='h6'
                    sx={{
                        ...textStyle
                    }}
                >
                    Your collection is live!
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}
                sx={{
                    mt: 2,
                    mb: 2
                }}
            >
                <Typography
                    variant='body1'
                    sx={{
                        ...textStyle
                    }}
                >
                    What&apos;s Next?
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}
                sx={{
                    mt: 2,
                    mb: 2
                }}
            >
                <List>
                    <ListItem sx={{ pl: 0 }}>
                        <ListItemIcon sx={{ minWidth: '32px' }}>
                            <AddIcon
                                sx={{
                                    borderRadius: 8,
                                    color: colors.darkGray
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText sx={{ '& .MuiTypography-root': { ...textStyle } }}>
                            Add Items and start building your collection.
                        </ListItemText>
                    </ListItem>
                    <ListItem sx={{ pl: 0 }}>
                        <ListItemIcon
                            sx={{
                                minWidth: 16,
                                pr: 1
                            }}
                        >
                            <img
                                src={Share}
                                style={{
                                    width: 16,
                                    height: 16,
                                    marginLeft: 4
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText sx={{ '& .MuiTypography-root': { ...textStyle } }}>
                            Share Your Collection and tell others to add items.
                        </ListItemText>
                    </ListItem>
                    <ListItem sx={{ pl: 0 }}>
                        <ListItemIcon sx={{ minWidth: '32px' }}>
                            <CelebrationIcon
                                sx={{
                                    borderRadius: 8,
                                    color: colors.darkGray
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText sx={{ '& .MuiTypography-root': { ...textStyle } }}>
                            Have fun!
                        </ListItemText>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    )
}


