import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import { TextField, useTheme } from '@mui/material'
import { useState, Fragment } from 'react'
import axios from 'axios'
import apiRoutes, { frontRoot } from '../../constants/api-routes'
import {
    getUserId,
    getUsername
} from '../../store/user/userSlice'
import { useSelector } from 'react-redux'

export default function ApplyToCurateModal(props) {
    const theme = useTheme()

    const userId = useSelector(getUserId)
    const username = useSelector(getUsername)

    const [firstStep, setFirstStep] = useState(true)
    const [message, setMessage] = useState(null)

    const modalBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            xs: '95%',
            sm: 660
        },
        bgcolor: 'background.paper',
        boxShadow: 24,
        py: 8,
        px: 1,
        m: 0,
        borderRadius: 5,
        '&:active': {
            outline: 'none'
        },
        '&:focus': {
            outline: 'none'
        }
    }

    const submitRequestDatabase = async () => {
        const collectionId = props.collection ? props.collection.collectionId : null
        await axios.post(apiRoutes.addPendingRequestToCurate, {
            headCuratorId: props.collection ? props.collection.curators.filter(curator => curator.isHeadCurator)[0].id : null,
            collectionId: props.collection ? props.collection.collectionId : null,
            curatorUsername: props.collection ? props.collection.curators.filter(curator => curator.isHeadCurator)[0].username : null,
            note: message,
            userProfile: `${frontRoot}/profile?userId=${userId}`,
            approveUrl: `${frontRoot}/collection?collectionId=${collectionId}&approveRequest=True&username=${username}`,
            rejectUrl: `${frontRoot}/collection?collectionId=${collectionId}&rejectRequest=True&username=${username}`,
            requestorUsername: username,
            collectionTitle: props.collection ? props.collection.collectionTitle : null
        }, {
            headers: {
                'authorization': `Bearer ${props.token}`
            }
        })
    }

    const submitRequest = async () => {
        if (props.token) {
            await submitRequestDatabase()
            props.getPendingRequestsToCurate()
        } else {
            props.setShowSignUp(true)
        }

        setFirstStep(true)
        setMessage(null)
        props.close()
    }

    return (
        <Modal
            open={props.open || false}
            onClose={() => {
                props.close()
            }}
        >
            <Box
                sx={modalBoxStyle}
            >
                {
                    firstStep ?
                        <Fragment>
                            <Typography
                                variant='h3'
                                sx={{
                                    fontFamily: theme.typography.openSans,
                                    pb: 1,
                                    mb: 2,
                                    mx: 'auto',
                                    width: 'fit-content',
                                    borderBottom: '3px solid black'
                                }}
                            >
                                Request to Curate
                            </Typography>
                            <Typography
                                variant='h6'
                                sx={{
                                    textAlign: 'center',
                                    fontFamily: theme.typography.openSans,
                                    fontWeight: 'bold',
                                    mb: 2
                                }}
                            >
                                What is a curator?
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    fontFamily: theme.typography.openSans,
                                    width: '75%',
                                    mb: 2,
                                    mx: 'auto'
                                }}
                            >
                                Curators review all items submitted to the collection by other users to check if they fit the collection. As a curator you can either &apos;Approve&apos; or &apos;Reject&apos; submitted items.
                            </Typography>
                            <Button
                                onClick={() => { setFirstStep(false) }}
                                variant='contained'
                                sx={{
                                    mt: 4,
                                    mx: 'auto',
                                    display: 'block',
                                    p: 1,
                                    px: 6,
                                    borderRadius: 8,
                                    backgroundColor: theme.palette.secondary.main,
                                    color: theme.palette.background.paper,
                                    fontFamily: theme.typography.openSans,
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    letterSpacing: 0.5,
                                    '&:hover': {
                                        cursor: 'pointer',
                                        backgroundColor: theme.palette.grey['300'],
                                        color: theme.palette.grey['700']
                                    }
                                }}
                            >
                                Continue
                            </Button>
                        </Fragment> :
                        <Fragment>
                            <Typography
                                variant='h4'
                                sx={{
                                    textAlign: 'center',
                                    fontFamily: theme.typography.openSans,
                                    mb: 3,
                                    mx: 'auto',
                                    width: '80%'
                                }}
                            >
                                Your Request to Curate will be sent to head curator
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    fontFamily: theme.typography.openSans,
                                    mb: 3
                                }}
                            >
                                We will tell you when your request is reviewed
                            </Typography>
                            <Typography
                                variant='subtitle2'
                                sx={{
                                    textAlign: 'center',
                                    fontFamily: theme.typography.openSans,
                                    fontStyle: 'italic',
                                    mx: 'auto'
                                }}
                            >
                                Add a note (Optional)
                            </Typography>
                            <Box
                                sx={{
                                    textAlign: 'center'
                                }}
                            >
                                <TextField
                                    placeholder='Tell the head curator why you want to curate this collection'
                                    multiline
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    minRows={5}
                                    sx={{
                                        width: '80%'
                                    }}
                                />
                            </Box>
                            <Button
                                onClick={submitRequest}
                                variant='contained'
                                sx={{
                                    mt: 3,
                                    mx: 'auto',
                                    display: 'block',
                                    p: 1,
                                    px: 6,
                                    borderRadius: 8,
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.background.paper,
                                    fontFamily: theme.typography.openSans,
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    letterSpacing: 0.5,
                                    '&:hover': {
                                        cursor: 'pointer',
                                        backgroundColor: theme.palette.grey['300'],
                                        color: theme.palette.grey['700']
                                    }
                                }}
                            >
                                Send Request
                            </Button>
                        </Fragment>
                }

                {/* Close Icon */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: {
                            xs: 9,
                            sm: 5
                        },
                        left: {
                            xs: 9,
                            sm: 5
                        },
                        margin: 'auto'
                    }}
                >
                    <IconButton
                        onClick={() => {
                            props.close()
                        }}
                        sx={{
                            m: 0,
                            p: 0
                        }}
                    >
                        <CloseIcon
                            sx={{
                                color: theme.palette.grey['700']
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>
        </Modal>
    )
}