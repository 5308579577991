import Curator from "./Curator/Curator";
import User from "./User/User";

export default function Engagement(props) {
    const {
        isCurator,
        isMobile
    } = props;

    if (isCurator) {
        return (
            <Curator
                isMobile={isMobile}
            />
        );
    } else {
        return (
            <User
                isMobile={isMobile}
            />
        );
    }
}