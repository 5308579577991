import {
    useState,
    Fragment
} from "react";
import {
    Menu
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

import UserMenuButton from "./UserMenuButton/UserMenuButton";
import UserMenuItem from "./UserMenuItem/UserMenuItem";

import useNavigateToProfile from "../../../hooks/navigation/useNavigateToProfile";
import useNavigateToFAQ from "../../../hooks/navigation/useNavigateToFAQ";
import useLogout from "../../../hooks/data/useLogout";

export default function UserMenu(props) {
    const {
        displayName,
        userId,
        isMobile
    } = props;

    const [menuOpen, setMenuOpen] = useState(false);
    const [anchor, setAnchor] = useState(false);

    const navigateToProfile = useNavigateToProfile();
    const navigateToFAQ = useNavigateToFAQ();
    const logout = useLogout();

    const menuElements = [
        { icon: <PersonIcon color="inherit" />, text: "My Slyke", onClick: () => navigateToProfile(userId) },
        { icon: <SettingsIcon color="inherit" />, text: "Settings" },
        { icon: <HelpOutlineOutlinedIcon color="inherit" />, text: "Help & Support", onClick: navigateToFAQ },
        { icon: <PowerSettingsNewIcon color="inherit" />, text: "Log Out", onClick: logout }
    ];

    return (
        <Fragment>
            <UserMenuButton
                setAnchor={setAnchor}
                setMenuOpen={setMenuOpen}
                displayName={displayName}
                isMobile={isMobile}
            />
            <Menu
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                anchorEl={anchor}
            >
                {
                    menuElements.map((el) => {
                        return (
                            <UserMenuItem
                                key={el.text}
                                text={el.text}
                                icon={el.icon}
                                setMenuOpen={setMenuOpen}
                                onClick={el.onClick}
                            />
                        );
                    })
                }
            </Menu>
        </Fragment>
    );
}