import {
    Box,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function Email(props) {
    const {
        isMobile,
        email,
        setEmail,
        isEmailValid,
        setIsEmailValid
    } = props;

    const validateEmail = (email) => {
        let result = email
            .toLowerCase()
            .match(
                // eslint-disable-next-line no-control-regex
                /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/
            );

        if (!result) {
            setIsEmailValid(false);
            return;
        }

        setIsEmailValid(true);
    };

    return (
        <Box>
            <Typography
                variant='body1'
                sx={{
                    fontWeight: "bold",
                    fontFamily: "Open Sans"
                }}
            >
                Email
                <Tooltip
                    title={
                        <Typography>
                            A valid email address. You will be sent a link to allow you to complete sign up.
                        </Typography>
                    }
                >
                    <InfoOutlinedIcon
                        fontSize="10"
                        sx={{
                            ml: 0.5,
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }}
                    />
                </Tooltip>
            </Typography>
            <TextField
                variant='outlined'
                placeholder="j.doe@email.com"
                type="text"
                value={email}
                error={!isEmailValid}
                onChange={(e) => {
                    setEmail(e.target.value);
                    validateEmail(e.target.value);
                }}
                sx={{
                    p: 0,
                    m: 0,
                    "& .MuiOutlinedInput-root": {
                        height: 35
                    },
                    mb: 2,
                    width: 300,
                    maxWidth: !isMobile ? null : "90%"
                }}
            />
        </Box>
    );
}