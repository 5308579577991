import {
    Box,
    InputAdornment,
    TextField,
    useTheme
} from "@mui/material";
import {
    useState
} from "react";

import SearchButton from "./SearchButton/SearchButton";
import useSearchCollections from "../../../../hooks/data/useSearchCollections";

export default function Search(props) {
    const {
        isMobile
    } = props;

    const theme = useTheme();

    const [searchString, setSearchString] = useState("");

    const searchCollections = useSearchCollections();

    return (
        <Box
            sx={{
                textAlign: "center"
            }}
        >
            <TextField
                value={searchString}
                autoComplete="off"
                type="text"
                onInput={(e) => setSearchString(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        searchCollections(searchString);
                    }
                }}
                placeholder={!isMobile ? "What are you looking for?" : "Search Collections"}
                sx={{
                    backgroundColor: theme.palette.common.white,
                    p: 0.75,
                    maxWidth: 500,
                    minWidth: 200,
                    width: "90%",
                    border: "1px solid black"
                }}
                variant="standard"
                inputProps={{
                    sx: {
                        "&::placeholder": {
                            color: theme.palette.grey["700"],
                            fontSize: 14,
                            opacity: 1
                        },
                    },
                }}
                InputProps={{
                    disableUnderline: true,
                    endAdornment:
                        <InputAdornment
                            position="end"
                        >
                            <SearchButton
                                onClick={() => searchCollections(searchString)}
                            />
                        </InputAdornment>
                }}
            />
        </Box>
    );
}