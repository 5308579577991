const validateDisplayName = (displayName) => {
    if (displayName.length < 2 || displayName.length > 35) {
        return false
    }

    if (displayName.match(/[a-zA-Z0-9 .-]+/)[0] !== displayName) {
        // regex allows display names to only have letters, numbers, spaces, periods, and hyphens
        return false
    }
    return true
}

export default validateDisplayName